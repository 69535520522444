import * as React from "react";
import { Panel } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../reducers/appReducer";

interface ICardState {
  showHelp: boolean;
  help: string;
}

interface ICardProps {
  title: string;
  help: string;
  loading: boolean;
  appState: any;
  children?: any;
  canToggle?: boolean;
  defaultOpen?: boolean;
  bsStyle?: string;
  showPermissionMessage?: boolean;
}

const defaultState: ICardState = {
  showHelp: false,
  help: "",
};

class Card extends React.Component<ICardProps, ICardState> {
  constructor(props: any){
    super(props);
    this.state = defaultState;
  }

  public componentDidMount(){
    const props: any = this.props;
    this.setState({  
      showHelp: false,
      help: props.help ? props.help : "",
    });
  }

  public componentWillReceiveProps(props: any){
    this.setState({
      help: props.help ? props.help : this.state.help,
    });
  }

  get title(){
    if(this.props.title === ""){
      return (<span />);
    }
    return this.props.title;
  }

  get help() {
    if(this.props.appState.showHelp && this.props.help && this.props.help !== ""){
      return (
        <div className="help-container">{this.props.help}</div>
      );
    } else {
      return (<span />);
    }
  }

  public render(){
    let canToggle = false;
    if(this.props.canToggle !== undefined){
      canToggle = this.props.canToggle;
    } else if(this.props.defaultOpen !== undefined && this.props.defaultOpen){
      canToggle = true;
    }
    return (
        <section>
          <Panel defaultExpanded={this.props.defaultOpen !== undefined ? this.props.defaultOpen : true} bsStyle={this.props.bsStyle !== undefined ? this.props.bsStyle : "default"}>
            {this.props.title && this.props.title !== "" && (
              <Panel.Heading>
                <Panel.Title componentClass="h3" toggle={canToggle} role="title">{this.title}</Panel.Title>
              </Panel.Heading>
            )}
            <Panel.Collapse>
              <Panel.Body>
                {this.props.showPermissionMessage && (
                  <div><strong>You do not have permission.</strong></div>
                )}
                {this.help}
                {this.props.loading && 
                  <div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />
                }
                {!this.props.loading &&  
                  this.props.children
                }
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </section>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Card);