import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";

interface IBeltStatsProps {
  appActions: any;
  history: any;
  users: any;
  beltId: number;
  beltName: string;
}

interface IBeltStatsState {
  loading: boolean;
  activeUsers: any;
  inactiveUsers: any;
}

class BeltStats extends React.Component<IBeltStatsProps, IBeltStatsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      activeUsers: [],
      inactiveUsers: []
    };

  }

  public componentDidMount(){
    this.calculate(this.props.users, this.props.beltId);
  }

  public componentWillReceiveProps(nextProps: any){
    this.calculate(nextProps.users, nextProps.beltId);
  }

  public render() {
    return (
      <Card title="Statistics" loading={this.state.loading} help="">
        <div className="row">
          <div className="col-md-12">
            <strong>Active Users ({this.state.activeUsers.length})</strong>
            <ul>
              {this.state.activeUsers.length === 0 &&
                (<li><strong>None</strong></li>)
              }
              {this.state.activeUsers.map((user: any) => {
                return (<li key={user.id}>{user.firstName} {user.lastName}</li>);
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <strong>Inactive Users ({this.state.inactiveUsers.length})</strong>
            <ul>
              {this.state.inactiveUsers.length === 0 &&
                (<li><strong>None</strong></li>)
              }
              {this.state.inactiveUsers.map((user: any) => {
                return (<li key={user.id}>{user.firstName} {user.lastName}</li>);
              })}
            </ul>
          </div>
        </div>
      </Card>
    );
  }

  private calculate(users: any, beltId: number){
    const active = [];
    const inactive = [];
    for(const u of users){
      if(u.role === "user" || u.role === ""){
        continue;
      }
      if(u.beltId === beltId){
        if(u.role === "inactive"){
          inactive.push(u);
        } else {
          active.push(u);
        }
      }
    }
    this.setState({activeUsers: active, inactiveUsers: inactive});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BeltStats) as React.ComponentType<any>);