import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";
import LazyLoad from "react-lazyload";
import { Modal } from "react-bootstrap";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";
import BarGraph from "../../structure/BarGraph";
import * as AppActions from "../../../reducers/appReducer";
import * as AlertActions from "../../../reducers/alertsReducer";
import { AlertsAPI } from "../../../API/";
import Alert from "./Alert";

interface IAlert {
  alert: string;
  body: string;
  created: moment.Moment;
  dismissed: boolean;
  dismissedBy: number;
  id: number;
  level: "info" | "critical" | "warning";
  schoolId: number;
  subject: string;
  userId: number;
}

interface IProcessedAlerts {
  info: IAlert[];
  warning: IAlert[];
  critical: IAlert[];
  chartData: any;
}

const defaultProcessedAlerts = {
  info: [],
  warning: [],
  critical: [],
  chartData: [
    {label: "Info", count: 0},
    {label: "Warning", count: 0},
    {label: "Critical", count: 0},
  ]
};

interface IAlertsScreenProps {
  appActions: any;
  alertActions: any;
  history: any;
  schoolState: any;
}

interface IAlertsScreenState {
  loading: boolean;
  alerts: IAlert[];
  alertsToShow: IAlert[];
  processedAlerts: IProcessedAlerts;
  selectedAlertLevel: "all" | "warning" | "info" | "critical";
  showDismissAllModal: boolean;
}

class AlertsScreen extends React.Component<IAlertsScreenProps, IAlertsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      alerts: [],
      alertsToShow: [],
      processedAlerts: defaultProcessedAlerts,
      selectedAlertLevel: "all",
      showDismissAllModal: false,
    };

    this.fetchAlerts = this.fetchAlerts.bind(this);
    this.processAlerts = this.processAlerts.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.selectAlertsToShow = this.selectAlertsToShow.bind(this);
    this.toggleAlertLevelSelect = this.toggleAlertLevelSelect.bind(this);
    this.toggleShowDismissAllModal = this.toggleShowDismissAllModal.bind(this);
    this.dismissAllAlerts = this.dismissAllAlerts.bind(this);
  }

  public componentDidMount(){
    this.fetchAlerts();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-1">
          <Card title="Open Alerts" loading={this.state.loading} help="Alerts are events that you hould be aware occurred, such as failed invoices, expiring contracts, or non-attendance for a student. You may view more information or delete the alert if you do not care about it.">
            <div className="form-group">
              <label>Showing Alerts with Level</label>
              <select className="form-control" id="selectedAlertLevel" value={this.state.selectedAlertLevel} onChange={this.toggleAlertLevelSelect}>
                <option value="all">All</option>
                <option value="info">Information</option>
                <option value="warning">Warning</option>
                <option value="critical">Critical</option>
              </select>
            </div>
            {this.state.alertsToShow.length === 0 && (<strong>None</strong>)}
            <div className="note-overflow-container" style={{height: 600}}>
            {this.state.alertsToShow.map((alert: any, index: number) => {
              return(
                <LazyLoad once={true} key={index} overflow={true} throttle={100} height={0}>
                    <Alert 
                      alert={alert}
                      className={index % 2 === 1 ? "list-row-odd" : ""}
                      onDismiss={this.handleDismiss}
                    />
                </LazyLoad>
              );
            })}
            </div>
            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-block btn-danger" style={{marginTop: 15}} onClick={this.toggleShowDismissAllModal}>Dismiss All Alerts</button>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4">
          <Card title="Open Alerts Count" loading={this.state.loading} help="">
            {this.state.alerts.length === 0 && (<strong>None</strong>)}
            <BarGraph data={{data: this.state.processedAlerts.chartData}} hideLegend={true} />
          </Card>
        </div>


        <Modal show={this.state.showDismissAllModal} onHide={this.toggleShowDismissAllModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              Are you absolutely sure you want to dismiss all open alerts for your school?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.dismissAllAlerts}>Yes, Dismiss All Alerts</button>
            <button className="btn btn-primary" onClick={this.toggleShowDismissAllModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }

  private async fetchAlerts(){
    this.setState({ loading: true }, async () => {
      try{
        const alertsRes = await AlertsAPI.getSchoolAlerts(this.props.schoolState.school.id, true, {});
        const alerts = alertsRes.body.data;
        const processedAlerts = this.processAlerts(alerts);

        this.setState({loading: false, alerts, processedAlerts}, () => {
          const alertsToShow = this.selectAlertsToShow();
          this.setState({alertsToShow});
          this.props.alertActions.setAlerts(alerts);
        });
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not fetch alerts!");
        });
      }
    });
  }

  private processAlerts(alerts: IAlert[]): IProcessedAlerts{
    const ret: any = {
      info: [],
      warning: [],
      critical: [],
      chartData: []
    };
    for(const a of alerts){
      if(a.level === "info"){
        ret.info.push(a);
      } else if(a.level === "warning"){
        ret.warning.push(a);
      } else if(a.level === "critical"){
        ret.critical.push(a);
      }
    }
    ret.chartData = [
      {label: "Info", count: ret.info.length},
      {label: "Warning", count: ret.warning.length},
      {label: "Critical", count: ret.critical.length},
    ];
    return ret;
  }

  private selectAlertsToShow(): IAlert[]{
    let alerts: IAlert[] = [];
    switch(this.state.selectedAlertLevel){
      case "warning":
        alerts = this.state.processedAlerts.warning;
        break;
      case "info":
        alerts = this.state.processedAlerts.info;
        break;
      case "critical":
        alerts = this.state.processedAlerts.critical;
        break;
      default:
        alerts = this.state.alerts;
        break;
    }
    return alerts;
  }

  private handleDismiss(alertId: number){
    this.setState({loading: true}, async () => {
      try{
        await AlertsAPI.dismissAlert(this.props.schoolState.school.id, alertId);
        // remove it from the store
        const alerts: IAlert[] = [];
        this.state.alerts.map((alert: IAlert) => {
          if(alert.id !== alertId){
            alerts.push(alert);
          }
        });
        const proc = this.processAlerts(alerts);
        this.setState({loading: false, alerts, processedAlerts: proc}, () => {
          this.props.alertActions.setAlerts(alerts);
        });
      }catch(err){
        error("We could not dismiss that alert. Make sure you have permission to do that.");
        this.setState({loading: false});
      }
    });
  }

  private toggleShowDismissAllModal(){
    this.setState({
      showDismissAllModal: !this.state.showDismissAllModal
    });
  }

  private toggleAlertLevelSelect(e: any){
    const newLevel = e.target.value;
    this.setState({ selectedAlertLevel: newLevel}, () => {
      const alertsToShow = this.selectAlertsToShow();
      this.setState({alertsToShow});
    });
  }

  private dismissAllAlerts(){
    this.setState({showDismissAllModal: false, loading: true}, async () => {
      try{
        await AlertsAPI.dismissAllAlerts(this.props.schoolState.school.id);
        this.setState({ alerts: [], alertsToShow: [], processedAlerts: defaultProcessedAlerts, loading: false});
      }catch(err){
        this.setState({loading: false});
        error("Could not dismiss all open alerts");
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    alertActions: bindActionCreators(AlertActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlertsScreen) as React.ComponentType<any>);