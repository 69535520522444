import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";
import { bindActionCreators } from "redux";

import * as AppActions from "../../reducers/appReducer";

import PlatformImage from "./PlatformImage";

interface IMenuProps {
  appState: any;
  schoolState: any;
  appActions: any;
}

interface IMenuState {
  open: boolean;
}

class Menu extends React.Component<IMenuProps, IMenuState> {
  constructor(props: any){
    super(props);
    const defaultState: IMenuState = {
      open: props.open ? props.open : true,
    };
    this.state = defaultState;

    this.toggleOpen = this.toggleOpen.bind(this);
    this.onTransition = this.onTransition.bind(this);
  }

  public componentWillReceiveProps(props: any){
    this.setState({
      ...props
    });
  }

  public toggleOpen(){
    this.setState({
      open: !this.state.open
    });
  }

  public onTransition(){
    this.props.appActions.toggleMenu();
  }


  get links(){
    const schoolId = this.props.schoolState.school.id;
    return (
      <div style={styles.sidebar}>
          <div style={styles.header}><PlatformImage preference="banner" /></div>
          <div style={styles.content}>

          <span style={styles.linkHeader}>School</span>
          <Link to="/dashboard" style={styles.sidebarLink} onClick={this.onTransition} id="menu_dashboard">Dashboard</Link>
          <Link to={`/schools/${schoolId}/users`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_students_list">Students</Link>
          <Link to={`/schools/${schoolId}/users/new`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_students_add">Add Student</Link>
          <Link to={`/schools/${schoolId}/families`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_families">Families</Link>
          <Link to={`/schools/${schoolId}/announcements`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_announcements">Announcements</Link>
          <Link to={`/schools/${schoolId}/finances`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_finances">Finances</Link>
          <Link to={`/schools/${schoolId}/schedule`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_schedule">Schedule</Link>
          <Link to={`/schools/${schoolId}/exams/templates`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_exam_templates">Exam Templates</Link>
          <Link to={`/schools/${schoolId}/sendMessage`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_send_message">Send Message</Link>
          <Link to={`/schools/${schoolId}/staff`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_staff">Manage Staff Page</Link>
          <div style={styles.divider} />


          <span style={styles.linkHeader}>Activities</span>
          <Link to={`/schools/${schoolId}/belts`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_setup_belts">Belts</Link>
          <Link to={`/schools/${schoolId}/materials`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_setup_knowledge">Knowledge</Link>
          <Link to={`/schools/${schoolId}/competitions`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_setup_competitions">Competitions</Link>
          <Link to={`/schools/${schoolId}/exams`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_setup_exams">Exams</Link>
          <Link to={`/schools/${schoolId}/reports`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_view_reports">Reports</Link>
          <div style={styles.divider} />


          <span style={styles.linkHeader}>Configuration</span>
          <Link to={`/schools/${schoolId}/setup/wizard`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_setup_wizard">Set Up Wizard</Link>
          <Link to={`/schools/${schoolId}/documents`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_setup_documents">Documents</Link>
          <Link to={`/schools/${schoolId}/equipment`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_setup_equipment">Equipment</Link>
          <Link to={`/schools/${schoolId}/messages`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_setup_messages">Messages</Link>
          <Link to={`/schools/${schoolId}/settings`} style={styles.sidebarLink} onClick={this.onTransition} id="menu_school_settings">Settings</Link>
      </div>
      </div>
    );
  }

  public render(){
    return (
      <Sidebar 
        sidebar={this.links}
        open={this.props.appState.showMenu}
        onSetOpen={this.onTransition}>
        {this.props.children}
    </Sidebar>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Menu));


const styles: any = {
  sidebar: {
    width: 256,
    height: "100%",
  },
  sidebarLink: {
    display: "block",
    paddingLeft: "8px",
    paddingTop: "2px",
    color: "#757575",
    textDecoration: "none",
  },
  linkHeader: {
    display: "block",
    paddingTop: "12px",
    color: "#757575",
    textDecoration: "none",
    fontWeight: "bold"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#e0e0e0",
  },
  content: {
    padding: "16px",
    height: "100%",
    backgroundColor: "white",
  },
  root: {
    fontFamily: "'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    fontWeight: 300,
  },
  header: {
    backgroundColor: "#101010",
    color: "white",
    padding: "16px",
    fontSize: "1.5em",
  },
};