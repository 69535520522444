import {
  createAction,
  createReducer
} from "redux-act";

export const setSchool: any = createAction("school - set data");
export const setUsers: any = createAction("school - set all users");
export const addUser: any = createAction("school - add user");
export const setBelts: any = createAction("school - set all belts");
export const changeUserBelt: any = createAction("school - change user belt");
export const changeUserRole: any = createAction("school - change user role");
export const saveUserContract: any = createAction("school - save user contract");


export default createReducer({
  [setSchool]: (state, payload) => {
    const school = {
      ...state.school,
      ...payload
    };
    return {
      ...state,
      school
    };
  },
  [setUsers]: (state, payload) => {
    const users = payload.map((u: any) => {
      u.fullName = `${u.firstName} ${u.lastName}`;
      if(u.nickname !== ""){
        u.fullName = `${u.firstName} (${u.nickname}) ${u.lastName}`;
      }
      return u;
    });
    return {
      ...state,
      users
    };
  },
  [addUser]: (state, payload) => {
    const users: any = state.users;
    const newUser = payload;
    newUser.fullName = newUser.firstName + " " + newUser.lastName;
    users.push(newUser);
    users.sort((a: any, b: any) => {
      return a.lastName > b.lastName ? 1 : -1;
    });
    return {
      ...state,
      users
    };
  },
  [setBelts]: (state: any, payload) => {
    state.school.belts = payload;
    return {
      ...state,
    };
  },
  [changeUserBelt]: (state: any, payload: any) => {
    for(const u of state.users){
      if(u.id === payload.userId){
        u.beltId = payload.beltId;
      }
    }
    return {
      ...state
    };
  },
  [changeUserRole]: (state: any, payload: any) => {
    for(const u of state.users){
      if(u.id === payload.userId){
        u.role = payload.role;
      }
    }
    return {
      ...state,
    };
  },
  [saveUserContract]: (state: any, payload: any) => {
    // we need to update it if it is there or add it if it is not
    const users = payload.users;
    for(const u of users){
      for(const found of state.users){
        if(found.id === u.id){
          if(!found.contracts){
            found.contracts = [];
          }
          let contractFound = false;
          for(let c of found.contracts){
            if(c.id === payload.id){
              c = payload;
              contractFound = true;
            }
          }
          if(!contractFound){
            found.contracts.push(payload);
          }
        }
      }
    }
    return {
      ...state,
    };
  },
}, {
  school: {},
  users: []
});