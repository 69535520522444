import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { ExamsAPI } from "../../../API";
import { success, error } from "../../structure/Alert";

import ExamTemplateListItem from "./ExamTemplateListItem";
import ExamTemplateCriteriaListItem from "./ExamTemplateCriteriaListItem";

interface IExamTemplatesScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface IExamTemplatesScreenState {
  loading: boolean;
  existingTemplates: any;
  selectedTemplate: any;
  newTemplateTitle: string;
  newTemplateDescription: string;

  templateTitle: string;
  templateDescription: string;
  templateStatus: "active" | "archived";

  newCriteriaName: string;
  newCriteriaDescription: string;
  newCriteriaPoints: string;

  criteriaName: string;
  criteriaDescription: string;
  criteriaPoints: string;
  currentTemplatePoints: number;
  orderChanged: boolean;

  showNewTemplateModal: boolean;
  showNewCriteriaModal: boolean;
}

const existingHelp = "Exams are created from templates, which contain the template information and criteria. Changes to a template will NOT impact exams already created from the template.";
const basicHelp = "Exam templates should have a name and a meaningful description.";
const criteriaHelp = "Criteria are the specific items that are graded on an exam. Ideally, all of the criteria add to 100. When grading criteria, the grader will specify a percentage, such as 75 or 100. The percentage then calculates the actual score of the criteria, based upon averaging all of the entered scores for the criteria, and a calculated score will then be saved for the exam.";


const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "#b6bbc1" : "#b6bbc1",
  padding: 2,
});

class ExamTemplatesScreen extends React.Component<IExamTemplatesScreenProps, IExamTemplatesScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      existingTemplates: [],
      selectedTemplate: {},
      newTemplateTitle: "",
      newTemplateDescription: "",
      templateTitle: "",
      templateDescription: "",
      templateStatus: "active",
      newCriteriaName: "",
      newCriteriaDescription: "",
      newCriteriaPoints: "0",
      criteriaName: "",
      criteriaDescription: "",
      criteriaPoints: "0",
      currentTemplatePoints: 0,
      orderChanged: false,

      showNewTemplateModal: false,
      showNewCriteriaModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.fetchTemplates = this.fetchTemplates.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.createNewTemplate = this.createNewTemplate.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.createNewCriteria = this.createNewCriteria.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
    this.deleteCriteria = this.deleteCriteria.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.saveCriteriaOrder = this.saveCriteriaOrder.bind(this);

    this.toggleShowNewTemplateCriteriaModal = this.toggleShowNewTemplateCriteriaModal.bind(this);
    this.toggleShowNewTemplateModal = this.toggleShowNewTemplateModal.bind(this);
    this.calculateTemplatePoints = this.calculateTemplatePoints.bind(this);
  }

  public componentDidMount(){
    this.fetchTemplates();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <Card title="Existing Templates" loading={this.state.loading} help={existingHelp}>
            {this.state.existingTemplates.length === 0 && (<strong>No templates exist yet</strong>)}
            {this.state.existingTemplates.map((t: any) => {
              return (
                <ExamTemplateListItem
                  key={t.id}
                  onSelect={this.selectTemplate}
                  onDelete={this.deleteTemplate}
                  {...t} />
              );
            })}
            <div className="form-group" style={{marginTop:10}}>
              <button className="btn btn-block btn-success" onClick={this.toggleShowNewTemplateModal}>Create New Template</button>
            </div>
          </Card>
        </div>
        {this.state.selectedTemplate && this.state.selectedTemplate.id && this.state.selectedTemplate.id !== 0 && (
          <div>
            <div className="col-md-3">
              <Card title="Basic Information" loading={this.state.loading} help={basicHelp}>
                <div className="form-group">
                  <label>Title</label>
                  <input type="text" className="form-control" id="templateTitle" value={this.state.templateTitle} onChange={this.updateField} />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea className="form-control" id="templateDescription" value={this.state.templateDescription} onChange={this.updateField} />
                </div>
                <div className="form-group">
                  <button className="btn btn-success btn-block" onClick={this.updateTemplate}>Update Template</button>
                </div>
              </Card>
            </div>
            <div className="col-md-6">
              <Card title="Criteria" loading={this.state.loading} help={criteriaHelp}>
                {this.state.selectedTemplate.criteria.length === 0 && (<strong>No criteria exist yet</strong>)}
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                      {this.state.selectedTemplate.criteria.map((t: any, index: number) => {
                        return (
                          <ExamTemplateCriteriaListItem
                            key={t.id}
                            index={index}
                            onUpdate={this.updateCriteria}
                            onDelete={this.deleteCriteria}
                            {...t} />
                        );
                      })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                
                <div className="row" style={{marginTop: 10}}>
                  <div className="col-md-2 col-md-offset-7">
                    <strong>Total Points:</strong>
                  </div>
                  <div className="col-md-2">
                    <input type="text" className="form-control" value={this.state.currentTemplatePoints} disabled={true} />
                  </div>
                </div>
                {this.state.orderChanged && (
                  <div className="form-group" style={{marginTop:10}}>
                    <button className="btn btn-block btn-primary" onClick={this.saveCriteriaOrder}>Save New Criteria Order</button>
                  </div>
                )}

                <div className="form-group" style={{marginTop:10}}>
                  <button className="btn btn-block btn-success" onClick={this.toggleShowNewTemplateCriteriaModal}>Create New Criteria</button>
                </div>
              </Card>
            </div>
          </div>
        )}



        <Modal show={this.state.showNewTemplateModal} onHide={this.toggleShowNewTemplateModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Title</label>
              <input type="text" className="form-control" id="newTemplateTitle" value={this.state.newTemplateTitle} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea className="form-control" id="newTemplateDescription" value={this.state.newTemplateDescription} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.createNewTemplate}>Create New Template</button>
            <button className="btn btn-primary" onClick={this.toggleShowNewTemplateModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showNewCriteriaModal} onHide={this.toggleShowNewTemplateCriteriaModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Criteria</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Criteria</label>
              <input type="text" className="form-control" id="newCriteriaName" value={this.state.newCriteriaName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea className="form-control" id="newCriteriaDescription" value={this.state.newCriteriaDescription} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Points</label>
              <input type="text" className="form-control" id="newCriteriaPoints" value={this.state.newCriteriaPoints} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.createNewCriteria}>Create New Criteria</button>
            <button className="btn btn-primary" onClick={this.toggleShowNewTemplateCriteriaModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private fetchTemplates(){
    this.setState({loading: true}, async () => {
      try{
        const res = await ExamsAPI.getExamTemplates(this.props.schoolState.school.id, "pending");
        this.setState({loading: false, existingTemplates: res.body.data});
      }catch(e){
        this.setState({loading: false});
      }
    });
  }

  private createNewTemplate(){
    const data = {
      status: "active",
      title: this.state.newTemplateTitle,
      description: this.state.newTemplateDescription
    };
    if(data.title === ""){
      return error("Title cannot be blank");
    }
    this.setState({loading: false}, async () => {
      try{
        const res = await ExamsAPI.createExamTemplate(this.props.schoolState.school.id, data);
        // add the template to the list
        const temps = this.state.existingTemplates;
        temps.push(res.body.data);
        this.setState({loading: false, selectedTemplate: res.body.data, templateDescription: res.body.data.description, templateTitle: res.body.data.title, showNewTemplateModal: false, existingTemplates: temps, newTemplateDescription: "", newTemplateTitle: ""});
        success("Template created!");
      }catch(e){
        this.setState({loading: false, showNewTemplateModal: false});
        error("Could not create that template");
      }
    });
  }

  private updateTemplate(){
    const data = {
      id: this.state.selectedTemplate.id,
      status: this.state.templateStatus,
      title: this.state.templateTitle,
      description: this.state.templateDescription
    };
    if(data.title === ""){
      return error("Title cannot be blank");
    }
    this.setState({loading: false}, async () => {
      try{
        const res = await ExamsAPI.updateExamTemplate(this.props.schoolState.school.id, this.state.selectedTemplate.id, data);
        // loop over the state to change it in the list
        const temps = this.state.existingTemplates;
        for(const t of temps){
          if(t.id === this.state.selectedTemplate.id){
            t.title = data.title;
            t.description = data.description;
            t.status = data.status;
          }
        }
        this.setState({loading: false, selectedTemplate: res.body.data, existingTemplates: temps});
        success("Template updated!");
      }catch(e){
        this.setState({loading: false, showNewTemplateModal: false});
        error("Could not update that template");
      }
    });
  }

  private selectTemplate(template: any){
    this.setState({loading: false}, async () => {
      // since the get all exams doesn't get the criteria, we fetch it here anyway
      try{
        const res = await ExamsAPI.getExamTemplateById(this.props.schoolState.school.id, template.id);
        this.setState({loading: false, selectedTemplate: res.body.data, templateDescription: res.body.data.description, templateTitle: res.body.data.title}, () => this.calculateTemplatePoints());
      }catch(e){
        // this shouldn't happen....
        this.setState({loading: false});
      }
    });
  }

  private deleteTemplate(template: any){
    this.setState({loading: false}, async () => {
      try{
        await ExamsAPI.deleteExamTemplate(this.props.schoolState.school.id, template.id);
        this.setState({loading: false, selectedTemplate: {}, templateDescription: "", templateTitle: "", currentTemplatePoints: 0});
      }catch(e){
        this.setState({loading: false});
        error("Could not delete that template");
      }
    });
  }

  private createNewCriteria(){
    this.setState({loading: false}, async () => {
      const data = {
        criteria: this.state.newCriteriaName,
        description: this.state.newCriteriaDescription,
        criteriaPoints: parseInt(this.state.newCriteriaPoints, 10)
      };
      try{
        const res = await ExamsAPI.createExamTemplateCriteria(this.props.schoolState.school.id, this.state.selectedTemplate.id, data);
        success("Criteria created!");
        this.setState({
          loading: false, 
          selectedTemplate: res.body.data, 
          showNewCriteriaModal: false, 
          newCriteriaName: "", 
          newCriteriaDescription: "", 
          newCriteriaPoints: "0",
          criteriaName: res.body.data.criteria,
          criteriaDescription: res.body.data.description,
          criteriaPoints: res.body.data.criteriaPoints}, () => this.calculateTemplatePoints());
      }catch(e){
        error("Could not create that criteria");
        this.setState({loading: false, showNewCriteriaModal: false});
      }
    });
  }

  private toggleShowNewTemplateModal(){
    this.setState({showNewTemplateModal: !this.state.showNewTemplateModal});
  }

  private toggleShowNewTemplateCriteriaModal(){
    this.setState({showNewCriteriaModal: !this.state.showNewCriteriaModal});
  }

  private updateCriteria(criteria: any){
    // we need to update the criteria
    const newCriteria: any = [];
    for(const c of this.state.selectedTemplate.criteria){
      if(c.id === criteria.id){
        newCriteria.push(criteria);
      } else {
        newCriteria.push(c);
      }
    }
    const st = this.state.selectedTemplate;
    st.criteria = newCriteria;
    this.setState({ selectedTemplate: st }, () => {
      this.calculateTemplatePoints();
    });
  }

  private deleteCriteria(criteria: any){
    // we need to remove it and update the scores
    const crit = [];
    for(const c of this.state.selectedTemplate.criteria){
      if(c.id !== criteria.id){
        crit.push(c);
      }
    }
    const temp = this.state.selectedTemplate;
    temp.criteria = crit;
    this.setState({selectedTemplate: temp}, () => this.calculateTemplatePoints());
  }

  private calculateTemplatePoints(){
    let total = 0;
    for(const c of this.state.selectedTemplate.criteria){
      total += typeof c.criteriaPoints === "string" ? parseInt(c.criteriaPoints, 10) : c.criteriaPoints;
    }
    this.setState({ currentTemplatePoints: total});
  }

  private async onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const resultArray = Array.from(this.state.selectedTemplate.criteria);
    const [removed] = resultArray.splice(result.source.index, 1);
    resultArray.splice(result.destination.index, 0, removed);

    const temp = this.state.selectedTemplate;
    temp.criteria = resultArray;
    this.setState({selectedTemplate: temp, orderChanged: true});
  }

  private saveCriteriaOrder(){
    const criteriaIds: any = [];
    for(const c of this.state.selectedTemplate.criteria){
      criteriaIds.push(c.id);
    }
    this.setState({loading: false}, async () => {
      try{
        await ExamsAPI.saveExamTemplateCriteriaOrder(this.props.schoolState.school.id, this.state.selectedTemplate.id, criteriaIds);
        success("Criteria saved!");
        this.setState({loading: false, orderChanged: false});
      }catch(e){
        error("Could not save the order");
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamTemplatesScreen) as React.ComponentType<any>);