import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import * as AppActions from "../../../../reducers/appReducer";
import { UserAPI } from "../../../../API";
import { error } from "../../../structure/Alert";

import SendNewMessage from "./SendNewMessage";
import MessageHistory from "./MessageHistory";

interface ISendMessageScreenProps {
  appActions: any;
  history: any;
  match: any;
  location: any;
  schoolState: any;
}

interface ISendMessageScreenState {
  loading: boolean;
  user: any;
  userId: number;
  lastUpdated: string;
}

class SendMessageScreen extends React.Component<ISendMessageScreenProps, ISendMessageScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      user: {id: 0},
      userId: 0,
      lastUpdated: moment().format("YYYY-MM-DDTHH:mm:SS")
    };

    this.fetchUser = this.fetchUser.bind(this);
    this.handleMessageSent = this.handleMessageSent.bind(this);
  }

  public componentDidMount(){
    this.fetchUser(this.props);
  }

  public componentWillReceiveProps(nextProps: any){
    if(this.props.location.pathname !== nextProps.location.pathname){
      this.fetchUser(nextProps);
    }
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-md-offset-1">
            <SendNewMessage 
              school={this.props.schoolState.school}
              user={this.state.user}
              userId={this.state.userId}
              onMessageSent={this.handleMessageSent}
            />
          </div>
          <div className="col-md-4">
            <MessageHistory 
              schoolId={this.props.schoolState.school.id}
              userId={this.state.userId}
              key={this.state.lastUpdated}
              />
          </div>
        </div>
      </div>
    );
  }

  private fetchUser(props: any){
    const userId = props.match.params.userId ? parseInt(props.match.params.userId, 10) : 0;
    this.setState({loading: true}, async () => {
      try{
        const userRes = await UserAPI.getUserProfile(this.props.schoolState.school.id, userId);
        this.setState({loading: false, user: userRes.body.data, userId: userRes.body.data.id});
      }catch(err){
        this.setState({loading: false}, () => {
          error("Could not load the user");
        });
      }
    });
  }

  private handleMessageSent(){
    this.setState({
      loading: true,
      lastUpdated: moment().format("YYYY-MM-DDTHH:mm:SS")
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendMessageScreen) as React.ComponentType<any>);