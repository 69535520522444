import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import TimePicker from "rc-time-picker";
import {ScheduleAPI} from "../../../API";

import * as AppActions from "../../../reducers/appReducer";
import { error, success } from "../../structure/Alert";
import DatePicker from "src/components/structure/DatePicker";

interface IEventInfoFormProps {
  appActions: any;
  history: any;
  schoolId: number;
  types: any;
  categories: any;
  instructors: any;
  event: any;
  onEventSaved: any;
  onEventDelete: any;
  onNewEventType: any;
}

interface IEventInfoFormState {
  loading: boolean;
  id: number;
  name: string;
  eventType: number;
  startTime: any;
  endTime: any;
  instructor: number;
  privacy: string;
  description: string;
  diff: number;
  showNewEventTypeModal: boolean;
  newEventTypeName: string;
  newEventTypeCategory: string;
  showDeleteModal: boolean;
  repeatUnit: string;
  repeatAmount: number;
  updateAll: string;
  deleteEventIncludeRelated: string;
  showEventRepeatModal: boolean;
  copyCount: number;
  copyUnit: string;
  copyStart: any;
}

const emptyState: IEventInfoFormState = {
  loading: false,
  id: 0,
  name: "",
  eventType: 0,
  startTime: moment(),
  endTime: moment().add(1, "hours"),
  instructor: 0,
  privacy: "public",
  description: "",
  diff: 0,
  showNewEventTypeModal: false,
  newEventTypeName: "",
  newEventTypeCategory: "class",
  showDeleteModal: false,
  repeatUnit: "no",
  repeatAmount: 0,
  updateAll: "no",
  deleteEventIncludeRelated: "no",
  showEventRepeatModal: false,
  copyCount: 0,
  copyUnit: "weekly",
  copyStart: moment(),
};

class EventInfoForm extends React.Component<IEventInfoFormProps, IEventInfoFormState> {

  constructor(props: any) {
    super(props);
    this.state = emptyState;

    this.updateField = this.updateField.bind(this);
    this.updateEndTime = this.updateEndTime.bind(this);
    this.updateStartTime = this.updateStartTime.bind(this);
    this.updateCopyStart = this.updateCopyStart.bind(this);
    this.saveEvent = this.saveEvent.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.toggleNewEventTypeModal = this.toggleNewEventTypeModal.bind(this);
    this.addEventType = this.addEventType.bind(this);
    this.toggleCopyModal = this.toggleCopyModal.bind(this);
    this.copyEvent = this.copyEvent.bind(this);
  }

  public componentDidMount() {
    let start = moment();
    let end = moment();
    if (this.props.event.startTime) {
      start = moment(this.props.event.startTime);
    } else if (this.props.event.newEventStartTime) {
      start = moment(this.props.event.newEventStartTime);
    } else {
      start = moment();
    }
    if (this.props.event.endTime) {
      end = moment(this.props.event.endTime);
    } else if (this.props.event.newEventStartTime) {
      end = moment(this.props.event.newEventStartTime).add(1, "hours");
    } else {
      end = moment().add(1, "hours");
    }
    const diff = end.diff(start, "minutes");
    const state: any = {
      loading: false,
      id: this.props.event.id,
      name: this.props.event.name ? this.props.event.name : "",
      eventType: this.props.event.eventType ? this.props.event.eventType : this.props.types[0].id,
      startTime: start,
      endTime: end,
      instructor: this.props.event.instructor ? this.props.event.instructor : 0,
      privacy: this.props.event.privacy ? this.props.event.privacy : "public",
      description: this.props.event.description ? this.props.event.description : "",
      additionalAttendees: this.props.event.additionalAttendees ? this.props.event.additionalAttendees : 0,
      diff,
      showNewEventTypeModal: false,
      newEventTypeName: "",
      newEventTypeCategory: "class",
      showDeleteModal: false,
      repeatUnit: "no",
      repeatAmount: 0,
      updateAll: "no",
      deleteEventIncludeRelated: "no",
      copyStart: start,
    };
    this.setState(state);
  }


  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Event Name:</label>
              <input
                id="name"
                type="text"
                className="form-control"
                placeholder="Event Title"
                value={this.state.name}
                onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Event Type</label>
              <select
                id="eventType"
                className="form-control"
                value={this.state.eventType}
                onChange={this.updateField} >
                <option disabled={true}>Select an Event Type</option>
                {this.props.types.map((cl: any) => {
                  return (<option key={cl.id} value={cl.id}>{cl.eventType}</option>);
                })}
                <option value={-1}>-- Create New Event Type --</option>
              </select>
            </div>
            <div className="form-group">
              <label>Date:</label>
              <input
                id="date"
                type="date"
                disabled={true}
                className="form-control"
                placeholder="When the event occurs"
                value={this.state.startTime.format("YYYY-MM-DD")}
                onChange={this.updateField} />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>From</label><br />
                  <TimePicker 
                    showSecond={false}
                    value={this.state.startTime}
                    onChange={this.updateStartTime}/>
                </div>
                <div className="col-md-6">
                  <label>To</label><br />
                  <TimePicker 
                    showSecond={false}
                    value={this.state.endTime}
                    onChange={this.updateEndTime}/>
                  <br />
                  {this.state.diff} minutes
              </div>
              </div>
            </div>
            <div className="form-group">
              <label>Instructor:</label>
              <select
                id="instructor"
                className="form-control"
                value={this.state.instructor}
                onChange={this.updateField}>
                <option value={0}>Default / Not Set</option>
                {this.props.instructors.map((s: any) => {
                  return (<option value={s.id} key={s.id}>{s.lastName}, {s.firstName}</option>);
                })}
              </select>
            </div>
            {/* <div className="form-group">
              <label>Privacy:</label>
              <select
                id="privacy"
                className="form-control"
                value={this.state.privacy}
                onChange={this.updateField}>
                <option value="private">Private</option>
                <option value="public">Public</option>
              </select>
            </div> */}
            {/* {this.getPrivacySelect} */}
            {this.repeatSelect}
            {this.repeatAmountSelect}
            {this.relatedEventsSelect}
            <div className="form-group">
              <button className="btn btn-block btn-primary" onClick={this.saveEvent}>Save</button>
            </div>
            <div className="form-group">
              {this.deleteButton}
            </div>
            <div className="form-group">
              {this.copyButton}
            </div>
          </div>
        </div>


        <Modal show={this.state.showNewEventTypeModal} onHide={this.toggleNewEventTypeModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Add a New Event Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Event Type Name</label>
              <input
                type="text"
                id="newEventTypeName"
                className="form-control"
                value={this.state.newEventTypeName}
                onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Event Category Type</label>
              <select
                id="newEventTypeCategory"
                className="form-control"
                value={this.state.newEventTypeCategory}
                onChange={this.updateField}>
                {this.props.categories.map((c: any) => {
                  return (<option key={c} value={c}>{c[0].toUpperCase() + c.substr(1, c.length)}</option>);
                })}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.addEventType}>Add Class Type</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <strong><p>Are you absolutely sure you want to delete this event? This will remove all attendance for this event as well and cannot be undone.</p></strong>
              <label>Also Delete Future Related Events?</label>
              <select
                id="deleteEventIncludeRelated"
                className="form-control"
                value={this.state.deleteEventIncludeRelated}
                onChange={this.updateField}>
                <option value="no">No, only delete this one</option>
                <option value="yes">Yes, also delete all future related events</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteEvent}>Delete Event</button>
            <button className="btn" onClick={this.toggleDeleteModal}>Cancel</button>
          </Modal.Footer>
        </Modal>



        <Modal show={this.state.showEventRepeatModal} onHide={this.toggleCopyModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Repeat Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p>Warning</p> This will create new instances of this event starting from the below date. If you already have instances of that event, duplicates will be made!
            </div>
            <div className="form-group">
              <label>Starting on</label>
              <DatePicker date={this.state.copyStart} onDateSaved={this.updateCopyStart} />
            </div>
            <div className="form-group">
              <label>Repeat</label>
              <select
                id="copyUnit"
                className="form-control"
                value={this.state.copyUnit}
                onChange={this.updateField}>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </select>

            </div>
            <div className="form-group">
              <label>This Many Times</label>
              <input
                id="copyCount"
                type="number"
                className="form-control"
                value={this.state.copyCount}
                onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.copyEvent}>Copy Event</button>
            <button className="btn" onClick={this.toggleCopyModal}>Cancel</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    if (e.target.id === "eventType" && e.target.value === "-1") {
      ns.showNewEventTypeModal = true;
    }
    if (e.target.id === "eventType"){
      ns.eventType = parseInt(e.target.value, 10);
    } else if (e.target.id === "instructor"){
      ns.instructor = parseInt(e.target.value, 10);
    } else if (e.target.id === "repeatAmount"){
      ns.repeatAmount = parseInt(e.target.value, 10);
    }
    this.setState(ns);
  }

  private updateStartTime(newStart: any) {
    this.setState({
      startTime: newStart,
      diff: this.state.endTime.diff(newStart, "minutes")
    });
  }
  private updateEndTime(newEnd: any) {
    this.setState({
      endTime: newEnd,
      diff: newEnd.diff(this.state.startTime, "minutes")
    });
  }

  private toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private toggleNewEventTypeModal() {
    this.setState({ showNewEventTypeModal: !this.state.showNewEventTypeModal});
  }

  private toggleCopyModal(){
    this.setState({ showEventRepeatModal: !this.state.showEventRepeatModal});
  }

  private async saveEvent() {
    if(this.state.name === ""){
      return error("Event name cannot be blank");
    }

    this.setState({loading: true}, async () => {
      try{
        let id = this.state.id;
        if(id === 0){
          // new event, so create it
          const res = await ScheduleAPI.createNewEvent(this.props.schoolId, this.state);
          id = res.body.data.id;
        } else {
          // updating an existing event
          await ScheduleAPI.updateEvent(this.props.schoolId, this.state.id, this.state);
        }
        success("Event saved!");
        this.setState({id, loading: false}, () => {
          this.props.onEventSaved(this.state);
        });
      } catch(err) {
        error("Could not save that event");
        this.setState({loading: false});
      }
    });
  }

  private deleteEvent() {
    this.setState({loading: true, showDeleteModal: false}, async () => {
      try{
        await ScheduleAPI.deleteEvent(this.props.schoolId, this.state.id, this.state.deleteEventIncludeRelated === "yes");
        success("Event deleted!");
        this.setState({loading: false}, () => {
          this.props.onEventDelete(this.state);
        });
      } catch(err) {
        error("Could not delete that event");
        this.setState({loading: false});
      }
    });
    
  }

  private async addEventType() {
    if(this.state.newEventTypeName === ""){
      return error("Event type cannot be blank");
    }
    // make sure it's not a dupe
    for(const t of this.props.types){
      if(t.eventType.toLowerCase() === this.state.newEventTypeName.toLowerCase()){
        return error("You already have an event type by that name");
      }
    }
    this.setState({loading: true, showNewEventTypeModal: false}, async () => {
      let newEventType = 0;
      try{
        const res = await ScheduleAPI.saveNewEventType(this.props.schoolId, this.state.newEventTypeName, this.state.newEventTypeCategory);
        newEventType = res.body.data.id;
        this.props.onNewEventType(res.body.data);
      } catch(err) {
        error("We could not save that event type");
      }
      this.setState({
        loading: false,
        eventType: newEventType
      });
    });
  }

  get deleteButton() {
    if (this.props.event.id !== 0) {
      return (<button className="btn btn-block btn-danger" onClick={this.toggleDeleteModal}>Delete</button>);
    }
    return null;
  }

  get repeatSelect() {
    if (this.props.event.id === 0) {
      return (
        <div className="form-group">
          <label>Repeat:</label>
          <select
            id="repeatUnit"
            className="form-control"
            value={this.state.repeatUnit}
            onChange={this.updateField}>
            <option value="no">No</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
          </select>
        </div>
      );
    }
    return null;
  }

  get repeatAmountSelect() {
    if (this.props.event.id === 0 && this.state.repeatUnit !== "no") {
      return (
        <div className="form-group">
          <label>Total Occurrences:</label>
          <input
            id="repeatAmount"
            type="number"
            className="form-control"
            value={this.state.repeatAmount}
            onChange={this.updateField} />
        </div>
      );
    }
    return null;
  }

  get relatedEventsSelect() {
    if (this.props.event.id !== 0) {
      return (
        <div className="form-group">
          <label>Save any future related events:</label><br />
          <span className="small">Only relevant if you chose to repeat this event</span>
          <select
            id="updateAll"
            className="form-control"
            value={this.state.updateAll}
            onChange={this.updateField}>
            <option value={"no"}>No, Just This One</option>
            <option value={"yes"}>Yes, Update All</option>
          </select>
        </div>
      );
    }
    return null;
  }

  get copyButton(){
    if(this.props.event.id !== 0){
      return (
        <button className="btn btn-block btn-info" onClick={this.toggleCopyModal}>Repeat This Event</button>
      );
    }
    return null;
  }

  private copyEvent(){
    const data = {
      copyStart: this.state.copyStart.format("YYYY-MM-DD"),
      copyCount: parseInt(this.state.copyCount + "", 10),
      copyUnit: this.state.copyUnit,
    };
    this.setState({ loading: true }, async () => {
      try{
        const result = await ScheduleAPI.createEventCopies(this.props.schoolId, this.props.event.id, data);
        success(`Event copied! We created ${result.body.data.length} copies of that event!`);
        this.setState({ showEventRepeatModal: false, copyCount: 0, copyUnit: "daily"}, () => {
          this.props.onEventSaved(this.state);
        });
      }catch(err){
        error("Could not copy that event");
        this.setState({ loading: false });
      }
    });
  }

  private updateCopyStart(newDate: moment.Moment){
    this.setState({ copyStart: newDate });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventInfoForm) as React.ComponentType<any>);