import { createAction, createReducer } from "redux-act";

export const setAlerts: any = createAction("activities - set");
export const clearAlert: any = createAction("activities - clear one");

const defaultAlertState: any = {
  critical: [],
  warning: [],
  info: [],
  dismissed: []
};

export default createReducer({
    [setAlerts]: (state, payload) => {
      const st: any = {
        critical: [],
        warning: [],
        info: [],
        dismissed: []
      };
      for(const a of payload){
        if(a.dismissed){
          st.dismissed.push(a);
        } else if(a.level === "critical"){
          st.critical.push(a);
        } else if(a.level === "warning"){
          st.warning.push(a);
        } else if(a.level === "info"){
          st.info.push(a);
        }
      }
      return {
        ...state, 
        ...st
      };
    },
    [clearAlert]: (state, payload) => {
      // TODO: needs testing
      
      state[payload.type] = state[payload.type].filter((i: any) => {
        return i.id !== payload.id;
      });

      state.dismissed.append(payload);
      return {
        ...state,
      };
    }
  }, defaultAlertState);