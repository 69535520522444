import * as React from "react";

import Card from "../../structure/Card";
import { ReportsAPI } from "../../../API";
import * as moment from "moment";


interface ILastAttendedReportProps {
  schoolId: number;
}

interface ILastAttendedReportState {
  loading: boolean;
  data: any[];
  sortField: string;
  sortDir: string;
}

class LastAttendedReport extends React.Component<ILastAttendedReportProps, ILastAttendedReportState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: [],
      sortField: "lastName",
      sortDir: "ASC",
    };

    this.fetch = this.fetch.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <Card title="Last Attended" loading={this.state.loading} help="">
              <div className="row" style={{marginBottom: 5}}>
                <div className="col-md-3 row-header">
                  Student
                </div>
                <div className="col-md-4 row-header">
                  Family
                </div>
                <div className="col-md-3 row-header">
                  Last Attended
                </div>
                <div className="col-md-2 row-header">
                  Days Ago
                </div>
              </div>
              {this.state.data.map((item: any, index: number) => {
                return (
                  <div className={index % 2 === 1 ? "list-row-odd row" : "row"} key={index} style={{marginBottom: 5}}>
                    <div className="col-md-3">
                      {item.firstName} {item.lastName}
                    </div>
                    <div className="col-md-4">
                      {item.familyName}
                    </div>
                    <div className="col-md-3">
                      {moment(item.lastAttended).isValid() ? moment(item.lastAttended).format("MM/DD/YYYY") : "Unknown"}
                    </div>
                    <div className="col-md-2">
                      {moment(item.lastAttended).isValid() ? moment(item.lastAttended).fromNow() : "Unknown"}
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
      </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try {
        const res = await ReportsAPI.getLastAttendedReport(this.props.schoolId, {sortField: this.state.sortField, sortDir: this.state.sortDir});
        this.setState({loading: false, data: res.body.data});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

}


export default LastAttendedReport;