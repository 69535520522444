import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../../reducers/appReducer";

import {ScheduleAPI} from "../../../../API/";
import UserPersonalRecords from "./UserPersonalRecords";
import UserAttendance from "./UserAttendance";
import UserCompetitions from "./UserCompetitions";
import UserEquipment from "./UserEquipment";

interface IUserActivitiesScreenProps {
  appActions: any;
  history: any;
  match: any;
}

interface IUserActivitiesScreenState {
  loading: boolean;
  eventTypes: any;
}

class UserActivitiesScreen extends React.Component<IUserActivitiesScreenProps, IUserActivitiesScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      eventTypes: []
    };

    this.fetch = this.fetch.bind(this);

  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-7">
            <UserAttendance 
              schoolId={this.props.match.params.schoolId}
              userId={this.props.match.params.userId}
              eventTypes={this.state.eventTypes}/>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <UserCompetitions 
                  schoolId={this.props.match.params.schoolId}
                  userId={this.props.match.params.userId}/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <UserEquipment 
                  schoolId={this.props.match.params.schoolId}
                  userId={this.props.match.params.userId}/>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <UserPersonalRecords 
              schoolId={this.props.match.params.schoolId}
              userId={this.props.match.params.userId}/>
          </div>
        </div>
      </div>
    );
  }

  private async fetch(){
    this.setState({loading: true}, async () => {
      try{
        const res = await ScheduleAPI.getEventsTypes(this.props.match.params.schoolId);
        this.setState({loading: false, eventTypes: res.body.data});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserActivitiesScreen) as React.ComponentType<any>);