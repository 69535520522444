import * as React from "react";
import { Modal } from "react-bootstrap";

import { ExamsAPI } from "../../../API";
import { error } from "../../structure/Alert";

import ExamCriteriaScoreListItem from "./ExamCriteriaScoreListItem";

interface IExamCriteriaListItem {
  calculatedScore: number;
  criteria: string;
  criteriaOrder: number;
  criteriaPoints: number;
  description: string;
  examId: number;
  examScore: any;
  id: number;
  schoolId: number;
}

interface IExamCriteriaListItemProps {
  onUpdate: any;
  item: IExamCriteriaListItem;
  examName: string;
  examStatus: string;
  loggedInUserId: number;
  loggedInUserRole: string;
}

interface IExamCriteriaListItemState {
  loading: boolean;
  showScoreModal: boolean;
  newScore: string;
  notes: string;
}

export default class ExamCriteriaListItem extends React.Component<IExamCriteriaListItemProps, IExamCriteriaListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showScoreModal: false,
      newScore: "0",
      notes: "",
    };

    this.updateField = this.updateField.bind(this);
    this.toggleShowScoreModal = this.toggleShowScoreModal.bind(this);
    this.saveScore = this.saveScore.bind(this);
    this.deleteScore = this.deleteScore.bind(this);

  }

  public render() {
    return (
      <div>
        <div className="row" style={{marginBottom: 5, cursor: "pointer"}}  onClick={this.toggleShowScoreModal}>
          <div className="col-md-6">
            <strong>{this.props.item.criteria}</strong><br />
            {this.props.item.description}
          </div>
          <div className="col-md-2">
            {this.props.item.calculatedScore}
          </div>
          <div className="col-md-2">
            {this.props.item.criteriaPoints}
          </div>
          <div className="col-md-2">
            {this.props.examStatus === "pending" && (<span className="glyphicon glyphicon-pencil" />)}
          </div>
        </div>


        <Modal show={this.state.showScoreModal} onHide={this.toggleShowScoreModal} dialogClassName="modal-x-large" backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Scores for {this.props.examName} - {this.props.item.criteria}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p>You may enter a new score and notes here. If you have already entered a score, your current entry will be <strong>replaced</strong> with the new score.</p>
            </div>
            <div className="form-group">
              <p><strong>Criteria:</strong> {this.props.item.criteria}</p>
              <p><strong>Description:</strong> {this.props.item.description}</p>
            </div>
            <div className="form-group">
              <label>New Score</label>
              <input type="text" id="newScore" className="form-control" value={this.state.newScore} onChange={this.updateField} />
              <span className="small">Enter a percentage for this criteria. For example, if the student would receive a 90% of the maximum score, enter <strong>90</strong></span>
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea id="notes" className="form-control" value={this.state.notes} onChange={this.updateField} />
            </div>
            
            <div className="form-group">
              <span style={{fontWeight: "bold", fontSize: 16}}>Current Scores</span>
              <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                <div className="col-md-5">
                  <strong>Entered By</strong>
                </div>
                <div className="col-md-5">
                  <strong>Raw Score</strong>
                </div>
                <div className="col-md-2" />
              </div>
              {this.props.item.examScore.length === 0 && (<strong> No scores have been entered yet!</strong>)}

              {this.props.item.examScore.map((score: any) => {
                return (
                  <ExamCriteriaScoreListItem
                    key={score.id}
                    item={score}
                    onDelete={this.deleteScore}
                    userCanDelete={this.props.loggedInUserRole === "admin" || this.props.loggedInUserRole === "administrator" || this.props.loggedInUserId === score.enteredBy}
                    />
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.saveScore}>Save Score</button>
            <button className="btn btn-primary" onClick={this.toggleShowScoreModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleShowScoreModal(){
    this.setState({showScoreModal: !this.state.showScoreModal});
  }

  private updateField(e: any){
    const id = e.target.id;
    const value = e.target.value;
    const ns = this.state;
    ns[id] = value;
    this.setState(ns);
  }

  private saveScore(){
    if(this.state.newScore === ""){
      return error("Score cannot be blank");
    }
    this.setState({loading: true}, async () => {
      try{
        const data = {
          notes: this.state.notes,
          rawScore: parseInt(this.state.newScore, 10)
        };
        const res = await ExamsAPI.saveExamScore(this.props.item.schoolId, this.props.item.examId, this.props.item.id, data);
        this.setState({loading: false, showScoreModal: false}, () => {
          this.props.onUpdate(res.body.data);
        });
      }catch(err){
        error("Could not save that exam score");
        this.setState({loading: false});
      }
    });
  }

  private deleteScore(exam: any){
    this.setState({loading: false, showScoreModal: false}, () => {
      this.props.onUpdate(exam);
    });
  }


}