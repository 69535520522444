import { makeCall,makeUrl } from "./client";

export class SchoolAPI {

  /**
   * Get the school's info
   * @param schoolId 
   * @param params 
   */
  public getSchoolInfo(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}`, params);
  }

  /**
   * Create a new school
   * @param data should have a user and a school property with the appropriate data
   * @param options 
   */
  public createNewSchool(data: any = {}, options: any = {}): Promise<any> {
    return makeCall("post", `signup`, data, options);
  }

  /**
   * Update the school's settings
   * @param schoolId 
   * @param params 
   */
  public updateSchoolSettings(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/settings`, params);
  }

  /**
   * Upload a new school logo
   * @param schoolId 
   * @param file 
   */
  public uploadSchoolLogo(schoolId: number, file: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/images/logo`, file, {asMultipartFile: true});
  }

  /**
   * Uploads a new banner
   * @param schoolId 
   * @param file 
   * @returns 
   */
  public uploadSchoolBanner(schoolId: number, file: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/images/banner`, file, {asMultipartFile: true});
  }

  /**
   * Get the school's branding
   * @param schoolId 
   * @param brandType 
   * @param query 
   * @returns 
   */
  public getSchoolBranding(schoolId: number, brandType: "logo" | "banner", query: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/images/${brandType}`, query);
  }

  /**
   * Get the URL for the school's logo
   * @param schoolId 
   */
  public getSchoolLogoString(schoolId: number): string {
    return makeUrl(`schools/${schoolId}/images/logo`);
  }

  /**
   * Gets the school banner string
   * @param schoolId 
   * @returns 
   */
  public getSchoolBannerString(schoolId: number): string {
    return makeUrl(`schools/${schoolId}/images/banner`);
  }


  /**
   * Update a school payment method
   * @param schoolId 
   * @param firstName 
   * @param lastName 
   * @param emailAddress 
   * @param token 
   * @param params 
   */
  public updateSchoolPaymentMethod(schoolId: number, firstName: string, lastName: string, emailAddress: string, token: string, params: any = {}): Promise<any> {
    const data = {
      ...params,
      firstName,
      lastName,
      emailAddress,
      token,
    };
    return makeCall("put", `schools/${schoolId}/settings/paymentMethod`, data);
  }

  /**
   * Delete a school payment method
   * @param schoolId 
   * @param params 
   */
  public deleteSchoolPaymentMethod(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/settings/paymentMethod`, params);
  }

  /**
   * Get the set up steps
   * @param schoolId 
   * @param params 
   * @returns 
   */
  public getSetupSteps(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/setup/wizard`, params);
  }

  /**
   * Update all the steps
   * @param schoolId 
   * @param status 
   * @param params 
   * @returns 
   */
  public updateAllSetupSteps(schoolId: number, status: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      status
    };
    return makeCall("patch", `schools/${schoolId}/setup/wizard`, params);
  }

  /**
   * Updates a single step by step number
   * @param schoolId 
   * @param stepNumber 
   * @param status 
   * @param params 
   * @returns 
   */
  public updateSingleSetupStepByStepNumber(schoolId: number, stepNumber: number, status: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      status
    };
    return makeCall("patch", `schools/${schoolId}/setup/wizard/${stepNumber}`, params);
  }

  /**
   * Updates a single step by step key
   * @param schoolId 
   * @param stepKey 
   * @param status 
   * @param params 
   * @returns 
   */
  public updateSingleSetupStepByStepKey(schoolId: number, stepKey: string, status: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      status
    };
    return makeCall("patch", `schools/${schoolId}/setup/wizard/${stepKey}`, params);
  }

  /**
   * Gets the school's logs
   * @param schoolId 
   * @param userId 
   * @param start 
   * @param end 
   * @param eventType 
   * @param params 
   * @returns 
   */
  public getSchoolUserLogs(schoolId: number, userId: number, start: string, end: string, eventType: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      userId,
      start,
      end,
      eventType
    };
    return makeCall("get", `schools/${schoolId}/logs`, params);
  }


  public getSchoolStaff(schoolId: number, params: any = {}): Promise<any> {
    params = {
      ...params,
    };
    return makeCall("get", `schools/${schoolId}/staff`, params);
  }

  public createSchoolStaff(schoolId: number, params: any = {}): Promise<any> {
    params = {
      ...params,
    };
    return makeCall("post", `schools/${schoolId}/staff`, params);
  }

  public deleteSchoolStaff(schoolId: number, staffId: number, params: any = {}): Promise<any> {
    params = {
      ...params,
    };
    return makeCall("delete", `schools/${schoolId}/staff/${staffId}`, params);
  }

  public updateSchoolStaff(schoolId: number, staffId: number, params: any = {}): Promise<any> {
    params = {
      ...params,
    };
    return makeCall("patch", `schools/${schoolId}/staff/${staffId}`, params);
  }
  
  public uploadStudentStaffPicture(schoolId: number, staffId: number, file: any): Promise<any> {
    return makeCall("post", `schools/${schoolId}/staff/${staffId}/upload`, file, {asMultipartFile: true});
  }
  

  public schoolLogEventTypeToDisplay(eventType: string): string {
    switch(eventType){
      case "all":
        return "All Events";
      case "login":
        return "Login";
      case "logout":
        return "Logout";
      case "school_user_created":
        return "User Created";
      case "belt_assigned":
        return "Promotion";
      case "attendance":
      case "event":
        return "Attendance";
      case "contract_completed":
        return "Contract Completed";
      case "invoice_success":
        return "Invoice Paid";
      default:
        return eventType;
    }
  }
}