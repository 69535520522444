import { makeCall } from "./client";

export class PermissionsAPI {

  /**
   * Get the user's permission for a school
   * @param schoolId 
   * @param userId 
   * @param params 
   */
  public getUserPermissions(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/permissions`, params);
  }

  /**
   * Give the user permission to do something
   * @param schoolId 
   * @param userId 
   * @param permission 
   * @param params 
   */
  public giveUserPermission(schoolId: number, userId: number, permission: string, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/permissions/${permission}`, params);
  }

  /**
   * Remove a user's permission to do something
   * @param schoolId 
   * @param userId 
   * @param permission 
   * @param params 
   */
  public removeUserPermission(schoolId: number, userId: number, permission: string, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/users/${userId}/permissions/${permission}`, params);
  }
}