import * as alerts from "./alerts";
import * as belts from "./belts";
import * as comps from "./competitions";
import * as docs from "./documents";
import * as equipment from "./equipment";
import * as exams from "./exams";
import * as finances from "./finances";
import * as knowledge from "./knowledge";
import * as messages from "./messages";
import * as news from "./news";
import * as notes from "./notes";
import * as permissions from "./permissions";
import * as reports from "./reports";
import * as schedule from "./schedule";
import * as schools from "./schools";
import * as schoolUsers from "./schoolUsers";
import * as user from "./user";

export const AlertsAPI = new alerts.AlertsAPI();
export const BeltsAPI = new belts.BeltsAPI();
export const CompetitionsAPI = new comps.CompetitionsAPI();
export const DocumentsAPI = new docs.DocumentsAPI();
export const EquipmentAPI = new equipment.EquipmentAPI();
export const ExamsAPI = new exams.ExamsAPI();
export const FinancesAPI = new finances.FinancesAPI();
export const KnowledgeAPI = new knowledge.KnowledgeAPI();
export const MessagesAPI = new messages.MessagesAPI();
export const NewsAPI = new news.NewsAPI();
export const NotesAPI = new notes.NotesAPI();
export const PermissionsAPI = new permissions.PermissionsAPI();
export const ReportsAPI = new reports.ReportsAPI();
export const ScheduleAPI = new schedule.ScheduleAPI();
export const SchoolsAPI = new schools.SchoolAPI();
export const SchoolUsersAPI = new schoolUsers.SchoolUsersAPI();
export const UserAPI = new user.UserAPI();