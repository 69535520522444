import * as React from "react";
import * as moment from "moment";

import DatePicker from "../../structure/DatePicker";
import PieChart from "../../structure/PieChart";

import Card from "../../structure/Card";
import { ReportsAPI } from "../../../API";


interface IMessagesSentReportProps {
  schoolId: number;
}

interface IMessagesSentReportState {
  loading: boolean;
  data: any[];
  start: moment.Moment;
  end: moment.Moment;
  pieData: any[];
  schoolEmails: number;
  schoolSMS: number;
  platformSMS: number;
  platformEmails: number;
}

const colors: string[] = [
  "4A148C",
  "B71C1C",
  "311B92",
  "FFEB3B",
  "01579B",
  "1B5E20",
  "F57F17",
  "263238",
  "1DE9B6",
];

class MessagesSentReport extends React.Component<IMessagesSentReportProps, IMessagesSentReportState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: [],
      pieData: [],
      start: moment().date(1),
      end: moment().add(1, "month").date(1),
      schoolEmails: 0,
      schoolSMS: 0,
      platformSMS: 0,
      platformEmails: 0,
    };

    this.fetch = this.fetch.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Card title="Dates" loading={this.state.loading} help="">
              <div className="row">
                <div className="col-md-6">
                  <label>From</label>
                  <DatePicker date={this.state.start} onDateSaved={this.handleStartDateChanged} />
                </div>
                <div className="col-md-6">
                  <label>To</label>
                  <DatePicker date={this.state.end} onDateSaved={this.handleEndDateChanged} />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-md-offset-1">
            <Card title="Messages Sent" loading={this.state.loading} help="">
              <div className="row" style={{marginBottom: 5}}>
                <div className="col-md-2 row-header">
                  Sent On
                </div>
                <div className="col-md-2 row-header">
                  To
                </div>
                <div className="col-md-2 row-header">
                  Message Type
                </div>
                <div className="col-md-2 row-header">
                  Sent From
                </div>
                <div className="col-md-4 row-header">
                  Subject
                </div>
              </div>
              {this.state.data.map((item: any, index: number) => {
                return (
                  <div className={index % 2 === 1 ? "list-row-odd row" : "row"} key={index} style={{marginBottom: 5}}>
                    <div className="col-md-2">
                      {moment(item.sentOn).format("MM/DD/YY HH:mm")}
                    </div>
                    <div className="col-md-2">
                      {item.firstName} {item.lastName}
                    </div>
                    <div className="col-md-2">
                      {item.messageType === "sms"? "SMS" : "Email"}
                    </div>
                    <div className="col-md-2">
                      {item.sendSource === "school" ? "School": "Platform"}
                    </div>
                    <div className="col-md-4">
                      {item.sendSource === "sms" ? 
                        (item.body.length > 20 ? item.body.substr(0,18) + "..." : item.body) : 
                        (item.subject.length > 20 ? item.subject.substr(0,18) + "..." : item.subject)}
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <div className="col-md-3">
                  <strong>Total School Emails Sent: {this.state.schoolEmails}</strong>
                </div>
                <div className="col-md-3">
                  <strong>Total School SMS Sent: {this.state.schoolSMS}</strong>
                </div>
                <div className="col-md-3">
                  <strong>Total Platform Emails Sent: {this.state.platformEmails}</strong>
                </div>
                <div className="col-md-3">
                  <strong>Total Platform SMS Sent: {this.state.platformSMS}</strong>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-2">
            <Card title="Distribution" loading={this.state.loading} help="">
            <PieChart data={this.state.pieData} />
            </Card>
          </div>
      </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try {
        const res = await ReportsAPI.getMessagesSentReport(this.props.schoolId, this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD"));
        let schoolEmails = 0;
        let schoolSMS = 0;
        let platformEmails = 0;
        let platformSMS = 0;
        for(const d of res.body.data){
          if(d.sendSource === "school"){
            if(d.messageType === "sms"){
              schoolSMS++;
            } else if(d.messageType === "email"){
              schoolEmails++;
            }
          } else if(d.sendSource === "platform"){
            if(d.messageType === "sms"){
              platformSMS++;
            } else if(d.messageType === "email"){
              platformEmails++;
            }
          }
        }
        const pieData: any = [{
          name: "School SMS",
          value: schoolSMS,
          color: colors[0],
        },{
          name: "School Emails",
          value: schoolEmails,
          color: colors[1],
        },{
          name: "Platform SMS",
          value: platformSMS,
          color: colors[2],
        },{
          name: "Platform SMS",
          value: platformSMS,
          color: colors[3],
        }];
        this.setState({loading: false, data: res.body.data, pieData, schoolEmails, schoolSMS, platformEmails, platformSMS});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private handleStartDateChanged(newDate: moment.Moment){
    this.setState({start: newDate}, () => { this.fetch();});
  }

  private handleEndDateChanged(newDate: moment.Moment){
    this.setState({end: newDate}, () => { this.fetch();});
  }

}


export default MessagesSentReport;