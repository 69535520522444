import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../../structure/Card";
import { error, success } from "../../../structure/Alert";
import Markdown from "../../../structure/MarkdownTextarea";
import * as AppActions from "../../../../reducers/appReducer";
import { MessagesAPI } from "../../../../API";

interface ISendNewMessageProps {
  appActions: any;
  history: any;
  school: any;
  user: any;
  onMessageSent: any;
}

interface ISendNewMessageState {
  loading: boolean;
  messageType: "email" | "sms";
  subject: string;
  body: string;
}

class SendNewMessage extends React.Component<ISendNewMessageProps, ISendNewMessageState> {

  // TODO: we should look up if the school has payment info before allowing them to send custom messages
  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      messageType: "email",
      subject: "",
      body: ""
    };

    this.updateField = this.updateField.bind(this);
    this.updateBody = this.updateBody.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  public render() {
    return (
      <Card title="Send New Message" loading={this.state.loading} help="You may send an email or SMS to the student if your school has been activated to allow sending messages. Note that there is generally a cost to do this and some messages are sent automatically, such as invoices and payments (automatic messages do not count towards your message quota).">
        <div className="form-group">
          <label>Message Type</label>
          <select id="messageType" className="form-control" value={this.state.subject} onChange={this.updateField}>
            <option value="email">Email</option>
            <option value="sms" disabled={true}>SMS - Coming Soon</option>
          </select>
        </div>
        {this.state.messageType === "email" && (
          <div className="form-group">
            <label>Subject</label>
            <input type="text" id="subject" className="form-control" value={this.state.subject} onChange={this.updateField} />
          </div>
        )}
        {this.state.messageType === "sms" && (
          <div className="form-group">
            <p><strong>Note:</strong> You can only send an SMS to a user with a valid phone number and if you have a valid payment method on record.</p>
          </div>
        )}
        <div className="form-group">
          <label>Body</label>
          {this.state.messageType === "sms" ? 
            (<textarea id="body" className="form-control" value={this.state.body} onChange={this.updateField} rows={10}/>) : 
            (<Markdown content={this.state.body} onChange={this.updateBody} showToggle={true} mode="edit"  showHelp={true} />)
          }
        </div>
        <div className="form-group">
          <button className="btn btn-block btn-success" onClick={this.sendMessage}>Send Message</button>
        </div>
      </Card>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateBody(newBody: string){
    const ns: any = this.state;
    ns.body = newBody;
    this.setState(ns);
  }

  private sendMessage(){
    const data = {
      ...this.state
    };
    if(data.body === ""){
      return error("Body cannot be blank");
    }
    if(data.messageType === "email" && data.subject === ""){
      return error("Subject cannot be blank for emails");
    }
    
    this.setState({loading: true}, async () => {
      try{
        await MessagesAPI.sendMessageToUser(this.props.school.id, this.props.user.id, data);
        this.setState({loading: false, subject: "", body: ""}, () => {
          this.props.onMessageSent();
          success("Message queued!");
        });
      } catch(err){
        if(err.body && err.body.data && err.body.data.code === "user_no_email"){
          error("User has not agreed to receive emails from the school");
          // TODO: we should check this on page load really
        } else {
          error("Could not send that message.");
        }
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendNewMessage) as React.ComponentType<any>);