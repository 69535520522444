import * as React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

interface IAnnouncementItem {
  id: number;
  posted: string;
  body: string;
  title: string;
  source: "platform" | "school";
  target: "all" | "schools" | "students";
}


interface IAnnouncementItemProps {
  className: string;
  announcement: IAnnouncementItem;
  onDelete: any;
}

interface IAnnouncementItemState {
  loading: boolean;
  showDeleteModal: boolean;
  showSelectModal: boolean;
}

class AnnouncementItem extends React.Component<IAnnouncementItemProps, IAnnouncementItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showDeleteModal: false,
      showSelectModal: false,
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleSelectModal = this.toggleSelectModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-3 vertical-container">
          {moment(this.props.announcement.posted).format("MM/DD/YY")}
        </div>
        <div className="col-md-7 vertical-container">
          {this.props.announcement.title}
        </div>
        <div className="col-md-2 vertical-container pull-right">
          <span className="text-primary glyphicon glyphicon-eye-open" onClick={this.toggleSelectModal} style={{ marginRight: 15 }} />
          <span className="text-danger glyphicon glyphicon-remove" onClick={this.toggleDeleteModal} />
        </div>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Announcement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Warning! This will delete the announcement. Some students may have seen the announcement already.
  </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleDelete}>Yes, Delete It</button>
            <button className="btn btn-primary" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showSelectModal} onHide={this.toggleSelectModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>{this.props.announcement.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Posted on: {moment(this.props.announcement.posted).format("MM/DD/YYYY")}</p>
            <p>{this.props.announcement.body}</p>
  </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.toggleSelectModal}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleSelectModal() {
    this.setState({ showSelectModal: !this.state.showSelectModal });
  }

  private toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private handleDelete() {
    this.props.onDelete(this.props.announcement);
    this.setState({ showDeleteModal: false, showSelectModal: false });
  }
}

export default AnnouncementItem;