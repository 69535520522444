import * as React from "react";
import { Modal } from "react-bootstrap";

import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";
import { CompetitionsAPI } from "../../../API";

import ListItem from "./SchoolCompetitionEventListItem";

interface ISchoolCompetitionsEventsListProps {
  selectedCompetition: any;
  selectedEvent: any;
  onNewEvent: any;
  onDeleteEvent: any;
  onSelectEvent: any;
  schoolId: number;
}

interface ISchoolCompetitionsEventsListState {
  loading: boolean;
  showNewModal: boolean;
  showDeleteModal: boolean;
  name: string;
  eventType: string;
  gender: "male" | "female" | "mixed" | "unknown" | "n/a";
}

class SchoolCompetitionsEventsList extends React.Component<ISchoolCompetitionsEventsListProps, ISchoolCompetitionsEventsListState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showNewModal: false,
      showDeleteModal: false,
      name: "",
      eventType: "other",
      gender: "unknown"
    };

    this.updateField = this.updateField.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.toggleShowNewModal = this.toggleShowNewModal.bind(this);
    this.newEvent = this.newEvent.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
  }

  public render() {
    return (
      <div>
      <Card title={`${this.props.selectedCompetition.name} Events`} loading={this.state.loading} help="Events are the primary activities during a competition. You can set them up in advance and link students to the events, leaving only updating their placement for when the event occurs. The more details you can provide for an event, the better the reporting and tracking will be.">
      <div className="row" style={{ marginBottom: 10 }}>
        <div className="col-md-4 vertical-container">
          <strong>Event</strong>
        </div>
        <div className="col-md-3 vertical-container">
          <strong>Type</strong>
        </div>
        <div className="col-md-3 vertical-container">
          <strong>Gender</strong>
        </div>
        <div className="col-md-2 vertical-container" />
      </div>
      {this.props.selectedCompetition.events.length === 0 && (<div className="row">
            <div className="col-md-12">
              <strong>No events have been entered for that competition</strong>
            </div>
          </div>) }
              {this.props.selectedCompetition.events.map((event: any, index: number) => {
                return(
                  <ListItem
                    key={event.id}
                    item={event}
                    className={index % 2 === 1 ? "list-row-odd" : ""}
                    onSelect={this.handleSelectEvent}
                    onDelete={this.handleDeleteEvent} />
                );
              })}
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-block btn-primary" onClick={this.toggleShowNewModal}>Create New Competition Event</button>
            </div>
          </div>
      </Card>

      <Modal show={this.state.showNewModal} onHide={this.toggleShowNewModal} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>Create New Competition Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>Name</label>
          <input type="text" id="name" className="form-control" value={this.state.name} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Event Type</label>
          <select id="eventType" className="form-control" value={this.state.eventType} onChange={this.updateField}>
            <option value="sparring">Sparring</option>
            <option value="forms">Forms</option>
            <option value="breaking">Breaking</option>
            <option value="creative">Creative</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Event Gender</label>
          <select id="gender" className="form-control" value={this.state.gender} onChange={this.updateField}>
            <option value="unknown">Unknown</option>
            <option value="mixed">Mixed</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="n/a">Not Applicable</option>
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-success" onClick={this.newEvent}>Create New Competition Event</button>
        <button className="btn" onClick={this.toggleShowNewModal}>Nevermind</button>
      </Modal.Footer>
    </Modal>

    <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>Remove {this.props.selectedEvent.name}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you absolutely sure you want to delete this event? This cannot be undone!
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger" onClick={this.deleteEvent}>Yes, Delete This Event</button>
        <button className="btn" onClick={this.toggleShowDeleteModal}>Nevermind</button>
      </Modal.Footer>
    </Modal>
    </div>
    );
  }

  private toggleShowNewModal(){
    this.setState({showNewModal: !this.state.showNewModal});
  }
  private toggleShowDeleteModal(){
    this.setState({showDeleteModal: !this.state.showDeleteModal});
  }

  private updateField(e: any){
    const id = e.target.id;
    const value = e.target.value;
    const ns = this.state;
    ns[id] = value;
    this.setState(ns);
  }

  private newEvent(){
    if(this.state.name === ""){
      return error("Name cannot be blank!");
    }
    const data = {
      name: this.state.name,
      eventType: this.state.eventType,
      gender: this.state.gender,
    };
    this.setState({ loading: true}, async () => {
      try{
        const res = await CompetitionsAPI.createSchoolCompetitionEvent(this.props.schoolId, this.props.selectedCompetition.id, data);
        success("Event created!");
        this.setState({loading: false, showNewModal: false, name: ""});
        res.body.data.users = [];
        this.props.onNewEvent(res.body.data);
      }catch(err){
        error("Could not create that event");
        this.setState({ loading: false });
      }
    });
  }



  private handleDeleteEvent(event: any){
    this.props.onSelectEvent(event);
    this.setState({ showDeleteModal: true});
  }

  private deleteEvent(){
    this.setState({ loading: true}, async () => {
      try{
        await CompetitionsAPI.deleteSchoolCompetitionEvent(this.props.schoolId, this.props.selectedEvent.competitionId, this.props.selectedEvent.id);
        success("Event deleted!");
        this.setState({loading: false, showDeleteModal: false });
        this.props.onDeleteEvent(event);
      }catch(err){
        error("Could not delete that event");
        this.setState({loading: false});
      }
    });
  }

  private handleSelectEvent(event: any){
    this.props.onSelectEvent(event);
  }
}

export default SchoolCompetitionsEventsList;