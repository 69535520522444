import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LazyLoad from "react-lazyload";
import { Modal } from "react-bootstrap";

import Card from "../../structure/Card";
import MessageTemplateListItem from "./MessageTemplateListItem";
import { error, success } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";
import { MessagesAPI } from "../../../API/";

interface IMessageTemplate {
  id: number;
  status: string;
  body: string;
  description: string;
  name: string;
  shortName: string;
  subject: string;
  messageType: string;
  platformTemplateId: number;
  schoolId: number;
  placeholders: any;
}

interface IMessageConfigurationScreenProps {
  schoolState: any;
}

interface IMessageConfigurationScreenState {
  loading: boolean;
  templates: IMessageTemplate[];
  selectedTemplate: IMessageTemplate;
  newSubject: string;
  newBody: string;
  newStatus: string;
  showResetAllModal: boolean;
}

const blankTemplate = {
  status: "inactive",
  body: "",
  description: "",
  id: 0,
  messageType: "email",
  name: "",
  platformTemplateId: 0,
  schoolId: 0,
  shortName: "",
  subject: "",
  placeholders: [],
};

class MessageConfigurationScreen extends React.Component<IMessageConfigurationScreenProps, IMessageConfigurationScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      templates: [],
      selectedTemplate: blankTemplate,
      newSubject: "",
      newBody: "",
      newStatus: "false",
      showResetAllModal: false
    };

    this.fetch = this.fetch.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.updateField = this.updateField.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleResetAll = this.handleResetAll.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveAndSend = this.handleSaveAndSend.bind(this);
    this.toggleShowResetAllModal = this.toggleShowResetAllModal.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <Card title="Templates" loading={this.state.loading} help="For messages sent by the platform, we allow schools to customize the template. Many of these messages are automatically generated and sent by the system if active. If you do not want the messages to be sent, you may mark them as inactive.">
            <div className="note-overflow-container" style={{height: 400}}>
              {this.state.templates.map((template: IMessageTemplate, index: number) => {
                return(
                  <LazyLoad once={true} key={index} overflow={true} throttle={100}>
                    <MessageTemplateListItem 
                      template={template}
                      className={index % 2 === 1 ? "list-row-odd" : ""}
                      onReset={this.handleReset}
                      onSelect={this.selectTemplate}
                    />
                  </LazyLoad>
                );
              })}
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-danger" onClick={this.toggleShowResetAllModal}>Reset All Templates</button>
            </div>
          </Card>
        </div>
        <div className="col-md-5">
          {this.state.selectedTemplate.id !== 0 && (
          <Card title={this.state.selectedTemplate.name} loading={this.state.loading} help="You may edit the default template below. Please note that we will automatically add the school logo (if there is one) and a signature block at the bottom of each template, so do not include those in your template. If you would like to see what your template will look like, please choose 'Save and Send To Me', which will save the template and then send a copy to your email with fake data.">
            <div className="form-group">
              <label>Status</label>
              <select id="newStatus" className="form-control" value={this.state.newStatus} onChange={this.updateField}>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="testing">In Testing</option>
              </select>
            </div>
            {this.state.newStatus === "testing" && (
              <div className="form-group">
                <p className="small">If set to "testing", the email will only be sent to the administrators and will include information about who the template would have been sent to.</p>
              </div>
            )}
            <div className="form-group">
              <label>Subject</label>
              <input type="text" id="newSubject" className="form-control" value={this.state.newSubject} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea id="newBody" className="form-control" value={this.state.newBody} onChange={this.updateField} rows={10}/>
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.handleSave}>Save</button>
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.handleSaveAndSend}>Save and Send Sample To Me</button>
            </div>
          </Card>
          )}
        </div>
        <div className="col-md-3">
          {this.state.selectedTemplate.id !== 0 && (
          <Card title="Placeholders" loading={this.state.loading} help="Placeholders are variables that you may use in the template. You can type them anywhere in the subject or body of the message. Please remember that you must include the { and } on the sides of the placeholders and do not include any spaces in the placeholder value.">
            {Object.keys(this.state.selectedTemplate.placeholders).map((p: any) => {
              return (
                <div className="row" key={p} style={{marginBottom: 3}}>
                  <div className="col-md-4"><strong>{p}</strong></div>
                  <div className="col-md-8">{this.state.selectedTemplate.placeholders[p]}</div>
                </div>
              );
            })}
          </Card>
          )}
        </div>

        <Modal show={this.state.showResetAllModal} onHide={this.toggleShowResetAllModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Reset Templates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Warning! This will reset all of the templates to the original settings and layout. This cannot be undone. Are you sure you want to reset ALL templates to the original templates?

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleResetAll}>Yes, Reset All Templates</button>
            <button className="btn btn-primary" onClick={this.toggleShowResetAllModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>

    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private async fetch(){
    this.setState({ loading: true }, async () => {
      try{
        const mR = await MessagesAPI.getSchoolTemplates(this.props.schoolState.school.id);
        this.setState({loading: false, templates: mR.body.data});
      }catch(err){
        error("Could not load templates");
        this.setState({ loading: false});
      }
    });
  }

  private selectTemplate(templateId: number){
    let template: any = {};
    for(const t of this.state.templates){
      if(t.id === templateId){
        template = t;
      }
    }
    this.setState({selectedTemplate: template, newSubject: template.subject, newBody: template.body, newStatus: template.status});
  }

  private handleReset(platformTemplateId: number){
    this.setState({loading: true}, async () => {
      try{
        await MessagesAPI.resetSchoolTemplate(this.props.schoolState.school.id, platformTemplateId);
        this.setState({loading: false, newSubject: "", newBody: "", selectedTemplate: blankTemplate}, () => {
          success("Template reset!");
          this.fetch();
        });
      } catch(err){
        this.setState({loading: false}, () => {
          error("Could not reset that template");
        });
      }
    });
  }

  private handleResetAll(){
    this.setState({loading: true}, async () => {
      try{
        await MessagesAPI.resetSchoolTemplates(this.props.schoolState.school.id);
        this.setState({loading: false, newSubject: "", newBody: "", selectedTemplate: blankTemplate, showResetAllModal: false}, () => {
          success("Templates reset!");
          this.fetch();
        });
      } catch(err){
        this.setState({loading: false, showResetAllModal: false}, () => {
          error("Could not reset all templates");
        });
      }
    });
  }

  private handleSave(){
    if(this.state.newBody === "" || this.state.newSubject === ""){
      return error("Subject and Body are required");
    }
    this.setState({loading: true}, async () => {
      try{
        await MessagesAPI.updateSchoolTemplate(this.props.schoolState.school.id, this.state.selectedTemplate.platformTemplateId, {
          subject: this.state.newSubject,
          body: this.state.newBody,
          status: this.state.newStatus,
        });
        this.setState({loading: false}, () => {
          success("Template saved!");
          this.fetch();
        });
      } catch(err){
        this.setState({loading: false}, () => {
          error("Could not update that template");
        });
      }
    });
  }

  private handleSaveAndSend(){
    if(this.state.newBody === "" || this.state.newSubject === ""){
      return error("Subject and Body are required");
    }
    this.setState({loading: true}, async () => {
      try{
        await MessagesAPI.sendSchoolTemplateSample(this.props.schoolState.school.id, this.state.selectedTemplate.platformTemplateId);
        this.setState({loading: false}, () => {
          success("Template saved and sample sent to you!");
        });
      } catch(err){
        this.setState({loading: false}, () => {
          error("Could not update that template");
        });
      }
    });
  }

  private toggleShowResetAllModal(){
    this.setState({showResetAllModal: !this.state.showResetAllModal});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageConfigurationScreen) as React.ComponentType<any>);