import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../../reducers/appReducer";

// import UserExams from "./UserExams";
import UserMaterialContainer from "./UserMaterialContainer";

interface IUserKnowledgeScreenProps {
  appActions: any;
  history: any;
  location: any;
  schoolState: any;
}

interface IUserKnowledgeScreenState {
  loading: boolean;
  userId: number;
}

class UserKnowledgeScreen extends React.Component<IUserKnowledgeScreenProps, IUserKnowledgeScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      userId: 0
    };

  }

  public componentDidMount(){
    this.parse(this.props);
  }

  public componentWillReceiveProps(nextProps: any){
    if(this.props.location.pathname !== nextProps.location.pathname){
      this.parse(nextProps);
    }
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <UserMaterialContainer 
              userId={this.state.userId}
              schoolId={this.props.schoolState.school.id} />
          </div>
          {/* <div className="col-md-6">
            <UserExams 
              userId={this.state.userId}
              schoolId={this.props.schoolState.school.id} />
          </div> */}
        </div>
      </div>
    );
  }

  private parse(props: any){
    const userId = props.match.params.userId ? parseInt(props.match.params.userId, 10) : 0;
    this.setState({userId, loading: false});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserKnowledgeScreen) as React.ComponentType<any>);