import * as React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import { ScrollBox } from "react-scroll-box";

import DatePicker from "../../structure/DatePicker";
import { FinancesAPI } from "../../../API/";
import { error, success } from "../../structure/Alert";
import { format } from "../../../utils/currency";


interface IContract {
  id: number;
  daysRemaining: number;
  invoices: any;
  lengthRaw: number;
  lengthUnit: string;
  monthlyPayment: number;
  notes: string;
  numberDays: number;
  numberPayments: number;
  pauses: any;
  paymentStartDate: moment.Moment;
  projectedEndDate: moment.Moment;
  schoolId: number;
  startDate: moment.Moment;
  status: string;
  statusDate: moment.Moment;
  terms: string;
  users: any;
  initialPayment: number;
  initialPaymentShouldCharge: boolean;
  invoicesShouldCharge: boolean;
}

const blankContract: IContract = {
  id: 0,
  daysRemaining: 0,
  invoices: [],
  lengthRaw: 0,
  lengthUnit: "months",
  monthlyPayment: 0,
  notes: "",
  numberDays: 0,
  numberPayments: 0,
  pauses: [],
  paymentStartDate: moment(),
  projectedEndDate: moment(),
  schoolId: 0,
  startDate: moment(),
  status: "active",
  statusDate: moment(),
  terms: "",
  users: [],
  initialPayment: 0,
  initialPaymentShouldCharge: true,
  invoicesShouldCharge: true
};

interface IContractProps {
  schoolActions: any;
  show: boolean;
  schoolId: number;
  contract: any;
  contractId: any;
  students: any;
  selectedStudent?: any;
  terms: string;
  userId?: number | string;
  user?: any;
  onClose: () => void;
  onDelete: () => void;
  onSave: () => void;
}

interface IContractState {
  loading: boolean;
  show: boolean;
  selectedContract: IContract;
  mode: string;
  showCancelModal: boolean;
  showPauseModal: boolean;
  showUnpauseModal: boolean;
  showDeletePauseModal: boolean;
  showUpdateInvoicesModal: boolean;
  pauseResumeType: string;
  pauseResumeDate: moment.Moment;
  pauseStartDate: moment.Moment;
  unpauseInvoiceDate: moment.Moment;
  updateInvoicesNotes: string;
  updateInvoicesAmount: string;
  updateInvoicesChargeDay: string;
}

class Contract extends React.Component<IContractProps, IContractState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      mode: "view",
      selectedContract: blankContract,
      showCancelModal: false,
      showPauseModal: false,
      showUnpauseModal: false,
      showDeletePauseModal: false,
      showUpdateInvoicesModal: false,
      pauseResumeType: "indefinite",
      pauseResumeDate: moment(),
      unpauseInvoiceDate: moment(),
      pauseStartDate: moment(),
      updateInvoicesNotes: "",
      updateInvoicesAmount: "0",
      updateInvoicesChargeDay: "01",
    };
    this.toggle = this.toggle.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateInvoiceField = this.updateInvoiceField.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleChargeDateChanged = this.handleChargeDateChanged.bind(this);
    this.handleLengthChanged = this.handleLengthChanged.bind(this);
    this.handlePauseResumeDateChanged = this.handlePauseResumeDateChanged.bind(this);
    this.handlePauseStartDateChanged = this.handlePauseStartDateChanged.bind(this);
    this.handlePauseResumeTypeChange = this.handlePauseResumeTypeChange.bind(this);
    this.handleUnpauseInvoiceDateChanged = this.handleUnpauseInvoiceDateChanged.bind(this);
    this.calculateDates = this.calculateDates.bind(this);
    this.toggleCancelContract = this.toggleCancelContract.bind(this);
    this.togglePauseContract = this.togglePauseContract.bind(this);
    this.toggleUnpauseContract = this.toggleUnpauseContract.bind(this);
    this.toggleDeletePause = this.toggleDeletePause.bind(this);
    this.toggleContractInvoicesModal = this.toggleContractInvoicesModal.bind(this);
    this.saveContract = this.saveContract.bind(this);
    this.handlePrintButton = this.handlePrintButton.bind(this);
    this.printContract = this.printContract.bind(this);
    this.cancelContract = this.cancelContract.bind(this);
    this.pauseContract = this.pauseContract.bind(this);
    this.unPauseContract = this.unPauseContract.bind(this);
    this.deletePause = this.deletePause.bind(this);
    this.getContractPauses = this.getContractPauses.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.contractHasActivePause = this.contractHasActivePause.bind(this);
    this.renderTypeahead = this.renderTypeahead.bind(this);
    this.handleSelectedStudent = this.handleSelectedStudent.bind(this);
    this.updateInvoices = this.updateInvoices.bind(this);
  }

  public componentDidUpdate(prevProps: IContractProps) {
    if (prevProps.contractId !== this.props.contractId || prevProps.show !== this.props.show) {
      const props: any = {
        contract: {
          ...this.props.contract
        }
      };
      let mode = "new";
      if (props.contract.id === 0 || !props.contract.id) {
        // fill in sane default
        mode = "new";
        props.contract = blankContract;
        // below is copied from the type ahead
        if (this.props.userId && this.props.user) {
          // so, somehow I goofed here
          // in the original implementation, user is a single object, even tho invoices is an array
          // since I am not 100% of where this is used, for NOW I am doing a check on the type
          // when I am confident, I can refactor
          if(Array.isArray(this.props.user)){
            props.contract.users = [{
              id: typeof this.props.userId === "string" ? parseInt(this.props.userId,10) : this.props.userId, 
              firstName: this.props.user[0].firstName, 
              lastName: this.props.user[0].lastName, 
              fullName: `${this.props.user[0].firstName} ${this.props.user[0].lastName}`}
            ];
          } else {
            props.contract.users = [{
              id: typeof this.props.userId === "string" ? parseInt(this.props.userId,10) : this.props.userId, 
              firstName: this.props.user.firstName, 
              lastName: this.props.user.lastName, 
              fullName: `${this.props.user.firstName} ${this.props.user.lastName}`}
            ];
          }
        }
      } else {
        // parse for display
        props.contract.paymentStartDate = moment(props.contract.paymentStartDate);
        props.contract.projectedEndDate = moment(props.contract.projectedEndDate);
        props.contract.startDate = moment(props.contract.startDate);
        props.contract.statusDate = moment(props.contract.statusDate);
        props.contract.monthlyPayment = props.contract.monthlyPayment / 100;
        props.contract.initialPayment = props.contract.initialPayment ? props.contract.initialPayment / 100 : 0;
        props.contract.initialPaymentShouldCharge = props.contract.initialPaymentShouldCharge ? props.contract.initialPaymentShouldCharge : false;
        props.contract.lengthRaw = props.contract.numberDays;

        // if it is paused or active, you can ONLY edit the students
        if (props.contract.status === "active" || props.contract.status === "paused") {
          mode = "edit";
        } else {
          // it's either cancelled or complete, so it cannot be edited at all
          mode = "view";
        }
      }
      props.contract.terms = this.props.terms;
      this.setState({
        mode,
        selectedContract: props.contract
      });
    }
  }

  public render() {
    return (
      <div>

        <Modal show={this.props.show} onHide={this.toggle} dialogClassName="modal-x-large" backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Students</label>
                  {this.state.mode === "new" ? (this.renderTypeahead()) : this.formatReadonlyNames()}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Starts</label>
                  {this.state.mode === "new" ?
                    (<DatePicker date={this.state.selectedContract.startDate} onDateSaved={this.handleStartDateChanged} />) :
                    (<input type="text" disabled={true} className="form-control" value={this.state.selectedContract.startDate.format("MM/DD/YYYY")} />)}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Length</label>
                  <input type="number" id="lengthRaw" disabled={this.state.mode !== "new"} className="form-control" value={this.state.selectedContract.lengthRaw} onChange={this.handleLengthChanged} />

                  {this.state.mode === "new" ?
                    (<select id="lengthUnit" className="form-control" value={this.state.selectedContract.lengthUnit} onChange={this.handleLengthChanged}>
                      <option value="months">Months</option>
                      <option value="weeks">Weeks</option>
                      <option value="days">Days</option>
                      <option value="years">Years</option>
                    </select>) :
                    (<input type="text" className="form-control" disabled={true} value="Days" />)}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Ends On</label>
                  <input type="text" className="form-control" disabled={true} id="endOn" value={this.state.selectedContract.projectedEndDate.format("MM/DD/YYYY")} />
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Initial Payment Due Today</label>
                  {this.state.mode === "new" ?
                    (<div>
                      <input type="number" id="initialPayment" className="form-control" value={this.state.selectedContract.initialPayment} onChange={this.updateField} />
                      <select id="initialPaymentShouldCharge" className="form-control" value={this.state.selectedContract.initialPaymentShouldCharge + ""} onChange={this.updateField} >
                        <option value="true">Charge Payment Method</option>
                        <option value="false">Paid in Person, Do Not Charge Payment Method</option>
                      </select>
                    </div>) :
                    (<input type="number" disabled={true} className="form-control" value={this.state.selectedContract.initialPayment} />)}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Paying Each Month</label>
                  {this.state.mode === "new" ? (
                  <input type="number" id="monthlyPayment" className="form-control"
                    value={this.state.selectedContract.monthlyPayment} onChange={this.updateField} />) :
                    (<input type="text" disabled={true} id="monthlyPayment" className="form-control"
                    value={format(this.state.selectedContract.monthlyPayment*100) } />)}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>For This Many Additional Monthly Payments</label>
                  <input type="number" id="numberPayments" disabled={this.state.mode !== "new"} className="form-control"
                    value={this.state.selectedContract.numberPayments} onChange={this.updateField} />

                  {this.state.mode === "new" ?
                  (<div>
                    <select id="invoicesShouldCharge" className="form-control" value={this.state.selectedContract.invoicesShouldCharge + ""} onChange={this.updateField} >
                      <option value="true">Future Invoices Should Automatically Charge</option>
                      <option value="false">Do Not Automatically Charge Future Invoices</option>
                    </select>
                  </div>) :
                  (null)}

                </div>
              </div>
              <div className="col-md-3">
              {this.state.mode === "new" ? (
                <div className="form-group">
                  <label>Payments Starting On</label>
                    <DatePicker date={this.state.selectedContract.paymentStartDate} onDateSaved={this.handleChargeDateChanged} />
                </div>) : (null)}
              </div>
            </div>


            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Terms</label>
                  <textarea id="terms" className="form-control" rows={10} disabled={this.state.mode !== "new"} value={this.state.selectedContract.terms} onChange={this.updateField} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Notes</label>
                  <textarea id="notes" className="form-control" rows={10} disabled={this.state.mode !== "new"} value={this.state.selectedContract.notes} onChange={this.updateField} />
                </div>
              </div>
              {this.state.mode !== "new" ? (
                <div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Invoices</label>
                      <ScrollBox style={{ height: "200px" }} disableScrollX={true} >
                        {this.getInvoices()}
                      </ScrollBox>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Pauses</label>
                      <ScrollBox style={{ height: "200px" }} disableScrollX={true} >
                        {this.getContractPauses()}
                      </ScrollBox>
                    </div>
                  </div></div>
              ) : null}
            </div>



          </Modal.Body>
          <Modal.Footer>
            {this.buttons}
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeletePauseModal} onHide={this.toggleDeletePause} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete that scheduled pause?

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deletePause}>Yes, Delete the Scheduled Pause</button>
            <button className="btn btn-primary" onClick={this.toggleCancelContract}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showCancelModal} onHide={this.toggleCancelContract} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to cancel this contract? This cannot be undone!

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.cancelContract}>Yes, Cancel This Contract</button>
            <button className="btn btn-primary" onClick={this.toggleCancelContract}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showPauseModal} onHide={this.togglePauseContract} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to pause this contract? Upon pausing, all future invoices will be deleted and the contract will resume either upon a resume date
            you specify OR when you manually resume the contract.
            <div className="form-group">
              <label>Contract should pause starting the morning of:</label>
              <DatePicker date={this.state.pauseStartDate} onDateSaved={this.handlePauseStartDateChanged} />
            </div>
            <div className="form-group">
              <label>Pause Contract Until:</label>
              <select className="form-control" id="pauseResumeType" onChange={this.handlePauseResumeTypeChange} value={this.state.pauseResumeType}>
                <option value="indefinite">Indefinite</option>
                <option value="date">Automatically Resume</option>
              </select>
              {this.state.pauseResumeType === "date" ? (<DatePicker date={this.state.pauseResumeDate} onDateSaved={this.handlePauseResumeDateChanged} />) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.pauseContract}>Yes, Pause This Contract</button>
            <button className="btn btn-primary" onClick={this.togglePauseContract}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showUnpauseModal} onHide={this.toggleUnpauseContract} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to unpause this contract? Upon unpausing, future invoices will be created with the first being due on the date selected:
            <div className="form-group">
              <label>Resume Invoices Starting:</label>
              <DatePicker date={this.state.unpauseInvoiceDate} onDateSaved={this.handleUnpauseInvoiceDateChanged} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.unPauseContract}>Yes, Resume This Contract</button>
            <button className="btn btn-primary" onClick={this.togglePauseContract}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showUpdateInvoicesModal} onHide={this.toggleContractInvoicesModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Use this form to change the information about any <strong>unpaid</strong> invoices tied to this contract.</p>
            <div className="form-group">
              <label>New Amount</label>
              <input type="text" className="form-control" id="updateInvoicesAmount" value={this.state.updateInvoicesAmount} onChange={this.updateInvoiceField} />
            </div>
            <div className="form-group">
              <label>Day of Month to Charge</label>
              <select className="form-control" id="updateInvoicesChargeDay" value={this.state.updateInvoicesChargeDay} onChange={this.updateInvoiceField}>
                <option value="0">Do not change</option>
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
              </select>
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea className="form-control" id="updateInvoicesNotes" value={this.state.updateInvoicesNotes} onChange={this.updateInvoiceField} />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.updateInvoices}>Update Future Invoices</button>
            <button className="btn btn-primary" onClick={this.toggleContractInvoicesModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggle() {
    this.props.onClose();
  }

  private updateField(e: any) {
    const ns: any = this.state.selectedContract;
    // react likes to be dumb with numbers, so we need to see if this is one we need to convert
    let v = e.target.value;
    const id = e.target.id;
    switch (id) {
      case "monthlyPayment":
      case "downPayment":
        v = parseFloat(v);
        break;
      case "numberPayments":
        v = parseInt(v, 10);
        break;
    }
    ns[id] = v;
    this.setState({ selectedContract: ns });
  }

  private updateInvoiceField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private handleStartDateChanged(newDate: moment.Moment) {
    const ns: any = this.state.selectedContract;
    ns.startDate = newDate;
    this.calculateDates(ns);
  }

  private handleChargeDateChanged(newDate: moment.Moment) {
    const ns: any = this.state.selectedContract;
    ns.paymentStartDate = newDate;
    this.setState({ selectedContract: ns });
  }

  private handlePauseResumeDateChanged(newDate: moment.Moment) {
    const ns: any = this.state;
    ns.pauseResumeDate = newDate;
    this.setState(ns);
  }

  private handlePauseStartDateChanged(newDate: moment.Moment) {
    const ns: IContractState = this.state;
    ns.pauseStartDate = newDate;
    this.setState(ns);
  }

  private handleUnpauseInvoiceDateChanged(newDate: moment.Moment) {
    const ns: any = this.state;
    ns.unpauseInvoiceDate = newDate;
    this.setState(ns);
  }

  private handlePauseResumeTypeChange(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private handleLengthChanged(e: any) {
    const current = this.state.selectedContract;
    if (e.target.id === "lengthRaw") {
      let v = e.target.value;
      if (v === "" || isNaN(parseInt(v, 10))) {
        v = 0;
      }
      current.lengthRaw = parseInt(v, 10);
    } else {
      current.lengthUnit = e.target.value;
    }
    this.calculateDates(current);
  }

  private calculateDates(current: IContract) {
    // we need to calculate the number of days AND the end date
    const start = moment(current.startDate);
    let endDate = moment(start);
    if (current.lengthUnit === "months") {
      endDate = endDate.add(current.lengthRaw, current.lengthUnit);
    } else if (current.lengthUnit === "weeks") {
      endDate = endDate.add(current.lengthRaw, current.lengthUnit);
    } else if (current.lengthUnit === "years") {
      endDate = endDate.add(current.lengthRaw, current.lengthUnit);
    }
    current.numberDays = endDate.diff(start, "days");
    current.projectedEndDate = endDate;
    this.setState({ selectedContract: current });
  }

  private get buttons() {
    const buttons: any = [];
    if (this.state.mode === "new") {
      buttons.push(<button key="save-button" className="btn btn-success" onClick={this.saveContract}>Save Contract</button>);
    } else if (this.state.mode === "edit") {
      if(this.props.contract.status === "paused"){
        buttons.push(<button key="pause-button" className="btn btn-warning" onClick={this.toggleUnpauseContract}>Unpause Contract</button>);
      }else{
        if (!this.contractHasActivePause()) {
          if (this.state.selectedContract.status === "active") {
            buttons.push(<button key="pause-button" className="btn btn-warning" onClick={this.togglePauseContract}>Pause Contract</button>);
            if(this.state.selectedContract.invoices.length > 0){
              buttons.push(<button key="contract-invoices-button" className="btn btn-primary" onClick={this.toggleContractInvoicesModal}>Update Contract Invoices</button>);
            }
          } else {
            buttons.push(<button key="pause-button" className="btn btn-warning" onClick={this.toggleUnpauseContract}>Unpause Contract</button>);
          }
        }
      }

      buttons.push(<button key="delete-button" className="btn btn-danger" onClick={this.toggleCancelContract}>Cancel Contract</button>);
      buttons.push(<button key="print-button" className="btn" onClick={this.handlePrintButton}>Print</button>);
    } else {
      buttons.push(<button key="print-button" className="btn" onClick={this.handlePrintButton}>Print</button>);
    }
    buttons.push(<button key="close-button" className="btn" onClick={this.toggle}>Close</button>);
    return buttons;
  }

  private getContractPauses() {
    if (!this.props.contract.pauses || this.props.contract.pauses.length === 0) {
      return (
        <strong>No pauses on contract</strong>
      );
    }
    const pauses: any = [(
      <div className="row" key="pause-0">
        <div className="col-md-3">
          <strong>Start</strong>
        </div>
        <div className="col-md-4">
          <strong>End</strong>
        </div>
        <div className="col-md-4">
          <strong>Pause Status</strong>
        </div>
        <div className="col-md-1" />
      </div>
    )];
    this.props.contract.pauses.map((pause: any) => {
      pauses.push(
        <div className="row" key={`pause-${pause.startDate}-${pause.status}`}>
          <div className="col-md-3">
            {moment(pause.startDate).format("MM/DD/YY")}
          </div>
          <div className="col-md-4">
            {pause.endDate === "1970-01-01" ? "Indefinite" : moment(pause.endDate).format("MM/DD/YY")}
          </div>
          <div className="col-md-4">
            {pause.status === "complete" ? "Complete" : pause.status === "active" && this.props.contract.status === "paused" ? "Active" : "Future"}
          </div>
          <div className="col-md-1">
            {// this is in case there is a pause in the future 
            }
            {pause.status === "active" && this.props.contract.status === "active" ? (<span className="text-danger glyphicon glyphicon-trash" onClick={this.toggleDeletePause} />) : null}
            </div>
        </div>
      );
    });

    return pauses;
  }

  private getInvoices() {
    const invoices: any = [];
    if (!this.props.contract.invoices || this.props.contract.invoices.length === 0) {
      return (
        <strong>No invoices exist on this contract</strong>
      );
    }
    invoices.push(
      <div className="row" key="invoices-0">
        <div className="col-md-4">
          <strong>Due</strong>
        </div>
        <div className="col-md-4">
          <strong>Amount</strong>
        </div>
        <div className="col-md-4">
          <strong>Status</strong>
        </div>
      </div>
    );
    for (const i of this.props.contract.invoices) {
      invoices.push(
        <div className="row" key={`invoice-${i.id}`}>
          <div className="col-md-4">
            {moment(i.due).format("MM/DD/YY")}
          </div>
          <div className="col-md-4">
            {format(i.amount)}
          </div>
          <div className="col-md-4">
            {i.status === "pending" ? "Due" : i.status === "paid" ? "Paid" : "Overdue"}
          </div>
        </div>
      );
    }
    return invoices;
  }

  private updateInvoices(){
    const data: any = {
      notes: this.state.updateInvoicesNotes,
      chargeDayofMonth: this.state.updateInvoicesChargeDay
    };
    if(this.state.updateInvoicesAmount !== "" && this.state.updateInvoicesAmount !== "0"){
      data.amount = parseInt((parseFloat(this.state.updateInvoicesAmount) * 100 + ""), 10);
    }
    this.setState({loading: true}, async () => {
      try{
        const result = await FinancesAPI.updateSchoolContractInvoices(this.props.schoolId, this.props.contractId, data);
        const selectedContract = this.state.selectedContract;
        selectedContract.invoices = result.body.data;
        success("Invoices updated!");
        this.setState({ showUpdateInvoicesModal: false, selectedContract, updateInvoicesAmount:"", updateInvoicesChargeDay: "0", updateInvoicesNotes: ""}, () => {
          this.props.onSave();
        });
      }catch(err){
        error("Could not update those invoices");
        this.setState({showUpdateInvoicesModal: false});
      }
    });
  }

  private contractHasActivePause() {
    if (!this.props.contract.pauses || this.props.contract.pauses.length === 0) {
      return false;
    }
    for (const p of this.props.contract.pauses) {
      if (p.status === "active") {
        return true;
      }
    }
    return false;
  }

  private async saveContract() {
    // ok, we have two different paths here if it's new or an update
    const data = this.state.selectedContract;
    // if (this.props.userId) {
    //   const uId = typeof this.props.userId === "string" ? parseInt(this.props.userId, 10) : this.props.userId;
    //   data.users = [{ id: uId }];
    // }
    if (data.users.length === 0) {
      return error("You must select at least one user for that contract");
    }
    if (data.lengthRaw === 0) {
      return error("Length cannot be 0");
    }
    this.setState({ loading: true }, async () => {
      try {
        data.initialPayment = Math.round(data.initialPayment * 100);
        data.monthlyPayment = Math.round(data.monthlyPayment * 100);
        let res: any = null;
        if (data.id === 0) {
          res = await FinancesAPI.createSchoolContract(this.props.schoolId, data);
        } else {
          res = await FinancesAPI.updateSchoolContract(this.props.schoolId, data.id, data);
        }
        data.initialPayment = data.initialPayment / 100;
        data.monthlyPayment = data.monthlyPayment / 100;
        this.props.schoolActions.saveUserContract(res.body.data);
        success("Saved!");
        this.setState({ loading: false, show: false }, () => {
          this.props.onSave();
          this.printContract(res.body.data.id);
        });
      } catch (err) {
        data.initialPayment = data.initialPayment / 100;
        data.monthlyPayment = data.monthlyPayment / 100;
        this.setState({ loading: false }, () => {
          error("Could not save that contract");
        });
      }
    });

  }

  private toggleCancelContract() {
    this.setState({
      showCancelModal: !this.state.showCancelModal
    });
  }

  private togglePauseContract() {
    this.setState({
      showPauseModal: !this.state.showPauseModal
    });
  }

  private toggleUnpauseContract() {
    this.setState({
      showUnpauseModal: !this.state.showUnpauseModal
    });
  }

  private toggleContractInvoicesModal(){
    const state: any = {
      showUpdateInvoicesModal: !this.state.showUpdateInvoicesModal
    };
    if(this.state.selectedContract.invoices.length === 0){
      return; // wtf?
    }
    const inv = this.state.selectedContract.invoices[this.state.selectedContract.invoices.length - 1];
    // if we are going to open, set the updates
    if(state.showUpdateInvoicesModal){
      state.updateInvoicesNotes = inv.notes;
      state.updateInvoicesAmount = format(inv.amount, true);
      state.updateInvoicesChargeDay = inv.due[8] + inv.due[9];
    } else {
      state.updateInvoicesNotes = "";
      state.updateInvoicesAmount = "0";
      state.updateInvoicesChargeDay = "01";
    }
    this.setState(state);
  }

  private toggleDeletePause() {
    this.setState({
      showDeletePauseModal: !this.state.showDeletePauseModal
    });
  }

  private async cancelContract() {
    // delete and notify
    try {
      const res = await FinancesAPI.updateContract(this.props.schoolId, this.props.contractId, { status: "cancelled" });
      this.props.schoolActions.saveUserContract(res.body.data);
      this.setState({
        show: false,
        showCancelModal: false
      }, () => {
        this.props.onDelete();
      });
    } catch (err) {
      return error("Could not cancel that contract. Please contact support");
    }

  }

  private async pauseContract() {
    const data: any = {
      endDate: this.state.pauseResumeDate.format("YYYY-MM-DD"),
      startDate: this.state.pauseStartDate.format("YYYY-MM-DD"),
    };
    if (this.state.pauseResumeType === "indefinite") {
      data.indefinite = true;
    }
    try {
      const res = await FinancesAPI.pauseSchoolContract(this.props.schoolId, this.props.contractId, data);
      this.props.schoolActions.saveUserContract(res.body.data);
      this.props.onSave();
      success("Contract paused!");
    } catch (err) {
      return error("Could not pause that contract");
    }
    this.setState({
      showPauseModal: false
    });
  }

  private async unPauseContract() {
    const data = {
      invoiceResumeDate: this.state.unpauseInvoiceDate.format("YYYY-MM-DD")
    };
    try {
      const res = await FinancesAPI.unpauseSchoolContract(this.props.schoolId, this.props.contractId, data);
      this.props.schoolActions.saveUserContract(res.body.data);
      this.props.onSave();
      success("Contract unpaused!");
    } catch (err) {
      return error("Could not unpause that contract");
    }
    this.setState({
      showUnpauseModal: false
    });
  }

  private async deletePause() {
    const data = {
      invoiceResumeDate: moment().format("YYYY-MM-DD")
    };
    try {
      await FinancesAPI.unpauseSchoolContract(this.props.schoolId, this.props.contractId, data);
      this.props.onSave();
      success("Contract pause deleted!");
    } catch (err) {
      return error("Could not delete that pause for that contract");
    }
    this.setState({
      showUnpauseModal: false,
      showDeletePauseModal: false,
    });
  }



  // we break this out into a button handler AND then the actual action since the onClick method can't be overridden
  private handlePrintButton(){
    this.printContract(this.props.contractId);
  }

  private async printContract(contractId: number = 0) {
    const contractIdToPrint = this.props.contractId === 0 && contractId !== 0 ? contractId : this.props.contractId;
    this.setState({ loading: true }, async () => {
      try {
        const options = { asDownload: true, accept: "application/pdf" };
        const res = await FinancesAPI.printContract(this.props.schoolId, contractIdToPrint, options);
        const blob = new Blob([res.body], { type: `application/pdf` });
        saveAs(blob, `contract-${contractIdToPrint}.pdf`);
        this.setState({ loading: false });
      } catch (err) {
        error("Could not print that contract");
        this.setState({ loading: false });
      }
    });
  }

  private formatReadonlyNames() {
    let names = "";
    if (this.state.selectedContract.users) {
      for (const u of this.state.selectedContract.users) {
        if (names !== "") {
          names += ", ";
        }
        names += u.firstName + " " + u.lastName;
      }
    }
    return (<input type="text" disabled={true} value={names} className="form-control" />);
  }

  private renderTypeahead() {
    let selectedStudent = this.props.selectedStudent ? this.props.selectedStudent : [];
    if (this.props.userId && this.props.user) {
      // so, somehow I goofed here
      // in the original implementation, user is a single object, even tho invoices is an array
      // since I am not 100% of where this is used, for NOW I am doing a check on the type
      // when I am confident, I can refactor
      if(Array.isArray(this.props.user)){
        selectedStudent = [{
          id: typeof this.props.userId === "string" ? parseInt(this.props.userId,10) : this.props.userId, 
          firstName: this.props.user[0].firstName, 
          lastName: this.props.user[0].lastName, 
          fullName: `${this.props.user[0].firstName} ${this.props.user[0].lastName}`}
        ];
      } else {
        selectedStudent = [{
          id: typeof this.props.userId === "string" ? parseInt(this.props.userId,10) : this.props.userId, 
          firstName: this.props.user.firstName, 
          lastName: this.props.user.lastName, 
          fullName: `${this.props.user.firstName} ${this.props.user.lastName}`}
        ];
      }
    }
    return (
      <Typeahead
        disabled={this.props.selectedStudent ? true : false}
        key={this.props.students.length}
        labelKey="fullName"
        multiple={true}
        placeholder="Enter Student Name"
        onChange={this.handleSelectedStudent}
        defaultSelected={selectedStudent}
        options={this.props.students} />
    );
  }

  private handleSelectedStudent(e: any) {
    if (e.length === 0) {
      return;
    }
    const si = this.state.selectedContract;
    const u: any = [];
    for (const i of e) {
      u.push({ id: i.id });
    }
    si.users = u;
    this.setState({
      selectedContract: si
    });
  }

}


export default Contract;