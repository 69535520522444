import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../../reducers/appReducer";

import UserCardInfo from "./UserCardInfo";
import SchoolInvoices from "../../Finances/SchoolInvoices";
import SchoolContracts from "../../Finances/SchoolContracts";

interface IUserFinancesScreenProps {
  appActions: any;
  history: any;
  match: any;
  user?: any;
}

interface IUserFinancesScreenState {
  loading: boolean;
  interval: number;
}

class UserFinancesScreen extends React.Component<IUserFinancesScreenProps, IUserFinancesScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      interval: 1,
    };
    this.handleContractSaved = this.handleContractSaved.bind(this);
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <UserCardInfo 
              schoolId={this.props.match.params.schoolId}
              userId={this.props.match.params.userId}/>
          </div>
          <div className="col-md-9">
              <SchoolContracts 
                userId={this.props.match.params.userId} 
                user={this.props.user}
                onSave={this.handleContractSaved}
                onDelete={this.handleContractSaved} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 col-md-offset-3">
              <SchoolInvoices 
                userId={this.props.match.params.userId}
                key={this.state.interval}
                interval={this.state.interval}
                schoolId={this.props.match.params.schoolId} />
          </div>
        </div>
      </div>
    );
  }

  private handleContractSaved(){
    // we need to retrigger the invoices
    const interval = this.state.interval + 1;
    this.setState({interval});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserFinancesScreen) as React.ComponentType<any>);