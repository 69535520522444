import * as React from "react";
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell } from "recharts";

interface IPieChartProps {
  data: any;
  toolFormatter?: any;
}

interface IPieChartState {
  loading: boolean;
}
class PieGraph extends React.Component<IPieChartProps, IPieChartState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
    };
  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
        <PieChart>
          <Pie dataKey="value" nameKey="name" isAnimationActive={true} data={this.props.data} outerRadius={120} fill="#8884d8" label={false}>
            {this.props.data.map((entry: any, index: number) => {
                return (<Cell key={`cell-${index}`} fill={"#"+entry.color} />);
              })}
          </Pie>
          <Tooltip formatter={this.props.toolFormatter ? this.props.toolFormatter : null}/>
        </PieChart>
      </ResponsiveContainer>
    );
  }

}


export default PieGraph;