import { makeCall } from "./client";

export class BeltsAPI {

  /**
   * Get the school's belts
   * @param schoolId 
   * @param params 
   */
  public getSchoolBelts(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/belts`, params);
  }

  /**
   * Create a new belt for the school
   * @param schoolId 
   * @param params 
   */
  public createSchoolBelt(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/belts`, params);
  }

  /**
   * Reorder the belts
   * @param schoolId 
   * @param params 
   */
  public reorderBelts(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/belts`, params);
  }

  /**
   * Update a single belt
   * @param schoolId 
   * @param beltId 
   * @param params 
   */
  public updateSchoolBelt(schoolId: number, beltId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/belts/${beltId}`, params);
  }

  /**
   * Delete a belt
   * @param schoolId 
   * @param beltId 
   * @param params 
   */
  public deleteSchoolBelt(schoolId: number, beltId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/belts/${beltId}`, params);
  }

  /**
   * Assign a belt to a student
   * @param schoolId 
   * @param studentId 
   * @param beltId 
   * @param params 
   */
  public assignStudentToBelt(schoolId: number, studentId: number, beltId: number, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${studentId}/belts/${beltId}`, params);
  }

  /**
   * Unassign a student from a belt
   * @param schoolId 
   * @param studentId 
   * @param beltId 
   * @param params 
   */
  public unassignStudentFromBelt(schoolId: number, studentId: number, beltId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/users/${studentId}/belts/${beltId}`, params);
  }

}