import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";
import LazyLoad from "react-lazyload";

import Card from "../../../structure/Card";
import MessageHistoryListItem from "./MessageHistoryListItem";
import * as AppActions from "../../../../reducers/appReducer";
import { MessagesAPI } from "../../../../API";

interface IMessageHistoryProps {
  appActions: any;
  history: any;
  userId: number;
  schoolId: number;
}

interface IMessageHistoryState {
  loading: boolean;
  history: any;
  start: string;
  end: string;
}

class MessageHistory extends React.Component<IMessageHistoryProps, IMessageHistoryState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      history: [],
      start: moment().day(0).format("YYYY-MM-00"),
      end: moment().add(2, "day").format("YYYY-MM-DD")
    };

    this.fetchHistory = this.fetchHistory.bind(this);
  }

  public componentDidMount(){
    if(this.props.userId !== 0){
      this.fetchHistory(this.props.userId);
    }
  }

  public componentWillReceiveProps(nextProps: any){
    if(nextProps.userId !== 0){
      this.fetchHistory(nextProps.userId);
    }
  }

  public render() {
    return (
      <Card title="Message History (This Month)" loading={this.state.loading} help="">
        <div className="row">
          <div className="col-md-3 vertical-container">
            <strong>Sent</strong>
          </div>
          <div className="col-md-2 vertical-container">
            <strong>Type</strong>
          </div>
          <div className="col-md-5 vertical-container">
            <strong>Subject</strong>
          </div>
        </div>
        {this.state.history.length === 0 && (
          <div className="row">
          <div className="col-md-12">
          <strong>None found</strong>
          </div>
          </div>
        )}
        {this.state.history.map((history: any, index: number) => {
          history.sentOn = moment(history.sentOn).format("MM/DD/YYYY HH:mm");
          return (
            <LazyLoad once={true} key={index} overflow={true} throttle={100}>
              <MessageHistoryListItem 
                message={history}
                className={index % 2 === 1 ? "list-row-odd" : ""}
              />
            </LazyLoad>
          );
        })}
        <div className="row">
          <div className="col-md-12 pull-right" style={{textAlign: "right"}}>
            <strong>Total: {this.state.history.length}</strong>
          </div>
        </div>
      </Card>
    );
  }

  private fetchHistory(userId: number){
    this.setState({loading: true}, async () => {
      try{
        const res = await MessagesAPI.getMessagesSentToUser(this.props.schoolId, userId, this.state.start, this.state.end);
        this.setState({ loading: false, history: res.body.data});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageHistory) as React.ComponentType<any>);