import * as React from "react";
import ReactTable from "react-table";

interface ITableProps {
  dataMap: any;
  columns: any;
  subField?: string;
  sortable?: boolean;
  dataIsCurrency?: boolean;
}

interface ITableState {
  loading: boolean;
  data: any;
}

export class ZipReportTable extends React.Component<ITableProps, ITableState> {


  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };

    this.processRawData = this.processRawData.bind(this);
  }

  public componentDidMount(){
    this.processRawData();
  }

  public render(){
    if(this.state.loading){
      return <div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />;
    }
    return (
      <ReactTable
        data={this.state.data}
        defaultPageSize={10}
        className="-striped -highlight"
        sortable={this.props.sortable ? this.props.sortable : true}
        columns={this.props.columns} />
    );
  }

  private processRawData(){
    const data: any[] = [];
    Object.keys(this.props.dataMap).forEach((zip: string) => {
      const d = {
        zip,
        ...this.props.dataMap[zip],
      };
      if(d.distanceFromSchool){
        d.distanceFromSchool = parseFloat(d.distanceFromSchool.toFixed(1));
      }
      if(this.props.subField && this.props.subField !== ""){
        d.last6 = this.props.dataMap[zip][this.props.subField].last6;
        d.last12 = this.props.dataMap[zip][this.props.subField].last12;
        d.last24 = this.props.dataMap[zip][this.props.subField].last24;
        if(this.props.dataIsCurrency){
          d.last6 = parseFloat((d.last6 / 100).toFixed(2));
          d.last12 = parseFloat((d.last12 / 100).toFixed(2));
          d.last24 = parseFloat((d.last24 / 100).toFixed(2));
        } else {
          d.last6 = parseFloat(d.last6.toFixed(0));
          d.last12 = parseFloat(d.last12.toFixed(0));
          d.last24 = parseFloat(d.last24.toFixed(0));
        }
      }
      data.push(d);
    });
    this.setState({ data, loading: false });
  }
}