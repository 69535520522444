import { makeCall } from "./client";

export class MessagesAPI {

  public sendContact(name: string, reason: string, body: string, email: string, browserInformation: string = "", userId: number = 0, schoolId: number = 0, url: string = "", params: any = {}): Promise<any> {
    const data = {
      ...params,
      name,
      reason,
      body,
      email,
      browserInformation,
      userId,
      schoolId
    };
    return makeCall("post", `contact`, data);
  }

  /**
   * Get the platform templates
   * @param params 
   */
  public getPlatformTemplates(params: any = {}): Promise<any> {
    return makeCall("get", `messages/templates`, params);
  }

  /**
   * Get the templates unique for the school
   * @param schoolId 
   * @param params 
   */
  public getSchoolTemplates(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/messages/templates`, params);
  }

  /**
   * Reset all message templates back to the platform template
   * @param schoolId 
   * @param params 
   */
  public resetSchoolTemplates(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/messages/templates/reset`, params);
  }

  /**
   * Reset a single template
   * @param schoolId 
   * @param templateId 
   * @param params 
   */
  public resetSchoolTemplate(schoolId: number, templateId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/messages/templates/${templateId}/reset`, params);
  }

  /**
   * Update a school template
   * @param schoolId 
   * @param templateId 
   * @param params 
   */
  public updateSchoolTemplate(schoolId: number, templateId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/messages/templates/${templateId}`, params);
  }


  public sendSchoolTemplateSample(schoolId: number, templateId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/messages/templates/${templateId}/test`, params);
  }

  /**
   * Get the messages the school has sent
   * @param schoolId 
   * @param start 
   * @param end 
   * @param params 
   */
  public getSchoolMessages(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      start,
      end,
    };
    return makeCall("get", `schools/${schoolId}/messages`, params);
  }  

  /**
   * Get the messages sent to a user
   * @param schoolId 
   * @param userId 
   * @param start 
   * @param end 
   * @param params 
   */
  public getMessagesSentToUser(schoolId: number, userId: number,  start: string, end: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      start,
      end,
    };
    return makeCall("get", `schools/${schoolId}/users/${userId}/messages`, params);
  }

  /**
   * Send a new custom message to a user
   * @param schoolId 
   * @param userId 
   * @param params 
   */
  public sendMessageToUser(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/users/${userId}/messages`, params);
  }

  public sendMessageToUsers(schoolId: number, users: number[], params: any = {}): Promise<any> {
    const data = {
      ...params,
      users
    };
    return makeCall("post", `schools/${schoolId}/messages`, data);
  }
}