import * as React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "../../structure/DatePicker";
import { format } from "../../../utils/currency";
import { FinancesAPI } from "../../../API/";
import { error, success } from "../../structure/Alert";


interface IInvoiceProps {
  show: boolean;
  schoolId: number;
  invoice: any;
  invoiceId: any;
  students: any;
  selectedStudent?: any;
  onClose: () => void;
  onDelete: () => void;
  onSave: () => void;
}

interface IInvoiceState {
  loading: boolean;
  show: boolean;
  selectedInvoice: any;
  mode: string;
  showDeleteModal: boolean;
  history: any;
  invoicePrintOptions: {
    hideCreateDate: "yes" | "no";
    hideTodaysDate: "yes" | "no";
    displayAs: "invoice" | "receipt";
    includeSchoolInformation: "yes" | "no";
    overridePaidOnDate: string;
    hideKDojangLogo: "yes" | "no";
  };
  showPrintOptions: boolean;
}

class Invoice extends React.Component<IInvoiceProps, IInvoiceState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      mode: "view",
      selectedInvoice: {
        userId: 0,
        firstName: "",
        lastName: "",
        created: "",
        due: moment(),
        lastAttemptToCharge: "",
        notes: "",
        amount: 0,
        invoiceType: "",
        source: "internal",
        shouldCharge: "yes"
      },
      showDeleteModal: false,
      history: [],
      invoicePrintOptions: {
        hideCreateDate: "no",
        hideTodaysDate: "no",
        displayAs: "invoice",
        includeSchoolInformation: "no",
        overridePaidOnDate: "",
        hideKDojangLogo: "no",
      },
      showPrintOptions: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleShowPrintOptions = this.toggleShowPrintOptions.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updatePrintOptionsField = this.updatePrintOptionsField.bind(this);
    this.handleDueDateChanged = this.handleDueDateChanged.bind(this);
    this.toggleDeleteInvoiceConfirm = this.toggleDeleteInvoiceConfirm.bind(this);
    this.saveInvoice = this.saveInvoice.bind(this);
    this.printInvoice = this.printInvoice.bind(this);
    this.deleteInvoice = this.deleteInvoice.bind(this);
    this.renderTypeahead = this.renderTypeahead.bind(this);
    this.handleSelectedStudent = this.handleSelectedStudent.bind(this);
    this.markPaid = this.markPaid.bind(this);
    this.getHistory = this.getHistory.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.invoiceId !== this.props.invoiceId || prevProps.show !== this.props.show) {
      const props: any = {
        invoice: {
          ...this.props.invoice
        }
      };
      let mode = "new";
      if (props.invoiceId === 0 || props.invoice.id === 0) {
        props.invoice.due = moment().add(1, "days");
        props.invoice.amount = 0;
        props.invoice.notes = "";
        props.shouldCharge = "yes";
        props.invoice.userId = this.props.selectedStudent ? this.props.selectedStudent[0].id : 0;
        mode = "new";
      } else {
        props.invoice.due = moment(props.invoice.due);
        props.invoice.amount = props.invoice.amount / 100;
        // if it has been paid, you cannot edit anything
        if (props.invoice.status === "pending" || props.invoice.status === "overdue") {
          mode = "edit";
        } else {
          mode = "view";
        }
        props.invoice.shouldCharge = props.invoice.shouldCharge ? "yes" : "no";
      }
      this.setState({
        mode,
        selectedInvoice: props.invoice
      }, () => {this.getHistory();});
    }
  }

  public render() {
    return (
      <div>

        <Modal show={this.props.show} onHide={this.toggle} dialogClassName="modal-x-large" backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Assigned To</label>
                  {this.state.mode === "new" ?
                    (this.renderTypeahead()) :
                    (<div>
                      {this.state.selectedInvoice.firstName} {this.state.selectedInvoice.lastName}<br />
                      <em>You cannot change who an invoice is assigned to.</em>
                    </div>)}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Due On</label>
                  {this.state.mode === "view" ?
                    (<input type="text" disabled={true} className="form-control" value={moment(this.props.invoice.due).format("MM/DD/YYYY")} />) :
                    (<DatePicker date={this.state.selectedInvoice.due} onDateSaved={this.handleDueDateChanged} />)}

                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Amount</label>
                  {this.state.mode === "view" ?
                    (<input type="text" disabled={true} className="form-control" value={format(this.state.selectedInvoice.amount * 100)} />) :
                    (<input type="text" className="form-control" id="amount" value={this.state.selectedInvoice.amount} onChange={this.updateField} />)}

                </div>
              </div>
              <div className="col-md-3">
              {this.state.mode === "view" ?
                (null) :
                (<div className="form-group">
                  <label>Automatically Charge</label>
                  <select className="form-control" id="shouldCharge" value={this.state.selectedInvoice.shouldCharge} onChange={this.updateField} >
                      <option value="yes">Yes, Attempt To Charge On Due Date</option>
                      <option value="no">No, Do Not Automatically Charge</option>
                  </select>
                </div>)}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Notes</label>
                  <textarea className="form-control" id="notes" value={this.state.selectedInvoice.notes} onChange={this.updateField} />
                </div>
              </div>
              <div className="col-md-2">
                {this.state.mode !== "new" ?
                  (<span>
                    <strong>Created On </strong><br />{moment(this.props.invoice.created).format("MM/DD/YYYY")}
                  </span>) :
                  (null)
                }
                {this.props.invoice.lastAttemptToCharge !== "1970-01-01T00:00:00Z" && (<div>
                  <strong>Last Attempted to Charge On </strong><br />{moment(this.props.invoice.lastAttemptToCharge).format("MM/DD/YYYY")}
                </div>)}
              </div>
              <div className="col-md-4">
                {this.state.mode !== "new" ?
                  (
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <strong>Invoice History</strong>
                        </div>
                      </div>
                      {this.state.history.length === 0 && (
                        
                        <div className="row">
                          <div className="col-md-12">
                            <strong>NONE</strong>
                          </div>
                        </div>
                      )}
                      {this.state.history.map((history: any) => {
                        return (
                          <div className="row" key={history.id}>
                            <div className="col-md-3">
                              {moment(history.historyDate).format("MM/DD/YYYY")}
                            </div>
                            <div className="col-md-6">
                              {this.translateHistoryStatus(history.status)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) :
                  (null)
                }
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            {this.buttons}
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteInvoiceConfirm} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete this invoice? This cannot be undone!

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteInvoice}>Yes, Delete This Invoice</button>
            <button className="btn btn-primary" onClick={this.toggleDeleteInvoiceConfirm}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showPrintOptions} onHide={this.toggleShowPrintOptions} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Print Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="form-group">
                <label>Hide or Show Create Date</label>
                <select className="form-control" id="hideCreateDate" value={this.state.invoicePrintOptions.hideCreateDate} onChange={this.updatePrintOptionsField} >
                    <option value="yes">Hide</option>
                    <option value="no">Show</option>
                  </select>
              </div>
              <div className="form-group">
                <label>Hide or Show Today's Date</label>
                <select className="form-control" id="hideTodaysDate" value={this.state.invoicePrintOptions.hideTodaysDate} onChange={this.updatePrintOptionsField} >
                    <option value="yes">Hide</option>
                    <option value="no">Show</option>
                  </select>
              </div>
              <div className="form-group">
                <label>Hide or Show Platform Logo</label>
                <select className="form-control" id="hideKDojangLogo" value={this.state.invoicePrintOptions.hideKDojangLogo} onChange={this.updatePrintOptionsField} >
                    <option value="yes">Hide</option>
                    <option value="no">Show</option>
                  </select>
              </div>
              <div className="form-group">
                <label>Invoice or Receipt</label>
                <select className="form-control" id="displayAs" value={this.state.invoicePrintOptions.displayAs} onChange={this.updatePrintOptionsField} >
                    <option value="invoice">Invoice</option>
                    <option value="receipt">Receipt</option>
                  </select>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.printInvoice}>Print With These Options</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private translateHistoryStatus(input: string): string {
    switch(input){
      case "paid":
        return "Paid";
        break;
      case "no_payment_method":
        return "No Payment Method";
        break;
      case "unknown":
        return "Unknown";
        break;
      case "declined_unknown":
        return "Declined";
        break;
      case "declined_expired":
        return "Card Expired";
        break;
      case "declined_invalid_card":
        return "Card Invalid";
        break;
      case "declined_stolen":
        return "Card Reported Stolen";
        break;
      case "declined_insufficient":
        return "Insufficient Funds";
        break;
      case "declined_fraudulent":
        return "Charge Reported Fraudulent";
        break;
      case "do_not_charge":
        return "Set To Not Charge";
        break;
      default: 
        return "Unknown";
    }  
  }

  private toggle() {
    this.props.onClose();
  }

  private toggleShowPrintOptions(){
    this.setState({showPrintOptions: !this.state.showPrintOptions});
  }

  private updateField(e: any) {
    const ns: any = this.state.selectedInvoice;
    ns[e.target.id] = e.target.value;
    this.setState({ selectedInvoice: ns });
  }

  private updatePrintOptionsField(e: any) {
    const ns: any = this.state.invoicePrintOptions;
    ns[e.target.id] = e.target.value;
    this.setState({ invoicePrintOptions: ns });
  }

  private handleDueDateChanged(newDate: moment.Moment) {
    const ns: any = this.state.selectedInvoice;
    ns.due = newDate;
    this.setState({ selectedInvoice: ns });
  }

  private get buttons() {
    const buttons: any = [];
    if (this.state.mode === "new") {
      buttons.push(<button key="save-button" className="btn btn-success" onClick={this.saveInvoice}>Save Invoice</button>);
    } else if (this.state.mode === "edit") {
      buttons.push(<button key="save-button" className="btn btn-success" onClick={this.saveInvoice}>Save Invoice</button>);
      buttons.push(<button key="paid-button" className="btn btn-primary" onClick={this.markPaid}>Mark As Paid</button>);
      buttons.push(<button key="delete-button" className="btn btn-danger" onClick={this.toggleDeleteInvoiceConfirm}>Delete Invoice</button>);
      buttons.push(<button key="print-button" className="btn" onClick={this.printInvoice}>Print</button>);
      buttons.push(<button key="print-button" className="btn" onClick={this.toggleShowPrintOptions}>Print with Options</button>);
    } else {
      buttons.push(<button key="print-button" className="btn" onClick={this.printInvoice}>Print</button>);
      buttons.push(<button key="print-button" className="btn" onClick={this.toggleShowPrintOptions}>Print with Options</button>);
    }
    buttons.push(<button key="close-button" className="btn" onClick={this.toggle}>Close</button>);
    return buttons;
  }

  private async saveInvoice() {
    const data = {
      userId: this.state.selectedInvoice.userId,
      amount: this.state.selectedInvoice.amount,
      notes: this.state.selectedInvoice.notes,
      due: this.state.selectedInvoice.due.format("YYYY-MM-DD"),
      shouldCharge: this.state.selectedInvoice.shouldCharge === "yes" ? true : false,
    };
    data.amount = parseFloat(data.amount).toFixed(2);

    // error checking
    if(!data.userId || data.userId === 0){
      return error("You must select a user to assign this invoice to");
    }
    if(isNaN(data.amount) || data.amount < 1.0){
      return error("Amount must be greater than $1.00");
    }
    data.amount = Math.round(data.amount * 100);
    try {
      if(this.state.mode === "new"){
        // create data
        await FinancesAPI.createInvoice(this.props.schoolId, data);
      } else {
        // update data
        await FinancesAPI.updateInvoice(this.props.schoolId, this.props.invoiceId, data);
      }
      this.setState({
        show: false,
        selectedInvoice: this.props.invoice,
      }, () => {
        success("Invoice saved!");
        this.props.onSave();
      });
    } catch(err) {
      return error("We could not save that invoice");
    }
  }

  private async markPaid(){
    // we call the update, passing in just that the source is external
    const data = {
      source: "external",
      status: "paid",
    };
    try {
      await FinancesAPI.updateInvoice(this.props.schoolId, this.props.invoiceId, data);
      success("Invoice marked as paid");
      return this.setState({
        show: false
      }, () => {
        this.props.onSave();
      });
    }catch(err){
      return error("Could not mark that invoice as already paid");
    }
  }

  private toggleDeleteInvoiceConfirm() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  private async deleteInvoice() {
    // delete and notify
    try{
      await FinancesAPI.deleteInvoice(this.props.schoolId, this.props.invoiceId);
      this.setState({
        show: false,
        showDeleteModal: false
      }, () => {
        this.props.onDelete();
      });
    } catch(err){
      return error("Could not delete that invoice. Please contact support");
    }
    
  }

  private async printInvoice() {
    this.setState({loading: true}, async () => {
      try{
        const query = {...this.state.invoicePrintOptions};
        const options = {asDownload: true, accept: "application/pdf"};
        const res = await FinancesAPI.printInvoice(this.props.schoolId, this.props.invoiceId, query, options);
        const blob = new Blob([res.body], {type: `application/pdf`});
        saveAs(blob, `invoice-${this.props.invoiceId}.pdf`);
        this.setState({loading: false});
      }catch(err){
        error("Could not print that invoice");
        this.setState({loading: false});
      }
    });
  }

  private renderTypeahead(){
    const ss = this.props.selectedStudent;
    return (
      <Typeahead
        disabled={ss && ss.length > 0 && ss[0].id !== 0 ? true : false}
        key={this.props.students.length}
        labelKey="fullName"
        multiple={false}
        placeholder="Enter Student Name"
        onChange={this.handleSelectedStudent}
        defaultSelected={ss && ss.length > 0 && ss[0].id !== 0 ? this.props.selectedStudent : []}
        options={this.props.students} />
    );
  }

  private handleSelectedStudent(e: any){
    if(e.length === 0){
      return;
    }
    const si = this.state.selectedInvoice;
    si.userId = e[0].id;
    this.setState({
      selectedInvoice: si
    });
  }

  private getHistory(){
    this.setState({loading: true}, async () => {
      try{
        const res = await FinancesAPI.getInvoiceHistory(this.props.schoolId, this.props.invoiceId);
        this.setState({loading: false, history: res.body.data});
      }catch(err){
        this.setState({loading: false, history: []});
      }
    });
  }

}


export default Invoice;