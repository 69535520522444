import * as React from "react";

import Card from "../../structure/Card";
import { ReportsAPI } from "../../../API";
import AreaChart from "../../structure/AreaChart";

interface IBeltsReportProps {
  schoolId: number;
}

interface IAgesReporttate {
  loading: boolean;
  data: any[];
  chartData: any[];
}

class BeltsReport extends React.Component<IBeltsReportProps, IAgesReporttate> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: [],
      chartData: [],
    };

    this.fetch = this.fetch.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-2 col-md-offset-2">
          <Card title="Ages Distribution" loading={this.state.loading} help="">
            <div className="row">
              <div className="col-md-6">
                <strong>Age</strong>
              </div>
              <div className="col-md-6">
                <strong>Count</strong>
              </div>
            </div>
            {Object.keys(this.state.data).map((item: any, index: number) => {
              return (
                <div className="row" key={index} style={{marginBottom: 5}}>
                  <div className="col-md-6">
                    {item}
                  </div>
                  <div className="col-md-6">
                    {this.state.data[item]}
                  </div>
                </div>
              );
            })}
          </Card>
        </div>

        <div className="col-md-6">
          <Card title="Ages Graph" loading={this.state.loading} help="">
            <AreaChart data={this.state.chartData} />
          </Card>
        </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try {
        const res = await ReportsAPI.getAgesDistributionReport(this.props.schoolId);
        const chartData: any = [];
        for(const a of Object.keys(res.body.data)){
          chartData.push({
            name: a,
            count: res.body.data[a]
          });
        }
        this.setState({loading: false, data: res.body.data, chartData});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

}


export default BeltsReport;