import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";
import { format } from "../../../utils/currency";
import * as AppActions from "../../../reducers/appReducer";
import { FinancesAPI } from "../../../API";
import SchoolContractsScreen from "./SchoolContracts";
import SchoolInvoicesScreen from "./SchoolInvoices";


interface IInvoiceStats {
  pending: InvoiceStat;
  paid: InvoiceStat;
  overdue: InvoiceStat;
}

interface InvoiceStat {
  count: number;
  amount: number;
}

const blankInvoiceStats: IInvoiceStats = {
  pending: {
    count: 0,
    amount: 0,
  },
  paid: {
    count: 0,
    amount: 0,
  },
  overdue: {
    count: 0,
    amount: 0,
  },
};


const blankContractStats: any = {
  active: 0,
  paused: 0,
  cancelled: 0,
  complete: 0
};

interface IFinancesScreenProps {
  appActions: any;
  schoolState: any;
  history: any;
  match: any;
}

interface IFinancesScreenState {
  loading: boolean;
  view: string;
  invoiceStats: any;
  contractStats: any;
}

class FinancesScreen extends React.Component<IFinancesScreenProps, IFinancesScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      view: "contracts",
      invoiceStats: blankInvoiceStats,
      contractStats: blankContractStats,
    };
    this.fetchInvoiceStats = this.fetchInvoiceStats.bind(this);
  }

  public componentDidMount() {
    this.fetchInvoiceStats();
  }

  get component() {
    switch (this.props.match.params.view) {
      case "invoices":
        return (
          <div className="row">
            <div className="col-md-9">
              <SchoolInvoicesScreen />
            </div>
            <div className="col-md-3">
              <Card title="Stats" loading={this.state.loading} help="">
                <div className="row">
                  <div className="col-md-4">
                    <strong>Paid:</strong>
                  </div>
                  <div className="col-md-3">
                    {this.state.invoiceStats.paid.count}
                  </div>
                  <div className="col-md-4">
                    {format(this.state.invoiceStats.paid.amount)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <strong>Future:</strong>
                  </div>
                  <div className="col-md-3">
                    {this.state.invoiceStats.pending.count}
                  </div>
                  <div className="col-md-4">
                    {format(this.state.invoiceStats.pending.amount)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <strong>Overdue:</strong>
                  </div>
                  <div className="col-md-3">
                    {this.state.invoiceStats.overdue.count}
                  </div>
                  <div className="col-md-4">
                    {format(this.state.invoiceStats.overdue.amount)}
                  </div>
                </div>
              </Card>
            </div>
          </div>);
      case "contracts":
      default:
        return (
          <div className="row">
            <div className="col-md-9">
              <SchoolContractsScreen />
            </div>
            <div className="col-md-3">
              <Card title="Stats" loading={this.state.loading} help="">
                <div className="row">
                  <div className="col-md-6">
                    <strong>Active:</strong>
                  </div>
                  <div className="col-md-6">
                    {this.state.contractStats.active}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Paused:</strong>
                  </div>
                  <div className="col-md-6">
                    {this.state.contractStats.paused}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Complete:</strong>
                  </div>
                  <div className="col-md-6">
                    {this.state.contractStats.complete}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Cancelled:</strong>
                  </div>
                  <div className="col-md-6">
                    {this.state.contractStats.cancelled}
                  </div>
                </div>
              </Card>
            </div>
          </div>);
    }
  }

  public render() {
    const active = this.props.match.params && this.props.match.params.view ? this.props.match.params.view : "contracts";
    return (
      <div>
        <div className="row">
          <div id="financesNavbar" className="col-md-8 col-md-offset-2">
            <nav className="navbar navbar-inverse" style={{ borderRadius: "5px" }}>
              <div className="collapse navbar-collapse">
                <ul className="nav navbar-nav">
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/finances/contracts`}
                    className={active === "contracts" ? "subNavLinkActive" : "subNavLink"}>Contracts</Link></li>
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/finances/invoices`}
                    className={active === "invoices" ? "subNavLinkActive" : "subNavLink"}>Invoices</Link></li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.component}
          </div>
        </div>
      </div>
    );
  }

  private fetchInvoiceStats() {
    this.setState({ loading: true }, async () => {
      let invoiceStats = this.state.invoiceStats;
      let contractStats = this.state.contractStats;
      try {
        const contractStatsRes = await FinancesAPI.getSchoolContractStats(this.props.schoolState.school.id);
        contractStats = contractStatsRes.body.data;
        const invoiceStatsRes = await FinancesAPI.getSchoolInvoiceStats(this.props.schoolState.school.id);
        invoiceStats = invoiceStatsRes.body.data;
      } catch (e) {
        error("Could not load invoice statistics");
      }
      this.setState({ loading: false, invoiceStats, contractStats });
    });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinancesScreen) as React.ComponentType<any>);