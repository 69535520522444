import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {Link, withRouter } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";


import * as moment from "moment";

import Contact from "./Contact";

import * as AppActions from "../../reducers/appReducer";
import * as UserActions from "../../reducers/userReducer";
import * as AlertsActions from "../../reducers/alertsReducer";
import * as SchoolActions from "../../reducers/schoolReducer";
import * as SetupActions from "../../reducers/setupReducer";
import { refreshData } from "../../utils/login";

import logo from "../../img/brand.png";
import PlatformImage from "./PlatformImage";

interface INavProps {
  alertsActions: any;
  appActions: any;
  schoolActions: any;
  setupActions: any;
  userActions: any;

  appState: any;
  alertsState:any;
  scheduleState: any;
  schoolState: any;
  userState: any;
  setupState: any;
  history: any;
  location: any;
}

interface INavState {
  open: boolean;
  users: any;
}

class NavBar extends React.Component<INavProps, INavState> {

  private taRef: any = {};

  constructor(props: any){
    super(props);
    this.state = {
      open: true,
      users: []
    };

    this.logout = this.logout.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
    this.scheduleBadge = this.scheduleBadge.bind(this);
    this.toggleContact = this.toggleContact.bind(this);
    this.getTypeahead = this.getTypeahead.bind(this);
    this.renderTypeahead = this.renderTypeahead.bind(this);
    this.handleSelectedStudent = this.handleSelectedStudent.bind(this);
    this.refresh = this.refresh.bind(this);
  }
  public logout(){
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/login");
  }

  public toggleMenu(e: any){
    e.preventDefault();
    this.props.appActions.toggleMenu();
  }

  public toggleHelp(e: any){
    e.preventDefault();
    this.props.appActions.toggleHelp();
  }

  public toggleContact(e: any){
    e.preventDefault();
    this.props.appActions.toggleContact();
  }

  public scheduleBadge(){
    const start = moment().hour(0).minute(0);
    const end = moment().hour(23).minute(59);
    let count = 0;
    for(const s of this.props.scheduleState.schedule){
      const sTime = moment(s.StartTime);
      if(sTime.isAfter(start) && sTime.isBefore(end)){
        count += 1;
      }
    }
    return count;
  }

  get links(){
    const schoolId = this.props.schoolState.school.id;
    const helpText = this.props.appState.showHelp ? "Hide Tips" : "Show Tips";
    const alertsCount = this.props.alertsState.critical.length + this.props.alertsState.warning.length + this.props.alertsState.info.length;
    let completedSteps = 0;
    for(const s of this.props.setupState.steps){
      if(s.status === "completed" || s.status === "skipped"){
        completedSteps++;
      }
    }
    const setupStepsDisplay: string = `${completedSteps} / ${this.props.setupState.steps.length}`;

    if(this.props.userState.loggedIn) {
      return (
        <div>
          <ul className="nav navbar-nav">
            <li><a href="#" onClick={this.toggleMenu} id="menu_toggle">Menu</a></li>
            <li><Link to={`/schools/${schoolId}/dashboard`} className="navbar-link">Home</Link></li>
            <li><Link to="/alerts" className="navbar-link">Alerts <span className="badge">{alertsCount}</span></Link></li>
            <li><Link to="/notes" className="navbar-link">Notes</Link></li>
            <li><Link to={`/schools/${schoolId}/finances`} className="navbar-link">Finances</Link></li>
            <li><Link to={`/schools/${schoolId}/schedule`} className="navbar-link">Schedule</Link></li>
            <li><Link to={`/schools/${schoolId}/users`} className="navbar-link">Users <span className="badge">{this.props.schoolState.users.length}</span></Link></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li><a href="#" onClick={this.refresh}>Refresh</a></li>
            <li><Link to={`/schools/${schoolId}/setup/wizard`} className="navbar-link">Setup: {setupStepsDisplay} </Link></li>
            <li><a href="#" onClick={this.toggleHelp}>{helpText}</a></li>
            <li><a href="#" onClick={this.toggleContact}>Contact</a></li>
            <li><a href="#" onClick={this.logout}>Logout</a></li>
          </ul>
          <div className="col-sm-2 col-md-2">
            <form className="navbar-form" role="search">
              <div className="input-group">
                  {this.getTypeahead()}
                  <div className="input-group-btn">
                      <button className="btn btn-default" type="submit"><i className="glyphicon glyphicon-search" /></button>
                  </div>
              </div>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <ul className="nav navbar-nav">
            <li><Link to="/login" id="nav-login">Login</Link></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li><a href="#" onClick={this.toggleHelp}>{helpText}</a></li>
            <li><a href="#" onClick={this.toggleContact}>Contact</a></li>
            <li><Link to={`/setup`} className="navbar-link">Create School</Link></li>
          </ul>
        </div>
      );
    }
  }

  get header(){
    if(this.props.userState.loggedIn) {
      return (
        <a className="navbar-brand" href="#" onClick={this.toggleMenu}>
          <PlatformImage preference="logo" height="24px" width="24px" overrideToPlatform={false} />
        </a>
      );
    } else {
      return (
        <a className="navbar-brand" href="/"><img src={logo} alt="logo" height="24px" width="24px" /></a>
      );
    }
  }
  
  public render (){
    return (
      <div id="navbar">
        <nav className="navbar navbar-inverse" style={{borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px"}}>
          <div className="navbar-header">
              {this.header}
          </div>
          <div className="collapse navbar-collapse">
            {this.links}
          </div>
        </nav>
        <Contact />
      </div>);
  }
  
  private getTypeahead(){
    return this.renderTypeahead();
  }

  private renderTypeahead(){
    return (
      <Typeahead
        key={this.props.schoolState.users.length}
        ref={(ref: any) => {this.taRef = ref;}}
        labelKey="fullName"
        multiple={false}
        placeholder="Enter Student Name"
        onChange={this.handleSelectedStudent}
        options={this.props.schoolState.users} />
    );
  }

  private handleSelectedStudent(e: any){
    this.taRef.getInstance().clear();
    const schoolId = this.props.schoolState.school.id;
    this.props.history.push(`/schools/${schoolId}/users/${e[0].id}`);
    this.taRef.getInstance().clear();
  }

  private async refresh(){
    const user = this.props.userState.user;
    await refreshData(user, this.props.userActions, this.props.schoolActions, this.props.alertsActions, this.props.setupActions, false, this.props.history);
    window.location.reload();
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
    alertsState: s.alertsState,
    schoolState: s.schoolState,
    scheduleState: s.scheduleState,
    setupState: s.setupState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    alertsActions: bindActionCreators(AlertsActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
    setupActions: bindActionCreators(SetupActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar)  as React.ComponentType<any>);