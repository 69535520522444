import * as React from "react";

interface IMonthSelectProps {
  selected: string;
  id?: string;
  className?: string;
  onSelect: any;
}

export class MonthSelect extends React.Component<IMonthSelectProps, any> {

  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <select id={this.props.id ? this.props.id : "month"}
        className={this.props.className ? this.props.className : "form-control"}
        value={this.props.selected}
        onChange={this.props.onSelect}>
        <option value="01">01 / Jan</option>
        <option value="02">02 / Feb</option>
        <option value="03">03 / Mar</option>
        <option value="04">04 / Apr</option>
        <option value="05">05 / May</option>
        <option value="06">06 / Jun</option>
        <option value="07">07 / Jul</option>
        <option value="08">08 / Aug</option>
        <option value="09">09 / Sep</option>
        <option value="10">10 / Oct</option>
        <option value="11">11 / Nov</option>
        <option value="12">12 / Dec</option>
      </select>
    );
  }
}