import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../reducers/appReducer";

import UserProfileScreen from "./Profile/UserProfileScreen";
import UserFinancesScreen from "./Finances/UserFinancesScreen";
import UserActivitiesScreen from "./Activities/UserActivitiesScreen";
import UserKnowledgeScreen from "./Knowledge/UserKnowledgeScreen";
import SendMessageScreen from "./Messages/SendMessagesScreen";
import PermissionsScreen from "./Permissions/PermissionsScreen";

interface IUserInfoScreenProps {
  appActions: any;
  schoolState: any;
  userId: any;
  history: any;
  match: any;
}

interface IUserInfoScreenState {
  loading: boolean;
  user: any;
}

class UserInfoScreen extends React.Component<IUserInfoScreenProps, IUserInfoScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      user: {
        id: 0,
        firstName: "",
        lastName: ""
      }
    };
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  public componentDidMount(){
    this.getUserInfo();
  }

  public componentWillReceiveProps(nextProps: any){
    if(nextProps.match.params.userId !== this.props.match.params.userId){
      this.getUserInfo();
    }
  }

  public async getUserInfo(){
    this.setState({loading: true}, async () => {
      const target = parseInt(this.props.match.params.userId, 10);
      let found: any = this.state.user;
      for(const u of this.props.schoolState.users){
        if(u.id === target){
          found = u;
          found.id = target;
          break;
        }
      }
      this.setState({user: found, loading: false});
    });
  }

  get component(){
    switch(this.props.match.params.view){
      case "finances":
        return (<UserFinancesScreen user={this.state.user} />);
      case "activities":
        return (<UserActivitiesScreen />);
      case "message":
        return (<SendMessageScreen />);
      case "knowledge":
        return (<UserKnowledgeScreen />);
      case "permissions":
        return (<PermissionsScreen userId={this.state.user.id} schoolId={this.props.schoolState.school.id} />);
      case "info":
      default:
        return (<UserProfileScreen />);
    }
  }

  get permissionsLink(){
    const active = this.props.match.params && this.props.match.params.view ? this.props.match.params.view : "info";
    if(this.state.user.role === "assistant" || this.state.user.role === "administrator" || this.state.user.platformRole === "administrator"){
      return(<li><Link to={`/schools/${this.props.schoolState.school.id}/users/${this.props.match.params.userId}/permissions`} 
        className={active === "permissions" ? "subNavLinkActive" : "subNavLink"}>Permissions</Link></li>);
    }
    return null;
  }

  public render() {
    const active = this.props.match.params && this.props.match.params.view ? this.props.match.params.view : "info";
    return (
      <div>
        <div className="row">
          <div id="financesNavbar" className="col-md-8 col-md-offset-2">
            <nav className="navbar navbar-inverse" style={{borderRadius: "5px"}}>
              <div className="navbar-header">
                <Link 
                  to={`/schools/${this.props.schoolState.school.id}/users/${this.props.match.params.userId}/info`}
                  className="navbar-brand">
                  {`${this.state.user.firstName} ${this.state.user.lastName}`}
                </Link>
              </div>
              <div className="collapse navbar-collapse">
                <ul className="nav navbar-nav">
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/users/${this.props.match.params.userId}/info`} 
                    className={active === "info" ? "subNavLinkActive" : "subNavLink"}>Info</Link></li>
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/users/${this.props.match.params.userId}/finances`} 
                    className={active === "finances" ? "subNavLinkActive" : "subNavLink"}>Financial</Link></li>
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/users/${this.props.match.params.userId}/activities`} 
                    className={active === "activities" ? "subNavLinkActive" : "subNavLink"}>Activities</Link></li>
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/users/${this.props.match.params.userId}/knowledge`} 
                      className={active === "knowledge" ? "subNavLinkActive" : "subNavLink"}>Curriculum</Link></li>
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/users/${this.props.match.params.userId}/message`} 
                    className={active === "message" ? "subNavLinkActive" : "subNavLink"}>Send Message</Link></li>
                  {this.permissionsLink}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.component}
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserInfoScreen) as React.ComponentType<any>);