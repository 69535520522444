import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as qs from "query-string";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { UserAPI } from "../../../API";
import { error } from "../../structure/Alert";

interface ISchoolSignupVerificationProps {
  appActions: any;
  history: any;
  location: any;
}

interface ISchoolSignupVerificationState {
  loading: boolean;
  step: 1 | 2;
  username: string;
  token: string;
}

class SchoolSignupVerification extends React.Component<ISchoolSignupVerificationProps, ISchoolSignupVerificationState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      step: 1,
      username: "",
      token: ""
    };

    this.updateField = this.updateField.bind(this);
    this.attemptVerify = this.attemptVerify.bind(this);

  }

  public componentDidMount(){
    // parse the URL
    const params = qs.parse(this.props.location.search); 
    this.setState({
      username: params.username ? params.username : "",
      token: params.token ? params.token : "",
    });
  }

  public render() {
    if(this.state.step === 2){
      // account has been verified, tell them and bring them to the login
      return (
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="Success!" loading={this.state.loading} help="">
              Thank you for verifying your email! You can now <Link to="/login">login</Link> with your username and password!
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <Card title="Verify Your Account" loading={this.state.loading} help="Use this form to verify an account. You should have received an email with your username and a verification token.">
            <div className="form-group">
              <label>Username</label>
              <input type="text" id="username" className="form-control" value={this.state.username} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Token</label>
              <input type="text" id="token" className="form-control" value={this.state.token} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.attemptVerify}>Verify Account</button>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private attemptVerify(){
    this.setState({loading: true}, async () => {
      try {
        await UserAPI.verifyEmailToken(this.state.username, this.state.token);
        this.setState({ loading: false, step: 2});
      } catch(err){
        error("Could not verify that username and token");
        this.setState({ loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolSignupVerification) as React.ComponentType<any>);