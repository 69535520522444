import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import * as AppActions from "../../../reducers/appReducer";

import EventInfo from "./EventInfo";
import EventAttendance from "./ClassAttendance";
import Schedule from "./Schedule";
import { ScheduleAPI } from "../../../API/";
import { error } from "../../structure/Alert";

interface IScheduleScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface IScheduleScreenState {
  loading: boolean;
  selectedEvent: any;
  addingNewEvent: boolean;
  newEventStartTime: any;
  start: any;
  end: any;
  events: any;
  attendance: any;
}

class ScheduleScreen extends React.Component<IScheduleScreenProps, IScheduleScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      addingNewEvent: false,
      newEventStartTime: null,
      selectedEvent: {
        id: 0,
        additionalAttendees: 0,
      },
      events: [],
      attendance: [],
      start: moment().day("Sunday"),
      end: moment().day("Saturday"),
    };

    this.handleEventSaved = this.handleEventSaved.bind(this);
    this.handleEventSelected = this.handleEventSelected.bind(this);
    this.handleNewEvent = this.handleNewEvent.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.handleEventDeleted = this.handleEventDeleted.bind(this);
    this.getEventAttendance = this.getEventAttendance.bind(this);
    this.handleAttendanceChanged = this.handleAttendanceChanged.bind(this);
    this.handleAdditionalAttendees = this.handleAdditionalAttendees.bind(this);
  }

  public componentDidMount() {
    this.getEvents();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-7">
          <Schedule
            onNewEvent={this.handleNewEvent}
            onEventSelected={this.handleEventSelected}
            onEventSaved={this.handleEventSaved}
            onNavigate={this.handleNavigate}
            events={this.state.events}
          />
        </div>
        <div className="col-md-2">
          <EventInfo
            event={this.state.selectedEvent}
            addingNewEvent={this.state.addingNewEvent}
            newEventStartTime={this.state.newEventStartTime}
            onEventSaved={this.handleEventSaved}
            onEventDeleted={this.handleEventDeleted} />
        </div>
        <div className="col-md-3">
          <EventAttendance
            key={this.state.selectedEvent ? this.state.selectedEvent.id : 0}
            event={this.state.selectedEvent}
            attendance={this.state.attendance} 
            onAdditionalAttendeesChanged={this.handleAdditionalAttendees}
            onAttendanceChanged={this.handleAttendanceChanged} />
        </div>
      </div>
    );
  }

  private async getEvents() {
    const data = {
      startTime: this.state.start.format("YYYY-MM-DDT00:00:00Z"),
      endTime: this.state.end.format("YYYY-MM-DDT23:59:59Z")
    };
    this.setState({ loading: true }, async () => {
      const events: any = [];
      try {
        const result = await ScheduleAPI.getEventsBetweenDays(this.props.schoolState.school.id, data.startTime, data.endTime);
        for (const event of result.body.data) {
          event.start = moment(event.startTime).toDate();
          event.end = moment(event.endTime).toDate();
          event.title = event.name;
          events.push({
            ...event,
            id: event.id,
            start: event.start,
            end: event.end,
            title: event.name
          });
        }
      } catch (e) {
        error("Could not fetch any events!");
      }
      this.setState({ loading: false, events });
    });
  }

  private async getEventAttendance(eventId: number) {this.setState({ loading: true }, async () => {
    let attendance: any = [];
    try {
      const result = await ScheduleAPI.getEventAttendance(this.props.schoolState.school.id, eventId);
      attendance = result.body.data;
    } catch (e) {
      error("Could not fetch any attendance!");
    }
    this.setState({ loading: false, attendance });
  });
  }

  private handleNewEvent(info: any) {
    this.setState({
      addingNewEvent: true,
      newEventStartTime: info.start,
      selectedEvent: null,
    });
  }

  private handleEventSelected(event: any) {
    this.setState({
      selectedEvent: event,
      addingNewEvent: false,
      attendance: []
    }, () => {
      this.getEventAttendance(event.id);
    });
  }

  private handleNavigate(startTime: any, endTime: any) {
    this.setState({
      start: startTime,
      end: endTime,
      loading: true
    }, () => { this.getEvents(); });
  }

  private handleEventSaved(event: any) {
    this.setState({ selectedEvent: event }, () => {
      this.getEvents();
    });
  }

  private handleEventDeleted(event: any) {
    this.setState({ selectedEvent: null }, () => {
      this.getEvents();
    });
  }

  // TODO: change to not need to fetch the attendance; we can update in place
  private handleAttendanceChanged(){
    this.getEventAttendance(this.state.selectedEvent.id);
  }

  private handleAdditionalAttendees(num: number) {
    const selectedEvent = this.state.selectedEvent;
    selectedEvent.additionalAttendees = num;
    this.setState({ selectedEvent });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleScreen) as React.ComponentType<any>);