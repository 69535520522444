import { makeCall } from "./client";

export class ExamsAPI {

  public getExamTemplates(schoolId: number, status: string = "pending", params: any = {}): Promise<any> {
    params = {
      ...params,
      status,
    };
    return makeCall("get", `schools/${schoolId}/exams/templates`, params);
  }

  public getExamTemplateById(schoolId: number, templateId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/exams/templates/${templateId}`, params);
  }

  public createExamTemplate(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/exams/templates`, params);
  }

  public updateExamTemplate(schoolId: number, templateId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/exams/templates/${templateId}`, params);
  }

  public deleteExamTemplate(schoolId: number, templateId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/exams/templates/${templateId}`, params);
  }

  public getExamTemplateCriteria(schoolId: number, templateId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/exams/templates/${templateId}/criteria`, params);
  }

  public createExamTemplateCriteria(schoolId: number, templateId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/exams/templates/${templateId}/criteria`, params);
  }

  public saveExamTemplateCriteriaOrder(schoolId: number, templateId: number, criteriaIds: any, params: any = {}): Promise<any> {
    params = {
      ...params,
      criteriaIds,
    };
    return makeCall("put", `schools/${schoolId}/exams/templates/${templateId}/criteria/order`, params);
  }

  public updateExamTemplateCriteria(schoolId: number, templateId: number, criteriaId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/exams/templates/${templateId}/criteria/${criteriaId}`, params);
  }

  public deletaExamTemplateCriteria(schoolId: number, templateId: number, criteriaId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/exams/templates/${templateId}/criteria/${criteriaId}`, params);
  }

  public getExams(schoolId: number, templateId: number, status: string = "pending", params: any = {}): Promise<any> {
    params = {
      ...params,
      status,
      templateId
    };
    return makeCall("get", `schools/${schoolId}/exams`, params);
  }

  public createExam(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/exams`, params);
  }

  public getExam(schoolId: number, examId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/exams/${examId}`, params);
  }

  public deleteExam(schoolId: number, examId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/exams/${examId}`, params);
  }

  public updateExam(schoolId: number, examId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/exams/${examId}`, params);
  }

  public saveExamScore(schoolId: number, examId: number, criteriaId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/exams/${examId}/criteria/${criteriaId}/score`, params);
  }

  public deleteExamScore(schoolId: number, examId: number, criteriaId: number, scoreId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/exams/${examId}/criteria/${criteriaId}/score/${scoreId}`, params);
  }



  public getExamsForStudent(schoolId: number,studentId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${studentId}/exams`, params);
  }
  
}