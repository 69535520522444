import * as React from "react";

import Card from "../structure/Card";

interface ITermsProps {
  appActions: any;
  history: any;
}

interface ITermsState {
  loading: boolean;
}

class Terms extends React.Component<ITermsProps, ITermsState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false
    };

  }

  public render() {
    return (
      <Card title="Terms" loading={this.state.loading} help="">
        {this.getTerms()}
      </Card>
    );
  }

  // this is usefull if another component needs just the terms, such as the school signup
  public getTerms() {
    return (
      <div>
        <p>
          <strong>Please Read KDojang's "Terms Of Service and Use" Carefully Before You Use This Application.</strong>
        </p>
        <p>
          KDojang is an application and framework designed to make managing and administering a martial arts academy easy and straightforward.
      Please read and agree to the following Terms of Service and Use carefully. These Terms of Service and Use contain important
      information regarding your rights, obligations and restrictions in connection with your use of the KDojang. By visiting
      or using the KDojang application, you accept and agree to be bound by these Terms of Service and Use ("Terms of Service
      and Use" or "Agreement"), whether or not you register for an account. If you do not agree to these Terms of Service and
    Use you may not use KDojang.</p>
        <p>
          You agree that we may modify this Agreement from time to time in our sole discretion and without prior notice. Any modification
      of this Agreement will be posted on the KDojang application. The modified Agreement will become effective upon such posting.
      Your continued use of the application after posting the amended Agreement indicates your acceptance of and agreement to
    be bound by the amended Agreement.</p>
        <ol className="inside">
          <li>
            <h3>
              The Service</h3>
            <p>
              The Service offered by KDojang includes the Web sites and any other services provided by KDojang or their Affiliates from
          time to time in connection with the Service. The Terms of Service and Use apply to all Services and all uses of the
          application.
      </p>
            <p>
              To use this Service, you understand and agree to the following: (a) as part of the registration process, you must submit
          a valid e-mail address and select a password and user name; (b) you are responsible for maintaining the confidentiality
          of your member name and password, and all uses of your account -- whether or not you've authorized such use; (c) you
          agree to notify KDojang immediately of any unauthorized use of your account; and (d) you are at least 13 years of age.
          KDojang will not be held liable for any loss or damage for non-compliance. KDojang reserves the right to refuse the
        Service to any user.</p>
            <p>
              In addition, you understand and agree that KDojang will use aggregated data collected from you and other users for statistical
          and analytical reporting purposes. You understand, and agree that, unless expressly stated, KDojang in no way controls,
          verifies, or endorses any of the information contained on or in the KDojang Service, including links, events, messages,
        message boards and members' published listings.</p>
          </li>
          <li>
            <h3>
              Registration</h3>
            <p>
              In order to use some of the Services on the application you will have to register with KDojang as a Member. As part of the
          registration process, you agree to: (a) provide certain limited information about you as prompted to do so by the Service
          (such information to be current, complete and accurate); and (b) maintain and update this information as required to
          keep it current, complete and accurate. The information requested upon original sign-up shall be referred to as registration
          data ("Registration Data"). KDojang reserves the right to delete accounts created by users who appropriate the name,
          likeness, e-mail address, or other personally identifiable information of another individual. You may not register for
        a Member account on behalf of an individual other than yourself.</p>
          </li>
          <li>
            <h3>
              Eligibility</h3>
            <p>
              You must be thirteen (13) years of age or older to register as a member of KDojang or use the application or Service. By
          using the application or registering for the Service, you represent and warrant that you are thirteen (13) years of
          age or older and that you have the right, authority, and capacity to agree to, and abide by, this Agreement. KDojang
          reserves the right to cancel the account of and delete the profile and any content or information posted by any user
        under the age of thirteen (13).</p>
            <p>
              Membership in KDojang is void where prohibited by law.</p>
          </li>
          <li>
            <h3>
              Term and Termination</h3>
            <p>
              This Agreement will remain in full force and effect as long as you use the KDojang Service and application and/or are a Member.
        You may cancel your KDojang membership at any time by contacting us at <a href="mailto:support@kvsstechnologies.com">support@kvsstechnologies.com</a>.
              Once we process your request, we will remove your account from our database. KDojang may terminate your membership (1)
              automatically without notice upon any violation or alleged violation of this Agreement or KDojang's Privacy Policy at
              KDojang's sole discretion; (2) for any reason, at any time without notice to Member, including, upon KDojang blocking
              Member's access to the application or Service, discontinuance of Member's account, or notification to Member at the
              e-mail address provided in Member's registration. Upon any termination by KDojang, Member shall discontinue all efforts
        to access the KDojang website or the Services.</p>
          </li>
          <li>
            <h3>
              Content Posted by Members on the KDojang Site</h3>
            <p>
              You are solely responsible for the content that you publish or display (hereinafter "Post") on the application, or transmit
          to other KDojang Members, including but not limited to photos, profiles, messages, notes, text, information, music,
        and videos ("Member Content").</p>
            <p>
              You represent, warrant and agree that no materials of any kind submitted by you or otherwise posted or shared by you through
          the Service will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity
          or other personal or proprietary rights or contain libelous, defamatory or otherwise unlawful material, or violate any
        other laws or regulations.</p>
            <p>
              You understand and agree that KDojang may, but is not obligated to, review and delete any Member Content that, in the sole
          judgment of KDojang, violate this Agreement or which might be offensive, illegal, or that might violate the rights,
          harm, or threaten the safety of KDojang, KDojang's Members or users, or the public, without notice. KDojang assumes
        no responsibility for monitoring the content posted on the Web site by Members or the conduct of its Members.</p>
            <p>
              Your profile must describe you, an individual person. Examples of inappropriate profiles include, but are not limited to,
          profiles that purport to represent an animal, place, inanimate object, fictional character, or real individual who is
          not you. The only exception to this is for the purposes of registering a school as a separate entity. Exceptions must
        be requested and cleared through KDojang.</p>
            <p>
              You retain all ownership rights in your Member Content. However, by posting Content to the Web site, you automatically grant,
          and you represent and warrant that you have the right to grant, to KDojang (and its successors) an irrevocable, perpetual,
          non-exclusive, assignable, royalty free, worldwide license to use, copy, perform, display, distribute and to prepare
          derivative works of such Content in connection with the Website and the Service, and to grant and authorize sublicenses
        of the foregoing.</p>
            <p>
              The following is a partial list of the kind of Content that is illegal or prohibited on the application. KDojang reserves
          the right to investigate and take appropriate legal action in its sole discretion against anyone who violates this provision
          including, without limitation, removing the offending communication from the KDojang site and terminating the membership
        of such violators and reporting you to law enforcement authorities. It includes Content that:</p>
            <ul>
              <li>
                is patently offensive to the online community, such as Content that promotes racism, bigotry, hatred or physical harm of
          any kind against any group or individual;</li>
              <li>
                harasses or advocates harassment of another person;</li>
              <li>
                involves the transmission of "junk mail," "chain letters" or unsolicited mass mailing or "spamming";</li>
              <li>
                promotes information that you know is false, misleading or promotes illegal activities or conduct that is abusive, threatening,
          obscene, defamatory or libelous;</li>
              <li>
                promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs
            or links to them, providing information to circumvent manufacturer-installed copy-protect devices, or providing pirated
          music or links to pirated music files;</li>
              <li>
                contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible
            page);
        </li>
              <li>
                displays pornographic or sexually explicit material of any kind;</li>
              <li>
                provides material that exploits people under the age of 18 in a sexual or violent manner, or solicits personal information
          from anyone under 18;</li>
              <li>
                provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's
            privacy, or providing or creating computer viruses or would otherwise create liability or violate any local, state,
          national or international laws;</li>
              <li>
                solicits passwords or personally-identifying information for commercial or unlawful purposes from other users;</li>
              <li>
                engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising
          and pyramid schemes; and solicits people you don't know to add you or accept you as a contact on KDojang;</li>
              <li>
                impersonates any person or entity, or falsely states or otherwise misrepresents yourself, your age, or your affiliate with
          any person or entity;</li>
              <li>
                contains the private information of any third party including without limitation, addresses, phone numbers, e-mail addresses,
          Social Security numbers and credit card numbers;</li>
              <li>
                contains software viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality
          of any computer software or hardware or telecommunications equipment;</li>
              <li>
                contains in the sole judgment of KDojang, matter that is objectionable or which restricts or inhibits any other person from
            using or enjoying the KDojang application or which may expose KDojang or its Members or users to any harm or liability
          of any type.</li>
            </ul>
            <p>
              Even though all of this is strictly prohibited, there is a small chance that you might become exposed to such items from
          other Members or Member Content. KDojang is not responsible for the content that Members submit and you hereby waive
        any legal or equitable rights or remedies you may have against KDojang related to such exposure.</p>
            <p>
              You must use the Service in a manner consistent with any and all applicable laws and regulations.</p>
            <p>
              Although KDojang cannot monitor the conduct of its Members off the Web site, it is also a violation of these rules to use
          any information obtained from the KDojang Service in order to harass, abuse, or harm another person, or in order to
          contact, advertise to, solicit, or sell to any Member without their prior explicit consent. In order to protect our
          Members from such advertising or solicitation, KDojang reserves the right to restrict the number of messages which a
        Member may send to other Members in any 24-hour period to a number which KDojang deems appropriate in its sole discretion.</p>
          </li>
          <li>
            <h3>
              Copyright Policy</h3>
            <p>
              If you believe that any Member Content or other material on the application infringes upon any copyright that you own or
          control, you may submit a notification to KDojang pursuant to the Digital Millennium Copyright Act ("DMCA") by providing
          our Designated Copyright Agent with the following information in writing: (1) an electronic or physical signature of
          the person authorized to act on behalf of the owner of the copyright interest that is allegedly infringed; (2) a description
          of the copyrighted work that you claim has been infringed; (3) a description of where the material that you claim is
          infringing is located on the Web site; (4) your address, telephone number, and e-mail address; (5) a written statement
          by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent,
          or the law; (6) a statement by you, made under penalty of perjury, that the information in your notice is accurate and
        that you are the copyright owner or authorized to act on the copyright owner's behalf.</p>
          </li>
          <li>
            <h3>
              Termination of Repeat Infringers</h3>
            <p>
              KDojang, in appropriate circumstances and at KDojang's sole discretion, will terminate Members who are deemed to be repeat
          infringers. KDojang may also at its sole discretion limit access to the application and/or terminate the memberships
        of any Members who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
          </li>
          <li>
            <h3>
              Member Disputes</h3>
            <p>
              You are solely responsible for your interactions with other KDojang Members or third parties. KDojang reserves the right,
        but has no obligation, to monitor disputes between you and other Members or third parties.</p>
          </li>
          <li>
            <h3>
              Links to Third Party Sites</h3>
            <p>
              Any links included within the KDojang application that take users out of the application are not owned by or under the control
          of KDojang and KDojang assumes no responsibility for the content, privacy policies or practices of any third party website.
          Use of such third party sites shall be at your own risk and is subject to the policies and procedures of the owner of
        such site. We encourage you to read those policies and know your rights.</p>
          </li>
          <li>
            <h3>
              Transactions with Organizations or Individuals</h3>
            <p>
              KDojang shall not be liable for your interactions with any organizations and/or individuals found on or through the KDojang
          Service. This includes, but is not limited to, payment and delivery of goods and services, and any other terms, conditions,
          warranties or representations associated with such dealings. These dealings are solely between you and such organizations
          and/or individuals. You agree that KDojang is not responsible for any damage or loss incurred as a result of any such
          dealings. KDojang is under no obligation to become involved in disputes between participants on the site, or between
          participants on the site and any third party. In the event of a dispute, you release KDojang, its officers, employees,
          agents and successors in rights from claims, damages and demands of every kind, known or unknown, suspected or unsuspected,
        disclosed or undisclosed, arising out of or in any way related to such disputes and our Service.</p>
          </li>
          <li>
            <h3>Payment Processing Procedures</h3>
            <p>Schools may elect to receive payment either via a mailed paper check or electronically, pending set up and confirmation. If paid electronically, payment processing services for schools on KDojang are provided by Stripe and are subject to the Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the “Stripe Services Agreement”). By agreeing to these terms or continuing to operate as a school on KDojang, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of KDojang enabling payment processing services through Stripe, you agree to provide KDojang accurate and complete information about you and your business, and you authorize KDojang to share it and transaction information related to your use of the payment processing services provided by Stripe.</p>
            <p>
              For more information about the Stripe Connected Account Agreement, please visit <a href="https://stripe.com/connect-account/legal" target="_blank">https://stripe.com/connect-account/legal</a>. The Stripe Terms of Service can be found at <a href="https://stripe.com/legal" target="_blank">https://stripe.com/legal</a>.
          </p>
          </li>
          <li>
            <h3>
              Disclaimers</h3>
            <p>
              KDojang is not responsible or liable in any manner for any Member Content posted on the application or in connection with
          the KDojang Service, whether caused by users of the application, Members or by any of the equipment or programming associated
          with or utilized in the Service. Although KDojang provides rules for Member conduct and postings, we do not control
          and are not responsible for what Members post on the application and are not responsible for any offensive, inappropriate,
          obscene, unlawful or otherwise objectionable content you may encounter on the Service, the application or in connection
          with any Member Content. KDojang is not responsible for the conduct, whether online or offline, of any user of the Web
          site or Member of the Service. KDojang assumes no responsibility for any error, omission, interruption, deletion, defect,
          delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or
          alteration of, user or Member communications. KDojang is not responsible for any problems or technical malfunction of
          any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure
          of e-mail or players on account of technical problems or traffic congestion on the Internet or at any web site or combination
          thereof, including injury or damage to users and/or Members or to any other person's computer related to or resulting
          from participating or downloading materials in connection with the Web and/or in connection with the Service. Under
          no circumstances will KDojang be responsible for any loss or damage, including personal injury or death, resulting from
          anyone's use of the Web site or the Service, any content posted on the Web site, including Member Content, or transmitted
          to Members, or any interactions between users of the Web site, whether online or offline. THE application AND THE SERVICE
          ARE PROVIDED "AS IS" AND KDojang EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED,
          INCLUDING WITHOUT LIMITATION ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. KDojang CANNOT GUARANTEE
          AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE application AND/OR THE SERVICE. The Service may be temporarily
          unavailable from time to time for maintenance or other reasons. No advice or information, whether oral or written, obtained
        by user from KDojang or through or from the Service shall create any warranty not expressly stated herein.</p>
          </li>
          <li>
            <h3>
              Limitation on Liability</h3>
            <p>
              EXCEPT IN JURISDICTIONS WHERE SUCH PROVISIONS ARE RESTRICTED, IN NO EVENT WILL KDojang OR ANY OF ITS CORPORATE PARENTS, SUBSIDIARIES,
          OFFICERS, DIRECTORS, AGENTS, AFFILIATES, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT,
          CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING ALSO LOST PROFITS AND LOST DATA ARISING
          FROM YOUR USE OF THE WEB SITE OR THE KDojang SERVICE OR ANY CONTENT OR OTHER MATERIALS ACCESSED THROUGH THE WEBSITE,
          EVEN IF KDojang HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
          HEREIN, KDojang'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES
        BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO KDojang FOR SERVICES.</p>
          </li>
          <li>
            <h3>
              Disputes</h3>
            <p>
              If there is any dispute about or involving this Agreement, the application and/or the Service between you and KDojang, you
          agree that the dispute will be governed by the laws of the State of Delaware without regard to its conflict of law rules.
        You agree to the exclusive jurisdiction and venue of the Delaware state courts and the Federal Courts in Delaware.</p>
          </li>
          <li>
            <h3>
              Indemnity</h3>
            <p>
              You agree to defend, indemnify and hold harmless KDojang, its corporate parents and subsidiaries, officers, directors, employees
          and agents with respect to any and all costs, claims, demands, investigations, liabilities, losses, damages, judgments,
          settlements, costs and expenses, including attorneys' fees arising out of or in connection with any Member Content,
          your use of the KDojang Service or application, your conduct in connection with the Services or Web site or with other
          users or Members of the Services or the application, or any breach of this Agreement. Notwithstanding the foregoing,
          KDojang retains the exclusive right to settle, compromise and pay any and all claims, demands, proceedings, suits, actions
          or causes of actions which are brought against KDojang herein under the terms and provisions of this Section and in
        no event shall you settle any such claim without KDojang's prior written approval.</p>
          </li>
          <li>
            <h3>
              General Practices Regarding Use and Storage</h3>
            <p>
              You acknowledge that KDojang may establish general practices and limits concerning use of the Service including, without
          limitation, the maximum number of days that messages or other uploaded Content will be retained by the Service, the
          maximum number of messages that may be sent from or received by an account on the Service, the maximum size of any message
          that may be sent from or received by an account on the Service, the maximum disk space that will be allotted on KDojang's
          servers on your behalf, and the maximum number of times (and the maximum duration for which) you may access the Service
          in a given period of time. You agree that KDojang has no responsibility or liability for the deletion or failure to
          store any messages and other communications or other Content maintained or transmitted by the Service. You acknowledge
          that KDojang reserves the right to log off accounts that are inactive for an extended period of time. You further acknowledge
        that KDojang reserves the right to modify these general practices and limits from time to time.</p>
          </li>
          <li>
            <h3>
              Preservation of Materials</h3>
            <p>
              You acknowledge and agree that KDojang may preserve any materials submitted or posted by you (including e-mails, profiles,
          postings or other content) and may disclose such material if required to do so by law or in the good faith belief that
          such preservation or disclosure is reasonably necessary to: (1) comply with legal process; (2) enforce this Agreement;
          (3) respond to claims that any material violates the rights of third parties; or (4) in our sole judgment, protect the
        rights, property, or personal safety of KDojang, its Members, employees or the public.</p>
          </li>
          <li>
            <h3>
              No Agency</h3>
            <p>
              The terms and provisions of this Agreement shall not be interpreted as creating an agency, partnership, joint venture, employee-employer
          or franchisor-franchisee relationship between KDojang and any User of the Service. A Member may not bind or obligate
        KDojang without KDojang's prior written consent.</p>
          </li>
          <li>
            <h3>
              Special Admonitions for International Use</h3>
            <p>
              As a consequence of the global nature of the Internet, you agree to comply with all local rules regarding user conduct on
          the Internet and acceptable content. Specifically, you agree to comply with all applicable laws regarding the transmission
        of technical data exported from the United States or the country in which you reside.</p>
          </li>
          <li>
            <h3>
              Miscellaneous</h3>
            <p>
              This Agreement contains the entire agreement between you and KDojang regarding the use of the application and/or the Service.
          The failure of KDojang to exercise or enforce any right or provision of this Agreement shall not constitute a waiver
          of such right or provision. If any provision of this Agreement is held invalid, the remainder of this Agreement shall
          continue in full force and effect. The section titles in this Agreement are for convenience only and have no legal or
          contractual effect. This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned
        by you, but may be assigned by KDojang without restriction.</p>
          </li>
          <li>
            <h3>
              Payment Services</h3>
            <h4>
              Subscription Services</h4>
            <p>
              Some features of, or services provided through, the Web site require a fee-based subscription (all fee-based features and/or
          services are referred to herein as "Subscription Services"). By purchasing Subscription Services, you agree that your
          subscription is personal to you and that you will not transfer or make available your account name and password to others,
          including without limitation your friends, co-workers and other contacts. Any distribution of your account name and
          password may result in cancellation of your subscription without refund and in additional charges based on unauthorized
        use. From time to time, Subscription Services may change without notice.</p>
            <h4>
              Payment</h4>
            <p>
              Prices for Services exclude all applicable taxes and telecommunication charges, unless expressly stated otherwise. To the
          extent permissible by law, you are responsible for any applicable taxes, whether or not they are listed on your receipt
          or statement. If you purchase Subscription Services, you agree to pay, using a valid credit card (or other form of payment
          that KDojang may accept from time to time), the applicable fees and taxes (if any) set forth on the Web site. KDojang
          reserves the right, upon prior notice to you, to change the amount of any fees and to institute new fees, effective
          at the end of your current subscription period. All authorized charges will be billed to your designated credit card
          account (or other payment method) on the terms described in the specific offer. If payment cannot be charged to your
          credit card or your payment is returned to KDojang for any reason, KDojang reserves the right to either suspend or terminate
        your access to Subscription Services.</p>
            <h4>
              Refund Policy</h4>
            <p>
              Payment for Services is non-refundable.</p>
            <h4>
              Automatic Renewal Program</h4>
            <p>
              By purchasing Subscription Services, you authorize KDojang to automatically renew your Subscription Services on the 1st of
          the month following the end of your then-current subscription. This is to ensure continuous service. You further authorize
          KDojang to charge the credit card on file for your account at the then-current subscription rate unless you provide
          KDojang with prior notice that you are canceling your subscription before the renewal date. Please note that even if
        you provide notice, charges applied before KDojang can reasonably act are non-refundable.</p>
            <h4>
              Cancellation/Termination of Subscription Services</h4>
            <p>
              You may cancel Subscription Services at any time by contacting KDojang customer service. KDojang will attempt to process
        all cancellation requests within 72 hours. Please contact <a href="mailto:support@kvsstechnologies.com">support@kvsstechnologies.com</a>					to cancel your automatic renewal, cancel your subscription, and/or change your payment method. In addition, KDojang
              reserves the right to terminate your Subscription Services if KDojang determines, in its sole discretion, that you are
              not complying with the Terms of Service. Any such cancellation or termination is subject to the no-refund policy as
        described above.</p>
          </li>
        </ol>
        <p>
          Please email <a href="mailto:support@kvsstechnologies.com">support@kvsstechnologies.com</a> with any questions regarding
    this Agreement.</p>
        <p>
          KDojang is a trademark of KVSS Technologies, LLC.</p>
        <p>
          --------------------</p>
        <p>
          The KDojang Terms of Service and Use was last updated on May 25th, 2017.</p>
      </div>

    );
  }

}

export default Terms;