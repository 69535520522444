import * as React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

import { capFirstLetter } from "../../../../utils";
import { error, success} from "../../../structure/Alert";
import { ScheduleAPI } from "src/API";


interface IUserAttendance {
  id: number;
  schoolId: number;
  startTime: string;
  eventSystemCategory: string;
  eventTypeName: string;
  name: string;
}

interface IUserAttendanceItemProps {
  item: IUserAttendance;
  className: string;
  userId: number;
  onDelete: any;
}

interface IUserAttendanceItemState {
  loading: boolean;
  showDeleteModal: boolean;
}

export default class UserAttendanceItem extends React.Component<IUserAttendanceItemProps, IUserAttendanceItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showDeleteModal: false,
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} key={this.props.item.id}>
        <div className="col-md-3 vertical-container">
          {moment.utc(this.props.item.startTime).local().format("MM/DD/YY HH:mm")}
        </div>
        <div className="col-md-4 vertical-container">
          {capFirstLetter(this.props.item.name)}
        </div>
        <div className="col-md-3 vertical-container">
          {this.props.item.eventTypeName}
        </div>
        <div className="col-md-2 vertical-container">
          <span className="glyphicon glyphicon-remove text-danger" onClick={this.toggleDeleteModal} />
        </div>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete this attendance entry? This cannot be undone!
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleDelete}>Delete</button>
            <button className="btn" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleDeleteModal(){
    this.setState({showDeleteModal: !this.state.showDeleteModal});
  }

  private handleDelete(){
    this.setState({loading: true}, async () => {
      try{
        await ScheduleAPI.removeUserAttendance(this.props.item.schoolId, this.props.item.id, this.props.userId);
        success("Attendance removed!");
        this.setState({loading: false, showDeleteModal: false}, () => {
          this.props.onDelete(this.props.item);
        });
      }catch(err){
        error("Could not remove that attendance entry for that user");
        this.setState({loading: false, showDeleteModal: false});
      }
    });
  }

}