import * as React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as moment from "moment";

import store from "./store";

import Menu from "./components/structure/Menu";
import Nav from "./components/structure/Nav";

// get the screens
import Home from "./components/screens/Home";
import Terms from "./components/screens/Terms";
import SchoolSetupScreen from "./components/screens/SchoolSignup/SchoolSignupScreen";
import VerifyScreen from "./components/screens/SchoolSignup/SchoolSignupVerification";
import PasswordReset from "./components/screens/PasswordReset/PasswordResetScreen";
import Login from "./components/screens/Login/Login";
import Dashboard from "./components/screens/Dashboard/DashboardScreen";
import SetupWizardScren from "./components/screens/SetupWizard/SetupWizardScreen";
import Alerts from "./components/screens/Alerts/AlertsScreen";
import SchoolNotes from "./components/screens/Notes/NotesScreen";

// school users and lists
import SchoolUsersScreen from "./components/screens/SchoolUsers/SchoolUsersScreen";
import NewUserScreen from "./components/screens/SchoolUsers/NewUserScreen";
import UserInfoScreen from "./components/screens/UserInfo/UserInfoScreen";
import FamilyManagementScreen from "./components/screens/Families/FamilyManagementScreen";

// financial
import SchoolFinancesScreen from "./components/screens/Finances/FinancesScreen";

// schedules
import SchedulesScreen from "./components/screens/Schedules/SchedulesScreen";

// belts
import SchoolBelts from "./components/screens/Belts/SchoolBelts";

// materials
import SchoolMaterials from "./components/screens/Materials/SchoolMaterials";

// school settings and signup
import SchoolSettingsScreen from "./components/screens/SchoolSettings/SchoolSettingsScreen";

// messages
import MessageConfigurationScreen from "./components/screens/Messages/MessageConfigurationScreen";
import SendMessageScreen from "./components/screens/Messages/SendMessageScreen";

// competitions
import SchoolCompetitionsScreen from "./components/screens/Competitions/SchoolCompetitionsScreen";

// reports
import ReportsScreen from "./components/screens/Reports/ReportsScreen";

// documents
import DocumentsScreen from "./components/screens/Documents/DocumentsScreen";

// equipment
import EquipmentScreen from "./components/screens/Equipment/EquipmentScreen";

// exams
import ExamTemplatesScreen from "./components/screens/SchoolExamTemplates/ExamTemplatesScreen";
import ExamsScreen from "./components/screens/Exams/ExamsScreen";

// oauth
import ConnectScreen from "./components/screens/OAuth/ConnectScreen";

// announcements
import AnnouncementsScreen from "./components/screens/Announcements/AnnouncementsScreen";
import SchoolStaff from "./components/screens/SchoolSettings/SchoolStaff";



class App extends React.Component {


  public componentDidMount() {
    (window as any).Stripe.setPublishableKey(process.env.REACT_APP_STRIPE || "pk_MYg1zlteHFYG6Z4h68CLgWjAtiqvM");
  }

  public render() {
    return (
      <Provider store={store}>
        <Router>
          <Menu>
            <div className="container-fluid">
              <Nav />
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <main>
                      <Switch>
                        <Route exact={true} path="/" component={Home} />
                        <Route exact={true} path="/setup" component={SchoolSetupScreen} />
                        <Route exact={true} path="/setup/verify" component={VerifyScreen} />
                        <Route exact={true} path="/reset" component={PasswordReset} />
                        <Route exact={true} path="/reset/:verify" component={PasswordReset} />
                        <Route exact={true} path="/terms" component={Terms} />
                        <Route exact={true} path="/login" component={Login} />
                        <Route exact={true} path="/dashboard" component={Dashboard} />
                        <Route exact={true} path="/schools/:schoolId/setup/wizard" component={SetupWizardScren} />
                        <Route exact={true} path="/schools/:schoolId/dashboard" component={Dashboard} />
                        <Route exact={true} path="/alerts" component={Alerts} />
                        <Route exact={true} path="/schools/:schoolId/alerts" component={Alerts} />
                        <Route exact={true} path="/notes" component={SchoolNotes} />
                        <Route exact={true} path="/schools/:schoolId/notes" component={SchoolNotes} />
                        <Route exact={true} path="/schools/:schoolId/staff" component={SchoolStaff} />

                        <Route exact={true} path="/schools/:schoolId/users" component={SchoolUsersScreen} />
                        <Route exact={true} path="/schools/:schoolId/users/new" component={NewUserScreen} />
                        <Route exact={true} path="/schools/:schoolId/users/:userId" component={UserInfoScreen} />
                        <Route exact={true} path="/schools/:schoolId/users/:userId/:view" component={UserInfoScreen} />
                        <Route exact={true} path="/schools/:schoolId/families" component={FamilyManagementScreen} />

                        <Route exact={true} path="/schools/:schoolId/finances/" component={SchoolFinancesScreen} />
                        <Route exact={true} path="/schools/:schoolId/finances/:view" component={SchoolFinancesScreen} />

                        <Route exact={true} path="/schools/:schoolId/belts" component={SchoolBelts} />

                        <Route exact={true} path="/schools/:schoolId/schedule/" component={SchedulesScreen} />
                        <Route exact={true} path="/schools/:schoolId/materials/" component={SchoolMaterials} />

                        <Route exact={true} path="/schools/:schoolId/settings" component={SchoolSettingsScreen} />
                        <Route exact={true} path="/schools/:schoolId/settings/:view" component={SchoolSettingsScreen} />

                        <Route exact={true} path="/schools/:schoolId/messages" component={MessageConfigurationScreen} />
                        <Route exact={true} path="/schools/:schoolId/sendMessage" component={SendMessageScreen} />

                        <Route exact={true} path="/schools/:schoolId/competitions" component={SchoolCompetitionsScreen} />

                        <Route exact={true} path="/schools/:schoolId/reports" component={ReportsScreen} />

                        <Route exact={true} path="/schools/:schoolId/documents" component={DocumentsScreen} />

                        <Route exact={true} path="/schools/:schoolId/equipment" component={EquipmentScreen} />

                        <Route exact={true} path="/schools/:schoolId/exams/templates" component={ExamTemplatesScreen} />
                        <Route exact={true} path="/schools/:schoolId/exams" component={ExamsScreen} />

                        <Route exact={true} path="/oauth/stripe" component={ConnectScreen} />

                        <Route exact={true} path="/schools/:schoolId/announcements" component={AnnouncementsScreen} />

                      </Switch>
                    </main>
                  </div>
                </div>

                <footer>
                  <div className="row">
                    <div className="col-md-12" style={{ "textAlign": "center" }}>
                      <p className="small">Copyright 2010-{moment().format("YYYY")} <a href="https://www.kvsstechnologies.com" target="_new">KVSS Technologies, LLC</a></p>
                      <p className="small">Use of this application constitutes agreement with our <a href="/terms" target="_new">Terms of Service and Privacy Policy</a></p>
                    </div>
                  </div>
                </footer>
              </div>


              <ReduxToastr
                timeOut={3000}
                newestOnTop={false}
                preventDuplicates={true}
                position="top-center"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar={true} />
            </div>
          </Menu>
        </Router>
      </Provider>
    );
  }
}

export default App;
