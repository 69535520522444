import * as React from "react";
import GoogleMapReact from "google-map-react";
import Card from "src/components/structure/Card";

interface IZipReportMapContainerProps {
  dataRaw: any;
  schoolLat: number;
  schoolLng: number;
}

interface IZipReportMapContainerState {
  loading: boolean;
  metric: string;
  timespan: "last6" | "last12" | "last24";
  heatmapData: IZipReportMapContainerHeatMap;
  markers: any[]; // {lat: lng: display: }
}

interface IZipReportMapContainerHeatMap {
  positions: any[]; // {lat: lng: weight: }
  options: any; // {radius, opacity }
}
const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY ||  "AIzaSyBNCCcQ7h6pxFYfaOyFuQLbTvIdafnIVmw";

export class ZipReportMapContainer extends React.Component<IZipReportMapContainerProps, IZipReportMapContainerState> {


  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      metric: "students",
      timespan: "last6",
      heatmapData: {
        positions: [],
        options: {
          radius: 50,
          opacity: .9,
        }
      },
      markers: [],
    };

    this.updateField = this.updateField.bind(this);
    this.initialize = this.initialize.bind(this);
    this.updateDisplay = this.updateDisplay.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  public componentDidMount(){
    this.initialize();
  }

  public render(){
    if(this.state.loading){
      return <div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />;
    }

    return (
      <div>
        <div className="row">
          <div className="col-12">
            <Card title="Filters">
              <div className="row">
                <div className="col-md-4">
                  <label>Metric</label>
                  <select className="form-control" id="metric" value={this.state.metric} onChange={this.updateField}>
                    <option value="" disabled={true}>Select...</option>
                    <option value="students">New Students</option>
                    <option value="income">Total Income</option>
                    <option value="averageContractLength">Average Contract Length</option>
                    <option value="averageContractAmount">Average Contract Value</option>
                    <option value="attendance">Attendance</option>
                    <option value="completedContracts">Completed Contracts</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label>Time</label>
                  <select className="form-control" id="timespan" value={this.state.timespan} onChange={this.updateField}>
                    <option value="last6">Last 6 Months</option>
                    <option value="last12">Last 12 Months</option>
                    <option value="last24">Last 24 Months</option>
                  </select>
                </div>
              </div>
            </Card>
          </div>
        </div>
          <div style={{width: "100%", height: "80vh"}}>
            <GoogleMapReact
              yesIWantToUseGoogleMapApiInternal={true}
              bootstrapURLKeys={{ key: mapKey, libraries: ["visualization"]}}
              defaultCenter={{lat: this.props.schoolLat, lng: this.props.schoolLng}}
              defaultZoom={10}
              heatmapLibrary={true}
              heatmap={this.state.heatmapData}
              onChildClick={this.toggleShow}
            >
              <SchoolMarker lat={this.props.schoolLat} lng={this.props.schoolLng} />
              {this.state.markers.map((marker: any, i: number) => {
                return <ZipMarker key={i} lat={marker.lat} lng={marker.lng} display={marker.display} show={marker.show} value={marker.value} city={marker.city} state={marker.state} zip={marker.zip} />;
              })}
            </GoogleMapReact>
          </div>
      </div>
    );
  }

  private initialize(){
    this.updateDisplay();
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState({...ns, loading: true }, this.updateDisplay);
  }

  private updateDisplay(){
    this.setState({ loading: true }, () => {
      // we need get the heatmap data based upon the filters
      const heatMapPositions: any[] = [];
      const markers: any[] = [];
      Object.keys(this.props.dataRaw).forEach((zip) => {
        const z = this.props.dataRaw[zip];
        const p: any = {
          lat: z.lat,
          lng: z.lng,
          weight: z[this.state.metric][this.state.timespan],
        };
        heatMapPositions.push(p);

        let val = z[this.state.metric][this.state.timespan];
        if(this.state.metric === "averageContractAmount" || this.state.metric === "income"){
          val = (val/100).toFixed(2);
        } else {
          val = val.toFixed(0);
        }
        const m: any = {
          lat: z.lat,
          lng: z.lng,
          display: `${z.city}, ${z.state}: ${val}`,
          value: val,
          city: z.city,
          state: z.state,
          zip: z.zip,
          show: false,
        };
        markers.push(m);
      });
      const heatmapData = this.state.heatmapData;
      heatmapData.positions = heatMapPositions;
      this.setState({loading: false, heatmapData, markers});
    });
  }

  private toggleShow(index: number){
    const markers = this.state.markers;
    index = parseInt(index + "", 10);
    for(let i = 0; i < markers.length; i++){
      if(i === index){
        markers[i].show = !markers[i].show;
      }else{
        markers[i].show = false;
      }
    }
    this.setState({markers});
  }
}

const InfoPanel = (props: any) => {
  
  return (
    <div style={{
      position: "relative",
      bottom: "150",
      left: "-45px",
      width: 220,
      backgroundColor: "white",
      boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
      padding: 10,
      fontSize: 14,
      zIndex: 100,
    }}>
      <div style={{fontSize: 16}}>
        {props.city}, {props.state}
      </div>
      <div>
        {props.value}
      </div>
    </div>
  );
};



const SchoolMarker = (props: any) => {
  return (
    <div style={{
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      textAlign: "center",
      paddingTop: 2 }}>
      <div className="glyphicon glyphicon-home" style={{textAlign: "center", width: "100%"}} />School
    </div>
  );
};

const ZipMarker = (props: any) => {
  return (
    <div style={{
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      textAlign: "center",
      paddingTop: 2 }}>
      <div className="glyphicon glyphicon-info-sign" style={{textAlign: "center", width: "100%"}} />{props.display}
      {props.show && (<InfoPanel city={props.city} state={props.state} value={props.value} />)}
    </div>
  );
};
