import { makeCall } from "./client";

export class EquipmentAPI {

  public getSchoolEquipment(schoolId: number, category: string, params: any = {}): Promise<any> {
    if(category !== ""){
      params.category = category;
    }
    return makeCall("get", `schools/${schoolId}/equipment`, params);
  }
  
  public createSchoolEquipment(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/equipment`, params);
  }

  public getSchoolEquipmentById(schoolId: number, equipmentId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/equipment/${equipmentId}`, params);
  }

  public updateSchoolEquipment(schoolId: number, equipmentId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/equipment/${equipmentId}`, params);
  }

  public deleteSchoolEquipment(schoolId: number, equipmentId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/equipment/${equipmentId}`, params);
  }

  public getUserEquipment(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/equipment`, params);
  }

  public assignEquipmentToUser(schoolId: number, userId: number, equipmentId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/users/${userId}/equipment/${equipmentId}`, params);
  }

  public removeEquipmentFromUser(schoolId: number, userId: number, equipmentId: number, linkId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/users/${userId}/equipment/${equipmentId}/${linkId}`, params);
  }
}