import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import List from "../../structure/List";
import { ScheduleAPI } from "../../../API";
import { error, success } from "../../structure/Alert";

interface IClassAttendanceProps {
  appActions: any;
  history: any;
  event: any;
  attendance: any;
  onAttendanceChanged: any;
  onAdditionalAttendeesChanged: any;
  schoolState: any;
}

interface IClassAttendanceState {
  loading: boolean;
  additionalAttendees: number;
  showDeleteModal: boolean;
  selectedAttendance: any;
  showAddModal: boolean;
}

class ClassAttendance extends React.Component<IClassAttendanceProps, IClassAttendanceState> {
  private taRef: any = {};

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      additionalAttendees: 0,
      showDeleteModal: false,
      showAddModal: false,
      selectedAttendance: {},
    };

    this.updateField = this.updateField.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.deleteAttendance = this.deleteAttendance.bind(this);
    this.showDeleteAttendanceModal = this.showDeleteAttendanceModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.saveAdditionalAttendees = this.saveAdditionalAttendees.bind(this);
    this.handleSelectedStudent = this.handleSelectedStudent.bind(this);
  }

  public componentDidMount() {
    if(this.props.event && this.props.event.additionalAttendees){
      this.setState({
        additionalAttendees: this.props.event.additionalAttendees
      });
    }
  }

  public render() {
    if (!this.props.event || this.props.event.id === 0) {
      return null;
    }
    return (
      <Card title="Attendance" loading={this.state.loading} help="Below is a list of students who have attended this class. You may add additional students by name. If someone attended but is not a student in the system, you may add that number directly. For example, some schools offer open classes or seminars in which attendance is open to the public.">
      <div className="form-group">
        <label>Add Attendee</label>
        <Typeahead
          key={this.props.schoolState.users.length}
          ref={(ref: any) => {this.taRef = ref;}}
          labelKey="fullName"
          multiple={false}
          placeholder="Enter Student Name"
          onChange={this.handleSelectedStudent}
          options={this.options} />
      </div>
        <List
          data={this.props.attendance}
          renderItem={this.renderItem}
          showTotal={true}
          addToTotal={this.state.additionalAttendees}
        />

        <div className="form-group">
          <label>Add Additional Attendees:</label>
          <input
            id="additionalAttendees"
            type="number"
            className="form-control"
            placeholder="If any non-students attended class, add the number here"
            value={this.state.additionalAttendees}
            onChange={this.updateField} />
        </div>
        <div className="form-group">
          <button className="btn btn-block btn-primary" onClick={this.saveAdditionalAttendees}>Save Additional Attendees</button>
        </div>
        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <strong><p>Are you absolutely sure you want to delete this attendance?</p></strong>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteAttendance}>Delete Attendance</button>
            <button className="btn" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </Card>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    if (e.target.id === "additionalAttendees") {
      ns.additionalAttendees = parseInt(e.target.value, 10);
    }
    this.setState(ns);
  }

  private get options(): [any]{
    const options = this.props.schoolState.users.filter((u: any) => {
      if(u.role === "student" || u.role === "assistant" || u.role === "administrator"){
        return true;
      }
      return false;
    });
    options.sort((a: any, b: any) => {
      return a.lastName > b.lastName ? 1 : -1;
    });
    return options;
  }

  private renderItem(index: number) {
    const d = this.props.attendance[index];
    let classes = "row list-item";

    if (index % 2 !== 0) {
      classes += " list-item-odd";
    }

    return (
      <div className={classes} key={d.id}>
        <div className="col-md-10">
          {d.lastName}, {d.firstName}
        </div>
        <div className="col-md-2">
          <span
            className="glyphicon glyphicon-remove text-danger"
            onClick={this.showDeleteAttendanceModal.bind(null, d)} />
        </div>
      </div>
    );
  }

  private showDeleteAttendanceModal(attendance: any) {
    this.setState({
      showDeleteModal: true,
      selectedAttendance: attendance
    });
  }

  private deleteAttendance() {
    this.setState({loading: true, showDeleteModal: false}, async () => {
      try {
        await ScheduleAPI.removeUserAttendance(this.props.schoolState.school.id, this.props.event.id, this.state.selectedAttendance.id);
        success(`Removed ${this.state.selectedAttendance.firstName} from the attendance roster for this event!`);
      } catch (err) {
        error("Could not delete that attendance");
      }
      this.setState({loading: false}, () => {
        this.props.onAttendanceChanged();
      });
    });
  }

  private toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  private toggleAddModal() {
    this.setState({
      showAddModal: !this.state.showAddModal
    });
  }

  private saveAdditionalAttendees(){
    this.setState({loading: false}, async () => {
      try{
        await ScheduleAPI.updateEvent(this.props.schoolState.school.id, this.props.event.id, {additionalAttendees: this.state.additionalAttendees});
        success("Saved the additional attendees!");
        // since the additional are loaded at the schedule time and not when this is mounted, we need
        // to send that info back up to the caller
        this.props.onAdditionalAttendeesChanged(this.state.additionalAttendees);
      } catch (err){
        error("Could not save the additional attendees");
      }
      this.setState({loading: false});
    });
  }

  private async handleSelectedStudent(e: any){
    this.taRef.getInstance().clear();
    try {
      await ScheduleAPI.saveUserAttendance(this.props.schoolState.school.id, this.props.event.id, e[0].id, {});
      success(`Added ${e[0].firstName}`);
    } catch(err){
      error("Could not save that attendance");
    }
    this.props.onAttendanceChanged();
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassAttendance) as React.ComponentType<any>);