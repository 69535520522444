import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../reducers/appReducer";
import { SchoolsAPI } from "../../API";
import kdBanner from "../../img/kdojang_175x28.png";
import kdBrand from "../../img/brand.png";

interface IPlatformImageProps {
  schoolState: any;
  overrideToPlatform?: boolean;
  preference?: "logo" | "banner";
  platform: "kdojang" | "kstudio";
  size?: "small" | "raw";
  height?: string;
  width?: string;
}

interface IPlatformImageState {
  loading: boolean;
  schoolId: number;
  hasLogo: boolean;
  hasBanner: boolean;
  platform: "kdojang" | "kstudio";
  src?: string;
}

class PlatformImage extends React.Component<IPlatformImageProps, IPlatformImageState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      schoolId: props.schoolState.school.id,
      hasLogo: false,
      hasBanner: false,
      platform: props.platform,
      src: "",
    };

  }

  public async componentDidMount(){
    // if we override, then override
    let src = "";
    if(this.props.overrideToPlatform){
        if(this.props.preference && this.props.preference === "logo"){
            src = kdBrand;
        } else {
            src = kdBanner;
        }
      this.setState({src, loading: false});
      return;
    }

    // so, we have a preference but they may not have what we want, so fall back if needed
    let hasLogo = false;
    let hasBanner = false;

    // find out what they have
    try {
        await SchoolsAPI.getSchoolBranding(this.state.schoolId, "banner", {meta: "yes"});
        hasBanner = true;
    }catch(err){
        // nothing to do, fall through
    }

    if(src === ""){
        try {
            await SchoolsAPI.getSchoolBranding(this.state.schoolId, "logo", {meta: "yes"});
            hasLogo = true;
        }catch(err){
            // nothing to do, fall through
        }
    }

    if(!hasLogo && !hasBanner){
        if(this.props.preference && this.props.preference === "logo"){
            src = kdBrand;
        } else {
            src = kdBanner;
        }
    } else {
        // so, check what was preferred and see if it lines up
        if(this.props.preference === "logo"){
            if(hasLogo){
                src = SchoolsAPI.getSchoolLogoString(this.state.schoolId);
            }else{
                src = SchoolsAPI.getSchoolBannerString(this.state.schoolId);
            }
        }else{
            if(hasBanner){
                src = SchoolsAPI.getSchoolBannerString(this.state.schoolId);
            }else{
                src = SchoolsAPI.getSchoolLogoString(this.state.schoolId);
            }
        }
    }

    if(this.props.size && this.props.size === "small"){
        src += "?size=small";
    }


    this.setState({src, loading: false});
  }

  public render() {
    if(this.state.loading){
      return (<div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />);
    }
    return (
      <img src={this.state.src} alt="Welcome" style={{width: this.props.width || "95%", height: this.props.height || "100%"}} />
    );
  }


}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlatformImage) as React.ComponentType<any>);