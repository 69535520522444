import * as React from "react";
import * as moment from "moment";
import Card from "../../structure/Card";
import { ReportsAPI } from "../../../API";
import { Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Tab, Tabs } from "react-bootstrap";
import { ZipReportTable } from "./ZipReports/ZipReportTable";
import { ZipReportMapContainer } from "./ZipReports/ZipReportMapContainer";
import { format } from "src/utils/currency";

const label6Month = "< 6m";
const label12Month = "< 12m";
const label24Month = "< 24m";

interface IZipReportProps {
  schoolId: number;
}

interface IZipReportState {
  loading: boolean;
  data: any[];
  lineData: any;
  pieData: any;
  zips: string[];
  zipMeta: any;
}

class ZipReport extends React.Component<IZipReportProps, IZipReportState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: [],
      lineData: {
        attendance: [],
        averageContractAmount: [],
        averageContractLength: [],
        completedContracts: [],
        income: [],
        students: [],
      },
      pieData: {
        attendance: {
          last6: [],
          last12: [],
          last24: [],
        },
        averageContractAmount: {
          last6: [],
          last12: [],
          last24: [],
        },
        averageContractLength: {
          last6: [],
          last12: [],
          last24: [],
        },
        completedContracts: {
          last6: [],
          last12: [],
          last24: [],
        },
        income: {
          last6: [],
          last12: [],
          last24: [],
        },
        students: {
          last6: [],
          last12: [],
          last24: [],
        },
      },
      zips: [],
      zipMeta: [],
    };

    this.fetch = this.fetch.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    if(this.state.loading){
      return <div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />;
    }
    return (
      <div>
        <Tabs defaultActiveKey={1} id="report_area_analysis">
          <Tab eventKey={1} title="Tables">
            <div className="row">
              <div className="col-md-6">
                <Card loading={this.state.loading} title="New Students">
                  <ZipReportTable columns={tableColumnsForSubfields} dataMap={this.state.data} subField="students" />
                </Card>
              </div>
              <div className="col-md-6">
                <Card loading={this.state.loading} title="Attendance">
                  <ZipReportTable columns={tableColumnsForSubfields} dataMap={this.state.data} subField="attendance" />
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Card loading={this.state.loading} title="Income">
                  <ZipReportTable columns={tableColumnsForSubfields} dataMap={this.state.data} subField="income" dataIsCurrency={true} />
                </Card>
              </div>
              <div className="col-md-6">
                <Card loading={this.state.loading} title="Completed Contracts">
                  <ZipReportTable columns={tableColumnsForSubfields} dataMap={this.state.data} subField="completedContracts" />
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Card loading={this.state.loading} title="Contract Value">
                  <ZipReportTable columns={tableColumnsForSubfields} dataMap={this.state.data} subField="averageContractAmount" dataIsCurrency={true} />
                </Card>
              </div>
              <div className="col-md-6">
                <Card loading={this.state.loading} title="Contract Length">
                  <ZipReportTable columns={tableColumnsForSubfields} dataMap={this.state.data} subField="averageContractLength" />
                </Card>
              </div>
            </div>
          </Tab>
          <Tab eventKey={2} title="Trends">
            <div className="row">
              <div className="col-md-3">
                <ZipReportTable columns={simpleColumns} dataMap={this.state.data} />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                  <Card loading={this.state.loading} title="Completed Contracts">
                    <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                      <LineChart data={this.state.lineData.completedContracts} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                        {this.state.zips.map((zip: string, i: number) => {
                          return <Line key={zip} type="monotone" dataKey={zip} stroke={getColor(i)} />;
                        })}
                        <YAxis />
                        <XAxis dataKey="what" />
                        <Tooltip />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                </div>

                <div className="col-md-6">
                  <Card loading={this.state.loading} title="Average Contract Length">
                    <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                      <LineChart data={this.state.lineData.averageContractLength} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                        {this.state.zips.map((zip: string, i: number) => {
                          return <Line key={zip} type="monotone" dataKey={zip} stroke={getColor(i)} />;
                        })}
                        <YAxis />
                        <XAxis dataKey="what" />
                        <Tooltip />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Card loading={this.state.loading} title="Average Contract Value">
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <LineChart data={this.state.lineData.averageContractAmount} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                          {this.state.zips.map((zip: string, i: number) => {
                            return <Line key={zip} type="monotone" dataKey={zip} stroke={getColor(i)} />;
                          })}
                          <YAxis />
                          <XAxis dataKey="what" />
                          <Tooltip />
                        </LineChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>

                  <div className="col-md-6">
                    <Card loading={this.state.loading} title="Income">
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <LineChart data={this.state.lineData.income} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                          {this.state.zips.map((zip: string, i: number) => {
                            return <Line key={zip} type="monotone" dataKey={zip} stroke={getColor(i)} />;
                          })}
                          <YAxis />
                          <XAxis dataKey="what" />
                          <Tooltip />
                        </LineChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Card loading={this.state.loading} title="Students Signed Up">
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <LineChart data={this.state.lineData.students} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                          {this.state.zips.map((zip: string, i: number) => {
                            return <Line key={zip} type="monotone" dataKey={zip} stroke={getColor(i)} />;
                          })}
                          <YAxis />
                          <XAxis dataKey="what" />
                          <Tooltip />
                        </LineChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>

                  <div className="col-md-6">
                    <Card loading={this.state.loading} title="Attendance">
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <LineChart data={this.state.lineData.attendance} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                          {this.state.zips.map((zip: string, i: number) => {
                            return <Line key={zip} type="monotone" dataKey={zip} stroke={getColor(i)} />;
                          })}
                          <YAxis />
                          <XAxis dataKey="what" />
                          <Tooltip />
                        </LineChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey={3} title="Percentages">
            <div className="row">
              <div className="col-md-3">
                <ZipReportTable columns={simpleColumns} dataMap={this.state.data} />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-4">
                    <Card title="Attendance < 6 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.attendance.last6} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Attendance < 12 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.attendance.last12} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Attendance < 24 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.attendance.last24} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <Card title="New Students < 6 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.students.last6} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="New Students < 12 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.students.last12} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="New Students < 24 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.students.last24} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <Card title="Income < 6 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.income.last6} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Income < 12 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.income.last12} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Income < 24 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.income.last24} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <Card title="Completed Contracts < 6 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.completedContracts.last6} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Completed Contracts < 12 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.completedContracts.last12} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Completed Contracts < 24 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.completedContracts.last24} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <Card title="Avg Contract Value < 6 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.averageContractAmount.last6} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Avg Contract Value < 12 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.averageContractAmount.last12} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Avg Contract Value < 24 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.averageContractAmount.last24} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <Card title="Avg Contract Length < 6 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.averageContractLength.last6} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Avg Contract Length < 12 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.averageContractLength.last12} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                  <div className="col-md-4">
                    <Card title="Avg Contract Length < 24 Months" loading={this.state.loading}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <PieChart>
                          <Pie data={this.state.pieData.averageContractLength.last24} dataKey="value">
                            {this.state.zips.map((zip: string, i: number) => {
                              return <Cell key={zip} fill={getColor(i)} />;
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey={4} title="Map">
            <ZipReportMapContainer dataRaw={this.state.data} schoolLat={42.791893} schoolLng={-71.2345497} />
          </Tab>
        </Tabs>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try {
        const res = await ReportsAPI.getZipReport(this.props.schoolId, moment().subtract(24, "months").format("YYYY-MM-DD"),moment().add(1, "days").format("YYYY-MM-DD"));
        const b = res.body.data;
        // we need to translate it 
        const zips: any = Object.keys(b);
        const temp = {
          "completedContracts6": {
            "what": label6Month,
          },
          "completedContracts12": {
            "what": label12Month,
          },
          "completedContracts24": {
            "what": label24Month,
          },
          "averageContractAmount6": {
            "what": label6Month,
          },
          "averageContractAmount12": {
            "what": label12Month,
          },
          "averageContractAmount24": {
            "what": label24Month,
          },
          "averageContractLength6": {
            "what": label6Month,
          },
          "averageContractLength12": {
            "what": label12Month,
          },
          "averageContractLength24": {
            "what": label24Month,
          },
          "income6": {
            "what": label6Month,
          },
          "income12": {
            "what": label12Month,
          },
          "income24": {
            "what": label24Month,
          },
          "students6": {
            "what": label6Month,
          },
          "students12": {
            "what": label12Month,
          },
          "students24": {
            "what": label24Month,
          },
          "attendance6": {
            "what": label6Month,
          },
          "attendance12": {
            "what": label12Month,
          },
          "attendance24": {
            "what": label24Month,
          },
        };
        const zipMeta: any[] = [];
        const pieData = this.state.pieData;
        zips.forEach((zip: any) => {
          zipMeta.push({
            zip,
            city: b[zip].city,
            state: b[zip].state,
            lat: b[zip].lat,
            lng: b[zip].lng,
            distanceFromSchool: parseFloat(b[zip].distanceFromSchool.toFixed(1)),
          });
          
          temp.completedContracts6[zip] = b[zip].completedContracts.last6;
          temp.completedContracts12[zip] = b[zip].completedContracts.last12;
          temp.completedContracts24[zip] = b[zip].completedContracts.last24;
          
          temp.attendance6[zip] = b[zip].attendance.last6;
          temp.attendance12[zip] = b[zip].attendance.last12;
          temp.attendance24[zip] = b[zip].attendance.last24;
          
          temp.averageContractAmount6[zip] = parseFloat(format(b[zip].averageContractAmount.last6, true));
          temp.averageContractAmount12[zip] = parseFloat(format(b[zip].averageContractAmount.last12, true));
          temp.averageContractAmount24[zip] = parseFloat(format(b[zip].averageContractAmount.last24, true));
          
          temp.averageContractLength6[zip] = b[zip].averageContractLength.last6;
          temp.averageContractLength12[zip] = b[zip].averageContractLength.last12;
          temp.averageContractLength24[zip] = b[zip].averageContractLength.last24;
          
          temp.income6[zip] = parseFloat(format(b[zip].income.last6, true));
          temp.income12[zip] = parseFloat(format(b[zip].income.last12, true));
          temp.income24[zip] = parseFloat(format(b[zip].income.last24, true));
          
          temp.students6[zip] = b[zip].students.last6;
          temp.students12[zip] = b[zip].students.last12;
          temp.students24[zip] = b[zip].students.last24;

          pieData.attendance.last6.push({
            name: zip,
            value: b[zip].attendance.last6,
          });
          pieData.attendance.last12.push({
            name: zip,
            value: b[zip].attendance.last12,
          });
          pieData.attendance.last24.push({
            name: zip,
            value: b[zip].attendance.last24,
          });

          pieData.averageContractAmount.last6.push({
            name: zip,
            value: parseFloat(format(b[zip].averageContractAmount.last6, true)),
          });
          pieData.averageContractAmount.last12.push({
            name: zip,
            value: parseFloat(format(b[zip].averageContractAmount.last12, true)),
          });
          pieData.averageContractAmount.last24.push({
            name: zip,
            value: parseFloat(format(b[zip].averageContractAmount.last24, true)),
          });

          pieData.averageContractLength.last6.push({
            name: zip,
            value: b[zip].averageContractLength.last6,
          });
          pieData.averageContractLength.last12.push({
            name: zip,
            value: b[zip].averageContractLength.last12,
          });
          pieData.averageContractLength.last24.push({
            name: zip,
            value: b[zip].averageContractLength.last24,
          });

          pieData.completedContracts.last6.push({
            name: zip,
            value: b[zip].completedContracts.last6,
          });
          pieData.completedContracts.last12.push({
            name: zip,
            value: b[zip].completedContracts.last12,
          });
          pieData.completedContracts.last24.push({
            name: zip,
            value: b[zip].completedContracts.last24,
          });

          pieData.income.last6.push({
            name: zip,
            value: parseFloat(format(b[zip].income.last6, true)),
          });
          pieData.income.last12.push({
            name: zip,
            value: parseFloat(format(b[zip].income.last12, true)),
          });
          pieData.income.last24.push({
            name: zip,
            value: parseFloat(format(b[zip].income.last24, true)),
          });

          pieData.students.last6.push({
            name: zip,
            value: b[zip].students.last6,
          });
          pieData.students.last12.push({
            name: zip,
            value: b[zip].students.last12,
          });
          pieData.students.last24.push({
            name: zip,
            value: b[zip].students.last24,
          });
        });
        const lineEntries = this.state.lineData;
        lineEntries.completedContracts = [temp.completedContracts24, temp.completedContracts12, temp.completedContracts6];
        lineEntries.attendance = [temp.attendance24, temp.attendance12, temp.attendance6];
        lineEntries.averageContractAmount = [temp.averageContractAmount24, temp.averageContractAmount12, temp.averageContractAmount6];
        lineEntries.averageContractLength = [temp.averageContractLength24, temp.averageContractLength12, temp.averageContractLength6];
        lineEntries.students = [temp.students24, temp.students12, temp.students6];
        lineEntries.income = [temp.income24, temp.income12, temp.income6];


        zipMeta.sort((first, second) => {
          return first.distanceFromSchool > second.distanceFromSchool ? 1 : -1;
        });
        this.setState({loading: false, data: b, lineData: lineEntries, zips, zipMeta, pieData });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }


}


export default ZipReport;

const colors = [
  "#165706",
  "#699df0",
  "#f06989",
  "#69f089",
  "#f0a469",
  "#060f57",
  "#570609",
  "#72e4e8",
  "#e872e4",
  "#d8e872"
];

const getColor = (index: number): string => {
  let idx = index;
  while(idx > colors.length){
    idx -= colors.length;
  }
  return colors[idx];
};

const simpleColumns = [
  {
    Header: "Zip",
    accessor: "zip",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "State",
    accessor: "state",
  },
  {
    Header: "Distance",
    accessor: "distanceFromSchool",
  },
];

const tableColumnsForSubfields = [
  {
    Header: "Zip",
    accessor: "zip",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "State",
    accessor: "state",
  },
  {
    Header: "Distance",
    accessor: "distanceFromSchool",
  },
  {
    Header: "< 6 Months",
    accessor: "last6",
  },
  {
    Header: "< 12 Months",
    accessor: "last12",
  },
  {
    Header: "< 24 Months",
    accessor: "last24",
  },
];