import * as React from "react";
import { Modal } from "react-bootstrap";

import { ExamsAPI } from "../../../API";
import { error } from "../../structure/Alert";

interface IExamCriteriaScoreListItem {
  calculatedScore: number;
  criteriaId: number;
  enteredBy: number;
  enteredByFirstName: string;
  enteredByLastName: string;
  examId: number;
  id: number;
  notes: string;
  rawScore: number;
  schoolId: number;
  studentId: number;
}

interface IExamCriteriaScoreListItemProps {
  onDelete: any;
  userCanDelete: boolean;
  item: IExamCriteriaScoreListItem;
}

interface IExamCriteriaScoreListItemState {
  loading: boolean;
  showDeleteModal: boolean;
}

export default class ExamCriteriaScoreListItem extends React.Component<IExamCriteriaScoreListItemProps, IExamCriteriaScoreListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showDeleteModal: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
  }

  public render() {
    return (
      <div style={{marginBottom: 10}}>
        <div className="row">
          <div className="col-md-5" style={{ marginTop: 5 }}>
            {this.props.item.enteredByFirstName} {this.props.item.enteredByLastName}
          </div>
          <div className="col-md-5" style={{ marginTop: 5 }}>
            {this.props.item.rawScore}
          </div>
          <div className="col-md-2" style={{ marginTop: 5 }}>
            {this.props.userCanDelete && (<span className="glyphicon glyphicon-remove text-danger" onClick={this.toggleShowDeleteModal} />)}
          </div>
        </div>

        <div className="row">
          <div className="col-md-2">
            Notes:
          </div>
          <div className="col-md-10">
            {this.props.item.notes}
          </div>
        </div>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this score? This cannot be undone!
            </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleDelete}>Delete Score</button>
            <button className="btn btn-primary" onClick={this.toggleShowDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleShowDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private handleDelete() {
    // we delete and send the result all the way up
    this.setState({ loading: true }, async () => {
      try {
        const res = await ExamsAPI.deleteExamScore(this.props.item.schoolId, this.props.item.examId, this.props.item.criteriaId, this.props.item.id);
        this.setState({ showDeleteModal: false, loading: false }, () => {
          this.props.onDelete(res.body.data);
        });
      } catch (err) {
        this.setState({ loading: false });
        error("Could not delete that score");
      }
    });
  }


}