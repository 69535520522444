import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";
import { SchoolUsersAPI } from "../../../API";

interface IFamilyManagementScreenProps {
  appActions: any;
  schoolState: any;
  history: any;
}

interface IFamilyManagementScreenState {
  loading: boolean;
  families: any[];
  status: "active" | "archived";
  selectedFamilyId: number;
  selectedFamily: any;
  selectedFamilyMembers: any;
}

class FamilyManagementScreen extends React.Component<IFamilyManagementScreenProps, IFamilyManagementScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      status: "active",
      families: [],
      selectedFamilyId: 0,
      selectedFamily: {id: 0},
      selectedFamilyMembers: []
    };

    this.fetchFamilies = this.fetchFamilies.bind(this);
    this.selectFamily = this.selectFamily.bind(this);
    this.updateField = this.updateField.bind(this);
    this.changeFamilyStatus = this.changeFamilyStatus.bind(this);
    this.changeFamilyName = this.changeFamilyName.bind(this);
    this.changeFamilyHeadAccount = this.changeFamilyHeadAccount.bind(this);
    this.saveFamily = this.saveFamily.bind(this);
  }

  public componentDidMount(){
    this.fetchFamilies();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <Card title="Families" loading={this.state.loading} help="Select a family from the list to manage the members and settings of the family">
            <div className="form-group">
              <label>Showing Families That Are</label>
              <select id="status" className="form-control" value={this.state.status} onChange={this.updateField}>
                <option value="active">Active</option>
                <option value="archived">Archived</option>  
              </select>  
            </div>
            <div className="form-group">
              <label>Families</label>
              <select id="selectedFamilyId" className="form-control" value={this.state.status} onChange={this.selectFamily}>
                <option value="0">Select a family</option>
                {this.state.families.map((family, index) => {
                  return (<option value={family.id} key={index}>{family.name}</option>);
                })}
              </select>  
            </div>
          </Card>
        </div>
        {this.state.selectedFamily.id !== 0 && (
          <div className="col-md-4">
            <Card title={`${this.state.selectedFamily.name}`} loading={this.state.loading} help="You can see which members are in a family, change the head account, and archive the family. Archived families will not appear in most lists. Please note that this does not automatically mark any students as inactive, as they may have remaining contracts, invoices, or other reasons to remain active">
              <div className="form-group">
                <label>Family Name</label>
                <input type="text" className="form-control" id="selectedFamilyName" value={this.state.selectedFamily.name} onChange={this.changeFamilyName} />
              </div>
              <div className="form-group">
                <label>Family Status</label>
                <select id="status" className="form-control" value={this.state.selectedFamily.status} onChange={this.changeFamilyStatus}>
                  <option value="active">Active</option>
                  <option value="archived">Archived</option>  
                </select>  
              </div>
              <div className="form-group">
                <label>Head Account</label>
                <select id="headAccount" className="form-control" value={this.state.selectedFamily.headAccount+""} onChange={this.changeFamilyHeadAccount}>
                  {this.state.selectedFamilyMembers.map((user: any) => { 
                    return (<option value={user.id} key={user.id}>{user.firstName} {user.lastName} ({user.schoolRole})</option>);
                  })}
                </select>  
              </div>
              <ul>
              {this.state.selectedFamilyMembers.map((user: any) => {
                return (<li key={user.id}><Link to={`/schools/${this.props.schoolState.school.id}/users/${user.id}`}>{user.firstName} {user.lastName} ({user.schoolRole})</Link></li>);
              })}
            </ul>
            <div className="form-group">
              <button className="btn btn-block btn-primary" onClick={this.saveFamily}>Update Family</button>
            </div>
            </Card>
          </div>
          )}
      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => {
      if(id === "status"){
        this.fetchFamilies();
      }
    });
  }

  private async selectFamily(e: any){
    const familyId = parseInt(e.target.value,10);
    let family = {id: 0};
    for(const f of this.state.families){
      if(f.id === familyId){
        family = f;
      }
    }
    this.setState({ loading: true }, async () => {
      // get the users
      try{
        const usersRes = await SchoolUsersAPI.getFamily(this.props.schoolState.school.id, familyId);
        const selectedFamilyMembers = usersRes.body.data.users ? usersRes.body.data.users : [] ;
        this.setState({selectedFamilyId: familyId, selectedFamily: family, selectedFamilyMembers, loading: false});
      }catch(err){
        error("Could not load the family");
        this.setState({loading: false});
      }
    });
  }

  private fetchFamilies(){
    this.setState({loading: true}, async () => {
      try{
        const famRes = await SchoolUsersAPI.getFamiliesInSchool(this.props.schoolState.school.id, this.state.status);
        this.setState({ loading: false, families: famRes.body.data});
      }catch(err){
        error("Could not load the families for the school");
        return this.setState({loading: false});
      }
    });
  }

  private changeFamilyName(e: any){
    const name = e.target.value;
    const selectedFamily = this.state.selectedFamily;
    selectedFamily.name = name;
    this.setState({ selectedFamily });
  }

  private changeFamilyStatus(e: any){
    const status = e.target.value;
    const selectedFamily = this.state.selectedFamily;
    selectedFamily.status = status;
    this.setState({ selectedFamily });
  }

  private changeFamilyHeadAccount(e: any){
    const headAccount = parseInt(e.target.value, 10);
    const selectedFamily = this.state.selectedFamily;
    selectedFamily.headAccount = headAccount;
    this.setState({ selectedFamily });
  }

  private saveFamily(){
    this.setState({loading: true}, async () => {
      try{
        await SchoolUsersAPI.updateFamily(this.props.schoolState.school.id, this.state.selectedFamily.id, this.state.selectedFamily);
        success("Family updated");
        this.setState({loading: false});
      }catch(err){
        error("Could not save that family information");
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FamilyManagementScreen) as React.ComponentType<any>);