import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import Card from "../../../structure/Card";
import * as AppActions from "../../../../reducers/appReducer";
import * as moment from "moment";
import { FinancesAPI } from "../../../../API/";
import { success, error } from "../../../structure/Alert";

interface IUserCardInfoProps {
  appActions: any;
  history: any;
  userId: number;
  schoolId: number;
}

interface IUserCardInfoState {
  loading: boolean;
  name: string;
  number: string;
  expirationMonth: string;
  expirationYear: string;
  cvc: string;
  showRemoveModal: boolean;
  hasMethod: boolean;
  currentMethod: {
    expiresMonth: string;
    expiresYear: string;
    lastFour: string;
  };
}

const helpText = `You can enter a credit card here. If a card already exists, it will be replaced. Only one card can be active for a user. If a user does not have a card, the system will automatically attempt to the charge the card on the head account for the family.`;

class UserCardInfo extends React.Component<IUserCardInfoProps, IUserCardInfoState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      number: "",
      expirationMonth: moment().format("MM"),
      expirationYear: moment().format("YY"),
      cvc: "",
      showRemoveModal: false,
      hasMethod: false,
      currentMethod: {
        expiresMonth: "",
        expiresYear: "",
        lastFour: ""
      }
    };

    this.updateField = this.updateField.bind(this);
    this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
    this.removePaymentMethod = this.removePaymentMethod.bind(this);
    this.toggleRemovePaymentMethodModal = this.toggleRemovePaymentMethodModal.bind(this);
    this.getCurrentPaymentMethod = this.getCurrentPaymentMethod.bind(this);
    this.createCardString = this.createCardString.bind(this);
  }

  public componentDidMount() {
    this.getCurrentPaymentMethod();
  }

  public render() {
    return (
      <Card title="Payment Info" loading={this.state.loading} help={helpText}>
        {this.state.hasMethod ?
          (<div><p className="bg-success" style={{ textAlign: "center", padding: 5 }}><strong>{this.createCardString()}</strong></p>
            <div className="row">
              <div className="col-md-12">
                <p><strong>Use the form below to replace the existing card</strong></p>
              </div>
            </div></div>) :
          (<div className="bg-danger" style={{ textAlign: "center", padding: 5 }}><strong>No payment method on file!</strong></div>)}
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Name on Card</label>
              <input type="text" id="name" value={this.state.name} onChange={this.updateField} className="form-control" placeholder="The Name On The Card" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Card Information</label>
              <input type="text" id="number" value={this.state.number} onChange={this.updateField} className="form-control" placeholder="The Credit Card Number" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <select id="expirationMonth" value={this.state.expirationMonth} onChange={this.updateField} className="form-control">
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <select id="expirationYear" value={this.state.expirationYear} onChange={this.updateField} className="form-control">
                <option value="20">2020</option>
                <option value="21">2021</option>
                <option value="22">2022</option>
                <option value="23">2023</option>
                <option value="24">2024</option>
                <option value="25">2025</option>
                <option value="26">2026</option>
                <option value="27">2027</option>
                <option value="28">2028</option>
                <option value="29">2029</option>
                <option value="30">2030</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <input type="text" id="cvc" value={this.state.cvc} onChange={this.updateField} className="form-control" placeholder="CVC" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-block btn-success" onClick={this.updatePaymentMethod}>Update Payment Method</button>
            {this.state.hasMethod && (
              <button className="btn btn-block btn-danger" onClick={this.toggleRemovePaymentMethodModal}>Remove Payment Method</button>
            )}
          </div>
        </div>

        <Modal show={this.state.showRemoveModal} onHide={this.toggleRemovePaymentMethodModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete this payment method? Any existing or future invoices will not be able to automatically charge
            until a new payment method is provided.

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.removePaymentMethod}>Yes, Remove Their Payment Method</button>
            <button className="btn btn-primary" onClick={this.toggleRemovePaymentMethodModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </Card>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private async updatePaymentMethod() {
    this.setState({ loading: true }, async () => {

      (window as any).Stripe.card.createToken({
        number: this.state.number,
        cvc: this.state.cvc,
        exp_month: this.state.expirationMonth,
        exp_year: this.state.expirationYear
      }, async (status: any, response: any) => {
        if (status !== 200) {
          return this.setState({ loading: false }, () => {
            return error("Could not save that payment information");
          });
        }

        try {
          const data = {
            token: response.id,
            methodType: "card"
          };
          await FinancesAPI.saveUserPaymentMethod(this.props.schoolId, this.props.userId, data);
          return this.setState({ loading: false, hasMethod: true }, () => {
            success("Payment information updated");
            this.getCurrentPaymentMethod();
          });
        } catch (err) {
          return this.setState({ loading: false }, () => {
            return error("Could not save that payment method");
          });
        }
      });
    });

  }

  private toggleRemovePaymentMethodModal() {
    this.setState({ showRemoveModal: !this.state.showRemoveModal });
  }

  private async removePaymentMethod() {
    this.setState({ loading: true }, async () => {
      try {
        await FinancesAPI.removeUserPaymentMethod(this.props.schoolId, this.props.userId);
        this.setState({ loading: false, hasMethod: false, showRemoveModal: false }, () => {
          return success("Payment method removed");
        });
      } catch (err) {
        this.setState({ loading: false, showRemoveModal: false }, () => {
          return error("Could not remove that payment method");
        });
      }
    });
  }

  private async getCurrentPaymentMethod() {
    this.setState({ loading: true }, async () => {
      try {
        const paymentRes = await FinancesAPI.getUserPaymentMethod(this.props.schoolId, this.props.userId);
        this.setState({
          loading: false,
          hasMethod: true,
          currentMethod: {
            lastFour: paymentRes.body.data.source.lastFour,
            expiresMonth: paymentRes.body.data.source.expiresMonth,
            expiresYear: paymentRes.body.data.source.expiresYear
          },
        });
      } catch (err) {
        this.setState({
          loading: false,
          hasMethod: false
        });
      }
    });
  }

  private createCardString() {
    if (this.state.currentMethod.lastFour === "") {
      return "Card On File But Unknown";
    }
    return `Ends in ${this.state.currentMethod.lastFour} Expiring ${this.state.currentMethod.expiresMonth}/${this.state.currentMethod.expiresYear}`;
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserCardInfo) as React.ComponentType<any>);