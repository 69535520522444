import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import BeltImage from "./BeltImage";
import BeltStats from "./BeltStats";
import {BeltsAPI} from "../../../API";
import {error, success} from "../../structure/Alert";
import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import * as SchoolActions from "../../../reducers/schoolReducer";

interface IBeltsProps {
  appActions: any;
  history: any;
  schoolState: any;
  schoolActions: any;
}

interface IBeltsState {
  loading: boolean;
  belts: any;
  selectedBelt: any;
  beltStats: any;
  showDeleteModal: boolean;
  showNewBeltModal: boolean;
  newBeltName: string;
  newBeltTopColor: string;
  newBeltBottomColor: string;
  newBeltNumberDisplay: number;
  newBeltNumberColor: string;
  orderChanged: boolean;
}

const paddingBase = 2;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  padding: paddingBase * 2,
  margin: `0 0 ${paddingBase}px 0`,
  background: isDragging ? "#edf2f9" : "white",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "#b6bbc1" : "#b6bbc1",
  padding: paddingBase,
});

class Belts extends React.Component<IBeltsProps, IBeltsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      belts: [],
      selectedBelt: {id : 0},
      beltStats: {},
      showDeleteModal: false,
      showNewBeltModal: false,
      newBeltName: "",
      newBeltTopColor: "#FFFFFF",
      newBeltBottomColor: "#FFFFFF",
      newBeltNumberDisplay: 0,
      newBeltNumberColor: "#FFFFFF",
      orderChanged: false,
    };
    this.fetch = this.fetch.bind(this);
    this.createBelt = this.createBelt.bind(this);
    this.deleteBelt = this.deleteBelt.bind(this);
    this.updateBelt = this.updateBelt.bind(this);
    this.toggleNewBeltModal = this.toggleNewBeltModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateBeltField = this.updateBeltField.bind(this);
    this.selectBelt = this.selectBelt.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.getContents = this.getContents.bind(this);
    this.saveBeltOrder = this.saveBeltOrder.bind(this);
    this.copyTopColorToBottomForBelt = this.copyTopColorToBottomForBelt.bind(this);
    this.copyTopColorToBottomForNewBelt = this.copyTopColorToBottomForNewBelt.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <Card title="Select a Belt" loading={this.state.loading} help="Belts represent ranks or progression in a school. Many schools use belts to denote progress. Belts should have an order of progression and can be tied to curriculum. In other words, if a student is assigned a new belt, they can automatically be assigned new curriculum!">
          
            {this.getContents()}

            <div className="form-group">
              {this.state.orderChanged && 
                (<button className="btn btn-success btn-block" onClick={this.saveBeltOrder}>Save Belt Order</button>)
              }
              <button className="btn btn-success btn-block" onClick={this.toggleNewBeltModal}>Add New Belt</button>
            </div>
          </Card>
        </div>
        {this.state.selectedBelt.id !== 0 && (
          <div className="col-md-5">
          <Card title="Belt Details" loading={this.state.loading} help="You should name the belt and provide the belt colors. For schools that have belts with multiple colors (such as a top half Black and bottom half Red), select the colors as appropriate.">
            <div className="form-group">
              <label>Name</label>
              <input type="text" className="form-control" id="name" value={this.state.selectedBelt.name} onChange={this.updateBeltField} />
            </div>
            <div className="form-group">
              <label>Top Color</label>
              <input type="color" className="form-control" id="topColor" value={this.state.selectedBelt.topColor} onChange={this.updateBeltField} />
            </div>
            <div className="form-group">
              <label>Bottom Color</label>
              <div className="row">
                <div className="col-md-11">
                  <input type="color" className="form-control" id="bottomColor" value={this.state.selectedBelt.bottomColor} onChange={this.updateBeltField} />
                </div>
                <div className="col-md-1" onClick={this.copyTopColorToBottomForBelt} style={{marginTop: 10}}>
                  <span className="glyphicon glyphicon-download" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <button className="btn btn-success btn-block" onClick={this.updateBelt}>Save Belt</button>
              <button className="btn btn-danger btn-block" onClick={this.toggleDeleteModal}>Delete Belt</button>
            </div>
          </Card>
          </div>
        )}
        {this.state.selectedBelt.id !== 0 && (
          <div className="col-md-4">
            <BeltStats users={this.props.schoolState.users} beltId={this.state.selectedBelt.id} beltName={this.state.selectedBelt.name} />
          </div>
        )}

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Belt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <strong><p>Are you absolutely sure you want to delete this belt? This cannot be undone.</p></strong>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteBelt}>Delete Belt</button>
            <button className="btn" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showNewBeltModal} onHide={this.toggleNewBeltModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>New Belt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Belt Name</label>
              <input type="text" id="newBeltName" className="form-control" value={this.state.newBeltName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Top Color</label>
              <input type="color" className="form-control" id="newBeltTopColor" value={this.state.newBeltTopColor} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Bottom Color</label>
              <div className="row">
                <div className="col-md-11">
                  <input type="color" className="form-control" id="newBeltBottomColor" value={this.state.newBeltBottomColor} onChange={this.updateField} />
                </div>
                <div className="col-md-1" onClick={this.copyTopColorToBottomForNewBelt} style={{marginTop: 10}}>
                  <span className="glyphicon glyphicon-download" />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.createBelt}>Create New Belt</button>
            <button className="btn" onClick={this.toggleNewBeltModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private getContents(){
    if(this.state.belts.length === 0){
      return (<strong>No belts exist for this school. Begin by creating a new belt!</strong>);
    }

    return (
      <div style={{marginBottom: 10}}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.belts.map((belt: any, index: number) => (
                  <Draggable key={belt.id} draggableId={belt.id} index={index}>
                    {(providedInner: any, snapshotInner) => (
                      <div
                        ref={providedInner.innerRef}
                        {...providedInner.draggableProps}
                        {...providedInner.dragHandleProps}
                        style={getItemStyle(
                          snapshotInner.isDragging, 
                          providedInner.draggableProps.style
                        )}
                      >
                        {this.getBelt(belt)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  private getBelt(belt: any){
    return (
      <div className="row" key={belt.id} style={{height: 36, paddingTop: 3, paddingBottom: 3}}>
        <div className="col-md-2">
          <span className="glyphicon glyphicon-align-justify" />
        </div>
        <div className="col-md-2">
          <BeltImage 
            topColor={belt.topColor}
            bottomColor={belt.bottomColor}
            numberDisplay={belt.numberDisplay}
            numberDisplayColor={belt.numberDisplayColor}
            />
        </div>
        <div className="col-md-5">
          {belt.name}
        </div>
        <div className="col-md-3" onClick={this.selectBelt.bind(null, belt)} style={{cursor: "default"}}>
          <span className="glyphicon glyphicon-pencil" />
        </div>
      </div>
    );
  }

  private async onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const belts = this.reorder(
      this.state.belts,
      result.source.index,
      result.destination.index
    );

    this.setState({
      belts,
      orderChanged: true,
    });
  }

  private reorder(list: any, startIndex: number, endIndex: number){
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  }

  private async fetch(){
    this.setState({loading: true}, async () => {
      try{
        const res = await BeltsAPI.getSchoolBelts(this.props.schoolState.school.id);
        // since we fetched them anyway, save them in redux
        await this.props.schoolActions.setBelts(res.body.data);
        const belts = [];
        for(const b of res.body.data){
          belts.push({
            ...b,
            topColor: "#"+ b.topColor,
            bottomColor: "#"+ b.bottomColor,
            numberDisplayColor: "#"+ b.numberDisplayColor,
          });
        }
        this.setState({loading: false, belts, showDeleteModal: false, showNewBeltModal: false, orderChanged: false});
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not load belts");
        });
      }
    });
  }

  private async saveBeltOrder(){
    this.setState({loading: true}, async () => {
      try{
        const data = {
          data: this.state.belts
        };
        await BeltsAPI.reorderBelts(this.props.schoolState.school.id, data);
        this.setState({loading: false, orderChanged: false}, () => {
          return success("Belts successfully reordered!");
        });
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not load belts");
        });
      }
    });
  }

  private async deleteBelt(){
    this.setState({loading: true}, async () => {
      try{
        await BeltsAPI.deleteSchoolBelt(this.props.schoolState.school.id, this.state.selectedBelt.id);
        this.setState({loading: false, selectedBelt: {id: 0}}, () => {
          this.fetch();
        });
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not delete that belt");
        });
      }
    });
  }

  private async createBelt(){
    if(this.state.newBeltName === ""){
      return error("Belt cannot have a blank name");
    }
    try{
      const params = {
        name: this.state.newBeltName,
        topColor: this.state.newBeltTopColor,
        bottomColor: this.state.newBeltBottomColor,
        numberDisplay: this.state.newBeltNumberDisplay,
        numberDisplayColor: this.state.newBeltNumberColor
      };
      const res = await BeltsAPI.createSchoolBelt(this.props.schoolState.school.id, params);
      // we need to add a # before the colors
      res.body.data.topColor = "#" + res.body.data.topColor;
      res.body.data.bottomColor = "#" + res.body.data.bottomColor;
      res.body.data.numberDisplayColor = "#" + res.body.data.numberDisplayColor;
      this.setState({
        loading: false, 
        selectedBelt: res.body.data,
        newBeltName: "",
        newBeltTopColor: "#FFFFFF",
        newBeltBottomColor: "#FFFFFF",
        newBeltNumberDisplay: 0,
        newBeltNumberColor: "#FFFFFF"}, () => {
        this.fetch();
      });
    }catch(err){
      this.setState({loading: false}, () => {
        return error("Could not load belts");
      });
    }
  }

  private async updateBelt(){
    if(this.state.selectedBelt.name === ""){
      return error("Belt cannot have a blank name");
    }
    try{
      const params = {
        name: this.state.selectedBelt.name,
        topColor: this.state.selectedBelt.topColor,
        bottomColor: this.state.selectedBelt.bottomColor,
        numberDisplay: this.state.selectedBelt.numberDisplay,
        numberDisplayColor: this.state.selectedBelt.numberDisplayColor
      };
      const res = await BeltsAPI.updateSchoolBelt(this.props.schoolState.school.id, this.state.selectedBelt.id, params);
      const ret = res.body.data;
      ret.topColor = "#" + ret.topColor;
      ret.bottomColor = "#" + ret.bottomColor;
      ret.numberDisplayColor = "#" + ret.numberDisplayColor;
      this.setState({loading: false, selectedBelt: ret}, () => {
        this.fetch();
      });
    }catch(err){
      this.setState({loading: false}, () => {
        return error("Could not load belts");
      });
    }
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateBeltField(e: any){
    const ns = this.state.selectedBelt;
    ns[e.target.id] = e.target.value;
    this.setState({selectedBelt: ns});
  }

  private selectBelt(selectedBelt: any){
    this.setState({selectedBelt});
  }

  private toggleNewBeltModal(){
    this.setState({ showNewBeltModal: !this.state.showNewBeltModal});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private copyTopColorToBottomForBelt(){
    const selectedBelt = this.state.selectedBelt;
    selectedBelt.bottomColor = selectedBelt.topColor;
    this.setState({
      selectedBelt
    });
  }

  private copyTopColorToBottomForNewBelt(){
    this.setState({
      newBeltBottomColor: this.state.newBeltTopColor
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Belts) as React.ComponentType<any>);