import * as React from "react";
import Dropzone from "react-dropzone";
import Img from "react-image";
import VisibilitySensor from "react-visibility-sensor";

import { error, success } from "./Alert";

import def from "../../img/default-user-icon.png";
import { SchoolsAPI } from "../../API";

interface IStaffImageProps {
  staffId: number;
  schoolId: number;
  height: number|string;
  width: number|string;
  size: string;
  useSensor?: boolean;
  imageUrl: string;
}

interface IStaffImageState {
  loading: boolean;
  loadTime: any;
  getElement?: any;
}

class StaffImage extends React.Component<IStaffImageProps, IStaffImageState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loadTime: new Date(), // cache busting,
      loading: false,
      getElement: null
    };
    this.getImage = this.getImage.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  public render() {
    if(this.state.loading || !this.props.staffId){
      return (<div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />);
    }
    
    if(!this.props.useSensor){
      // just return the drop zone
      return this.renderDropzone();
    }
    const containmentDOMRect = this.state.getElement
      ? this.state.getElement
      : null;

    return ( 
      <VisibilitySensor key={`image-${this.props.staffId}`} containment={containmentDOMRect} partial={false}>
        {(isVisible: any, rect: any) => {
          if(isVisible.isVisible){
          return this.renderDropzone();
        } else {
          return (<div style={{width: this.props.width, height: this.props.height}} />);
        }
      }}
      </VisibilitySensor>
    );
  }

  private renderDropzone(){
    const im = this.getImage();
    return ((
    <Dropzone onDrop={this.handleDrop} maxSize={5 * 1024 * 1024}>
      {({getRootProps, getInputProps}) => {
        return (
          <div
            {...getRootProps()}
            className="dropzone"
            style={{"textAlign": "center"}}>
            <input {...getInputProps()} />
            <Img 
              width={this.props.width}
              height={this.props.height}
              loader={<div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />}
              src={[im, def]} 
              unloader={(<img src="/img/default-user-icon.png" height={this.props.height} width={this.props.width} />)}/>
          </div>
        );
      }
      }
    </Dropzone>));
  }

  private async handleDrop(acceptedFiles: any) {
    this.setState({loading: true}, async () => {
      try{
        await SchoolsAPI.uploadStudentStaffPicture(this.props.schoolId, this.props.staffId, acceptedFiles[0]);
        return this.setState({ loadTime: new Date(), loading: false}, () => {
          return success("Staff image updated! It may take a few minutes for the new image to appear throughout the application.");
        });
      }catch(err){
        return this.setState({loading: false}, () => {
          return error("Could not save that image");
        });
      }
    });
  }

  private getImage() {
    return this.props.imageUrl;
  }
}


export default StaffImage;