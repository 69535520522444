import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import LazyLoad from "react-lazyload";

import UserMaterial from "./UserMaterial";
import { KnowledgeAPI } from "../../../../API";
import { error, success } from "../../../structure/Alert";
import Card from "../../../structure/Card";
import * as AppActions from "../../../../reducers/appReducer";

interface IUserMaterialContainerProps {
  appActions: any;
  history: any;
  userId: number;
  schoolId: number;
}

interface IUserMaterialContainerState {
  loading: boolean;
  materials: any;
  filteredMaterials: any;
  schoolGroups: any;
  selectedGroup: number;
  selectedStatus: "assigned" | "completed" | "pending" | "all";
  assignSchoolGroupId: number;
  showAssignModal: boolean;

}

class UserMaterialContainer extends React.Component<IUserMaterialContainerProps, IUserMaterialContainerState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      materials: [],
      filteredMaterials: [],
      schoolGroups: [],
      selectedGroup: 0,
      selectedStatus: "all",
      assignSchoolGroupId: 0,
      showAssignModal: false,
    };

    this.fetch = this.fetch.bind(this);
    this.filter = this.filter.bind(this);
    this.toggleAssignModal = this.toggleAssignModal.bind(this);
    this.assignMaterial = this.assignMaterial.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.changeSelectedGroup = this.changeSelectedGroup.bind(this);
    this.changeSelectedStatus = this.changeSelectedStatus.bind(this);
    this.changeAssignedMaterialSelect = this.changeAssignedMaterialSelect.bind(this);
    this.getAssignedGroupOptions = this.getAssignedGroupOptions.bind(this);
  }

  public componentDidMount() {
    this.fetch();
  }

  public render() {
    const assignOptions = this.getAssignedGroupOptions();
    return (
      <Card title="Curriculum" loading={this.state.loading} help="Many schools assign curriculum to coincide with a belt or training regimen. If set up using the Knowledge screen in the Menu, the student can have their progress tracked, including their completion towards their next rank or belt.">
      <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.toggleAssignModal}>Assign New Material</button>
            </div>
          </div>
      </div>
        <div className="row" style={{marginBottom: 10}}>
          <div className="col-md-12">
            <select id="selectedGroup" className="form-control" value={this.state.selectedGroup} onChange={this.changeSelectedGroup}>
              <option key="0" value="0">Showing All</option>
              {this.state.schoolGroups.map((m: any) => {
                return (<option key={m.id} value={m.id}>Showing {m.name}</option>);
              })}
            </select>
          </div>
        </div>
        <div className="row" style={{marginBottom: 10}}>
          <div className="col-md-12">
            <select id="selectedStatus" className="form-control" value={this.state.selectedStatus} onChange={this.changeSelectedStatus}>
              <option value="all">With Any Status</option>
              <option value="assigned">That Are Assigned But Not Complete</option>
              <option value="pending">That Are Pending Testing</option>
              <option value="completed">That Are Completed</option>
            </select>
          </div>
        </div>
        <div className="row" style={{ marginTop: 15, marginBottom:5, paddingBottom: 15, borderBottom: "thin solid gray" }}>
          <div className="col-md-3">
            <span className="row-header">Group</span>
          </div>
          <div className="col-md-1">
            <span className="row-header">Type</span>
          </div>
          <div className="col-md-4">
            <span className="row-header">Title</span>
          </div>
          <div className="col-md-3">
            <span className="row-header">Status</span>
          </div>
        </div>
        <div className="note-overflow-container" style={{height: 600}}>
          {this.state.filteredMaterials.length === 0 && (<strong>No materials match those criteria</strong>)}
          {this.state.filteredMaterials.map((material: any, index: number) => {
              return(
                <LazyLoad once={true} key={index} overflow={true} throttle={100}>
                    <UserMaterial 
                      material={material}
                      materialStatus={material.status}
                      className={index % 2 === 1 ? "list-row-odd" : ""}
                      onChangeStatus={this.handleStatusChange}
                      onRemove={this.handleRemove}
                    />
                </LazyLoad>
              );
            })}
        </div>

        <Modal show={this.state.showAssignModal} onHide={this.toggleAssignModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Assign New Material</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {assignOptions.foundOptions ? ( 
              <select id="assignSchoolGroupId" className="form-control" value={this.state.assignSchoolGroupId} onChange={this.changeAssignedMaterialSelect}>
                <option key="assign-select" value="0" disabled={true}>Please Select A Material Group To Assign</option>
                {assignOptions.options}
              </select>
            ) : (
              <strong>Student already has all available material assigned to them so you cannot assign any new material.</strong>
            )}
          </Modal.Body>
          <Modal.Footer>
          {assignOptions.foundOptions && <button className="btn btn-success" onClick={this.assignMaterial}>Assign This Material Group</button>}
            <button className="btn btn-primary" onClick={this.toggleAssignModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      </Card>
    );
  }

  private fetch() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await KnowledgeAPI.getMaterialsForStudent(this.props.schoolId, this.props.userId);
        // get all groups for the school
        const groupsRes = await KnowledgeAPI.getMaterialGroups(this.props.schoolId);
        this.setState({ 
          loading: false, 
          materials: res.body.data, 
          filteredMaterials: res.body.data, 
          schoolGroups: groupsRes.body.data,
          assignSchoolGroupId: 0,
          selectedGroup: 0,
          showAssignModal: false
        });
      } catch (err) {
        this.setState({ loading: false }, () => {
          error("Could not fetch student materials");
        });
      }
    });
  }

  private toggleAssignModal() {
    this.setState({
      showAssignModal: !this.state.showAssignModal
    });
  }

  private changeSelectedGroup(e: any) {
    const groupId = parseInt(e.target.value, 10);
    this.setState({ selectedGroup: groupId }, () => {
      this.filter();
    });
  }

  private changeSelectedStatus(e: any){
    const selectedStatus = e.target.value;
    this.setState({ selectedStatus }, () => {
      this.filter();
    });
  }

  private filter(){
    let filteredMaterials = [];

    // first filter by the group
    if(this.state.selectedGroup === 0){
      filteredMaterials = this.state.materials;
    } else {
      for(const m of this.state.materials){
        if(m.groupId === this.state.selectedGroup){
          filteredMaterials.push(m);
        }
      }
    }

    // now the status
    if(this.state.selectedStatus !== "all"){
      filteredMaterials = filteredMaterials.filter((material: any) => {
        return material.status === this.state.selectedStatus;
      });
    }
    
    this.setState({
      filteredMaterials
    });
  }

  private handleRemove(groupId: number, materialId: number){
    this.setState({loading: false}, async () => {
      try{
        await KnowledgeAPI.removeSingleMaterialFromStudent(this.props.schoolId, this.props.userId, groupId, materialId);
        // now we need to filter
        const materials = [];
        const filteredMaterials = [];
        for(const m of this.state.materials){
          if(m.materialId !== materialId){
            materials.push(m);
          }
        }
        for(const m of this.state.filteredMaterials){
          if(m.materialId !== materialId){
            filteredMaterials.push(m);
          }
        }
        success("Material removed from the student");
        this.setState({loading: false, materials, filteredMaterials});
      }catch(err){
        this.setState({loading: false});
        error("Could not remove that material");
      }
    });
  }

  private handleStatusChange(groupId: number, materialId: number, status: string){
    this.setState({loading: false}, async () => {
      try{
        await KnowledgeAPI.updateMaterialAssignmentForStudent(this.props.schoolId, this.props.userId, groupId, materialId, {status});
        // now we need to filter
        const materials = [];
        const filteredMaterials = [];
        for(const m of this.state.materials){
          if(m.materialId === materialId){
            m.status = status;
          }
          materials.push(m);
        }
        for(const m of this.state.filteredMaterials){
          if(m.materialId === materialId){
            m.status = status;
          }
          filteredMaterials.push(m);
        }
        success("Material updated for the student");
        this.setState({loading: false, materials, filteredMaterials});
      }catch(err){
        this.setState({loading: false});
        error("Could not update that material. Check your permissions and remember that you cannot change your own status");
      }
    });
  }

  private changeAssignedMaterialSelect(e: any){
    const newId = e.target.value;
    this.setState({
      assignSchoolGroupId: newId
    });
  }

  private assignMaterial(){
    this.setState({loading: false}, async () => {
      try{
        await KnowledgeAPI.assignMaterialGroupToStudent(this.props.schoolId, this.props.userId, this.state.assignSchoolGroupId);
        this.fetch();
      }catch(err){
        this.setState({loading: false});
        error("Could not update that material. Check your permissions and remember that you cannot change your own status");
      }
    });
  }

  private getAssignedGroupOptions(){
    const options = [];
    let found = false;
    for(const g of this.state.schoolGroups){
      // if it is already assigned, we filter it out
      let foundInner = false;
      for(const m of this.state.materials){
        if(m.groupId === g.id){
          foundInner = true;
          break;
        }
      }
      if(!foundInner){
        found = true;
        let groupType = "Belt";
        if(g.groupType === "training"){
          groupType = "Training";
        } else if(g.groupType === "other"){
          groupType = "Other";
        }
        options.push(<option key={g.id} value={g.id}>{groupType} - {g.name}</option>);
      }
    }
    const ret = {
      options,
      foundOptions: found
    };
    return ret;
  }


}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMaterialContainer) as React.ComponentType<any>);