import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../../reducers/appReducer";
import {PermissionsAPI} from "../../../../API";
import Card from "../../../structure/Card";
import {error} from "../../../structure/Alert";

interface ISchoolPermissions {
  manageExams: boolean;
  manageFinances: boolean;
  manageMessages: boolean;
  manageSchedule: boolean;
  manageSchoolSettings: boolean;
  manageStudents: boolean;
  seeReports: boolean;
}

const defaultPermissions: ISchoolPermissions = {
  manageExams: false,
  manageFinances: false,
  manageMessages: false,
  manageSchedule: false,
  manageSchoolSettings: false,
  manageStudents: false,
  seeReports: false,
};

interface IPermissionsScreenScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
  userState: any;
  userId: number;
  schoolId: number;
}

interface IPermissionsScreenScreenState {
  loading: boolean;
  userPermissions: ISchoolPermissions;
  schoolRole: string;
}

class PermissionsScreen extends React.Component<IPermissionsScreenScreenProps, IPermissionsScreenScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      userPermissions: defaultPermissions,
      schoolRole: "assistant"
    };

    this.fetch = this.fetch.bind(this);
    this.changePermission = this.changePermission.bind(this);
  }

  public componentDidMount(){
    this.fetch(this.props.schoolId, this.props.userId);
  }
  
  public componentWillReceiveProps(nextProps: any){
    if(nextProps.userId !== this.props.userId){
      this.fetch(nextProps.schoolId, nextProps.userId);
    }
  }

  public render() {
    if(this.state.schoolRole === "student" || this.state.schoolRole === "user" || this.state.schoolRole === "inactive"){
      return (
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="User Permissions">
              <p><strong>You can only assign permissions to an assistant. Please go to the shcool users screen and upgrade their permissions.</strong></p>
              <p>User is currently {this.state.schoolRole === "inactive" ? "inactive" : `a ${this.state.schoolRole}`}.</p>
            </Card>
          </div>
        </div>
      );
    }
    if(this.state.schoolRole === "administrator"){return (
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <Card title="User Permissions">
            <p><strong>An administrator always has full permissions and cannot change any persmissions on their account.</strong></p>
          </Card>
        </div>
      </div>
    );
    }
    if(this.props.userId === this.props.userState.user.id){return (
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <Card title="User Permissions">
            <p><strong>You cannot modify your own permissions.</strong></p>
          </Card>
        </div>
      </div>
    );
    }
    return (
      <div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="User Permissions">
              <div className="row">
                <div className="col-md-4">
                  <label>Manage Students</label>
                  <select id="manageStudents" value={this.state.userPermissions.manageStudents+""} onChange={this.changePermission} className="form-control">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <p className="small">Allows the user to manage and create students</p>
                </div>
                <div className="col-md-4">
                  <label>Manage Schedule</label>
                  <select id="manageSchedule" value={this.state.userPermissions.manageSchedule+""} onChange={this.changePermission} className="form-control">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <p className="small">Allows the user to manage and create events</p>
                </div>
                <div className="col-md-4">
                  <label>Manage School Settings</label>
                  <select id="manageSchoolSettings" value={this.state.userPermissions.manageSchoolSettings+""} onChange={this.changePermission} className="form-control">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <p className="small">Allows the user to manage the school's settings</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Manage Exams</label>
                  <select id="manageExams" value={this.state.userPermissions.manageExams+""} onChange={this.changePermission} className="form-control">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <p className="small">Allows the user to manage and grade exams</p>
                </div>
                <div className="col-md-4">
                  <label>Manage Finances</label>
                  <select id="manageFinances" value={this.state.userPermissions.manageFinances+""} onChange={this.changePermission} className="form-control">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <p className="small">Allows the user to manage contracts and invoices</p>
                </div>
                <div className="col-md-4">
                  <label>Manage Messages</label>
                  <select id="manageMessages" value={this.state.userPermissions.manageMessages+""} onChange={this.changePermission} className="form-control">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <p className="small">Allows the user to manage and send messages</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>See Reports</label>
                  <select id="seeReports" value={this.state.userPermissions.seeReports+""} onChange={this.changePermission} className="form-control">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <p className="small">Allows the user to see the school reports</p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  private async fetch(schoolId: number, userId: number){
    if(userId === 0){
      return;
    }
    this.setState({loading: true}, async () => {
      try{
        const res = await PermissionsAPI.getUserPermissions(schoolId, userId);
        const permissions = res.body.data;
        // find the user
        let role = "inactive";
        for(const u of this.props.schoolState.users){
          if(u.id === this.props.userId){
            role = u.role;
            break;
          }
        }
        return this.setState({ loading: false, userPermissions: permissions, schoolRole: role});
      }catch(err){
        return this.setState({ loading: false}, () => {
          return error("Could not load that user's permissions");
        });
      }
    });
  }

  private changePermission(e: any){
    const permission = e.target.id;
    const value = e.target.value === "true";
    const ns = this.state.userPermissions;
    ns[permission] = value;
    // now, we need to call the api
    this.setState({loading: true}, async () => {
      try{
        if(value){
          await PermissionsAPI.giveUserPermission(this.props.schoolId, this.props.userId, permission);
        } else {
          await PermissionsAPI.removeUserPermission(this.props.schoolId, this.props.userId, permission);
        }
        this.setState({loading: false, userPermissions: ns});
      }catch(err){
        this.setState({loading: false}, () => {
          error("Could not update that permission. Check your own permissions with the school or contact support");
        });
      }
    });
    this.setState({ userPermissions: ns});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PermissionsScreen) as React.ComponentType<any>);