import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

class Home extends React.Component<any, any> {
  public render() {
    if(this.props.userState.loggedIn){
      return <Redirect to="/dashboard" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)  as React.ComponentType<any>);