import * as React from "react";
import * as moment from "moment";

interface ISchoolCompetitionListItem {
  id: number;
  name: string;
  city: string;
  state: string;
  startDate: string;
  shareResults: string;
}


interface ISchoolCompetitionListItemProps {
  className: string;
  item: ISchoolCompetitionListItem;
  onSelect: any;
  onDelete: any;
}

interface ISchoolCompetitionListItemState {
  loading: boolean;
}

class SchoolCompetitionListItem extends React.Component<ISchoolCompetitionListItemProps, ISchoolCompetitionListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-3 vertical-container">
          {moment(this.props.item.startDate).format("MM/DD/YYYY")}
        </div>
        <div className="col-md-3 vertical-container">
          {this.props.item.name}
        </div>
        <div className="col-md-2 vertical-container">
          {this.props.item.shareResults === "no" && "Private"}
          {this.props.item.shareResults === "school" && "School"}
          {this.props.item.shareResults === "all" && "Public"}
        </div>
        <div className="col-md-2 vertical-container">
          {this.props.item.state}
        </div>
        <div className="col-md-2 vertical-container">
          <span className="text-primary glyphicon glyphicon-edit" onClick={this.handleSelect} style={{marginRight: 5}} />
          <span className="text-danger glyphicon glyphicon-remove" onClick={this.handleDelete}  />
        </div>
      </div>
    );
  }

  private handleSelect() {
    this.props.onSelect(this.props.item);
  }

  private handleDelete(){
    this.props.onDelete(this.props.item);
  }
}

export default SchoolCompetitionListItem;