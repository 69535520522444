import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";

import Card from "../../structure/Card";
import List from "../../structure/List";
import UserImage from "../../structure/UserImage";
import * as AppActions from "../../../reducers/appReducer";
import * as SchoolActions from "../../../reducers/schoolReducer";
import * as SetupActions from "../../../reducers/setupReducer";
import * as AlertsActions from "../../../reducers/alertsReducer";
import * as UserActions from "../../../reducers/userReducer";
import { error, success } from "../../structure/Alert";
import { BeltsAPI, SchoolUsersAPI } from "../../../API";
import { refreshData } from "src/utils/login";
// import { refreshData } from "../../../utils/login";

const helpText = `Below is a list of users, students, and members of your school. Clicking on their photo will allow you to upload a new image. You can optionally filter and sort. If the user is a student, you may change their assigned belt or role in the school from this screen. Note that changing the role does NOT change their permissions. To change their permissions, you must visit their User Information screen.`;

interface ISchoolUsersScreenProps {
  schoolState: any;
  userState: any;
  history: any;
  schoolActions: any;
  setupActions: any;
  alertsActions: any;
  userActions: any;
}

interface ISchoolUsersScreenState {
  loading: boolean;
  users: any;
  displayedUsers: any;
  filters: any;
  sortField: "name" |"email" | "status" | "dob" | "belt" | "contract";
  sortDir: "asc" | "desc";
  nameFilter: string;
}

class SchoolUsersScreen extends React.Component<ISchoolUsersScreenProps, ISchoolUsersScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      users: [],
      displayedUsers: [],
      filters: {
        assistant: true,
        student: true,
        nonStudents: false,
        inactive: false
      },
      nameFilter: "",
      sortField: "name",
      sortDir: "asc",
    };
    this.setup = this.setup.bind(this);
    this.changeStudentStatus = this.changeStudentStatus.bind(this);
    this.changeStudentBelt = this.changeStudentBelt.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.sortName = this.sortName.bind(this);
    this.sortContract = this.sortContract.bind(this);
    this.sortDOB = this.sortDOB.bind(this);
    this.sortEmail = this.sortEmail.bind(this);
    this.sortStatus = this.sortStatus.bind(this);
    this.sortBelt = this.sortBelt.bind(this);
    this.sort = this.sort.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.filterOnName = this.filterOnName.bind(this);
    this.updateNameFilter = this.updateNameFilter.bind(this);
  }

  public componentDidMount() {
    // change 20230503 - just automatically refresh on page load so that things stay up to date
    // in the future, it'd be best to just determine when I need to make this giant call
    this.setState({ loading: true }, async () => {
      await refreshData(this.props.userState.user, this.props.userActions, this.props.schoolActions, this.props.alertsActions, this.props.setupActions, false);
      this.setState({ loading: false }, () => {this.setup(); });
    });
  }

  public setup() {
    this.sort("name", "asc");
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Card title="Students" loading={this.state.loading} help={helpText}>
              <div className="row">
                <div className="col-md-2">
                  <strong>Showing:</strong>
                </div>
                <div className="col-md-2">
                  <input type="checkbox" id="assistant"
                    checked={this.state.filters.assistant} onChange={this.changeFilter} /> Admins and Assistants
                </div>
                <div className="col-md-2">
                  <input type="checkbox" id="student"
                    checked={this.state.filters.student} onChange={this.changeFilter} /> Students
                </div>
                <div className="col-md-2">
                  <input type="checkbox" id="nonStudents"
                    checked={this.state.filters.nonStudents} onChange={this.changeFilter} /> Non-Students
                </div>
                <div className="col-md-2">
                  <input type="checkbox" id="inactive"
                    checked={this.state.filters.inactive} onChange={this.changeFilter} /> Inactive
                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" placeholder="Search Name" value={this.state.nameFilter} onChange={this.updateNameFilter} />
                </div>
              </div>
              <div className="row" style={{marginTop: 15, paddingBottom: 15, borderBottom: "thin solid gray"}}>
                <div className="col-md-1">
                  <span />
                </div>
                <div className="col-md-3">
                  <span className="row-header-sortable" onClick={this.sortName}>Name and Family</span>
                  {this.state.sortField === "name" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                  {this.state.sortField === "name" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
                </div>
                <div className="col-md-2">
                  <span className="row-header-sortable" onClick={this.sortEmail}>Email</span>
                  {this.state.sortField === "email" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                  {this.state.sortField === "email" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
                </div>
                <div className="col-md-2">
                  <span className="row-header-sortable" onClick={this.sortDOB}>DOB</span>
                  {this.state.sortField === "dob" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                  {this.state.sortField === "dob" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
                </div>
                <div className="col-md-1">
                  <span className="row-header-sortable" onClick={this.sortStatus}>Role</span>
                  {this.state.sortField === "status" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                  {this.state.sortField === "status" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
                </div>
                <div className="col-md-1">
                  <span className="row-header-sortable" onClick={this.sortBelt}>Belt</span>
                  {this.state.sortField === "belt" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                  {this.state.sortField === "belt" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
                </div>
                <div className="col-md-2">
                  <span className="row-header-sortable" onClick={this.sortContract}>Contract Days Remaining</span>
                  {this.state.sortField === "contract" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                  {this.state.sortField === "contract" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
                </div>
              </div>
              <List
                data={this.state.displayedUsers}
                renderItem={this.renderItem}
                showTotal={true}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }

  private changeFilter(e: any) {
    const filters = this.state.filters;
    filters[e.target.id] = !this.state.filters[e.target.id];
    this.setState({ filters }, () => {
      this.setup();
    });
  }

  private renderItem(i: any) {
    const user = this.state.displayedUsers[i];
    let classes = "row list-row school-users-row ";

    // dob
    if (user.dob === "1970-01-01T00:00:00Z") {
      return (<span>Unknown</span>);
    }
    const dob = moment(user.dob);
    let dobDisplay: any = "Unknown";
    if (dob.isValid()) {
      const diff = moment().diff(dob, "years");
      dobDisplay = `${dob.format("MM/DD/YYYY")} (${diff})`;
      if (diff < 18) {
        dobDisplay = (<span>{dobDisplay} <span style={{ marginLeft: 2, color: "darkRed" }} className="glyphicon glyphicon-warning-sign" /></span>);
      }
    }

    // role
    let roleDisplay = (<span>Administrator</span>);
    if(user.role !== "administrator"){
      roleDisplay = (
        <select className="form-control" value={user.role} onChange={this.changeStudentStatus} id={user.id}>
            <option value="student">Student</option>
            <option value="assistant">Assistant</option>
            <option value="user">Non-Student</option>
            <option value="inactive">Inactive</option>
          </select>
      );
    }

    // belts
    let beltSelect = (<span>None</span>);
    if (user.role !== "user") {
      const belts: any = [];
      belts.push(<option value={0} key={0}>None</option>);
      for (const b of this.props.schoolState.school.belts) {
        if (b.id === user.beltId) {
          belts.push(<option value={b.id} key={b.id} selected={true}>{b.name}</option>);
        } else {
          belts.push(<option value={b.id} key={b.id}>{b.name}</option>);
        }
      }
      beltSelect = (
        <select className="form-control" onChange={this.changeStudentBelt} id={user.id}>
          {belts}
        </select>
      );
    }

    // contracts
    let contractDisplay = (<span>Not Applicable</span>);
    if (user.role !== "administrator") {
      let display = "";
      let className = "";
      if (!user.contracts || (user.contracts && user.contracts.length === 0)) {
        className = "bg-danger";
        display = "NONE";
      } else if (user.contracts.length > 1) {
        display = "MANY";
        let max = 0;
        for (const c of user.contracts) {
          if (c.daysRemaining > max) {
            max = c.daysRemaining;
          }
        }
        className = max < 14 ? "bg-warning" : "";
        display = `${user.contracts.length} Contracts - ${max} Days`;
      } else if (user.contracts.length === 1) {
        // ok, figure out status
        const c = user.contracts[0];
        if (c.status === "paused") {
          display = "PAUSED";
          className = "bg-warning";
        } else if (c.status === "active") {
          // shouldn't we just use the days remaining field from the server?
          // const daysRemaining = moment(c.startDate).add(c.numberDays, "days").diff(moment(), "days");
          const daysRemaining = c.daysRemaining;
          display = ` ${daysRemaining} Days`;
          if (daysRemaining < 14) {
            className = "bg-warning";
          }
        }
      }
      if (className === "") {
        classes += i % 2 !== 0 ? " list-row-odd " : "";
      } else {
        classes += " " + className;
      }
      contractDisplay = (
        <div>{display}</div>
      );
    }

    return (
      <div className={classes} key={i}>
        <div className="col-md-1">
          <UserImage userId={user.id} schoolId={this.props.schoolState.school.id} height={40} width={40} size="thumb" useSensor={false} />
        </div>
        <div className="col-md-3">
          <Link to={`/schools/${this.props.schoolState.school.id}/users/${user.id}`}>{user.lastName}, {user.firstName} {user.nickname && user.nickname !== "" && `(${user.nickname})`} - {user.familyName}</Link>
        </div>
        <div className="col-md-2">
          <Link to={`/schools/${this.props.schoolState.school.id}/users/${user.id}`}>{user.emailAddress}</Link>
        </div>
        <div className="col-md-2">
          <Link to={`/schools/${this.props.schoolState.school.id}/users/${user.id}`}>{dobDisplay}</Link>
        </div>
        <div className="col-md-1">
          {roleDisplay}
        </div>
        <div className="col-md-1">
          {beltSelect}
        </div>
        <div className="col-md-2">
          {contractDisplay}
        </div>
      </div>
    );
  }

  private sort(sortField: "name" |"email" | "status" | "dob" | "belt" | "contract", sortDir: "asc" | "desc"){
    let users = this.props.schoolState.users;
    // first filter
    users = users.filter((user: any) => {
      const role = user.role;
      if ((role === "assistant" || role === "administrator") && this.state.filters.assistant) {
        return true;
      } else if (role === "student" && this.state.filters.student) {
        return true;
      } else if (role === "user" && this.state.filters.nonStudents) {
        return true;
      } else if (role === "inactive" && this.state.filters.inactive) {
        return true;
      }
      return false;
    });
    if(sortField === "name"){
      users = users.sort((a: any, b: any) => {
        if(a.lastName === b.lastName){
          if(sortDir === "asc"){
            return a.firstName < b.firstName ? -1 : 1;
          }
          return a.firstName < b.firstName ? 1 : -1;
        }
        if(sortDir === "asc"){
          return a.lastName < b.lastName ? -1 : 1;
        }
        return a.lastName < b.lastName ? 1 : -1;
      });
    } else if (sortField === "email") {
      users = users.sort((a: any, b: any) => {
        if(a.emailAddress === b.emailAddress){
          return 0;
        }
        if(sortDir === "asc"){
          return a.emailAddress < b.emailAddress ? -1 : 1;
        }
        return a.emailAddress < b.emailAddress ? 1 : -1;
      });
    } else if (sortField === "dob") {
      users = users.sort((a: any, b: any) => {
        if(!a.dob){
          return sortDir === "asc" ? 1 : -1;
        }
        if(!b.dob){
          return sortDir === "asc" ? -1 : 1;
        }
        if(sortDir === "asc"){
          return moment(a.dob).isBefore(moment(b.dob)) ? -1 : 1;
        }
        return moment(a.dob).isBefore(moment(b.dob)) ? 1 : -1;
      });
    } else if (sortField === "status") {
      users = users.sort((a: any, b: any) => {
        // the easiest thing to d ohere is assign a score for each
        // I hate myself for this line
        const aRole = a.role === "inactive" ? 0 : a.role === "user" ? 1 : a.role === "student" ? 2 :  a.role === "assistant" ? 3 : a.role === "admin" ? 4 : 5;
        const bRole = b.role === "inactive" ? 0 : b.role === "user" ? 1 : b.role === "student" ? 2 :  b.role === "assistant" ? 3 : b.role === "admin" ? 4 : 5;
        if(sortDir === "asc"){
          return aRole > bRole ? -1 : 1;
        }
        return aRole > bRole ? 1 : -1;
      });
    } else if (sortField === "belt") {
      users = users.sort((a: any, b: any) => {
        // some users won't have belts, so first see if both don't have a belt, and return 0
        // otherwise, one of them does, so first check a, then fall back to b, then compare
        if((!a.beltId || a.beltId === 0) && (!b.beltId || b.beltId === 0)){
          return 0;
        } else if(!a.beltId || a.beltId === 0){
          if(sortDir === "asc"){
            return 1;
          }
          return -1;
        } else if(!b.beltId || b.beltId === 0){
          if(sortDir === "asc"){
            return -1;
          }
          return 1;
        }
        let beltAOrder = 0;
        let beltBOrder = 0;
        for(const belt of this.props.schoolState.school.belts){
          if(belt.id === a.beltId){
            beltAOrder = belt.beltOrder;
          } else if(belt.id === b.beltId){
            beltBOrder = belt.beltOrder;
          }
          // if both are found we break
          if(beltAOrder !== 0 && beltBOrder !== 0){
            break;
          }
        }
        if(sortDir === "asc"){
          return beltAOrder > beltBOrder ? 1 : -1;
        }
        return beltAOrder > beltBOrder ? -1 : 1;
      });
    } else if (sortField === "contract") {
      users = users.sort((a: any, b: any) => {
        let aRemaining = 0;
        let bRemaining = 0;
        if(!a.contracts || a.contracts.length === 0){
          aRemaining = -1000;
        } else {
          aRemaining = a.contracts[0].daysRemaining;
        }
        if(!b.contracts || b.contracts.length === 0){
          bRemaining = -1000;
        } else {
          bRemaining = b.contracts[0].daysRemaining;
        }

        if(aRemaining === bRemaining){
          return 0;
        }

        if(sortDir === "asc"){
          return aRemaining > bRemaining ? -1 : 1;
        }
        return aRemaining > bRemaining ? 1 : -1;
      });
    }
    this.setState({users, sortDir, sortField}, () => { this.filterOnName(); });
  }

  private sortName(){
     const dir = this.state.sortField === "name" && this.state.sortDir === "asc" ? "desc" : "asc";
     this.sort("name", dir);
  }

  private sortEmail(){
    const dir = this.state.sortField === "email" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("email", dir);
  }

  private sortDOB(){
    const dir = this.state.sortField === "dob" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("dob", dir);
  }

  private sortStatus(){
    const dir = this.state.sortField === "status" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("status", dir);
  }

  private sortContract(){
    const dir = this.state.sortField === "contract" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("contract", dir);
  }

  private sortBelt(){
    const dir = this.state.sortField === "belt" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("belt", dir);
  }

  private updateNameFilter(e: any){
    this.setState({nameFilter: e.target.value}, () => {
      this.filterOnName();
    });
  }

  private filterOnName(){
    // first filter
    const nf = this.state.nameFilter.toLowerCase();
    const filteredUsers = this.state.users.filter((user: any) => {
      if(nf === ""){
        return true;
      }
      const f = user.firstName.toLowerCase();
      const l = user.lastName.toLowerCase();
      if(f.indexOf(nf) > -1 || l.indexOf(nf) > -1){
        return true;
      }
      return false;
    });
    this.setState({displayedUsers: filteredUsers});
  }

  private async changeStudentStatus(e: any) {
    const userId = parseInt(e.target.id, 10);
    const role = e.target.value;
    try {
      await SchoolUsersAPI.updateUserRole(this.props.schoolState.school.id, userId, role);
      // we need to set the status for the user in redux
      this.props.schoolActions.changeUserRole({ userId, role });
      return success("User's role changed. We did not change any permissions, financial onformation, or access controls.");
    } catch (err) {
      return error("Could not change that user's role");
    }
  }

  private async changeStudentBelt(e: any) {
    const userId = parseInt(e.target.id, 10);
    const beltId = parseInt(e.target.value, 10);
    if (beltId === 0) {
      return;
    }
    try {
      await BeltsAPI.assignStudentToBelt(this.props.schoolState.school.id, userId, beltId);
      // we need to set the belt for the user in redux
      this.props.schoolActions.changeUserBelt({ userId, beltId });
      return success("User's belt changed");
    } catch (err) {
      return error("Could not change that user's belt");
    }
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    alertsActions: bindActionCreators(AlertsActions, dispatch),
    setupActions: bindActionCreators(SetupActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolUsersScreen) as React.ComponentType<any>);