import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UserAPI } from "../../../../API";
import Card from "../../../structure/Card";
import { error } from "../../../structure/Alert";
import * as AppActions from "../../../../reducers/appReducer";

interface IUserFamiliesProps {
  appActions: any;
  history: any;
  userId: number;
  schoolId: number;
}

interface IUserFamiliesState {
  loading: boolean;
  familyMembers: any;
}

const helpText = `All users belong to a family, even if it is a single user. Families can share certain permissions and payment profiles.`;

class UserFamilies extends React.Component<IUserFamiliesProps, IUserFamiliesState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      familyMembers: []
    };

    this.fetch = this.fetch.bind(this);

  }

  public componentDidMount(){
    this.fetch();
  }
  
  public componentWillReceiveProps(nextProps: any){
    if(nextProps.userId !== this.props.userId){
      this.fetch();
    }
  }

  public render() {
    return (
      <Card title="Family" loading={this.state.loading} help={helpText}>
        {this.state.familyMembers.length <= 1 ? 
        (<strong>This is the only user in the family</strong>) :
        (
          <ul>
            {this.state.familyMembers.map((user: any) => {
            return (<li key={user.id}><Link to={`/schools/${this.props.schoolId}/users/${user.id}`}>{user.firstName} {user.lastName} ({user.schoolRole})</Link></li>);
            })}
          </ul>
        )}
      </Card>
    );
  }

  private async fetch(){
    this.setState({loading: true}, async () => {
      try{
        const famRes = await UserAPI.getUsersInFamily(this.props.schoolId, this.props.userId);
        const familyMembers = famRes.body.data;
        return this.setState({ loading: false, familyMembers});
      }catch(err){
        return this.setState({ loading: false}, () => {
          return error("Could not load that user's family members");
        });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserFamilies) as React.ComponentType<any>);