import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";

import AgesReport from "./AgesReport";
import BeltsReport from "./BeltsReport";
import InstructorCreditsReport from "./InstructorCreditsReport";
import LastAttendedReport from "./LastAttendedReport";
import MessagesSentReport from "./MessagesSentReport";
import PaymentsReport from "./PaymentsReport";
import RevenuePerUserReport from "./RevenuePerUserReport";
import RevenuePerFamilyReport from "./RevenuePerFamilyReport";
import ZipReport from "./ZipReport";

interface IReportsScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface IReportsScreenState {
  loading: boolean;
  selectedReport: string;
}

const helpText = (
<div>Here you can select and view various reports about your school. The currently available reports are:
  <ul>
    <li><strong>Payments and Charges</strong>: The actual payments made by students to your school. This is the report used to determine how much money you will receive if using KDojang billing.</li>
    <li><strong>Revenue Per User</strong>: The total revenue per user within a time frame</li>
    <li><strong>Revenue Per Family</strong>: The total revenue per family within a time frame</li>
    <li><strong>Sent Messages</strong>: Messages that have bene sent by you or assistants to the users in your school.</li>
    <li><strong>Instructor Credits</strong>: How many events a specific instructor has led.</li>
    <li><strong>Last Attended</strong>: When students last attended classes.</li>
    <li><strong>Age Distribution</strong>: The distribution of ages in your school.</li>
    <li><strong>Belt Distribution</strong>: The distribution of belts in your school.</li>
    <li><strong>Area Analysis</strong>: Analyzes area zip codes for historical data.</li>
  </ul>
</div>);

class ReportsScreen extends React.Component<IReportsScreenProps, IReportsScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      selectedReport: "",
    };

    this.selectReport = this.selectReport.bind(this);
    this.getReportDisplay = this.getReportDisplay.bind(this);
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Card title="Select a Report" loading={this.state.loading} help={helpText}>
              <select id="selectedReport" className="form-control" value={this.state.selectedReport} onChange={this.selectReport}>
                <option value="">----</option>
                <option value="payments">Payments and Charges</option>
                <option value="revenuePerUser">Revenue Per User</option>
                <option value="revenuePerFamily">Revenue Per Family</option>
                <option value="messagesSent">Sent Messages</option>
                <option value="instructors">Instructor Credits</option>
                <option value="lastAttended">Last Attended</option>
                <option value="ages">Age Distribution</option>
                <option value="belts">Belt Distribution</option>
                <option value="zips">Area Analysis</option>
              </select>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.getReportDisplay()}
          </div>
        </div>
      </div>
    );
  }

  private selectReport(e: any) {
    this.setState({ selectedReport: e.target.value });
  }

  private getReportDisplay() {
    switch (this.state.selectedReport) {
      case "ages":
        return (<AgesReport schoolId={this.props.schoolState.school.id} />);
      case "belts":
        return (<BeltsReport schoolId={this.props.schoolState.school.id} />);
      case "instructors":
        return (<InstructorCreditsReport schoolId={this.props.schoolState.school.id} />);
      case "lastAttended":
        return (<LastAttendedReport schoolId={this.props.schoolState.school.id} />);
      case "messagesSent":
      return (<MessagesSentReport schoolId={this.props.schoolState.school.id} />);
      case "payments":
        return (<PaymentsReport schoolId={this.props.schoolState.school.id} />);
      case "revenuePerUser":
        return (<RevenuePerUserReport schoolId={this.props.schoolState.school.id} />);
      case "revenuePerFamily":
        return (<RevenuePerFamilyReport schoolId={this.props.schoolState.school.id} />);
      case "zips":
        return (<ZipReport schoolId={this.props.schoolState.school.id} />);
      default:
        return null;
    }
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsScreen) as React.ComponentType<any>);