import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

interface IAlert {
  alert: string;
  body: string;
  created: moment.Moment;
  dismissed: boolean;
  dismissedBy: number;
  id: number;
  level: "info" | "critical" | "warning";
  schoolId: number;
  subject: string;
  userId: number;
}


interface IAlertProps {
  appActions: any;
  history: any;
  className: string;
  alert: IAlert;
  onDismiss: any;
}

interface IAlertState {
  loading: boolean;
  showDismissModal: boolean;
  showAlertModal: boolean;
}

class Alert extends React.Component<IAlertProps, IAlertState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showDismissModal: false,
      showAlertModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.toggleDismiss = this.toggleDismiss.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.formatAlertLevel = this.formatAlertLevel.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-2 vertical-container">
          {this.formatAlertLevel()}
        </div>
        <div className="col-md-3 vertical-container">
          {moment(this.props.alert.created).format("MM/DD/YY HH:mm")}
        </div>
        <div className="col-md-5 vertical-container">
          {this.props.alert.subject.length > 40 ?
            (<span>{this.props.alert.subject.substr(0, 37) + "..."}</span>) :
            (<span>{this.props.alert.subject}</span>)}
        </div>
        <div className="col-md-2 vertical-container">
          <span className="text-primary glyphicon glyphicon-eye-open" onClick={this.toggleShow} style={{ marginRight: 15 }} />
          <span className="text-danger glyphicon glyphicon-trash" onClick={this.toggleDismiss} />
        </div>

        <Modal show={this.state.showDismissModal} onHide={this.toggleDismiss} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Dismiss Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Warning! This will dismiss the alert. Are you sure you want to do that?

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleDismiss}>Yes, Dismiss It</button>
            <button className="btn btn-primary" onClick={this.toggleDismiss}>Nevermind</button>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showAlertModal} onHide={this.toggleShow} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" style={{marginBottom: 5}}>
              <div className="col-md-3">
                <strong>Created:</strong>
              </div>
              <div className="col-md-9">
                {moment(this.props.alert.created).format("MM/DD/YY hh:mm")}
              </div>
            </div>
            <div className="row" style={{marginBottom: 5}}>
              <div className="col-md-3">
                <strong>Level:</strong>
              </div>
              <div className="col-md-9">
                {this.formatAlertLevel()}
              </div>
            </div>
            <div className="row" style={{marginBottom: 5}}>
              <div className="col-md-3">
                <strong>Subject:</strong>
              </div>
              <div className="col-md-9">
                {this.props.alert.subject}
              </div>
            </div>
            <div className="row" style={{marginBottom: 5}}>
              <div className="col-md-3">
                <strong>Body:</strong>
              </div>
              <div className="col-md-9">
                {this.props.alert.body}
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.toggleDismiss}>Dismiss It</button>
            <button className="btn btn-primary" onClick={this.toggleShow}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private formatAlertLevel() {
    switch (this.props.alert.level) {
      case "info":
        return (<span>Info</span>);
      case "warning":
        return (<span className="text-warning">Warning</span>);
      case "critical":
        return (<span className="text-danger"><strong>Critical</strong></span>);
    }
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleDismiss() {
    this.setState({ showDismissModal: !this.state.showDismissModal, showAlertModal: false });
  }

  private handleDismiss() {
    this.props.onDismiss(this.props.alert.id);
    this.setState({ showDismissModal: false });
  }

  private toggleShow() {
    this.setState({ showAlertModal: !this.state.showAlertModal });
  }

}


const mapStateToProps = function map(s: any) {
  return {};
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Alert) as React.ComponentType<any>);