import * as React from "react";
import { Modal } from "react-bootstrap";
import { DocumentsAPI } from "../../../../API";
import { success, error } from "../../../structure/Alert";
import * as moment from "moment";
import { saveAs } from "file-saver";

interface IUserDocumentListItem {
  id: number;
  schoolId: number;
  title: string;
  uploadedOn: string;
  categoryName: string;
  mime: string;
  extension: string;
}


interface IUserDocumentListItemProps {
  className: string;
  schoolId: number;
  userId: number;
  categoryId: number;
  item: IUserDocumentListItem;
  onDelete: any;
  onEdit: any;
}

interface IUserDocumentListItemState {
  loading: boolean;
  showDeleteModal: boolean;
  showEditModal: boolean;
  title: string;
}

class UserDocumentListItem extends React.Component<IUserDocumentListItemProps, IUserDocumentListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showEditModal: false,
      showDeleteModal: false,
      title: "",
    };
    this.updateField = this.updateField.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.download = this.download.bind(this);
  }

  public componentDidMount(){
    this.setState({
      title: this.props.item.title,
    });
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-4 vertical-container">
          {this.props.item.title}
        </div>
        <div className="col-md-5 vertical-container">
          {moment(this.props.item.uploadedOn).format("MM/DD/YY")}
        </div>
        <div className="col-md-3 vertical-container">
          <span className="text-primary glyphicon glyphicon-edit" onClick={this.toggleEdit} style={{ marginRight: 15 }} />
          <span className="text-primary glyphicon glyphicon-cloud-download" onClick={this.download} style={{ marginRight: 15 }} />
          <span className="text-danger glyphicon glyphicon-remove" onClick={this.toggleDelete} />

        </div>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDelete} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Warning! This will permanently delete the document and the file. This cannot be undone!
          </Modal.Body>
          <Modal.Footer>
          {this.state.loading ? (<span className="glyphicon glyphicon-repeat normal-right-spinner" style={{marginRight: 20}} />) :
              (<button className="btn btn-danger" onClick={this.handleDelete}>Yes, Delete It</button>)}
            <button className="btn btn-primary" onClick={this.toggleDelete}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showEditModal} onHide={this.toggleEdit} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Title</label>
              <input type="text" id="title" className="form-control" value={this.state.title} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.handleEdit}>Save</button>
            <button className="btn btn-primary" onClick={this.toggleEdit}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleEdit() {
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleDelete() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private async handleDelete(){
    // call the API
    this.setState({loading: true}, async () => {
      try{
        await DocumentsAPI.deleteDocument(this.props.schoolId, this.props.item.id);
        success("Document deleted");
        this.setState({loading: false, showDeleteModal: false});
        this.props.onDelete(this.props.item.id);
      }catch(err){
        this.setState({loading: false});
        return error("Could not delete that document!");
      }
    });
  }

  private async handleEdit(){
    // call the API
    const data = {
      title: this.state.title
    };
    if(data.title === ""){
      return error("Title is required required");
    }
    try{
      await DocumentsAPI.updateDocument(this.props.schoolId, this.props.item.id, data);
      success("Document saved");
      this.setState({showEditModal: false});
      this.props.onEdit(this.props.item.id, this.state.title);
    }catch(err){
      return error("Could not save that document!");
    }
  }

  private download(){
    this.setState({loading: true}, async () => {
      try{
        const options = {asDownload: true, accept: this.props.item.mime};
        const res = await DocumentsAPI.getDocument(this.props.schoolId, this.props.item.id, options);
        const blob = new Blob([res.body], {type: `${this.props.item.mime};charset=utf-8`});
        saveAs(blob, `${this.props.item.categoryName}-${this.props.item.title}.${this.props.item.extension}`);
      }catch(err){
        error("Could not download that file");
        this.setState({loading: false});
      }
    });
  }
}

export default UserDocumentListItem;