import * as currency from "currency-formatter";
import * as moment from "moment";

/**
 * Converts a whole number into a displayable currency, adding the $
 * @param amount 
 */
export function convertCurrency(amount: number): string {
  return currency.format((amount / 100), {
    code: "USD"
  });
}

interface DateValidOptions {
  startThresh: string;
  endThresh: string;
}

/**
 * Determines whether a date is valid, optionally between a startThresh and endThresh
 * @param input 
 * @param options 
 */
export function isDateValid(input: string, options: DateValidOptions): boolean{
  const da = moment(input);
  const startThresh = options && options.startThresh ? moment(options.startThresh) : moment().year(2012).month(1).date(1);
  const endThresh = options && options.endThresh ? moment(options.endThresh) : moment().add(1, "year");

  return da.isValid() && da.isAfter(startThresh) && da.isBefore(endThresh);
}

/**
 * Provides a helper for displaying dates
 * @param input 
 * @param format 
 */
export function convertDateForDisplay(input: string, format: string): string{
  const da = moment(input, "YYYY-MM-DDTHH:mm:SSZ");
  format = format.toLowerCase();
  switch(format){
    case "date": 
      return da.format("MM/DD/YY");
    case "":
    case "full":
    default:
      return da.format("MM/DD/YY HH:mm:SS");
  }
}

export function capFirstLetter(input: string): string {
  return input[0].toUpperCase() + input.substr(1);
}