import * as React from "react";
import { Modal } from "react-bootstrap";
import { success, error } from "../../structure/Alert";
import { ExamsAPI } from "../../../API";
import { Draggable } from "react-beautiful-dnd";

const paddingBase = 2;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  padding: paddingBase * 2,
  margin: `0 0 ${paddingBase}px 0`,
  background: isDragging ? "#edf2f9" : "white",
  ...draggableStyle,
});

interface IExamTemplateCriteriaListItemProps {
  id: number;
  index: number;
  schoolId: number;
  examTemplateId: number;
  criteria: string;
  criteriaOrder: number;
  description: string;
  criteriaPoints: number | string;
  onDelete: (e: any) => {};
  onUpdate: (e: any) => {};
}

interface IExamTemplateCriteriaListItemState {
  loading: boolean;
  showDeleteModal: boolean;
  criteriaPoints: number | string;
  criteria: string;
  criteriaOrder: number;
  description: string;
  id: number;
}

export default class ExamTemplateCriteriaListItem extends React.Component<IExamTemplateCriteriaListItemProps, IExamTemplateCriteriaListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showDeleteModal: false,
      criteriaPoints: "0",
      criteria: "",
      criteriaOrder: 0,
      description: "",
      id: 0,
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.deleteCriteria = this.deleteCriteria.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  public componentDidMount() {
    this.setState({
      criteriaPoints: this.props.criteriaPoints,
      criteria: this.props.criteria,
      criteriaOrder: this.props.criteriaOrder,
      description: this.props.description,
      id: this.props.id,
    });
  }

  public componentDidUpdate(prevProps: IExamTemplateCriteriaListItemProps) {
    if (prevProps.criteria !== this.props.criteria || prevProps.description !== this.props.description || prevProps.criteriaPoints !== this.props.criteriaPoints || prevProps.criteriaOrder !== this.props.criteriaOrder) {
      this.setState({
        criteriaPoints: this.props.criteriaPoints,
        criteria: this.props.criteria,
        criteriaOrder: this.props.criteriaOrder,
        description: this.props.description,
      });
    }
  }

  public render() {
    return (
      <div>
        <Draggable key={this.props.id} draggableId={this.props.id + ""} index={this.props.index}>
          {(providedInner: any, snapshotInner) => (
            <div
              ref={providedInner.innerRef}
              {...providedInner.draggableProps}
              {...providedInner.dragHandleProps}
              style={getItemStyle(
                snapshotInner.isDragging,
                providedInner.draggableProps.style
              )}
            >
              <div className="row">
                <div className="col-md-1"><span className="glyphicon glyphicon-align-justify" /></div>
                <div className="col-md-4">{this.props.criteria}</div>
                <div className="col-md-4">{this.props.description}</div>
                <div className="col-md-2"><input type="number" className="form-control" value={this.state.criteriaPoints} id="criteriaPoints" onChange={this.updateField} /></div>
                <div className="col-md-1">
                  <span className="glyphicon glyphicon-check text-success" onClick={this.updateCriteria} style={{ marginTop: 10, marginRight: 8 }} />
                  <span className="glyphicon glyphicon-remove text-danger" onClick={this.toggleDeleteModal} style={{ marginTop: 10 }} />
                </div>
              </div>
            </div>
          )}
        </Draggable>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Criteria</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this criteria? This cannot be undone and will not impact exams already created from this template.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteCriteria}>Yes, Delete This Criteria</button>
            <button className="btn btn-primary" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private deleteCriteria() {
    this.setState({ loading: false }, async () => {
      try {
        await ExamsAPI.deletaExamTemplateCriteria(this.props.schoolId, this.props.examTemplateId, this.props.id);
        success("Criteria deleted");
        this.setState({ showDeleteModal: false, loading: false }, () => {
          this.props.onDelete({
            id: this.props.id,
          });
        });
      } catch (e) {
        this.setState({ loading: false, showDeleteModal: false });
        error("Could not delete that criteria");
      }
    });
  }

  private updateCriteria() {
    this.setState({ loading: false }, async () => {
      const data = {
        criteria: this.state.criteria,
        description: this.state.description,
        criteriaPoints: typeof this.state.criteriaPoints === "string" ? parseInt(this.state.criteriaPoints, 10) : this.state.criteriaPoints,
        criteriaOrder: this.state.criteriaOrder,
      };
      try {
        await ExamsAPI.updateExamTemplateCriteria(this.props.schoolId, this.props.examTemplateId, this.props.id, data);
        success("Criteria points updated");
        this.props.onUpdate({
          ...this.state,
        });
      } catch (e) {
        this.setState({ loading: false });
        error("Could not update that criteria");
      }
    });
  }

}