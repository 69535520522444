import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Modal } from "react-bootstrap";
import * as moment from "moment";

import Card from "../../structure/Card";
import {MonthSelect} from "../../structure/MonthSelect";
import {YearSelect} from "../../structure/YearSelect";
import { error, success } from "../../structure/Alert";
import {SchoolsAPI} from "../../../API";
import * as AppActions from "../../../reducers/appReducer";

interface ISchool {
  bankToken: string;
  id: number;
  paymentMethodType: string;
  paymentMethodToken: string;
  plan: string;
}

const defaultSchool: ISchool = {
  bankToken: "",
  id: 0,
  paymentMethodType: "card",
  paymentMethodToken: "",
  plan: "basic",
};

interface ISchoolPlanScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface ISchoolPlanScreenState {
  loading: boolean;
  school: any;
  firstName: string;
  lastName: string;
  emailAddress: string;
  cardNumber: string;
  cardExpMonth: string;
  cardExpYear: string;
  cardCVC: string;

  currentPlan: string;
  showPaymentConfirm: boolean;
  showDeletePaymentConfirm: boolean;
}


class SchoolPlanScreen extends React.Component<ISchoolPlanScreenProps, ISchoolPlanScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      emailAddress: "",
      cardNumber: "",
      cardExpMonth: moment().format("MM"),
      cardExpYear: moment().format("YYYY"),
      cardCVC: "",
      currentPlan: "custom",
      school: defaultSchool,
      showPaymentConfirm: false,
      showDeletePaymentConfirm: false,
    };

    this.fetch = this.fetch.bind(this);
    this.updateField = this.updateField.bind(this);
    this.savePaymentMethod = this.savePaymentMethod.bind(this);
    this.toggleShowPaymentConfirm = this.toggleShowPaymentConfirm.bind(this);
    this.toggleDeletePaymentConfirm = this.toggleDeletePaymentConfirm.bind(this);
    this.deletePaymentMethod = this.deletePaymentMethod.bind(this);

  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <Card title="Payment Method" loading={this.state.loading} help="A credit card is required on hand to send messages in order to prevent abuse. It will only be charged monthly for usage of advanced features.">
          {this.state.school.paymentMethodType === "card" ? (
            <div className="bg-success" style={{padding: 10, textAlign:"center"}}><strong>Payment Method On File</strong></div>
          ) : (
            <div className="bg-danger" style={{padding: 10, textAlign:"center"}}><strong>No Payment Method On File</strong></div>
          )}
          <p><strong>Enter a new payment method:</strong></p>
          <div className="form-group">
            <label>First Name On Card</label>
            <input type="text" id="firstName" className="form-control" value={this.state.firstName} onChange={this.updateField} />
          </div>
          <div className="form-group">
            <label>Last Name On Card</label>
            <input type="text" id="lastName" className="form-control" value={this.state.lastName} onChange={this.updateField} />
          </div>
          <div className="form-group">
            <label>Contact Email Address</label>
            <input type="text" id="emailAddress" className="form-control" value={this.state.emailAddress} onChange={this.updateField} />
          </div>
          <div className="form-group">
            <label>Card Number</label>
            <input type="text" id="cardNumber" className="form-control" value={this.state.cardNumber} onChange={this.updateField} />
          </div>
          <div>
            <label>Expiration</label>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <MonthSelect id="cardExpMonth" selected={this.state.cardExpMonth} onSelect={this.updateField} />
              </div>
              <div className="col-md-8">
                <YearSelect id="cardExpYear" selected={this.state.cardExpYear} onSelect={this.updateField} />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>CVC</label>
            <input type="text" id="cardCVC" className="form-control" value={this.state.cardCVC} onChange={this.updateField} />
          </div>
          <div className="form-group">
            <button className="btn btn-success btn-block" onClick={this.toggleShowPaymentConfirm}>Save New Payment Method</button>
            {this.state.school.paymentMethodToken !== "" && (
              <button className="btn btn-danger btn-block" onClick={this.toggleDeletePaymentConfirm}>Delete Existing Payment Method</button>
            )}
          </div>
          </Card>
        </div>
        <div className="col-md-9">
          <Card title="Prices" loading={this.state.loading} help="">
            <p>We aim to make KDojang as simple to understand and price as possible. The core features will always remain free. We only charge a small fee for some features
            to offset costs.</p>
            <p><strong>If you never send messages to your students and handle your own invoicing, KDojang is completely free to use!</strong></p>

            <Table striped={true} bordered={true} hover={true}>
              <tbody>
                <tr>
                  <td>Number of Students</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Credit Card Processing</td>
                  <td>5% + $0.50 for each credit card charge</td>
                </tr>
                <tr>
                  <td>SMS</td>
                  <td>$0.03 each</td>
                </tr>
                <tr>
                  <td>Emails</td>
                  <td>$0.01 each</td>
                </tr>
                <tr>
                  <td>Document Storage</td>
                  <td>$0.05 / GB</td>
                </tr>
                <tr>
                  <td>Support</td>
                  <td>Email</td>
                </tr>
                </tbody>
            </Table>
          </Card>
        </div>


        <Modal show={this.state.showPaymentConfirm} onHide={this.toggleShowPaymentConfirm} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            By submitting a valid credit card, you agree to our Terms of Service. You also agree to be charged automatically each month for your usage of the platform.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.savePaymentMethod}>Yes, Update Method</button>
            <button className="btn btn-default" onClick={this.toggleShowPaymentConfirm}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeletePaymentConfirm} onHide={this.toggleDeletePaymentConfirm} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            If you delete your payment method, you will not be able to take advantage of our advanced features. You will also be automatically charged for any outstanding charges.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deletePaymentMethod}>Yes, Delete Method</button>
            <button className="btn btn-default" onClick={this.toggleDeletePaymentConfirm}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const schoolRes = await SchoolsAPI.getSchoolInfo(this.props.schoolState.school.id);
        this.setState({
          loading: false, 
          school: schoolRes.body.data
        });
      } catch(err){
        error("could not load the school settings");
        this.setState({loading: false});
      }
    });
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleShowPaymentConfirm(){
    this.setState({showPaymentConfirm: !this.state.showPaymentConfirm});
  }

  private savePaymentMethod(){
    if (this.state.cardNumber === "" || this.state.cardCVC === "") {
      return error("All fields are required");
    }
  
    this.setState({loading: true, showPaymentConfirm: false}, () => {
      const tokenInput = {
        number: this.state.cardNumber,
        cvc: this.state.cardCVC,
        exp_month: this.state.cardExpMonth,
        exp_year: this.state.cardExpYear
      };
      
      (window as any).Stripe.card.createToken(tokenInput, async (status: any, response: any) => {
        try{
          if (status === 200) {
            await SchoolsAPI.updateSchoolPaymentMethod(this.props.schoolState.school.id, this.state.firstName, this.state.lastName, this.state.emailAddress, response.id);
            success("Payment method updated!");
          } else {
            error("Could not save payment method!");
          }
        }catch(err){
          error("Could not save payment method!");
        }finally{
          this.setState({loading: false, showPaymentConfirm: false}, () => {
            this.fetch();
          });
        }
      });
    });
  }

  private toggleDeletePaymentConfirm(){
    this.setState({showDeletePaymentConfirm: !this.state.showDeletePaymentConfirm});
  }

  private deletePaymentMethod(){
    this.setState({loading: true, showDeletePaymentConfirm: false}, async () => {
      try{
        await SchoolsAPI.deleteSchoolPaymentMethod(this.props.schoolState.school.id);
        success("Payment method removed!");
      }catch(err){
        error("Could not delete that payment method. Please contact support");
      }finally{
        this.setState({loading: false}, () => {
          this.fetch();
        });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolPlanScreen) as React.ComponentType<any>);