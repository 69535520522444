import * as React from "react";
import { Modal } from "react-bootstrap";

import Card from "../../structure/Card";
import { CompetitionsAPI } from "../../../API";
import StateSelect from "../../structure/StateSelect";
import DatePicker from "../../structure/DatePicker";
import * as moment from "moment";
import { error, success } from "src/components/structure/Alert";

import SchoolCompetitionListItem from "./SchoolCompetitionListItem";

interface ISchoolCompetitionsListProps {
  onNewCompetition: any;
  onDeleteCompetition: any;
  onSelectCompetition: any;
  onFilterChange: any;
  filterStart: moment.Moment;
  filterEnd: moment.Moment;
  schoolId: number;
  competitions: any;
  selectedCompetition: any;
}

interface ISchoolCompetitionsListState {
  loading: boolean;
  showNewModal: boolean;
  showDeleteModal: boolean;
  name: string;
  city: string;
  state: string;
  shareResults: "no" | "school" | "all";
  startDate: moment.Moment;
}

class SchoolCompetitionsList extends React.Component<ISchoolCompetitionsListProps, ISchoolCompetitionsListState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showNewModal: false,
      showDeleteModal: false,
      name: "",
      city: "",
      state: "AK",
      shareResults: "no",
      startDate: moment(),
    };

    this.updateField = this.updateField.bind(this);
    this.toggleShowNewModal = this.toggleShowNewModal.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleStateChanged = this.handleStateChanged.bind(this);
    this.newCompetition = this.newCompetition.bind(this);
    this.handleFilterEndDateChanged = this.handleFilterEndDateChanged.bind(this);
    this.handleFilterStartDateChanged = this.handleFilterStartDateChanged.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteCompetition = this.deleteCompetition.bind(this);
  }

  public render() {
    return (
      <div>
        <Card title="Competition List" loading={this.state.loading} help="Many schools participate in competitions. You may enter competions here and optionally share the results among the school (for users using the KDojang Student app) or share the results publicly (this feature is coming soon).">
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-md-6">
              <label>From</label>
              <DatePicker
                date={this.props.filterStart}
                onDateSaved={this.handleFilterStartDateChanged} />
            </div>
            <div className="col-md-6">
              <label>To</label>
              <DatePicker
                date={this.props.filterEnd}
                onDateSaved={this.handleFilterEndDateChanged} />
            </div>
          </div>
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-md-3 vertical-container">
              <strong>Start Date</strong>
            </div>
            <div className="col-md-3 vertical-container">
              <strong>Name</strong>
            </div>
            <div className="col-md-2 vertical-container">
              <strong>Sharing</strong>
            </div>
            <div className="col-md-2 vertical-container">
              <strong>State</strong>
            </div>
            <div className="col-md-2 vertical-container" />
          </div>
          {this.props.competitions.length === 0 && (<div className="row">
            <div className="col-md-12">
              <strong>No competitions match your criteria</strong>
            </div>
          </div>)}
          {this.props.competitions.map((competition: any, index: number) => {
            return (
              <SchoolCompetitionListItem
                key={competition.id}
                item={competition}
                className={index % 2 === 1 ? "list-row-odd" : ""}
                onSelect={this.handleSelect}
                onDelete={this.handleDelete}
              />
            );
          })}
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-block btn-primary" onClick={this.toggleShowNewModal}>Create New Competition</button>
            </div>
          </div>
        </Card>
        <Modal show={this.state.showNewModal} onHide={this.toggleShowNewModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Competition</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name</label>
              <input type="text" id="name" className="form-control" value={this.state.name} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>City</label>
              <input type="text" id="city" className="form-control" value={this.state.city} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>State</label>
              <StateSelect
                selectedState={this.state.state}
                onSelect={this.handleStateChanged} />
            </div>
            <div className="form-group">
              <label>Start Date</label>
              <DatePicker
                date={this.state.startDate}
                onDateSaved={this.handleStartDateChanged} />
            </div>
            <div className="form-group">
              <label>Share Results</label>
              <select id="shareResults" className="form-control" value={this.state.shareResults} onChange={this.updateField}>
                <option value="no">No, the results should remain to the school and participants</option>
                <option value="school">School - Share the results with members of the school</option>
                <option value="all">Public - Anyone with the app can look up the results</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.newCompetition}>Create New Competition</button>
            <button className="btn" onClick={this.toggleShowNewModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete {this.props.selectedCompetition.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete this competition? This cannot be undone!
      </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteCompetition}>Yes, Delete This Competition</button>
            <button className="btn" onClick={this.toggleShowDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any) {
    const id = e.target.id;
    const value = e.target.value;
    const ns = this.state;
    ns[id] = value;
    this.setState(ns);
  }

  private handleStartDateChanged(newDate: moment.Moment) {
    this.setState({
      startDate: newDate
    });
  }

  private handleStateChanged(e: any) {
    this.setState({
      state: e.target.value
    });
  }

  private handleFilterStartDateChanged(newDate: moment.Moment) {
    this.props.onFilterChange("start", newDate);
  }

  private handleFilterEndDateChanged(newDate: moment.Moment) {
    this.props.onFilterChange("end", newDate);
  }

  private toggleShowNewModal() {
    this.setState({ showNewModal: !this.state.showNewModal });
  }

  private toggleShowDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private handleSelect(competition: any) {
    this.props.onSelectCompetition(competition);
  }

  private newCompetition() {
    if (this.state.name === "" || this.state.city === "") {
      return error("Name and City are required");
    }
    this.setState({ loading: true }, async () => {
      const data = {
        name: this.state.name,
        city: this.state.city,
        state: this.state.state,
        startDate: this.state.startDate.format("YYYY-MM-DD"),
        shareResults: this.state.shareResults,
      };
      try {
        const rest = await CompetitionsAPI.createSchoolCompetition(this.props.schoolId, data);
        const newCompetition = {
          ...rest.body.data,
        };
        this.setState({ loading: false, name: "", city: "", state: "AK", startDate: moment(), showNewModal: false, shareResults: "no" }, () => {
          success("Competition created!");
          this.props.onNewCompetition(newCompetition);
        });
      } catch (err) {
        this.setState({ loading: false }, () => {
          error("Could not create that competition");
        });
      }
    });
  }

  private handleDelete(comp: any) {
    this.props.onSelectCompetition(comp);
    this.setState({ showDeleteModal: true });
  }

  private deleteCompetition() {
    this.setState({ loading: true }, async () => {
      try {
        await CompetitionsAPI.deleteSchoolCompetition(this.props.schoolId, this.props.selectedCompetition.id);
        success("Competition Deleted!");
        this.setState({ loading: false, showDeleteModal: false, name: "" });
        this.props.onDeleteCompetition();
      } catch (err) {
        error("Could not delete that competition");
        this.setState({ loading: false });
      }
    });
  }


}

export default SchoolCompetitionsList;