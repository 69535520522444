import * as React from "react";

import Card from "../../structure/Card";
import { ReportsAPI } from "../../../API";
import * as moment from "moment";
import DatePicker from "../../structure/DatePicker";
import {format} from "../../../utils/currency";
import PieChart from "../../structure/PieChart";

interface IPaymentReportEntry {
  amount: number;
  contractId: number;
  created: string;
  due: string;
  eligible: string;
  familyId: number;
  familyName: string;
  firstName: string;
  id: number;
  invoiceType: string;
  lastAttemptToCharge: string;
  lastName: string;
  notes: string;
  platformPaidOn: string;
  schoolDue: number;
  schoolId: number;
  schoolPaidOn: string;
  source: string;
  status: string;
  totalFees: number;
  userId: number;
}

interface IPaymentsReportProps {
  schoolId: number;
}

interface IPaymentsReportState {
  loading: boolean;
  data: IPaymentReportEntry[];
  pieData: any[];
  start: moment.Moment;
  end: moment.Moment;
  gross: number;
  fees: number;
  dueSchool: number;
  paidToSchool: number;
  external: number;
}

class PaymentsReport extends React.Component<IPaymentsReportProps, IPaymentsReportState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: [],
      pieData: [],
      start: moment().date(1),
      end: moment().add(1, "month").date(1),
      gross: 0,
      fees: 0,
      dueSchool: 0,
      paidToSchool: 0,
      external: 0
    };

    this.fetch = this.fetch.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Card title="Dates" loading={this.state.loading} help="">
              <div className="row">
                <div className="col-md-6">
                  <label>From</label>
                  <DatePicker date={this.state.start} onDateSaved={this.handleStartDateChanged} />
                </div>
                <div className="col-md-6">
                  <label>To</label>
                  <DatePicker date={this.state.end} onDateSaved={this.handleEndDateChanged} />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10">
            <Card title="Payments" loading={this.state.loading} help="">
              <div className="row" style={{marginBottom: 5}}>
                <div className="col-md-1 row-header">
                  Date
                </div>
                <div className="col-md-2 row-header">
                  User
                </div>
                <div className="col-md-3 row-header">
                  Family
                </div>
                <div className="col-md-2 row-header">
                  Status
                </div>
                <div className="col-md-1 row-header">
                  Gross
                </div>
                <div className="col-md-1 row-header">
                  Fees
                </div>
                <div className="col-md-2 row-header">
                  Due School
                </div>
              </div>
              {this.state.data.map((item: IPaymentReportEntry, index: number) => {
                let status = (<span>Paid</span>);
                if(item.source === "internal"){
                  if(item.status === "overdue"){
                    status = (<strong>Overdue (last attempted {moment(item.lastAttemptToCharge).format("MM/DD/YY")}</strong>);
                  }
                }else{
                  status = (<span>External</span>);
                }
                return (
                  <div className={index % 2 === 1 ? "list-row-odd row" : "row"} key={index} style={{marginBottom: 5}}>
                    <div className="col-md-1">
                      {moment(item.due).format("MM/DD/YYYY")}
                    </div>
                    <div className="col-md-2">
                      {item.firstName} {item.lastName}
                    </div>
                    <div className="col-md-3">
                      {item.familyName}
                    </div>
                    <div className="col-md-2">
                      {status}
                    </div>
                    <div className="col-md-1">
                      {format(item.amount)}
                    </div>
                    <div className="col-md-1">
                      {format(item.totalFees)}
                    </div>
                    <div className="col-md-2">
                      {item.source === "external" ? "--" : format(item.amount - item.totalFees)}
                    </div>
                  </div>
                );
              })}
              <div className="row" style={{marginBottom: 5}}>
                <div className="col-md-1 row-header col-md-offset-8">
                  {format(this.state.gross)}
                </div>
                <div className="col-md-1 row-header">
                  {format(this.state.fees)}
                </div>
                <div className="col-md-2 row-header">
                  {format(this.state.dueSchool)}
                </div>
              </div>
            </Card>
          </div>

          <div className="col-md-2">
            <Card title="Distribution" loading={this.state.loading} help="">
              <PieChart data={this.state.pieData} toolFormatter={this.formatTip}/>
            </Card>
          </div>
      </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try {
        const res = await ReportsAPI.getChargesReport(this.props.schoolId, this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD"));
        let gross = 0.0;
        let fees = 0.0;
        let dueSchool = 0.0;
        let external = 0.0;
        
        for(const d of res.body.data){
          gross += d.amount;
          fees += d.totalFees;
          if(d.source === "external"){
            external += d.amount;
          } else {
            dueSchool += d.amount - d.totalFees;
          }
        }
        const pieData: any = [{
          name: "Fees",
          value: fees,
          color: "FF5733"
        },{
          name: "External",
          value: external,
          color: "0D47A1"
        },{
          name: "Due School",
          value: dueSchool,
          color: "81C784"
        }];
        this.setState({loading: false, data: res.body.data, pieData, gross, fees, dueSchool, external});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private handleStartDateChanged(newDate: moment.Moment){
    this.setState({start: newDate}, () => { this.fetch();});
  }

  private handleEndDateChanged(newDate: moment.Moment){
    this.setState({end: newDate}, () => { this.fetch();});
  }

  private formatTip(value: any){
    return format(value);
  }

}


export default PaymentsReport;