import * as React from "react";
import { Modal } from "react-bootstrap";
import { DocumentsAPI } from "../../../API";
import { success, error } from "../../structure/Alert";

interface IDocumentCategoryListItem {
  id: number;
  schoolId: number;
  name: string;
  description: string;
}


interface IDocumentCategoryListItemProps {
  className: string;
  schoolId: number;
  item: IDocumentCategoryListItem;
  onDelete: any;
  onEdit: any;
}

interface IDocumentCategoryListItemState {
  loading: boolean;
  showDeleteModal: boolean;
  showEditModal: boolean;
  name: string;
  description: string;
}

class DocumentCategoryListItem extends React.Component<IDocumentCategoryListItemProps, IDocumentCategoryListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showEditModal: false,
      showDeleteModal: false,
      name: "",
      description: ""
    };
    this.updateField = this.updateField.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  public componentDidMount(){
    this.setState({
      name: this.props.item.name,
      description: this.props.item.description
    });
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-3 vertical-container">
          {this.props.item.name}
        </div>
        <div className="col-md-7 vertical-container">
          {this.props.item.description}
        </div>
        <div className="col-md-2 vertical-container">
          <span className="text-primary glyphicon glyphicon-edit" onClick={this.toggleEdit} style={{ marginRight: 15 }} />
          <span className="text-danger glyphicon glyphicon-remove" onClick={this.toggleDelete} />
        </div>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDelete} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Warning! This will permanently delete the category and any documents assigned to the category. This cannot be undone. Are you absolutely sure you want
            to delete the category and all associated documents?
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleDelete}>Yes, Delete It</button>
            <button className="btn btn-primary" onClick={this.toggleDelete}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showEditModal} onHide={this.toggleEdit} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name</label>
              <input type="text" id="name" className="form-control" value={this.state.name} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea id="description" className="form-control" value={this.state.description} onChange={this.updateField} rows={4} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.handleEdit}>Save</button>
            <button className="btn btn-primary" onClick={this.toggleEdit}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleEdit() {
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleDelete() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private async handleDelete(){
    // call the API
    try{
      await DocumentsAPI.deleteDocumentCategory(this.props.schoolId, this.props.item.id);
      success("Document category deleted");
      this.setState({showDeleteModal: false});
      this.props.onDelete(this.props.item.id);
    }catch(err){
      return error("Could not delete that category!");
    }
  }

  private async handleEdit(){
    // call the API
    const data = {
      name: this.state.name,
      description: this.state.description
    };
    if(data.name === "" || data.description === ""){
      return error("Name and description are both required");
    }
    try{
      await DocumentsAPI.updateDocumentCategory(this.props.schoolId, this.props.item.id, data);
      success("Document category saved");
      this.setState({showEditModal: false});
      this.props.onEdit(this.props.item.id, this.state.name, this.state.description);
    }catch(err){
      return error("Could not save that category!");
    }
  }
}

export default DocumentCategoryListItem;