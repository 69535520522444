import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";

import Card from "../../../structure/Card";
import { error } from "../../../structure/Alert";
import * as AppActions from "../../../../reducers/appReducer";
import {DocumentsAPI} from "../../../../API";
import Item from "./UserDocumentListItem";

interface IUserDocumentsProps {
  appActions: any;
  history: any;
  userId: number;
  schoolState: any;
}

interface IUserDocumentsState {
  loading: boolean;
  userId: number;
  selectedCategory: number;
  documents: any;
  categories: any;
  showDeleteModal: boolean;
  showUploadModal: boolean;
  uploadCategory: number;
  title: string;
  file: any;
  time: number;
}

const helpText = `Once you have set up document categories, you can store documents related to the user here. For example, you can upload photo release forms or signed contracts as desired.`;

class UserDocuments extends React.Component<IUserDocumentsProps, IUserDocumentsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      userId: 0,
      selectedCategory: 0,
      documents: [],
      categories: [],
      showDeleteModal: false,
      showUploadModal: false,
      uploadCategory: 0,
      title: "",
      file: null,
      time: 0,
    };
    this.updateField = this.updateField.bind(this);
    this.fetch = this.fetch.bind(this);
    this.upload = this.upload.bind(this);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.toggleUpload = this.toggleUpload.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  public componentDidMount(){
    if(this.props.userId !== 0){
      this.setState({userId: this.props.userId}, () => this.fetch());
    }
  }
  
  public componentWillReceiveProps(nextProps: any){
    if(nextProps.userId !== 0 && nextProps.userId !== this.state.userId){
      this.setState({userId: nextProps.userId}, () => this.fetch());
    }
  }

  public render() {
    return (
      <Card title="Documents" loading={this.state.loading} help={helpText}>
        <div className="form-group">
          <label>Select a Category</label>
          <select id="selectedCategory" className="form-control" value={this.state.selectedCategory+""} onChange={this.updateField}>
            <option value="0">All</option>
            {this.state.categories.map((c: any, index: number) => {
              return (<option key={index} value={c.id}>{c.name}</option>);
            })}
          </select>
          
          {this.state.documents.length === 0 && (<strong>No documents have been uploaded</strong>)}
            {this.state.documents.map((doc: any, index: number) => {
              if(this.state.selectedCategory === 0 || (this.state.selectedCategory === doc.categoryId)){
                return (<Item 
                  key={doc.id}
                  item={doc}
                  userId={this.state.userId}
                  categoryId={this.state.selectedCategory}
                  schoolId={this.props.schoolState.school.id}
                  className={index % 2 === 1 ? "list-row-odd" : ""}
                  onDelete={this.delete}
                  onEdit={this.edit} />);
              }
              return null;
            })}
        </div>
        <div className="form-group">
            <button className="btn btn-block btn-primary" onClick={this.toggleUpload}>Upload New Document</button>
        </div>


        <Modal show={this.state.showUploadModal} onHide={this.toggleUpload} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Upload New Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Select a Category</label>
              <select id="uploadCategory" className="form-control" value={this.state.uploadCategory+""} onChange={this.updateField}>
                {this.state.categories.map((c: any, index: number) => {
                  return (<option key={index} value={c.id}>{c.name}</option>);
                })}
              </select>
            </div>
            <div className="form-group">
              <label>Title</label>
              <input type="text" id="title" className="form-control" value={this.state.title} onChange={this.updateField} />
            </div>
            <div className="row">
              <div className="col-md-12">
              {this.state.file !== null ? (<div data-time={this.state.time}>File "{this.state.file.name}" Selected!</div>) : (
                <Dropzone onDrop={this.handleDrop} maxSize={5 * 1024 * 1024}>
                  {({getRootProps, getInputProps}) => {
                    return (
                      <div
                        {...getRootProps()}
                        className="dropzone file-upload-modal"
                        style={{"textAlign": "center"}}>
                        <input {...getInputProps()} />
                        <div style={{height: 40}}>Click or Drop a file here to begin the upload process</div>
                      </div>
                    );
                  }
                  }
                </Dropzone>
              )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.upload}>Upload</button>
            <button className="btn btn-primary" onClick={this.toggleUpload}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </Card>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const data: any = {
          userId: this.state.userId
        };  
        const docsRes = await DocumentsAPI.getUserDocuments(this.props.schoolState.school.id, this.state.userId, data);
        const catsRes = await DocumentsAPI.getDocumentCategories(this.props.schoolState.school.id);
        this.setState({loading: false, documents: docsRes.body.data, categories: catsRes.body.data, uploadCategory: catsRes.body.data[0]});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private updateField(e: any){
    const id = e.target.id;
    let val = e.target.value;
    if(id === "selectedCategory" || id === "uploadCategory"){
      val = parseInt(val, 10);
    }
    const ns = this.state;
    ns[id] = val;
    this.setState(ns);
  }

  private upload(){
    const data: any = {
      title: this.state.title,
      categoryId: this.state.uploadCategory,
      userId: this.props.userId,
    };
    if(data.title === ""){
      return error("Title cannot be blank");
    }
    if(this.state.file === null){
      return error("You must select a file to upload");
    }
    // first the file, then the document
    this.setState({loading: true}, async () => {
      try{
        const res = await DocumentsAPI.uploadDocument(this.props.schoolState.school.id, this.state.uploadCategory, this.state.file);
        data.fileId = res.body.data.id;
      }catch(err){
        this.setState({loading: false});
        return error("We could not upload that file");
      }
      
      try{
        await DocumentsAPI.createDocument(this.props.schoolState.school.id, this.state.userId, data);
        this.setState({file: null, title: "", showUploadModal: false}, () => {this.fetch();});
      }catch(err){
        this.setState({loading: false});
        return error("File uploaded but the document metadata could not be saved");
      }
    });
  }

  private delete(id: number){
    const docs: any = [];
    for(const d of this.state.documents){
      if(d.id !== id){
        docs.push(d);
      }
    }
    this.setState({documents: docs});
  }

  private edit(id: number, title: string){
    const docs: any = [];
    for(const d of this.state.documents){
      if(d.id === id){
        d.title = title;
      }
      docs.push(d);
    }
    this.setState({documents: docs});
  }

  private toggleDelete(){
    this.setState({showDeleteModal: !this.state.showDeleteModal});
  }

  private toggleUpload(){
    if(this.state.categories.length === 0){
      return error("You cannot upload a file until you set up document categories under the Configuration menu");
    }
    this.setState({showUploadModal: !this.state.showUploadModal, uploadCategory: this.state.categories[0].id});
  }

  private async handleDrop(acceptedFiles: any) {
    this.setState({file: acceptedFiles[0], time: new Date().getTime()});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDocuments) as React.ComponentType<any>);