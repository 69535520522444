import * as React from "react";
import * as moment from "moment";

import DatePicker from "../../structure/DatePicker";
import PieChart from "../../structure/PieChart";

import Card from "../../structure/Card";
import { ReportsAPI } from "../../../API";


interface IInstructorCreditsReportProps {
  schoolId: number;
}

interface IInstructorCreditsReportState {
  loading: boolean;
  data: any[];
  start: moment.Moment;
  end: moment.Moment;
  pieData: any[];
}

const colors: string[] = [
  "4A148C",
  "B71C1C",
  "311B92",
  "FFEB3B",
  "01579B",
  "1B5E20",
  "F57F17",
  "263238",
  "1DE9B6",
];

class InstructorCreditsReport extends React.Component<IInstructorCreditsReportProps, IInstructorCreditsReportState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: [],
      pieData: [],
      start: moment().month(1).date(1),
      end: moment().month(12).date(31),
    };

    this.fetch = this.fetch.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Card title="Dates" loading={this.state.loading} help="">
              <div className="row">
                <div className="col-md-6">
                  <label>From</label>
                  <DatePicker date={this.state.start} onDateSaved={this.handleStartDateChanged} />
                </div>
                <div className="col-md-6">
                  <label>To</label>
                  <DatePicker date={this.state.end} onDateSaved={this.handleEndDateChanged} />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-md-offset-2">
            <Card title="Last Attended" loading={this.state.loading} help="">
              <div className="row" style={{marginBottom: 5}}>
                <div className="col-md-8 row-header">
                  Instructor
                </div>
                <div className="col-md-4 row-header">
                  Classes Led
                </div>
              </div>
              {this.state.data.map((item: any, index: number) => {
                return (
                  <div className={index % 2 === 1 ? "list-row-odd row" : "row"} key={index} style={{marginBottom: 5}}>
                    <div className="col-md-8">
                      {item.firstName} {item.lastName}
                    </div>
                    <div className="col-md-4">
                      {item.count}
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
          <div className="col-md-3">
            <Card title="Instructors" loading={this.state.loading} help="">
            <PieChart data={this.state.pieData} />
            </Card>
          </div>
      </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try {
        const res = await ReportsAPI.getInstructorsReport(this.props.schoolId, this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD"));
        const pieData: any = [];
        let index: number = 0;
        for(const d of res.body.data){
          if(index >= colors.length){
            index = 0;
          }
          pieData.push({
            name: `${d.firstName} ${d.lastName}`,
            color: colors[index],
            value: d.count
          });
        }
        this.setState({loading: false, data: res.body.data, pieData});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private handleStartDateChanged(newDate: moment.Moment){
    this.setState({start: newDate}, () => { this.fetch();});
  }

  private handleEndDateChanged(newDate: moment.Moment){
    this.setState({end: newDate}, () => { this.fetch();});
  }

}


export default InstructorCreditsReport;