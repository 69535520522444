import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";
import BigCalendar from "react-big-calendar";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";

interface IScheduleProps {
  appActions: any;
  history: any;
  schoolState: any;
  events: any;

  onEventSelected: any;
  onNewEvent: any;
  onNavigate: any;
}

interface IScheduleState {
  loading: boolean;
  events: any;
  start: any;
  end: any;
}

const scheduleFormats = {
  dayFormat: "ddd MM/DD",
  dayHeaderFormat: "MM/DD",
  weekHeaderFormat: "MM/DD",
};

class Schedule extends React.Component<IScheduleProps, IScheduleState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      start: moment().day("Sunday"),
      end: moment().day("Saturday"),
      events: []
    };
    BigCalendar.momentLocalizer(moment);

    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.handleSelectSlot = this.handleSelectSlot.bind(this);
  }

  public render() {
    return (
      <Card title="Schedule" loading={this.state.loading} help="The schedule should include all classes or events that you want students to be able to attend. It is used to track attendance and generate analytics for your school. When combined with the KDojang Admin mobile application, it can be seen by students for signing in before each class.">
        <BigCalendar
          events={this.props.events}
          defaultView="day"
          views={["week", "day"]}
          selectable={true}
          formats={scheduleFormats}
          startAccessor="start"
          endAccessor="end"

          onNavigate={this.handleNavigate}
          eventPropGetter={this.eventPropGetter}
          onSelectEvent={this.handleSelectEvent}
          
          onSelectSlot={this.handleSelectSlot}
          min={new Date(2016,1,1,8)}
          max={new Date(2016,1,1,22)}

      />
      </Card>
    );
  }

  private eventPropGetter(event: any, start: string, end: string){
    let className = "schedule-event-pending";
    if(moment(end).isBefore(moment())){
      className = "schedule-event-done";
    } else if(event.privacy === "private"){
      className = "schedule-event-private";
    }
    return {className};
  }

  private handleNavigate(info: any, type: string){
    this.props.onNavigate(moment(info).day("Sunday").utc(), moment(info).day("Saturday").utc());
  }

  private handleSelectEvent(info: any){
    this.props.onEventSelected(info);
  }

  private handleSelectSlot(info: any){
    this.props.onNewEvent(info);
  }

}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schedule) as React.ComponentType<any>);