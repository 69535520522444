import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";

// sentry
import * as Sentry from "@sentry/browser";

// css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "../node_modules/react-bootstrap-typeahead/css/Typeahead.css";
import "../node_modules/react-table/react-table.css";
import "../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";
import "rc-time-picker/assets/index.css";
import "react-day-picker/lib/style.css";

import "./css/app.css";

if(process.env.REACT_APP_SENTRY_DSN){
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN// "https://f1833ae37a6242b1a3259ef3bb0534c8@sentry.io/1419296",
  });
}


// is local storage is set, we will want to set the info
if(window.localStorage.user){
  let user = window.localStorage.user;
  if(typeof user === "string"){
    user = JSON.parse(user);
  }
  const env = process.env.REACT_APP_ENV === "production" ? "production" : "develop";
  Sentry.configureScope((scope: any) => {
    scope.setExtra("environment", env);
    scope.setUser(user);
  });
}

ReactDOM.render(
  <App />,
  document.getElementById("root") as HTMLElement
);
// unregister the service worker to be sure it no longer looks for the update
unregister();