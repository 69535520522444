import * as React from "react";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import Card from "../../structure/Card";
import ListItem from "./SchoolCompetitionsEventsParticipantsListItem";
import { CompetitionsAPI } from "src/API";
import { error, success } from "src/components/structure/Alert";

interface ISchoolCompetitionsEventsParticipantsListProps {
  selectedEvent: any;
  schoolId: number;
  users: any;
  onUserAdded: any;
  onUserRemoved: any;
  onUserEdited: any;
}

interface ISchoolCompetitionsEventsParticipantsListState {
  loading: boolean;
  showNewModal: boolean;
  showEditModal: boolean;
  showRemoveUserModal: boolean;
  selectedUser: any;

  userId: number;
  role: string;
  place: number;
  notes: string;
}

class SchoolCompetitionsEventsParticipantsList extends React.Component<ISchoolCompetitionsEventsParticipantsListProps, ISchoolCompetitionsEventsParticipantsListState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showNewModal: false,
      showEditModal: false,
      showRemoveUserModal: false,
      role: "competitor",
      place: 0,
      notes: "",
      userId: 0,
      selectedUser: {
        id: 0,
      },
    };
    this.updateField = this.updateField.bind(this);
    this.handleSelectUser = this.handleSelectUser.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleUserSelectedFromTypeahead = this.handleUserSelectedFromTypeahead.bind(this);
    this.handleRemoveUser = this.handleRemoveUser.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
    this.toggleShowNewModal = this.toggleShowNewModal.bind(this);
    this.toggleShowRemoveUserModal = this.toggleShowRemoveUserModal.bind(this);
  }

  public render() {
    return (
      <div>
      <Card title="Participants" loading={this.state.loading} help="Here you can link a participant to an event. The role could be competitor, coach, judge, referree, or something similar. You can update the placement after the event completes.">
        {this.props.selectedEvent.users.length === 0 && (<div className="row">
            <div className="col-md-12">
              <strong>No users have been added to this event.</strong>
            </div>
          </div>) }
              {this.props.selectedEvent.users.map((user: any, index: number) => {
                return(
                  <ListItem
                    key={user.userId}
                    item={user}
                    className={index % 2 === 1 ? "list-row-odd" : ""}
                    onSelect={this.handleSelectUser} 
                    onDelete={this.handleDeleteUser} />
                );
              })}
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-block btn-primary" onClick={this.toggleShowNewModal}>Add User</button>
            </div>
          </div>
      </Card>

      <Modal show={this.state.showNewModal} onHide={this.toggleShowNewModal} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>Add Participant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>Student</label>
          <Typeahead
            key={this.props.users.length}
            labelKey="fullName"
            multiple={false}
            placeholder="Enter Student Name"
            onChange={this.handleUserSelectedFromTypeahead}
            options={this.props.users} />
        </div>
        <div className="form-group">
          <label>Role</label>
          <select id="role" className="form-control" value={this.state.role} onChange={this.updateField}>
            <option value="competitor">Competitor</option>
            <option value="participant">Participant</option>
            <option value="judge">Judge</option>
            <option value="referee">Referee</option>
            <option value="coach">Coach</option>
            <option value="assistant">Assistant</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Place</label><br />
          <span className="small">You can set their place here. If they were not competing or the competition has not occurred, you may leave it as Not Applicable</span>
          <select id="place" className="form-control" value={this.state.place+""} onChange={this.updateField}>
            <option value="0">Not Applicable / Not Placed</option>
            <option value="1">First</option>
            <option value="2">Second</option>
            <option value="3">Third</option>
            <option value="4">Fourth</option>
            <option value="5">Fifth</option>
            <option value="6">Sixth</option>
            <option value="7">Seventh</option>
            <option value="8">Eight</option>
            <option value="9">Ninth</option>
            <option value="10">Tenth</option>
            <option value="11">Eleventh</option>
            <option value="12">Twelfth</option>
          </select>
        </div>
        <div className="form-group">
          <label>Notes</label>
          <textarea id="notes" className="form-control" rows={5} value={this.state.notes} onChange={this.updateField} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-success" onClick={this.handleAddUser}>Add Participant</button>
        <button className="btn" onClick={this.toggleShowNewModal}>Nevermind</button>
      </Modal.Footer>
    </Modal>

    <Modal show={this.state.showEditModal} onHide={this.toggleShowEditModal} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>Editing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>Role</label>
          <select id="role" className="form-control" value={this.state.role} onChange={this.updateField}>
            <option value="competitor">Competitor</option>
            <option value="participant">Participant</option>
            <option value="judge">Judge</option>
            <option value="referee">Referee</option>
            <option value="coach">Coach</option>
            <option value="assistant">Assistant</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Place</label>
          <span className="small">You can set their place here. If they were not competing or the competition has not occurred, you may leave it as Not Applicable</span>
          <select id="place" className="form-control" value={this.state.place+""} onChange={this.updateField}>
            <option value="0">Not Applicable / Not Placed</option>
            <option value="1">First</option>
            <option value="2">Second</option>
            <option value="3">Third</option>
            <option value="4">Fourth</option>
            <option value="5">Fifth</option>
            <option value="6">Sixth</option>
            <option value="7">Seventh</option>
            <option value="8">Eight</option>
            <option value="9">Ninth</option>
            <option value="10">Tenth</option>
            <option value="11">Eleventh</option>
            <option value="12">Twelfth</option>
          </select>
        </div>
        <div className="form-group">
          <label>Notes</label>
          <textarea id="notes" className="form-control" rows={5} value={this.state.notes} onChange={this.updateField} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-success" onClick={this.handleEditUser}>Save</button>
        <button className="btn" onClick={this.toggleShowEditModal}>Nevermind</button>
      </Modal.Footer>
    </Modal>

    <Modal show={this.state.showRemoveUserModal} onHide={this.toggleShowRemoveUserModal} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>Remove {this.state.selectedUser.firstName} {this.state.selectedUser.lastName}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you absolutely sure you want to remove {this.state.selectedUser.firstName} {this.state.selectedUser.lastName}? This cannot be undone!
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger" onClick={this.handleRemoveUser}>Yes, Remove This User</button>
        <button className="btn" onClick={this.toggleShowRemoveUserModal}>Nevermind</button>
      </Modal.Footer>
    </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    let value = e.target.value;
    if(id === "place"){
      value = parseInt(value, 10);
    }
    const ns = this.state;
    ns[id] = value;
    this.setState(ns);
  }

  private handleSelectUser(user: any){
    this.setState({ selectedUser: user, showEditModal: true});
  }

  private handleAddUser(){
    const data = {
      userId: 0,
      role: this.state.role,
      place: this.state.place,
      notes: this.state.notes,
    };
    this.setState({ loading: true}, async () => {
      try{
        const res = await CompetitionsAPI.linkUserToCompetition(this.props.schoolId, this.props.selectedEvent.competitionId, this.props.selectedEvent.id, this.state.userId, data);
        success("User saved!");
        this.setState({loading: false, showNewModal: false, userId: 0, role: "competitor", place: 0});
        this.props.onUserAdded(res.body.data);
      }catch(err){
        error("Could not add that user to the competition");
        this.setState({loading: false});
      }
    });
  }

  private handleDeleteUser(user: any){
    this.setState({ selectedUser: user, showRemoveUserModal: true});
  }

  private handleEditUser(){
    const data = {
      ...this.state.selectedUser,
      userId: this.state.selectedUser.userId,
      role: this.state.role,
      place: this.state.place,
      notes: this.state.notes,
    };
    this.setState({ loading: true}, async () => {
      try{
        await CompetitionsAPI.linkUserToCompetition(this.props.schoolId, this.props.selectedEvent.competitionId, this.props.selectedEvent.id, this.state.selectedUser.userId, data);
        success("User saved!");
        this.setState({loading: false, showEditModal: false, userId: 0, role: "competitor", place: 0});
        this.props.onUserEdited(data);
      }catch(err){
        error("Could not add that user to the competition");
        this.setState({loading: false});
      }
    });
  }

  private handleRemoveUser(){
    this.setState({ loading: true}, async () => {
      try{
        await CompetitionsAPI.removeUserFromCompetition(this.props.schoolId, this.props.selectedEvent.competitionId, this.props.selectedEvent.id, this.state.selectedUser.userId);
        success("User removed!");
        this.props.onUserRemoved(this.state.selectedUser);
        this.setState({loading: false, showRemoveUserModal: false, userId: 0, role: "competitor", place: 0, selectedUser: {}});
      }catch(err){
        error("Could not remove that user to the competition");
        this.setState({loading: false});
      }
    });
  }

  private toggleShowNewModal(){
    this.setState({showNewModal: !this.state.showNewModal});
  }

  private toggleShowEditModal(){
    this.setState({showEditModal: !this.state.showEditModal});
  }

  private toggleShowRemoveUserModal(){
    this.setState({showRemoveUserModal: !this.state.showRemoveUserModal});
  }


  private handleUserSelectedFromTypeahead(e: any){
    this.setState({ userId: e[0].id});
  }

}


export default SchoolCompetitionsEventsParticipantsList;