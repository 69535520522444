import * as moment from "moment";
import { makeCall } from "./client";

export class FinancesAPI {

  /**
   * Get school invoice
   * @param schoolId 
   * @param start 
   * @param end 
   * @param status 
   * @param params 
   */
  public getSchoolInvoices(schoolId: number, start: string, end: string, status: string = "pending", params: any = {}): Promise<any> {
    params = {
      ...params,
      start,
      end,
      status
    };
    return makeCall("get", `schools/${schoolId}/invoices`, params);
  }

  /**
   * Get user invoices
   * @param schoolId 
   * @param userId 
   * @param start 
   * @param end 
   * @param status 
   * @param params 
   */
  public getUserInvoices(schoolId: number, userId: number, start: string, end: string, status: string = "pending", params: any = {}): Promise<any> {
    params = {
      ...params,
      start,
      end,
      status
    };
    return makeCall("get", `schools/${schoolId}/users/${userId}/invoices`, params);
  }
  
  /**
   * Get the invoice stats for a school
   * @param schoolId 
   * @param options 
   */
  public getSchoolInvoiceStats(schoolId: number, options: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/invoices/stats`, options);
  }

  /**
   * Get the invoices stats for the dashboard
   * @param schoolId 
   * @param start 
   * @param end 
   * @param options 
   */
  public getSchoolInvoiceDashboard(schoolId: number, start: string = "", end: string = "", options: any = {}): Promise<any> {
    const data = {
      start,
      end
    };
    if(data.start === ""){
      data.start = moment().subtract(7, "days").format("YYYY-MM-DD");
    }
    if(data.end === ""){
      data.end = moment().format("YYYY-MM-DD");
    }
    return makeCall("get", `schools/${schoolId}/invoices/dashboard`, data, options);
  }

  /**
   * Create a new invoice
   * @param schoolId 
   * @param invoiceData 
   */
  public createInvoice(schoolId: number, invoiceData: any): Promise<any> {
    return makeCall("post", `schools/${schoolId}/invoices/`, invoiceData);
  }
  
  /**
   * Update an existing invoice
   * @param schoolId 
   * @param invoiceId 
   * @param invoiceData 
   */
  public updateInvoice(schoolId: number, invoiceId: number, invoiceData: any): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/invoices/${invoiceId}`, invoiceData);
  }
  
  /**
   * Delete an invoice
   * @param schoolId 
   * @param invoiceId 
   * @param options 
   */
  public deleteInvoice(schoolId: number, invoiceId: number, options: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/invoices/${invoiceId}`, options);
  }

  /**
   * Get the history for this invoice
   * @param schoolId 
   * @param invoiceId 
   * @param options 
   */
  public getInvoiceHistory(schoolId: number, invoiceId: number, options: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/invoices/${invoiceId}/history`, options);
  }

  /**
   * Print an invoice
   * @param schoolId 
   * @param invoiceId 
   * @param options 
   */
  public printInvoice(schoolId: number, invoiceId: number, queryParams: any = {}, options: any = {}){
    return makeCall("get", `schools/${schoolId}/invoices/${invoiceId}/print`, queryParams, options);
  }

  /**
   * Get the user payment method
   * @param schoolId 
   * @param userId 
   * @param options 
   */
  public getUserPaymentMethod(schoolId: number, userId: number, options: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/card`, options);
  }

  /**
   * Saves the user payment method
   * @param schoolId 
   * @param userId 
   * @param options 
   */
  public saveUserPaymentMethod(schoolId: number, userId: number, options: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/card`, options);
  }

  /**
   * Remove the user payment method
   * @param schoolId 
   * @param userId 
   * @param options 
   */
  public removeUserPaymentMethod(schoolId: number, userId: number, options: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/users/${userId}/card`, options);
  }

  /**
   * Get the school's contracts filtered by status
   * @param schoolId 
   * @param status 
   * @param options 
   */
  public getSchoolContracts(schoolId: number, status: string = "active", options: any = {}): Promise<any> {
    options = {
      ...options,
      status,
    };
    return makeCall("get", `schools/${schoolId}/contracts`, options);
  }

  /**
   * Get contracts expiring in the next X days
   * @param schoolId 
   * @param numberOfDays 
   * @param options 
   * @returns 
   */
  public getSchoolContractsExpiringSoon(schoolId: number, numberOfDays: number = 7, options: any = {}): Promise<any> {
    options = {
      ...options,
      numberOfDays,
    };
    return makeCall("get", `schools/${schoolId}/contracts/expiring`, options);
  }

  /**
   * Get the contracts for a user
   * @param schoolId 
   * @param userId 
   * @param status 
   * @param options 
   */
  public getUserContracts(schoolId: number, userId: number, status: string = "active", options: any = {}): Promise<any> {
    options = {
      ...options,
      status,
    };
    return makeCall("get", `schools/${schoolId}/users/${userId}/contracts`, options);
  }

  /**
   * Get the contract stats for a school
   * @param schoolId 
   * @param options 
   */
  public getSchoolContractStats(schoolId: number, options: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/contracts/stats`, options);
  }

  /**
   * Print a contract
   * @param schoolId 
   * @param contractId 
   * @param options 
   */
  public printContract(schoolId: number, contractId: number, options: any = {}){
    return makeCall("get", `schools/${schoolId}/contracts/${contractId}/print`, {}, options);
  }

  /**
   * Creates a new contract
   * @param schoolId 
   * @param data 
   * @param options 
   */
  public createSchoolContract(schoolId: number, data: any, options: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/contracts`, data, options);
  }

  /**
   * Updates an existing contract
   * @param schoolId 
   * @param contractId 
   * @param data 
   * @param options 
   */
  public updateSchoolContract(schoolId: number, contractId: number, data: any, options: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/contracts/${contractId}`, data, options);
  }

  /**
   * Update the future invoices on the contract
   * @param schoolId 
   * @param contractId 
   * @param data 
   * @param options 
   */
  public updateSchoolContractInvoices(schoolId: number, contractId: number, data: any, options: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/contracts/${contractId}/invoices`, data, options);
  }

  /**
   * Pause a contract
   * @param schoolId 
   * @param contractId 
   * @param data 
   * @param options 
   */
  public pauseSchoolContract(schoolId: number, contractId: number, data: any, options: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/contracts/${contractId}/pauses`, data, options);
  }

  /**
   * Unpause a contract
   * @param schoolId 
   * @param contractId 
   * @param data 
   * @param options 
   */
  public unpauseSchoolContract(schoolId: number, contractId: number, data: any, options: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/contracts/${contractId}/pauses`, data, options);
  }

  /**
   * Update a contract
   * @param schoolId 
   * @param contractId 
   * @param data 
   * @param options 
   */
  public updateContract(schoolId: number, contractId: number, data: any, options: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/contracts/${contractId}`, data, options);
  }

  /**
   * Get the correct Stripe connect oauth URL
   * @param schoolId 
   * @param options 
   */
  public getConnectOAuthLink(schoolId: number, options: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/connect/url`, {}, options);
  }

  /**
   * Attempt to verify the oauth token
   * @param schoolId 
   * @param code 
   * @param state 
   * @param scope 
   * @param options 
   */
  public attemptConnectVerify(schoolId: number, code: string, state: string, scope: string, options: any = {}): Promise<any> {
    const data = {
      code,
      state,
      scope
    };
    return makeCall("post", `schools/${schoolId}/connect`, data, options);
  }
}