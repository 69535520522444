import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import Card from "../../../structure/Card";
import * as AppActions from "../../../../reducers/appReducer";
import { EquipmentAPI } from "../../../../API";
import UserEquipmentItem from "./UserEquipmentItem";
import { capFirstLetter } from "../../../../utils";
import { success, error } from "../../../structure/Alert";


interface IUserEquipmentProps {
  appActions: any;
  history: any;
  schoolId: number;
  userId: number;
}

interface IUserEquipmentState {
  loading: boolean;
  assignedEquipment: any;
  filteredAssignedEquipment: any;
  selectedCategory: "all" | "other" | "uniform" | "belt" | "sparring" | "merchandise";
  schoolEquipment: any;
  showAssignModal: boolean;
  assignEquipmentId: number;
  createInvoice: "yes" | "no";
  invoiceAlreadyPaid: "yes" | "no";
  modifyInventory:  "yes" | "no";
  notes: string;
}

class UserEquipment extends React.Component<IUserEquipmentProps, IUserEquipmentState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      assignedEquipment: [],
      filteredAssignedEquipment: [],
      selectedCategory: "all",
      schoolEquipment: [],
      showAssignModal: false,
      assignEquipmentId: 0,
      createInvoice: "no",
      invoiceAlreadyPaid: "no",
      modifyInventory: "no",
      notes: "",
    };

    this.fetch = this.fetch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.filter = this.filter.bind(this);
    this.toggleAssignModal = this.toggleAssignModal.bind(this);
    this.updateField = this.updateField.bind(this);
    this.assign = this.assign.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    if(this.state.schoolEquipment.length === 0){
      return (
        <Card title="Equipment" loading={this.state.loading} help="If Equipment is set up on the Equipment Screen found in the Menu, you can track which equipment has been assigned to a student. This is useful for keeping track of sizes.">
          <div className="row">
            <div className="col-md-12">
              <strong>You must first set up equipment inventory by going <Link to={`/schools/${this.props.schoolId}/equipment`}>here</Link></strong>
            </div>
          </div>
        </Card>
      );
    }
    return (
      <Card title="Equipment" loading={this.state.loading} help="">
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-md-6">
            <label>Showing</label>
            <select id="category" className="form-control" value={this.state.selectedCategory} onChange={this.filter}>
              <option value="all">All</option>
              <option value="belt">Belts</option>
              <option value="merchandise">Merchandise</option>
              <option value="sparring">Sparring</option>
              <option value="uniform">Uniform</option>
              <option value="other">other</option>
            </select>
          </div>
          <div className="col-md-6">
            <button onClick={this.toggleAssignModal} className="btn btn-block btn-success" style={{marginTop: 20}}>Assign Equipment</button>
          </div>
        </div>
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-md-3 vertical-container">
            <strong>Name</strong>
          </div>
          <div className="col-md-3 vertical-container">
            <strong>Category</strong>
          </div>
          <div className="col-md-2 vertical-container">
            <strong>Size</strong>
          </div>
          <div className="col-md-2 vertical-container">
            <strong>Received</strong>
          </div>
        </div>
        {this.state.filteredAssignedEquipment.length === 0 && (
          <div className="row">
            <div className="col-md-12">
              No equipment has been assigned to this user that matches that category.
            </div>
          </div>
        )}
        {this.state.filteredAssignedEquipment.map((e: any) => {
          return (
            <UserEquipmentItem
              key={e.id}
              item={e}
              className=""
              onDelete={this.handleDelete} />
          );
        })}


        <Modal show={this.state.showAssignModal} onHide={this.toggleAssignModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Add New Inventory Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Item</label>
              <select id="assignEquipmentId" className="form-control" value={this.state.assignEquipmentId} onChange={this.updateField}> 
                  <option key={0} value={0} disabled={true}>Select the equipment to assign</option> 
                  {this.state.schoolEquipment.map((eq: any) => {
                    return (<option key={eq.id} value={eq.id}>{capFirstLetter(eq.category)} - {eq.name} - {eq.size}</option>);
                  })};
              </select>
            </div>
            <div className="form-group">
              <label>Modify Inventory</label>
              <select id="modifyInventory" className="form-control" value={this.state.modifyInventory} onChange={this.updateField}> 
                <option value="yes">Yes, remove 1 from the existing school inventory</option> 
                <option value="no">No, do not modify the school's existing inventory</option> 
              </select>
            </div>
            <div className="form-group">
              <label>Create an Invoice</label>
              <select id="createInvoice" className="form-control" value={this.state.createInvoice} onChange={this.updateField}> 
                <option value="yes">Yes, create a new invoice for the equipment</option> 
                <option value="no">No, do not create an invoice</option> 
              </select>
            </div>
            {this.state.createInvoice === "yes" && (
              <div className="form-group">
              <label>Invoice Paid In Person?</label>
              <select id="invoiceAlreadyPaid" className="form-control" value={this.state.invoiceAlreadyPaid} onChange={this.updateField}> 
                <option value="yes">Yes, the user paid in person and the system should not charge</option> 
                <option value="no">No, please attempt to charge the user's payment method tonight</option> 
              </select>
            </div>
            )}
            <div className="form-group">
              <label>Notes</label>
              <textarea id="notes" className="form-control" value={this.state.notes} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.assign}>Assign</button>
            <button className="btn" onClick={this.toggleAssignModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      </Card>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleAssignModal(){
    this.setState({ showAssignModal: !this.state.showAssignModal});
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const assignedReses = await EquipmentAPI.getUserEquipment(this.props.schoolId, this.props.userId);
        const schoolRes = await EquipmentAPI.getSchoolEquipment(this.props.schoolId, "");
        this.setState({loading: false, assignedEquipment: assignedReses.body.data, schoolEquipment: schoolRes.body.data}, () => {
          this.filter({ target: { value: this.state.selectedCategory}});
        });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private filter(e: any){
    const newCategory = e.target.value;
    let filteredEquipment: any = [];
    if(newCategory === "all"){
      filteredEquipment = this.state.assignedEquipment;
    } else {
      for(const eq of this.state.assignedEquipment){
        if(eq.category === newCategory){
          filteredEquipment.push(eq);
        }
      }
    }
    this.setState({filteredAssignedEquipment: filteredEquipment, selectedCategory: newCategory});
  
  }
  
  private handleDelete(item: any){
    const equipment: any = [];
    for(const eq of this.state.assignedEquipment){
      if(eq.id !== item.id){
        equipment.push(eq);
      }
    }
    this.setState({assignedEquipment: equipment});
  }

  private assign(){
    const data = {
      modifyInventory: this.state.modifyInventory === "yes" ? true : false,
      createInvoice: this.state.createInvoice === "yes" ? true : false,
      invoiceAlreadyPaid: this.state.invoiceAlreadyPaid === "yes" ? true : false,
      notes: this.state.notes,
    };
    this.setState({loading: true}, async () => {
      try{
        await EquipmentAPI.assignEquipmentToUser(this.props.schoolId, this.props.userId, this.state.assignEquipmentId, data);
        success("Assigned that equipment to the user");
        this.setState({ showAssignModal: false}, () => {
          this.fetch();
        });
      }catch(err){
        error("Could not assign that inventory");
        this.setState({loading: false, showAssignModal: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEquipment) as React.ComponentType<any>);