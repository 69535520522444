import * as React from "react";
import * as moment from "moment";
import { Modal } from "react-bootstrap";
import { format } from "../../../utils/currency";
import { capFirstLetter } from "../../../utils";
import { EquipmentAPI } from "../../../API";
import { success, error } from "../../structure/Alert";

// note that the numbers are strings. It has to do with editing a float in react and it's a massive PITA
interface IEquipmentListItem {
  id: number;
  schoolId: number;
  name: string;
  category: string;
  barcode: string;
  currentInventory: number;
  size: string;
  salePrice: string;
  inventoryLastUpdated: string;
}

interface IEquipmentListItemProps {
  className: string;
  item: IEquipmentListItem;
  onUpdate: any;
  onDelete: any;
}

interface IEquipmentListItemState {
  loading: boolean;
  showUpdateModal: boolean;
  showDeleteModal: boolean;

  id: number;
  name: string;
  category: string;
  barcode: string;
  currentInventory: number;
  size: string;
  salePrice: string;
}

class EquipmentListItem extends React.Component<IEquipmentListItemProps, IEquipmentListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showUpdateModal: false,
      showDeleteModal: false,
      id: 0,
      name: "",
      category: "",
      barcode: "",
      currentInventory: 0,
      size: "",
      salePrice: "",
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateField = this.updateField.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
  }

  public componentDidMount(){
    if(this.props.item.id !== 0){
      this.setState({
        id: this.props.item.id,
        name: this.props.item.name,
        category: this.props.item.category,
        barcode: this.props.item.barcode,
        currentInventory: this.props.item.currentInventory,
        size: this.props.item.size,
        salePrice: (parseInt(this.props.item.salePrice, 10) / 100).toFixed(2),
      });
    }
  }

  public componentWillReceiveProps(nextProps: any){
    if(nextProps.item.id !== 0 && nextProps.item.id !== this.props.item.id){
      const ns = nextProps;
      ns.item.salePrice = (parseInt(ns.item.salePrice, 10) / 100).toFixed(2);
      this.setState(ns);
    }
  }

  public render() {
    return (
      <div className="row" key={this.props.item.id}>
        <div className="col-md-3 vertical-container">
          {this.props.item.name}
        </div>
        <div className="col-md-2 vertical-container">
          {capFirstLetter(this.props.item.category)}
        </div>
        <div className="col-md-1 vertical-container">
          {this.props.item.size}
        </div>
        <div className="col-md-1 vertical-container">
          {this.props.item.currentInventory}
        </div>
        <div className="col-md-2 vertical-container">
          {moment(this.props.item.inventoryLastUpdated).format("MM/DD/YY")}
        </div>
        <div className="col-md-2 vertical-container">
          {format(this.props.item.salePrice)}
        </div>
        <div className="col-md-1 vertical-container">
          <span className="glyphicon glyphicon-pencil text-primary" style={{marginRight: 10}} onClick={this.toggleUpdateModal} />
          <span className="glyphicon glyphicon-remove text-danger" onClick={this.toggleDeleteModal} />
        </div>

        <Modal show={this.state.showUpdateModal} onHide={this.toggleUpdateModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Add New Inventory Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Category</label>
              <select id="category" className="form-control" value={this.state.category} onChange={this.updateField}>  
                  <option value="belt">Belts</option>
                  <option value="merchandise">Merchandise</option>
                  <option value="sparring">Sparring</option>
                  <option value="uniform">Uniform</option>
                  <option value="other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label>Name</label>
              <input type="text" id="name" className="form-control" value={this.state.name} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Barcode</label>
              <input type="text" id="barcode" className="form-control" value={this.state.barcode} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Equipment Size</label>
              <input type="text" id="size" className="form-control" value={this.state.size} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Sale Price</label>
              <input type="text" id="salePrice" className="form-control" value={this.state.salePrice} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Current On-Hand Inventory</label>
              <input type="number" id="currentInventory" className="form-control" value={this.state.currentInventory} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.handleUpdate}>Update</button>
            <button className="btn" onClick={this.toggleUpdateModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to delete this inventory item? It will be removed from any users who have been assigned this item.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleDelete}>Delete</button>
            <button className="btn" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }


  private updateField(e: any){
    const ns = this.state;
    const id = e.target.id;
    const value = e.target.value;
    ns[id] = value;
    this.setState(ns);
  }

  private toggleUpdateModal(){
    this.setState({showUpdateModal: !this.state.showUpdateModal});
  }

  private toggleDeleteModal(){
    this.setState({showDeleteModal: !this.state.showDeleteModal});
  }

  private handleUpdate(){
      this.setState({loading: true}, async () => {
      try{
        const data = {
          name: this.state.name,
          barcode: this.state.barcode,
          category: this.state.category,
          currentInventory: this.state.currentInventory,
          salePrice: Math.round(parseFloat(this.state.salePrice) * 100),
          size: this.state.size,
        };
        if(data.name === "" || data.category === ""){
          return error("Name and Category are required");
        }
        const res = await EquipmentAPI.updateSchoolEquipment(this.props.item.schoolId, this.props.item.id, data);
        success("Equipment updated!");
        this.props.onUpdate(res.body.data);
        this.setState({ loading: false, showUpdateModal: false});
      }catch(err){
        error("Could not update that equipment inventory item");
        this.setState({ loading: false, showUpdateModal: false});
      }
    });
  }

  private handleDelete(){
    this.setState({loading: true}, async () => {
      try{
        await EquipmentAPI.deleteSchoolEquipment(this.props.item.schoolId, this.props.item.id);
        success("Equipment deleted!");
        this.props.onDelete(this.props.item);
        this.setState({ loading: false, showDeleteModal: false});
      }catch(err){
        error("Could not delete that equipment inventory item");
        this.setState({loading: false, showDeleteModal: false});
      }
    });
  }

}

export default EquipmentListItem;