// TODO: break the material into a separate class because damn this is large
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {KnowledgeAPI} from "../../../API";
import {error, success} from "../../structure/Alert";
import Card from "../../structure/Card";
import Markdown from "../../structure/MarkdownTextarea";
import * as AppActions from "../../../reducers/appReducer";

interface IKnowledgeProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface IKnowledgeState {
  loading: boolean;
  viewingGroupType: string;
  groups: any;
  selectedGroup: any;
  selectedMaterial: any;
  selectedAttachment: any;
  showDeleteGroupModal: boolean;
  showDeleteMaterialModal: boolean;
  showNewGroupModal: boolean;
  showNewMaterialModal: boolean;
  newGroupName: string;
  newGroupBeltId: string | number;
  newGroupType: string;
  newMaterialTitle: string;
  newMaterialContent: string;
  newMaterialAssignToExisting: boolean;
  newMaterialAssignToExistingAndSetStatus: "assigned" | "completed" | "pending_verification";
  orderGroupChanged: boolean;
  orderMaterialChanged: boolean;
  deleteGroupTarget: string | number;

  newAttachmentType: "external" | "youtube" | "file";
  newAttachmentTitle: string;
  newAttachmentDescription: string;
  newAttachmentLocation: string;
  showNewAttachmentModal: boolean;
  showDeleteAttachmentModal: boolean;
  showUpdateAttachmentModal: boolean;
}

const paddingBase = 2;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  padding: paddingBase * 2,
  margin: `0 0 ${paddingBase}px 0`,
  background: isDragging ? "#edf2f9" : "white",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "#b6bbc1" : "#b6bbc1",
  padding: paddingBase,
});

class Knowledge extends React.Component<IKnowledgeProps, IKnowledgeState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      viewingGroupType: "belt",
      groups: [],
      selectedGroup: {id : 0},
      selectedMaterial: { id: 0},
      selectedAttachment: { id: 0},
      showDeleteGroupModal: false,
      showDeleteMaterialModal: false,
      showNewGroupModal: false,
      showNewMaterialModal: false,

      newGroupName: "",
      newGroupBeltId: "0",
      newGroupType: "belt",
      
      newMaterialTitle: "",
      newMaterialContent: "",
      newMaterialAssignToExisting: true,
      newMaterialAssignToExistingAndSetStatus: "assigned",

      newAttachmentType: "external",
      newAttachmentTitle: "",
      newAttachmentDescription: "",
      newAttachmentLocation: "",
      showDeleteAttachmentModal: false,
      showNewAttachmentModal: false,
      showUpdateAttachmentModal: false,

      orderGroupChanged: false,
      orderMaterialChanged: false,

      deleteGroupTarget: 0,
    };
    this.fetch = this.fetch.bind(this);
    this.createGroup = this.createGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
    this.createMaterial = this.createMaterial.bind(this);
    this.deleteMaterial = this.deleteMaterial.bind(this);
    this.updateMaterial = this.updateMaterial.bind(this);
    this.toggleNewGroupModal = this.toggleNewGroupModal.bind(this);
    this.toggleNewMaterialModal = this.toggleNewMaterialModal.bind(this);
    this.toggleDeleteGroupModal = this.toggleDeleteGroupModal.bind(this);
    this.toggleDeleteMaterialModal = this.toggleDeleteMaterialModal.bind(this);

    this.updateField = this.updateField.bind(this);
    this.updateGroupField = this.updateGroupField.bind(this);
    this.updateMaterialField = this.updateMaterialField.bind(this);
    this.updateAttachmentField = this.updateAttachmentField.bind(this);
    this.updateMaterialContent = this.updateMaterialContent.bind(this);
    this.updateNewMaterialContent = this.updateNewMaterialContent.bind(this);
    this.updateNewMaterialAssignToExisting = this.updateNewMaterialAssignToExisting.bind(this);
    this.updateNewMaterialAssignToExistingAndSetStatus = this.updateNewMaterialAssignToExistingAndSetStatus.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.selectMaterial = this.selectMaterial.bind(this);
    this.onGroupDragEnd = this.onGroupDragEnd.bind(this);
    this.onMaterialDragEnd = this.onMaterialDragEnd.bind(this);
    this.getGroupContents = this.getGroupContents.bind(this);
    this.saveGroupOrder = this.saveGroupOrder.bind(this);

    this.createNewAttachment = this.createNewAttachment.bind(this);
    this.updateAttachment = this.updateAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.toggleDeleteAttachmentModal = this.toggleDeleteAttachmentModal.bind(this);
    this.toggleNewAttachmentModal = this.toggleNewAttachmentModal.bind(this);
    this.toggleUpdateAttachmentModal = this.toggleUpdateAttachmentModal.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <Card title="Select A Group" loading={this.state.loading} help="Knowledge is curriculum designed to augment in-class teaching, such as philosophies, rules, and terminology. Here you create groups of knowledge materials. They can either be assigned automatically with promotion to a belt or assigned individually. The order is important and you may drag them into the desired order.">
            <div className="form-group">
              <label>Material Group Type</label>
              <select id="viewingGroupType" className="form-control" value={this.state.viewingGroupType} onChange={this.updateField}>
                <option value="belt">Belts</option>
                <option value="training">Training</option>
                <option value="other">Other</option>
              </select>
            </div>
            {this.getGroupContents()}

            <div className="form-group">
              {this.state.orderGroupChanged && 
                (<button className="btn btn-success btn-block" onClick={this.saveGroupOrder}>Save Order</button>)
              }
              <button className="btn btn-success btn-block" onClick={this.toggleNewGroupModal}>Add New Group</button>
            </div>
          </Card>
        </div>

        {this.state.selectedGroup.id !== 0 && (
          <div className="col-md-4">
          <Card title={`${this.state.selectedGroup.name}`} loading={this.state.loading} help="Within a group, individual pieces of knowledge may be created, assigned, and tracked.">
            <div className="form-group">
              <label>Name</label>
              <input type="text" className="form-control" id="name" value={this.state.selectedGroup.name} onChange={this.updateGroupField} />
            </div>
            <div className="form-group">
              <label>Type</label>
              <select id="groupType" className="form-control" value={this.state.selectedGroup.groupType} onChange={this.updateGroupField}>
                <option value="belt">Belts</option>
                <option value="training">Training</option>
                <option value="other">Other</option>
              </select>
            </div>
            {this.state.selectedGroup.groupType === "belt" && (
              <div className="form-group">
                <label>Should it be automatically assigned to students promoted to a belt?</label>
                <select className="form-control" id="beltId" value={this.state.selectedGroup.beltId} onChange={this.updateGroupField}>
                  <option value="0">No</option>
                  {this.props.schoolState.school.belts.map((belt: any) => {
                    return (<option value={belt.id} key={belt.id}>{belt.name}</option>);
                  })}
                </select>
              </div>
            )}
            {this.getMaterialContents()}
            <div className="form-group">
              <button className="btn btn-success btn-block" onClick={this.updateGroup}>Save Details and Order</button>
              <button className="btn btn-primary btn-block" onClick={this.toggleNewMaterialModal}>Add Material</button>
              <button className="btn btn-danger btn-block" onClick={this.toggleDeleteGroupModal}>Delete Group</button>
            </div>
          </Card>
          </div>
        )}
        {this.state.selectedMaterial.id !== 0 && (
          <div className="col-md-5">
            <Card title={`${this.state.selectedMaterial.title}`} loading={this.state.loading} help="Create or edit the knowledge below. Use Markdown to format the material as you desire.">
            <div className="form-group">
              <label>Title</label>
              <input type="text" id="title" className="form-control" value={this.state.selectedMaterial.title} onChange={this.updateMaterialField} />
            </div>
            <div className="form-group">
              <Markdown content={this.state.selectedMaterial.content} mode="edit" showToggle={true} onChange={this.updateMaterialContent} showHelp={true} />
            </div>
            <div className="form-group">
              <label>Attachments</label>
              {this.state.selectedMaterial.attachments && this.state.selectedMaterial.attachments.length === 0 && (<p><strong>No attachments belong to this material</strong></p>)}
              {this.state.selectedMaterial.attachments && this.state.selectedMaterial.attachments.map((attachment: any) => {
                return (
                  <div key={attachment.id} className="row">
                    <div className="col-md-4">
                      {attachment.title}
                    </div>
                    <div className="col-md-3">
                      {attachment.attachmentType === "file" ? "Download" : attachment.attachmentType === "youtube" ? "Youtube Video" : "External"}
                    </div>
                    <div className="col-md-3">
                      <a href={attachment.location}>Open</a>
                    </div>
                    <div className="col-md-1" onClick={this.toggleUpdateAttachmentModal.bind(null, attachment)}>
                      <span className="glyphicon glyphicon-pencil" />
                    </div>
                    <div className="col-md-1" onClick={this.toggleDeleteAttachmentModal.bind(null, attachment)} >
                      <span className="text-danger glyphicon glyphicon-trash" />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.updateMaterial}>Save Material</button>
              <button className="btn btn-block btn-primary" onClick={this.toggleNewAttachmentModal}>Add External Attachment</button>
              <button className="btn btn-block btn-danger" onClick={this.toggleDeleteMaterialModal}>Delete Material</button>
            </div>
            </Card>
          </div>
        )}

        <Modal show={this.state.showDeleteGroupModal} onHide={this.toggleDeleteGroupModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <strong><p>Are you absolutely sure you want to delete this group? This cannot be undone. If you choose not to move the material to a new group, 
                all students currently assigned to material in this group
                will no longer have access to it and the material will be permanently deleted.</p></strong>
            </div>
            <div className="form-group">
              <label>Would you like to transfer the material in this group to a new group?</label>
              <select id="deleteGroupTarget" value={this.state.deleteGroupTarget} onChange={this.updateField} className="form-control">
                <option value={0}>No, permanently delete all material in this group</option>
                {this.state.groups.map((group: any) => {
                  if(group.id !== this.state.selectedGroup.id){
                    return (<option key={`del-${group.id}`} value={group.id}>Transfer to: {group.name}</option>);
                  }
                  return null;
                })}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteGroup}>Delete Group</button>
            <button className="btn" onClick={this.toggleDeleteGroupModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showNewGroupModal} onHide={this.toggleNewGroupModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>New Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Group Name</label>
              <input type="text" id="newGroupName" className="form-control" value={this.state.newGroupName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Group Type</label>
              <select id="newGroupType" className="form-control" value={this.state.newGroupType} onChange={this.updateField}>
                <option value="belt">Belts</option>
                <option value="training">Training</option>
                <option value="other">Other</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.createGroup}>Create New Group</button>
            <button className="btn" onClick={this.toggleNewGroupModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      <Modal show={this.state.showDeleteMaterialModal} onHide={this.toggleDeleteMaterialModal} backdrop="static">
        <Modal.Header closeButton={true}>
          <Modal.Title>Delete Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <strong><p>Are you absolutely sure you want to delete this piece of material? This cannot be undone. All students currently assigned to it will no longer have access to it and no historical logs will remain.</p></strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={this.deleteMaterial}>Delete Material</button>
          <button className="btn" onClick={this.toggleDeleteMaterialModal}>Nevermind</button>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.showNewMaterialModal} onHide={this.toggleNewMaterialModal} backdrop="static">
        <Modal.Header closeButton={true}>
          <Modal.Title>New Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Material Name</label>
            <input type="text" id="newMaterialTitle" className="form-control" value={this.state.newMaterialTitle} onChange={this.updateField} />
          </div>
          <div className="form-group">
            <label>Content</label>
            <Markdown content={this.state.newMaterialContent} onChange={this.updateNewMaterialContent} showToggle={true} mode="edit"  showHelp={true} />
          </div>
          <div className="form-group">
            <label>Assign to students who have or had this material group?</label>
            <select id="newMaterialAssignToExisting" className="form-control" value={this.state.newMaterialAssignToExisting ? "yes" : "no"} onChange={this.updateNewMaterialAssignToExisting}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="form-group">
            <label>Assign to students who have or had this material group?</label>
            <select id="newMaterialAssignToExistingAndSetStatus" className="form-control" value={this.state.newMaterialAssignToExistingAndSetStatus} onChange={this.updateNewMaterialAssignToExistingAndSetStatus}>
              <option value="assigned">Assigned</option>
              <option value="pending_verification">Pending Testing</option>
              <option value="completed">Completed</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success" onClick={this.createMaterial}>Create New Material</button>
          <button className="btn" onClick={this.toggleNewMaterialModal}>Nevermind</button>
        </Modal.Footer>
      </Modal>

      

      <Modal show={this.state.showNewAttachmentModal} onHide={this.toggleNewAttachmentModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>New Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Title</label>
              <p className="small">Attachments are sorted alphabetically for students</p>
              <input type="text" id="newAttachmentTitle" className="form-control" value={this.state.newAttachmentTitle} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Attachment Type</label>
              <select id="newAttachmentType" className="form-control" value={this.state.newAttachmentType} onChange={this.updateField}>
                <option value="external">Exteral Website or Document</option>
                <option value="youtube">Youtube Link</option>
              </select>
            </div>
            <div className="form-group">
              <label>Location / URL</label>
              <input type="text" id="newAttachmentLocation" className="form-control" value={this.state.newAttachmentLocation} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input type="text" id="newAttachmentDescription" className="form-control" value={this.state.newAttachmentDescription} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.createNewAttachment}>Create New Attachment</button>
            <button className="btn" onClick={this.toggleNewAttachmentModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>



      <Modal show={this.state.showUpdateAttachmentModal} onHide={this.toggleUpdateAttachmentModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Update Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Title</label>
              <p className="small">Attachments are sorted alphabetically for students</p>
              <input type="text" id="title" className="form-control" value={this.state.selectedAttachment.title} onChange={this.updateAttachmentField} />
            </div>
            <div className="form-group">
              <label>Attachment Type</label>
              <select id="attachmentType" className="form-control" value={this.state.selectedAttachment.type} onChange={this.updateAttachmentField}>
                <option value="external">Exteral Website or Document</option>
                <option value="youtube">Youtube Link</option>
              </select>
            </div>
            <div className="form-group">
              <label>Location / URL</label>
              <input type="text" id="location" className="form-control" value={this.state.selectedAttachment.location} onChange={this.updateAttachmentField} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input type="text" id="description" className="form-control" value={this.state.selectedAttachment.description} onChange={this.updateAttachmentField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.updateAttachment}>Update Attachment</button>
            <button className="btn" onClick={this.toggleUpdateAttachmentModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      <Modal show={this.state.showDeleteAttachmentModal} onHide={this.toggleDeleteAttachmentModal} backdrop="static">
        <Modal.Header closeButton={true}>
          <Modal.Title>Delete Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <strong><p>Are you absolutely sure you want to delete this attachment? This cannot be undone.</p></strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={this.deleteAttachment}>Delete Attachment</button>
          <button className="btn" onClick={this.toggleDeleteAttachmentModal}>Nevermind</button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }

  private getGroupContents(){
    if(this.state.groups.length === 0){
      return (<strong>No groups of material exist for this school with this filter. Begin by creating a new group!</strong>);
    }

    const groups: any = [];
    this.state.groups.map((group: any, index: number) => {
      // does the group match the filter?
      if(this.state.viewingGroupType === "all" || group.groupType === this.state.viewingGroupType){
        return groups.push(
          <Draggable key={group.id} draggableId={group.id} index={index}>
            {(providedInner: any, snapshotInner) => (
              <div
                ref={providedInner.innerRef}
                {...providedInner.draggableProps}
                {...providedInner.dragHandleProps}
                style={getItemStyle(
                  snapshotInner.isDragging, 
                  providedInner.draggableProps.style
                )}
              >
                {this.getGroup(group)}
              </div>
            )}
          </Draggable>
        );
      }else{
        return (null);
      }
    });

    if(groups.length === 0){
      return (<strong>No groups match that type</strong>);
    }

    return (
      <div style={{marginBottom: 10}}>
        <DragDropContext onDragEnd={this.onGroupDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {groups}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  private getGroup(group: any){
    return (
      <div className="row" key={group.id} style={{height: 36, paddingTop: 3, paddingBottom: 3}}>
        <div className="col-md-2">
          <span className="glyphicon glyphicon-align-justify" />
        </div>
        <div className="col-md-7">
          {group.name}
        </div>
        <div className="col-md-3" onClick={this.selectGroup.bind(null, group)} style={{cursor: "default"}}>
          <span className="glyphicon glyphicon-pencil" />
        </div>
      </div>
    );
  }



  private getMaterialContents(){
    if(this.state.selectedGroup.material.length === 0){
      return (<strong>No material exists for this group. Begin by creating a new piece of material!!</strong>);
    }

    const mats: any = [];
    this.state.selectedGroup.material.map((mat: any, index: number) => {
      // does the group match the filter?
        return mats.push(
          <Draggable key={mat.id} draggableId={mat.id} index={index}>
            {(providedInner: any, snapshotInner) => (
              <div
                ref={providedInner.innerRef}
                {...providedInner.draggableProps}
                {...providedInner.dragHandleProps}
                style={getItemStyle(
                  snapshotInner.isDragging, 
                  providedInner.draggableProps.style
                )}
              >
                {this.getMaterial(mat)}
              </div>
            )}
          </Draggable>
        );
    });

    return (
      <div style={{marginBottom: 10}}>
        <DragDropContext onDragEnd={this.onMaterialDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {mats}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  private getMaterial(material: any){
    return (
      <div className="row" key={material.id} style={{height: 36, paddingTop: 3, paddingBottom: 3}}>
        <div className="col-md-2">
          <span className="glyphicon glyphicon-align-justify" />
        </div>
        <div className="col-md-7">
          {material.title}
        </div>
        <div className="col-md-3" onClick={this.selectMaterial.bind(null, material)} style={{cursor: "default"}}>
          <span className="glyphicon glyphicon-pencil" />
        </div>
      </div>
    );
  }

  private async onGroupDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const groups = this.reorderGroups(
      this.state.groups,
      result.source.index,
      result.destination.index
    );

    this.setState({
      groups,
      orderGroupChanged: true,
    });
  }

  private reorderGroups(list: any, startIndex: number, endIndex: number){
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  }


  private async onMaterialDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const mats = this.reorderMaterials(
      this.state.selectedGroup.material,
      result.source.index,
      result.destination.index
    );

    // find the group
    const groups = this.state.groups;
    for(const g of groups){
      if(g.id === this.state.selectedGroup.id){
        g.material = mats;
      }
    }

    this.setState({
      groups,
      orderMaterialChanged: true,
    });
  }

  private reorderMaterials(list: any, startIndex: number, endIndex: number){
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  }

  private async fetch(){
    this.setState({loading: true}, async () => {
      try{
        const res = await KnowledgeAPI.getMaterialGroups(this.props.schoolState.school.id);
        
        this.setState({loading: false, groups: res.body.data, orderGroupChanged: false, orderMaterialChanged: false});
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not load belts");
        });
      }
    });
  }

  private async saveGroupOrder(){
    this.setState({loading: true}, async () => {
      try{
        const groups = [];
        for(const g of this.state.groups){
          if(g.groupType === this.state.viewingGroupType){
            groups.push({
              id: g.id,
            });
          }
        }
        const params = {
          groups
        };
        await KnowledgeAPI.updateMaterialGroupOrder(this.props.schoolState.school.id, params);
        this.setState({loading: false, orderGroupChanged: false, orderMaterialChanged: false}, () => {
          return success("Groups successfully reordered!");
        });
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not reorder groups");
        });
      }
    });
  }

  private async deleteGroup(){
    this.setState({loading: true}, async () => {
      const params: any = {};
      if(this.state.deleteGroupTarget){
        if(typeof this.state.deleteGroupTarget === "string" && this.state.deleteGroupTarget !== "0"){
          params.target = parseInt(this.state.deleteGroupTarget, 10);
        }else {
          params.target = this.state.deleteGroupTarget;
        }
      }
      try{
        await KnowledgeAPI.deleteMaterialGroup(this.props.schoolState.school.id, this.state.selectedGroup.id, params);
        this.setState({loading: false, selectedGroup: {id: 0}, selectedMaterial: {id: 0}, showDeleteGroupModal: false}, () => {
          this.fetch();
        });
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not delete that belt");
        });
      }
    });
  }

  private async deleteMaterial(){
    this.setState({loading: true}, async () => {
      try{
        await KnowledgeAPI.deleteMaterial(this.props.schoolState.school.id, this.state.selectedGroup.id, this.state.selectedMaterial.id);
        // remove it from the group
        const mats = [];
        for(const m of this.state.selectedGroup.material){
          if(m.id !== this.state.selectedMaterial.id){
            mats.push(m);
          }
        }
        const group = this.state.selectedGroup;
        group.material = mats;
        this.setState({loading: false, selectedGroup: group, selectedMaterial: {id: 0}, showDeleteMaterialModal: false}, () => {
          return success("Deleted that material");
        });
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not delete that material");
        });
      }
    });
  }

  private async createGroup(){
    if(this.state.newGroupName === ""){
      return error("Group cannot have a blank name");
    }
    try{
      const params = {
        name: this.state.newGroupName,
        groupType: this.state.newGroupType
      };
      const res = await KnowledgeAPI.createMaterialGroup(this.props.schoolState.school.id, params);
      const newState = {loading: false, 
        newGroupName: "",
        selectedGroup: res.body.data, 
        showNewGroupModal: false};
      this.setState(newState, async () => {
        this.fetch();
        await this.saveGroupOrder();
      });
    }catch(err){
      this.setState({loading: false}, () => {
        return error("Could not create group");
      });
    }
  }

  private async createMaterial(){
    if(this.state.newMaterialTitle === "" || this.state.newMaterialContent === ""){
      return error("Material cannot have a blank title or content");
    }
    try{
      const params = {
        title: this.state.newMaterialTitle,
        content: this.state.newMaterialContent,
        assignToExisting: this.state.newMaterialAssignToExisting,
        assignToExistingAndSetStatus: this.state.newMaterialAssignToExistingAndSetStatus,
      };
      const res = await KnowledgeAPI.createMaterial(this.props.schoolState.school.id, this.state.selectedGroup.id, params);
      // we need to add it locally
      const group = this.state.selectedGroup;
      group.material.push(res.body.data);
      const newState = {
        loading: false, 
        selectedMaterial: res.body.data, 
        showNewMaterialModal: false, 
        selectedGroup: group,
        newMaterialTitle: "",
        newMaterialContent: ""
      };
      this.setState(newState, () => {
        this.fetch();
        return success("Material created!");
      });
    }catch(err){
      this.setState({loading: false}, () => {
        return error("Could not create material");
      });
    }
  }

  private async updateGroup(){
    if(this.state.selectedGroup.name === ""){
      return error("Group cannot have a blank name");
    }
    try{
      const params = {
        name: this.state.selectedGroup.name,
        groupType: this.state.selectedGroup.groupType,
        beltId: this.state.selectedGroup.groupType === "belt" ? parseInt(this.state.selectedGroup.beltId, 10) : 0,
        material: this.state.selectedGroup.material,
      };
      const res = await KnowledgeAPI.updateMaterialGroup(this.props.schoolState.school.id, this.state.selectedGroup.id, params);
      this.setState({loading: false, selectedGroup: res.body.data}, () => {
        success("Group saved!");
      });
    }catch(err){
      this.setState({loading: false}, () => {
        return error("Could not update group");
      });
    }
  }

  private async updateMaterial(){
    if(this.state.selectedMaterial.name === "" || this.state.selectedMaterial.content === ""){
      return error("Materials cannot have a blank title or content");
    }
    this.setState({loading: true}, async () => {
      try{
        const params = {
          title: this.state.selectedMaterial.title,
          content: this.state.selectedMaterial.content,
        };
        await KnowledgeAPI.updateMaterial(this.props.schoolState.school.id, this.state.selectedGroup.id, this.state.selectedMaterial.id, params);
        this.setState({loading: false});
        return success("Saved!");
      }catch(err){
        this.setState({loading: false});
        return error("We could not save that material");
      }
    });
  }

  private updateField(e: any){
    const ns:any = this.state;
    ns[e.target.id] = e.target.value;
    if(e.target.id === "viewingGroupType"){
      ns.newGroupType = e.target.value;
      ns.selectedGroup = {id: 0};
      ns.selectedMaterial = {id: 0};
    }
    this.setState(ns);
  }

  private updateGroupField(e: any){
    const ns = this.state.selectedGroup;
    ns[e.target.id] = e.target.value;
    this.setState({selectedGroup: ns});
  }

  private updateMaterialField(e: any){
    const ns = this.state.selectedMaterial;
    ns[e.target.id] = e.target.value;
    this.setState({selectedMaterial: ns});
  }

  private updateAttachmentField(e: any){
    const ns = this.state.selectedAttachment;
    ns[e.target.id] = e.target.value;
    this.setState({selectedAttachment: ns});
  }

  private updateMaterialContent(newContent: string){
    const ns = this.state.selectedMaterial;
    ns.content = newContent;
    this.setState({selectedMaterial: ns});
  }

  private updateNewMaterialContent(newContent: string){
    const ns: any = this.state;
    ns.newMaterialContent = newContent;
    this.setState(ns);
  }

  private updateNewMaterialAssignToExisting(e: any){
    this.setState({
      newMaterialAssignToExisting:  e.target.value === "yes" ? true : false,
    });
  }

  private updateNewMaterialAssignToExistingAndSetStatus(e: any){
    this.setState({
      newMaterialAssignToExistingAndSetStatus:  e.target.value,
    });
  }

  private selectGroup(selectedGroup: any){
    this.setState({selectedGroup, selectedMaterial: {id: 0}});
  }

  private selectMaterial(selectedMaterial: any){
    // we need to get the attachments
    this.setState({loading: true}, async () => {
      try{
        const att = await KnowledgeAPI.getAttachmentsForMaterial(selectedMaterial.schoolId, selectedMaterial.groupId, selectedMaterial.id);
        selectedMaterial.attachments = att.body.data;
        this.setState({loading: false, selectedMaterial});
      }catch(err){
        this.setState({loading: false, selectedMaterial});
      }
    });
  }

  private createNewAttachment(){
    const data = {
      title: this.state.newAttachmentTitle.trim(),
      description: this.state.newAttachmentDescription.trim(),
      location: this.state.newAttachmentLocation.trim(),
      attachmentType: this.state.newAttachmentType.trim(),
    };
    if(data.title === "" || data.description === "" || data.attachmentType === ""){
      return error("Title, Description, and Type are required");
    }
    // TODO: we need to eventually build out file uploads as part of this
    this.setState({loading: true}, async () => {
      try{
        await KnowledgeAPI.createAttachmentForMaterial(this.state.selectedMaterial.schoolId, this.state.selectedMaterial.groupId, this.state.selectedMaterial.id, data);
        this.setState({showNewAttachmentModal: false, newAttachmentDescription: "", newAttachmentTitle: "", newAttachmentLocation: ""}, () => {
          this.selectMaterial(this.state.selectedMaterial);
        });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private updateAttachment(){
    const data = {
      title: this.state.selectedAttachment.title.trim(),
      description: this.state.selectedAttachment.description.trim(),
      location: this.state.selectedAttachment.location.trim(),
      attachmentType: this.state.selectedAttachment.attachmentType.trim(),
    };
    if(data.title === "" || data.description === "" || data.attachmentType === ""){
      return error("Title, Description, and Type are required");
    }
    // TODO: we need to eventually build out file uploads as part of this
    this.setState({loading: true}, async () => {
      try{
        await KnowledgeAPI.updateAttachmentForMaterial(this.state.selectedMaterial.schoolId, this.state.selectedMaterial.groupId, this.state.selectedMaterial.id, this.state.selectedAttachment.id, data);
        this.setState({showUpdateAttachmentModal: false}, () => {
          this.selectMaterial(this.state.selectedMaterial);
        });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private deleteAttachment(){
    this.setState({loading: true}, async () => {
      try{
        await KnowledgeAPI.deleteAttachmentForMaterial(this.state.selectedMaterial.schoolId, this.state.selectedMaterial.groupId, this.state.selectedMaterial.id, this.state.selectedAttachment.id);
        this.setState({showDeleteAttachmentModal: false}, () => {
          this.selectMaterial(this.state.selectedMaterial);
        });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private toggleNewGroupModal(){
    this.setState({ showNewGroupModal: !this.state.showNewGroupModal});
  }

  private toggleNewMaterialModal(){
    this.setState({ showNewMaterialModal: !this.state.showNewMaterialModal});
  }

  private toggleDeleteGroupModal(){
    this.setState({ showDeleteGroupModal: !this.state.showDeleteGroupModal});
  }

  private toggleDeleteMaterialModal(){
    this.setState({ showDeleteMaterialModal: !this.state.showDeleteMaterialModal});
  }

  private toggleNewAttachmentModal(){
    this.setState({ showNewAttachmentModal: !this.state.showNewAttachmentModal});
  }

  private toggleUpdateAttachmentModal(att: any){
    const selectedAttachment = att && att.id && att.id !== 0 ? att : {id: 0};
    this.setState({ showUpdateAttachmentModal: !this.state.showUpdateAttachmentModal, selectedAttachment});
  }

  private toggleDeleteAttachmentModal(att: any){
    const selectedAttachment = att && att.id && att.id !== 0 ? att : {id: 0};
    this.setState({ showDeleteAttachmentModal: !this.state.showDeleteAttachmentModal, selectedAttachment});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Knowledge) as React.ComponentType<any>);