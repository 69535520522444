import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../../reducers/appReducer";

import UserProfile from "./UserProfile";
import UserNotes from "../../Notes/NotesScreen";
import UserDocuments from "./UserDocuments";
import UserFamilies from "./UserFamilies";

interface IUserProfileScreenProps {
  appActions: any;
  history: any;
  match: any;
  location: any;
  schoolState: any;
}

interface IUserProfileScreenState {
  loading: boolean;
  userId: number;
}

class UserProfileScreen extends React.Component<IUserProfileScreenProps, IUserProfileScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      userId: 0
    };
    this.parse = this.parse.bind(this);
  }

  public componentDidMount(){
    this.parse(this.props);
  }

  public componentWillReceiveProps(nextProps: any){
    if(this.props.location.pathname !== nextProps.location.pathname){
      this.parse(nextProps);
    }
  }

  public parse(props: any){
    const userId = props.match.params.userId ? parseInt(props.match.params.userId, 10) : 0;
    this.setState({userId, loading: false});
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <UserProfile 
              userId={this.state.userId} />
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <UserNotes 
                  userId={this.state.userId}
                  schoolId={this.props.schoolState.school.id}
                  height={200}/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <UserDocuments 
                  userId={this.state.userId}
                  schoolId={this.props.schoolState.school.id}/>
              </div>
              <div className="col-md-6">
                <UserFamilies 
                  userId={this.state.userId}
                  schoolId={this.props.schoolState.school.id}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfileScreen) as React.ComponentType<any>);