import { makeCall } from "./client";

export class ScheduleAPI {
  
  /**
   * Get all of the events for a school between a specific period of time
   * @param schoolId 
   * @param start 
   * @param end 
   * @param params 
   */
  public getEventsBetweenDays(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    params.start = start;
    params.end = end;
    return makeCall("get", `schools/${schoolId}/schedule`, params);
  }

  /**
   * Gets the dashboard attendance stats
   * @param schoolId 
   * @param start 
   * @param end 
   * @param params 
   */
  public getEventsAttendanceDashboard(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      start, 
      end
    };
    return makeCall("get", `schools/${schoolId}/schedule/attendance/dashboard`, params);
  }
  
  /**
   * Get the event types created by a school
   * @param schoolId 
   * @param params 
   */
  public getEventsTypes(schoolId: number,params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/schedule/eventTypes`, params);
  }

  /**
   * Get all of the event categories for the system
   * @param params 
   */
  public getEventsCategories(params: any = {}): Promise<any> {
    return makeCall("get", `events/categories`, params);
  }

  /**
   * Saves a new event type for the school
   * @param schoolId 
   * @param name 
   * @param category 
   * @param params 
   */
  public saveNewEventType(schoolId: number, name: string, category: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      eventType: name,
      eventSystemCategory: category
    };
    return makeCall("post", `schools/${schoolId}/schedule/eventTypes`, params);
  }

  /**
   * Create a new event in the system
   * @param schoolId 
   * @param params 
   */
  public createNewEvent(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/schedule`, params);
  }

  /**
   * Update an existing event and optionally all related events
   * @param schoolId 
   * @param eventId 
   * @param params 
   */
  public updateEvent(schoolId: number, eventId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/schedule/${eventId}`, params);
  }

  /**
   * Delete an event from the system
   * @param schoolId 
   * @param eventId 
   * @param deleteRelated 
   * @param params 
   */
  public deleteEvent(schoolId: number, eventId: number, deleteRelated: boolean = false, params: any = {}): Promise<any> {
    let q = "";
    if(deleteRelated){
      q = "?deleteRelated=true";
    }
    return makeCall("delete", `schools/${schoolId}/schedule/${eventId}/${q}`, params);
  }

  /**
   * Create new instances of an event
   * @param schoolId 
   * @param eventId 
   * @param params 
   * @returns 
   */
  public createEventCopies(schoolId: number, eventId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/schedule/${eventId}/copy`, params);
  }

  /**
   * Gets the event attendance
   * @param schoolId 
   * @param eventId 
   * @param params 
   * @returns 
   */
  public getEventAttendance(schoolId: number, eventId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/schedule/${eventId}/attendance`, params);
  }

  /**
   * Gets a user's attendance
   * @param schoolId 
   * @param userId 
   * @param params 
   * @returns 
   */
  public getUserAttendance(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/attendance`, params);
  }

  /**
   * Saves a user's attendance
   * @param schoolId 
   * @param eventId 
   * @param userId 
   * @param params 
   * @returns 
   */
  public saveUserAttendance(schoolId: number, eventId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/schedule/${eventId}/attendance/users/${userId}`, params);
  }

  /**
   * Remove a user's attendance
   * @param schoolId 
   * @param eventId 
   * @param userId 
   * @param params 
   * @returns 
   */
  public removeUserAttendance(schoolId: number, eventId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/schedule/${eventId}/attendance/users/${userId}`, params);
  }
}