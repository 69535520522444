import { makeCall } from "./client";

export class ReportsAPI {
  
  public getChargesReport(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    const data = {
      ...params,
      start,
      end
    };
    return makeCall("get", `schools/${schoolId}/reports/charges`, data);
  }

  public getInstructorsReport(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    const data = {
      ...params,
      start,
      end
    };
    return makeCall("get", `schools/${schoolId}/reports/schedule/instructors`, data);
  }

  public getLastAttendedReport(schoolId: number, params: any = {}): Promise<any> {
    const data = {
      ...params,
    };
    return makeCall("get", `schools/${schoolId}/reports/schedule/lastAttended`, data);
  }

  public getAgesDistributionReport(schoolId: number, params: any = {}): Promise<any> {
    const data = {
      ...params,
    };
    return makeCall("get", `schools/${schoolId}/reports/users/age`, data);
  }

  public getBeltsDistributionReport(schoolId: number, params: any = {}): Promise<any> {
    const data = {
      ...params,
    };
    return makeCall("get", `schools/${schoolId}/reports/belts`, data);
  }

  public getMessagesSentReport(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    const data = {
      ...params,
      start,
      end
    };
    return makeCall("get", `schools/${schoolId}/reports/messages/sent`, data);
  }

  public getRevenuePerUserReport(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    const data = {
      ...params,
      start,
      end
    };
    return makeCall("get", `schools/${schoolId}/reports/revenue/users`, data);
  }

  public getRevenuePerFamilyReport(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    const data = {
      ...params,
      start,
      end
    };
    return makeCall("get", `schools/${schoolId}/reports/revenue/families`, data);
  }


  public getZipReport(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    const data = {
      ...params,
      start,
      end
    };
    return makeCall("get", `schools/${schoolId}/reports/zips`, data);
  }

}