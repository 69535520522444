import * as React from "react";


interface IYearSelectProps {
  selected: string;
  id?: string;
  className?: string;
  onSelect: any;
}

export class YearSelect extends React.Component<IYearSelectProps, any> {

  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <select id={this.props.id ? this.props.id : "year"}
        className={this.props.className ? this.props.className : "form-control"}
        value={this.props.selected}
        onChange={this.props.onSelect}>
        <option value="19">2019</option>
        <option value="20">2020</option>
        <option value="21">2021</option>
        <option value="22">2022</option>
        <option value="23">2023</option>
        <option value="24">2024</option>
        <option value="25">2025</option>
        <option value="26">2026</option>
        <option value="27">2027</option>
        <option value="28">2028</option>
        <option value="29">2029</option>
      </select>
    );
  }
}