import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LazyLoad from "react-lazyload";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import Card from "../../structure/Card";
import Note from "./Note";
import * as AppActions from "../../../reducers/appReducer";
import { NotesAPI } from "../../../API";
import { error } from "../../structure/Alert";

interface INotesListContainerProps {
  appActions: any;
  history: any;
  schoolState: any;
  userId: number;
  height: number;
}

interface INotesListContainerState {
  loading: boolean;
  includeArchived: boolean;
  notes: any;
  filter: string;
  filtered: any;
  display: "grid" | "list";
  showNewNoteModal: boolean;
  newNoteSubject: string;
  newNoteBody: string;
  newNoteStudents: any;
}

const helpText = `Notes allow you to keep track of basic information, statuses, or updates for a user. They can be viewed by any assistant with the appropriate permissions. They can be archived when no longer relevant.`;

class NotesListContainer extends React.Component<INotesListContainerProps, INotesListContainerState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      includeArchived: false,
      filter: "",
      notes: [],
      filtered: [],
      display: "grid",
      showNewNoteModal: false,
      newNoteSubject: "",
      newNoteBody: "",
      newNoteStudents: [],
    };
    this.fetch = this.fetch.bind(this);
    this.toggleArchived = this.toggleArchived.bind(this);
    this.filter = this.filter.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.updateField = this.updateField.bind(this);
    this.toggleShowNewNoteModal = this.toggleShowNewNoteModal.bind(this);
    this.saveNewNote = this.saveNewNote.bind(this);
    this.handleSelectedStudent = this.handleSelectedStudent.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }
  
  public componentWillReceiveProps(nextProps: any){
    if(nextProps.userId !== this.props.userId){
      this.fetch();
    }
  }

  public render() {
    const cellClassName = this.state.display === "grid" ? "col-md-3" : "";
    const display = this.state.display === "grid" ? "card" : "list";
    return (
          <Card title="Notes" loading={this.state.loading} help={helpText}>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label> Include Archived Notes</label>
                  <select className="form-control" value={this.state.includeArchived ? "include" : "exclude"} onChange={this.toggleArchived}>
                    <option value="exclude">No, Hide Archived</option>
                    <option value="include">Yes, Show Archived</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Display As: </label>
                  <select className="form-control" value={this.state.display} onChange={this.changeDisplay}>
                    <option value="grid">Grid</option>
                    <option value="list">List</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Filter: </label>
                  <input type="text" className="form-control" value={this.state.filter} onChange={this.filter} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label />
                  <button className="btn btn-block btn-success" onClick={this.toggleShowNewNoteModal}>New Note</button>
                </div>
              </div>
            </div>

            <div className="note-overflow-container" style={{height: this.props.height && this.props.height !== 0 ? this.props.height: 600}}>
              {this.state.filtered.length === 0 ? <div><strong>No Results Found</strong></div> : null}
              {this.state.filtered.map((note: any, index: number) => {
                return(
                  <LazyLoad once={true} key={index} overflow={true} throttle={100}>
                    <div className={cellClassName}>
                      <Note 
                        id={note.id}
                        subject={note.subject}
                        body={note.body}
                        users={note.students}
                        archived={note.archived}
                        updated={note.lastUpdatedOn}
                        display={display}
                        className={display === "list" && index % 2 === 1 ? "list-row-odd" : ""}
                        onStatusChange={this.handleStatusChange}
                        onDelete={this.handleDelete}
                        onUpdate={this.handleUpdate}
                      />
                    </div>
                  </LazyLoad>
                );
              })}
            </div>
            
            <Modal show={this.state.showNewNoteModal} onHide={this.toggleShowNewNoteModal} backdrop="static">
              <Modal.Header closeButton={true}>
                <Modal.Title>New Note</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!this.props.userId && (
                  <div className="form-group">
                    <label>Students</label>
                    {this.renderTypeahead()}
                  </div>
                )}
                <div className="form-group">
                  <label>Subject</label>
                  <input type="text" id="newNoteSubject" className="form-control" value={this.state.newNoteSubject} onChange={this.updateField} />
                </div>
                <div className="form-group">
                  <label>Body</label>
                  <textarea id="newNoteBody" className="form-control" value={this.state.newNoteBody} onChange={this.updateField} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-success" onClick={this.saveNewNote}>Save</button>
                <button className="btn btn-primary" onClick={this.toggleShowNewNoteModal}>Nevermind</button>
              </Modal.Footer>
            </Modal>
          </Card>
    );
  }

  private async fetch(){
    this.setState({loading: true}, async () => {
      let notes = [];
      let filtered = [];
      let res: any;
      try{
        if(this.props.userId && this.props.userId !== 0){
          res = await NotesAPI.getUserNotes(this.props.schoolState.school.id, this.props.userId, this.state.includeArchived);
        } else {
          res = await NotesAPI.getSchoolNotes(this.props.schoolState.school.id, this.state.includeArchived);
        }
        notes = res.body.data;
        filtered = res.body.data;
      } catch(e){
        notes = [];
      }
      return this.setState({loading: false, notes, filtered});
    });
  }

  private toggleArchived(){
    this.setState({
      includeArchived: !this.state.includeArchived
    }, () => this.fetch());
  }

  private filter(e: any){
    const val = e.target.value;
    // build new notes
    this.setState({filter: val}, () => {
      let filtered  = [];
      if(val === ""){
        filtered = this.state.notes;
      } else {
        const f = val;
        filtered = this.state.notes.filter((note: any) => {
          if(note.subject.indexOf(f) > -1 || note.body.indexOf(f) > -1){
            return true;
          } else {
            for(const u of note.students){
              if(u.firstName.indexOf(f) > -1 || u.lastName.indexOf(f) > -1){
                return true;
              }
            }
          }
          return false;
        });
        this.setState({loading: false, filtered});
      }
    });
  }

  private changeDisplay(e: any){
    this.setState({
      display: e.target.value
    });
  }

  private async handleStatusChange(noteId: number, archived: boolean){
    const notes = this.state.notes;
    const filtered = this.state.filtered;
    for(const n of notes){
      if(n.id === noteId){
        n.archived = archived;
        break;
      }
    }
    for(const n of filtered){
      if(n.id === noteId){
        n.archived = archived;
        break;
      }
    }
    this.setState({notes, filtered}, async () => {
      try{
        await NotesAPI.toggleNoteArchiveStatus(this.props.schoolState.school.id, noteId, archived, {});
      } catch(e){
        error("We could not update that note");
      }
    });
  }

  private toggleShowNewNoteModal(){
    this.setState({ showNewNoteModal: !this.state.showNewNoteModal});
  }

  private async saveNewNote(){
    this.setState({loading: true}, async () => {
      try{
        const students: any = [];
        for(const s of this.state.newNoteStudents){
          s.studentId = s.id;
          students.push(s);
        }
        if(this.props.userId){
          students.push({
            id: this.props.userId,
            studentId: this.props.userId
          });
        }
        await NotesAPI.createNote(this.props.schoolState.school.id, this.state.newNoteSubject, this.state.newNoteBody, students);
        this.setState({showNewNoteModal: false}, () => {
          this.fetch();
        });
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not save that note");
        });
      }
    });
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private async handleDelete(noteId: number){
    this.setState({loading: true}, async () => {
      try{
        await NotesAPI.deleteNote(this.props.schoolState.school.id, noteId);
        return this.fetch();
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not delete that note");
        });
      }
    });
  }

  private async handleUpdate(noteId: number, subject: string, body: string){
    this.setState({loading: true}, async () => {
      try{
        await NotesAPI.updateNote(this.props.schoolState.school.id, noteId, subject, body);
        return this.fetch();
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not update that note");
        });
      }
    });
  }

  private renderTypeahead() {
    return (
      <Typeahead
        key={this.state.newNoteStudents.length}
        labelKey="fullName"
        multiple={true}
        placeholder="Enter Student Name"
        onChange={this.handleSelectedStudent}
        defaultSelected={this.state.newNoteStudents}
        options={this.props.schoolState.users} />
    );
  }

  private handleSelectedStudent(e: any) {
    if (e.length === 0) {
      return;
    }
    this.setState({
      newNoteStudents: e
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotesListContainer) as React.ComponentType<any>);