import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { SchoolsAPI } from "src/API";
import { error, success } from "src/components/structure/Alert";
import StaffImage from "src/components/structure/StaffImage";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Modal } from "react-bootstrap";

interface IStaffMemberScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface IStaffMemberScreenState {
  loading: boolean;
  staff: any[];
  showNewStaffMemberForm: boolean;
  newStaffMember: any;
  selectedStaffMember: any;
  orderChanged: boolean;
  showDeleteModal: boolean;
}

interface IStaffMember {
  id: number;
  schoolId: number;
  title: string;
  firstName: string;
  lastName: string;
  description: string;
  receiveMessages: string;
}

const BlankStaffMember: IStaffMember = {
  id: 0,
  schoolId: 0,
  title: "",
  firstName: "",
  lastName: "",
  description: "",
  receiveMessages: "all"
};

class StaffMemberScreen extends React.Component<IStaffMemberScreenProps, IStaffMemberScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      staff: [],
      showNewStaffMemberForm: false,
      newStaffMember: BlankStaffMember,
      selectedStaffMember: BlankStaffMember,
      orderChanged: false,
      showDeleteModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.updateNewStaffMemberField = this.updateNewStaffMemberField.bind(this);
    this.updateSelectedStaffMemberField = this.updateSelectedStaffMemberField.bind(this);
    this.fetch = this.fetch.bind(this);
    this.showNewStaffMemberForm = this.showNewStaffMemberForm.bind(this);
    this.selectStaffMember = this.selectStaffMember.bind(this);
    this.createStaffMember = this.createStaffMember.bind(this);
    this.updateStaffMember = this.updateStaffMember.bind(this);
    this.getContents = this.getContents.bind(this);
    this.getStaffMember = this.getStaffMember.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.reorder = this.reorder.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.deleteStaffMember = this.deleteStaffMember.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="School Staff" loading={this.state.loading} help="">
              Here you can set up your school staff. These are independent entries that you can control and will be available in the Student Mobile App. If you choose not to add any
              staff members, the mobile app will display a message that the school has not configured this setting. You can specify custom data for each staff member.
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Card title="Staff Members" loading={this.state.loading} help="">
            {this.getContents()}
            {this.state.orderChanged && (
              <div className="form-group" style={{marginTop: 20}}>
                <button className="btn btn-success btn-block" onClick={this.saveOrder}>Save Order</button>
              </div>
            )}
            <div className="form-group" style={{marginTop: 20}}>
              <button className="btn btn-primary btn-block" onClick={this.showNewStaffMemberForm}>Add Staff Member</button>
            </div>
            </Card>
          </div>
          <div className="col-md-8">
            {this.state.showNewStaffMemberForm && (
              <Card title="Add Staff" loading={this.state.loading} help="">
                <div className="form-group">
                <div className="form-group">
                  <label>Title</label>
                  <input type="text" id="title" className="form-control" value={this.state.newStaffMember.title} onChange={this.updateNewStaffMemberField} />
                </div>
                  <label>First Name</label>
                  <input type="text" id="firstName" className="form-control" value={this.state.newStaffMember.firstName}  onChange={this.updateNewStaffMemberField} />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" id="lastName" className="form-control" value={this.state.newStaffMember.lastName} onChange={this.updateNewStaffMemberField}  />
                </div>
                <div className="form-group">
                  <label>Description or Background</label>
                  <textarea id="description" className="form-control" value={this.state.newStaffMember.description} onChange={this.updateNewStaffMemberField}  />
                </div>
                <div className="form-group">
                  <label>Message Setting</label>
                  <select id="receiveMessages" className="form-control" value={this.state.newStaffMember.receiveMessages} onChange={this.updateNewStaffMemberField} >
                  <option value="all">All</option>
                  <option value="q_a_only">Only Q&A</option>
                  <option value="none">None</option>
                  </select>
                </div>
                <div className="form-group">
                  <button className="btn btn-primary btn-block" onClick={this.createStaffMember}>Add Staff Member</button>
                </div>
              </Card>
            )}
            {this.state.selectedStaffMember && this.state.selectedStaffMember.id !== 0 && (
              <Card title="Update Staff" loading={this.state.loading} help="">
                <div className="form-group">
                  <label>Title</label>
                  <input type="text" id="title" className="form-control" value={this.state.selectedStaffMember.title} onChange={this.updateSelectedStaffMemberField} />
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" id="firstName" className="form-control" value={this.state.selectedStaffMember.firstName}  onChange={this.updateSelectedStaffMemberField} />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" id="lastName" className="form-control" value={this.state.selectedStaffMember.lastName} onChange={this.updateSelectedStaffMemberField}  />
                </div>
                <div className="form-group">
                  <label>Description or Background</label>
                  <textarea id="description" className="form-control" value={this.state.selectedStaffMember.description} onChange={this.updateSelectedStaffMemberField}  />
                </div>
                <div className="form-group">
                  <label>Message Setting</label>
                  <select id="receiveMessages" className="form-control" value={this.state.selectedStaffMember.receiveMessages} onChange={this.updateSelectedStaffMemberField} >
                    <option value="all">All</option>
                    <option value="q_a_only">Only Q&A</option>
                    <option value="none">None</option>
                  </select>
                </div>
                <div className="form-group">
                  <StaffImage staffId={this.state.selectedStaffMember.id} schoolId={this.props.schoolState.school.id} width={200} height={200} size="sm" imageUrl={this.state.selectedStaffMember.publicPhotoURL} />
                </div>
                <div className="form-group">
                  <button className="btn btn-primary btn-block" onClick={this.updateStaffMember}>Update Staff Member</button>
                </div>
                <div className="form-group">
                  <button className="btn btn-danger btn-block" onClick={this.toggleDeleteModal}>Delete Staff Member</button>
                </div>

                <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
                  <Modal.Header closeButton={true}>
                    <Modal.Title>Delete Staff Member</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-group">
                      <strong><p>
                        Are you absolutely sure you want to delete this staff member entry? This will only impact the Staff Member section and the user may still have access to the system elsewhere.
                      </p></strong>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-danger" onClick={this.deleteStaffMember}>Delete Staff Member</button>
                    <button className="btn" onClick={this.toggleDeleteModal}>Nevermind</button>
                  </Modal.Footer>
                </Modal>
              </Card>
            )}
          </div>
        </div>
      </div>
    );
  }

  private getContents(){
    if(this.state.staff.length === 0){
      return (<strong>No staff have been created!</strong>);
    }

    return (
      <div style={{marginBottom: 10}}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.staff.map((staff: any, index: number) => (
                  <Draggable key={staff.id} draggableId={staff.id} index={index}>
                    {(providedInner: any, snapshotInner) => (
                      <div
                        ref={providedInner.innerRef}
                        {...providedInner.draggableProps}
                        {...providedInner.dragHandleProps}
                        style={getItemStyle(
                          snapshotInner.isDragging, 
                          providedInner.draggableProps.style
                        )}
                      >
                        {this.getStaffMember(staff)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  private getStaffMember(staffMember: any){
    return (
      <div className="row" key={staffMember.id} style={{height: 36, paddingTop: 3, paddingBottom: 3}}>
        <div className="col-md-2">
          <span className="glyphicon glyphicon-align-justify" />
        </div>
        <div className="col-md-8">
          {staffMember.title} {staffMember.firstName} {staffMember.lastName}
        </div>
        <div className="col-md-2">
          <span className="glyphicon glyphicon-pencil text-primary" style={{marginRight: 10}} onClick={this.selectStaffMember.bind(null, staffMember)} />
        </div>
      </div>
    );
  }


  private async onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const staff = this.reorder(
      this.state.staff,
      result.source.index,
      result.destination.index
    );

    this.setState({
      staff,
      orderChanged: true,
    });
  }

  private reorder(list: any, startIndex: number, endIndex: number){
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }


  private updateNewStaffMemberField(e: any) {
    const ns: any = this.state.newStaffMember;
    ns[e.target.id] = e.target.value;
    this.setState({newStaffMember: ns});
  }

  private updateSelectedStaffMemberField(e: any) {
    const ns: any = this.state.selectedStaffMember;
    ns[e.target.id] = e.target.value;
    this.setState({selectedStaffMember: ns});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const schoolRes = await SchoolsAPI.getSchoolStaff(this.props.schoolState.school.id);
        this.setState({
          staff: schoolRes.body.data,
          loading: false, 
        });
      } catch(err){
        error("could not load the school settings");
        this.setState({loading: false});
      }
    });
  }

  private saveOrder(){
    this.setState({loading: true}, async () => {
      try{
        const staff = [];
        for(let i = 0; i < this.state.staff.length; i++){
          const staffMember = this.state.staff[i];
          staffMember.staffOrder = i;
          await SchoolsAPI.updateSchoolStaff(this.props.schoolState.school.id, staffMember.id, staffMember);
          // find the staff member and update the order to prevent editing confusion
          staff.push(staffMember);
        }
        this.setState({
          staff,
          orderChanged: false,
          loading: false, 
        });
      } catch(err){
        error("could not load the school settings");
        this.setState({loading: false});
      }
    });
  }

  private showNewStaffMemberForm(){
    this.setState({
      showNewStaffMemberForm: true,
      selectedStaffMember: BlankStaffMember,
    });
  }

  private selectStaffMember(staffMember: any){
    this.setState({
      showNewStaffMemberForm: false,
      selectedStaffMember: staffMember
    });
  }

  private createStaffMember(){
    const data = {
      ...this.state.newStaffMember,
      schoolId: this.props.schoolState.school.id
    };
    if(!data.firstName || data.firstName === "" || data.lastName === "" || data.title === ""){
      return error("Please fill out all fields");
    }
    this.setState({loading: true}, async () => {
      try{
        await SchoolsAPI.createSchoolStaff(this.props.schoolState.school.id, data);
        this.setState({
          showNewStaffMemberForm: false,
          newStaffMember: BlankStaffMember,
          selectedStaffMember: BlankStaffMember,
        }, () => {
          this.fetch();
        });
      }catch(err){
        error("Could not create the staff member");
      }
    });    
  }

  private updateStaffMember(){
    const data = {
      ...this.state.selectedStaffMember,
      schoolId: this.props.schoolState.school.id
    };
    if(!data.firstName || data.firstName === "" || data.lastName === "" || data.title === ""){
      return error("Please fill out all fields");
    }
    this.setState({loading: true}, async () => {
      try{
        await SchoolsAPI.updateSchoolStaff(this.props.schoolState.school.id, data.id, data);
        this.setState({
          showNewStaffMemberForm: false,
          newStaffMember: BlankStaffMember,
          selectedStaffMember: BlankStaffMember,
        }, () => {
          success("Staff member updated");
          this.fetch();
        });
      }catch(err){
        error("Could not create the staff member");
      }
    });    
  }

  private deleteStaffMember(){
    this.setState({loading: true}, async () => {
      try{
        await SchoolsAPI.deleteSchoolStaff(this.props.schoolState.school.id, this.state.selectedStaffMember.id);
        this.setState({
          showNewStaffMemberForm: false,
          newStaffMember: BlankStaffMember,
          selectedStaffMember: BlankStaffMember,
        }, () => {
          success("Staff member deleted");
          this.fetch();
        });
      }catch(err){
        error("Could not delete the staff member");
      }
    });    
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}


const paddingBase = 2;
const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "#b6bbc1" : "white",
  padding: paddingBase,
});
const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  padding: paddingBase * 2,
  margin: `0 0 ${paddingBase}px 0`,
  background: isDragging ? "#edf2f9" : "white",
  ...draggableStyle,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StaffMemberScreen) as React.ComponentType<any>);