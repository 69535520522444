import * as React from "react";

// todo: hook this into redux and read from there is belts is empty

interface IBeltSelectProps {
  selectedBelt: number;
  belts: any;
  id?: string;
  className?: string;
  onSelect: any;
}

class BeltSelect extends React.Component<IBeltSelectProps, any> {

  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <select id={this.props.id ? this.props.id : "belt"}
        className={this.props.className ? this.props.className : "form-control"}
        value={this.props.selectedBelt}
        onChange={this.props.onSelect}>
        <option value={0}>NONE</option>
        {this.props.belts.map((b: any) => {
          return (<option value={b.id} key={b.id}>{b.name}</option>);
        })}
      </select>
    );
  }

}

export default BeltSelect;