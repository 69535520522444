import * as React from "react";
import { capFirstLetter } from "../../../utils";

interface ISchoolCompetitionEventListItem {
  id: number;
  name: string;
  eventType: string;
  gender: string;
}


interface ISchoolCompetitionEventListItemProps {
  className: string;
  item: ISchoolCompetitionEventListItem;
  onSelect: any;
  onDelete: any;
}

interface ISchoolCompetitionEventListItemState {
  loading: boolean;
}

class SchoolCompetitionEventListItem extends React.Component<ISchoolCompetitionEventListItemProps, ISchoolCompetitionEventListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-4 vertical-container">
          {this.props.item.name}
        </div>
        <div className="col-md-3 vertical-container">
          {capFirstLetter(this.props.item.eventType)}
        </div>
        <div className="col-md-3 vertical-container">
          {capFirstLetter(this.props.item.gender)}
        </div>
        <div className="col-md-2 vertical-container">
          <span className="text-primary glyphicon glyphicon-edit" onClick={this.handleSelect} style={{marginRight: 5}} />
          <span className="text-danger glyphicon glyphicon-remove" onClick={this.handleDelete}  />
        </div>
      </div>
    );
  }

  private handleSelect() {
    this.props.onSelect(this.props.item);
  }

  private handleDelete(){
    this.props.onDelete(this.props.item);
  }
}

export default SchoolCompetitionEventListItem;