import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import { format } from "../../../utils/currency";
import Card from "../../structure/Card";
import List from "../../structure/List";
import DatePicker from "../../structure/DatePicker";
import * as AppActions from "../../../reducers/appReducer";
import { FinancesAPI } from "../../../API/";
import { error } from "../../structure/Alert";
import Invoice from "./Invoice";

interface ISchoolInvoicesScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
  userId?: number;
  interval: number;
}

interface ISchoolInvoicesScreenState {
  loading: boolean;
  start: moment.Moment;
  end: moment.Moment;
  showStatus: string;
  invoices: any;
  filteredInvoices: any;
  sortField: "due" | "assigned" | "amount" | "status" | "type" | "willCharge";
  sortDir: "asc" | "desc";
  selectedInvoice: any;
  showingInvoice: boolean;
  selectedStudent: any;
}

class SchoolInvoicesScreen extends React.Component<ISchoolInvoicesScreenProps, ISchoolInvoicesScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      start: moment().subtract(2, "months"),
      end: moment().add(2, "months"),
      showStatus: "pending",
      invoices: [],
      filteredInvoices: [],
      sortField: "due",
      sortDir: "asc",
      selectedInvoice: { shouldCharge: "yes" },
      showingInvoice: false,
      selectedStudent: []
    };

    this.updateField = this.updateField.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
    this.fetchInvoices = this.fetchInvoices.bind(this);
    this.viewInvoice = this.viewInvoice.bind(this);
    this.toggleInvoice = this.toggleInvoice.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.newInvoice = this.newInvoice.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.getSelectedStudent = this.getSelectedStudent.bind(this);

    this.sort = this.sort.bind(this);
    this.sortDue = this.sortDue.bind(this);
    this.sortAssigned = this.sortAssigned.bind(this);
    this.sortAmount = this.sortAmount.bind(this);
    this.sortStatus = this.sortStatus.bind(this);
    this.sortWillCharge = this.sortWillCharge.bind(this);
  }

  public componentDidMount() {
    const student = this.getSelectedStudent();
    this.setState({ selectedStudent: student }, () => {
      this.fetchInvoices();
    });
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Card title="Invoices" loading={this.state.loading} help="Invoices represent a combination of contract-connected charges (such as for tuition) and one-off payments (such as for gear or private lessons). They are typically charged automatically each evening, although they may also be set to not automatically charge (such as if the student wishes to pay in person).">

            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label> Due Between</label>
                  <DatePicker date={this.state.start} onDateSaved={this.handleStartDateChanged} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label> And</label>
                  <DatePicker date={this.state.end} onDateSaved={this.handleEndDateChanged} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Show With Status: </label>
                  <select className="form-control" id="showStatus" value={this.state.showStatus} onChange={this.updateField}>
                    <option value="all">Pending and Paid</option>
                    <option value="pending">Just Pending and Overdue</option>
                    <option value="paid">Just Paid</option>
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <button className="btn btn-success btn-block" style={{ marginTop: 25 }} onClick={this.newInvoice}>
                  <i className="glyphicon glyphicon-plus" /> New Invoice
                  </button>
              </div>
            </div>

            <div className="row" style={{ marginTop: 15, paddingBottom: 15, borderBottom: "thin solid gray" }}>
              <div className="col-md-2">
                <span className="row-header-sortable" onClick={this.sortDue}>Due</span>
                {this.state.sortField === "due" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                {this.state.sortField === "due" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
              </div>
              <div className="col-md-2">
                <span className="row-header-sortable" onClick={this.sortAssigned}>Assigned To</span>
                {this.state.sortField === "assigned" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                {this.state.sortField === "assigned" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
              </div>
              <div className="col-md-1">
                <span className="row-header-sortable" onClick={this.sortAmount}>Amount</span>
                {this.state.sortField === "amount" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                {this.state.sortField === "amount" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
              </div>
              <div className="col-md-2">
                <span className="row-header-sortable" onClick={this.sortStatus}>Status</span>
                {this.state.sortField === "status" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                {this.state.sortField === "status" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
              </div>
              <div className="col-md-2">
                <span className="row-header">Type</span>
              </div>
              <div className="col-md-2">
                <span className="row-header-sortable" onClick={this.sortWillCharge}>Will Charge</span>
                {this.state.sortField === "willCharge" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                {this.state.sortField === "willCharge" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
              </div>
              <div className="col-md-1" />
            </div>
            <List
              data={this.state.filteredInvoices}
              renderItem={this.renderItem}
              showTotal={true}
            />
          </Card>
        </div>
        <Invoice
          // key={this.state.selectedInvoice.id}
          schoolId={this.props.schoolState.school.id}
          show={this.state.showingInvoice}
          invoice={this.state.selectedInvoice}
          invoiceId={this.state.selectedInvoice.id ? this.state.selectedInvoice.id : 0}
          onClose={this.toggleInvoice}
          onDelete={this.handleDelete}
          students={this.props.schoolState.users}
          onSave={this.handleSave}
          selectedStudent={this.state.selectedStudent} />
      </div>
    );
  }

  private async fetchInvoices() {
    this.setState({ loading: true }, async () => {
      try {
        const st = this.state.start.format("YYYY-MM-DDT00:00:00");
        const end = this.state.end.format("YYYY-MM-DDT00:00:00");
        const status = this.state.showStatus === "all" ? "" : this.state.showStatus;
        let res = null;
        if (this.props.userId && this.props.userId !== 0) {
          res = await FinancesAPI.getUserInvoices(this.props.schoolState.school.id, this.props.userId, st, end, status);
        } else {
          res = await FinancesAPI.getSchoolInvoices(this.props.schoolState.school.id, st, end, status);
        }
        let invoices = res.body.data;
        // now sort
        invoices = invoices.sort((a: any, b: any) => {
          return a.due < b.due ? -1 : 1;
        });
        this.setState({ invoices }, () => {
          this.sort(this.state.sortField, this.state.sortDir);
        });
      } catch (e) {
        error("Could not load invoices");
        this.setState({ loading: false });
      }
    });
  }

  private renderItem(index: number) {
    const invoice = this.state.filteredInvoices[index];
    let classes = "row list-row school-users-row ";
    if (index % 2 !== 0) {
      classes += "list-row-odd";
    }
    const due = moment(invoice.due);
    let statusDisplay = invoice.status;
    let statusClass = "";
    if (invoice.status === "paid") {
      statusClass = "col-md-2 cell-text-success";
      statusDisplay = "Paid";
    } else {
      if (due.isAfter(moment())) {
        statusClass = "col-md-2";
        statusDisplay = "Pending";
      } else {
        // find out HOW over due it is
        const overdueDays = due.diff(moment(), "days");
        if(overdueDays === 0){
          statusClass = "col-md-2";
          statusDisplay = "Due Today";
        } else if (overdueDays < -7) {
          statusClass = "col-md-2 cell-text-danger-dark";
          statusDisplay = "Very Overdue";
        } else {
          statusClass = "col-md-2 cell-text-danger";
          statusDisplay = "Overdue";
        }
      }
    }
    return (
      <div className={classes} key={invoice.id}>
        <div className="col-md-2">{due.format("MM/DD/YYYY")}</div>
        <div className="col-md-2">
          <Link to={`/schools/${this.props.schoolState.school.id}/users/${invoice.userId}`}>{invoice.firstName} {invoice.lastName}</Link>
        </div>
        <div className="col-md-1">{format(invoice.amount)}</div>
        <div className={statusClass}>{statusDisplay}</div>
        <div className="col-md-2">
          {invoice.invoiceType === "single" ? "One Time" : "Contract"}
        </div>
        <div className="col-md-2">
          {invoice.shouldCharge ? (<span>Automatically</span>) : (<span style={{ fontWeight: "bold", color: "darkRed" }}>NO</span>)}
        </div>
        <div className="col-md-1">
          <button className="btn btn-primary" onClick={this.viewInvoice} value={invoice.id}>View</button>
        </div>
      </div>
    );
  }


  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns, () => {
      this.fetchInvoices();
    });
  }

  private sort(sortField: "due" | "assigned" | "amount" | "status" | "type" | "willCharge", sortDir: "asc" | "desc") {
    let invoices = this.state.invoices;
    // first filter
    invoices = invoices.filter((user: any) => {
      // TODO: implement filters
      return true;
    });
    this.setState({ loading: true }, () => {

      // sort based upon the entered
      // this is a giant block; I do the if on the sort outside of the sort method in case there's a lot of invoices; do as few conditionals inside
      // as possible
      if (sortField === "due") {
        invoices = invoices.sort((a: any, b: any) => {
          const aDue = moment(a.due);
          const bDue = moment(b.due);
          if (sortDir === "asc") {
            return aDue.isBefore(bDue) ? -1 : 1;
          } else {
            return aDue.isBefore(bDue) ? 1 : -1;
          }
        });
      } else if (sortField === "assigned") {
        invoices = invoices.sort((a: any, b: any) => {
          if (sortDir === "asc") {
            return a.lastName > b.lastName ? 1 : -1;
          } else {
            return a.lastName > b.lastName ? -1 : 1;
          }
        });
      } else if (sortField === "amount") {
        invoices = invoices.sort((a: any, b: any) => {
          if (sortDir === "asc") {
            return a.amount > b.amount ? 1 : -1;
          } else {
            return a.amount > b.amount ? -1 : 1;
          }
        });
      } else if (sortField === "status") {
        invoices = invoices.sort((a: any, b: any) => {
          if (sortDir === "asc") {
            return a.status > b.status ? 1 : -1;
          } else {
            return a.status > b.status ? -1 : 1;
          }
        });
      } else if (sortField === "type") {
        invoices = invoices.sort((a: any, b: any) => {
          if (sortDir === "asc") {
            return a.invoiceType > b.invoiceType ? 1 : -1;
          } else {
            return a.invoiceType > b.invoiceType ? -1 : 1;
          }
        });

      } else if (sortField === "willCharge") {
        invoices = invoices.sort((a: any, b: any) => {
          if (sortDir === "asc") {
            return a.shouldCharge === b.shouldCharge ? 0 : a.shouldCharge && !b.shouldCharge ? 1 : -1;
          } else {
            return a.shouldCharge === b.shouldCharge ? 0 : a.shouldCharge && !b.shouldCharge ? -1 : 1;
          }
        });
      }

      this.setState({ filteredInvoices: invoices, sortDir, sortField, loading: false });
    });
  }

  private sortDue() {
    const dir = this.state.sortField === "due" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("due", dir);
  }

  private sortAssigned() {
    const dir = this.state.sortField === "assigned" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("assigned", dir);
  }

  private sortAmount() {
    const dir = this.state.sortField === "amount" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("amount", dir);
  }

  private sortStatus() {
    const dir = this.state.sortField === "status" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("status", dir);
  }

  private sortWillCharge() {
    const dir = this.state.sortField === "willCharge" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("willCharge", dir);
  }

  private handleStartDateChanged(newDate: moment.Moment) {
    this.setState({
      start: newDate
    }, () => {
      this.fetchInvoices();
    });
  }

  private handleEndDateChanged(newDate: moment.Moment) {
    this.setState({
      end: newDate
    }, () => {
      this.fetchInvoices();
    });
  }

  private viewInvoice(e: any) {
    let selected = this.state.selectedInvoice;
    const target = parseInt(e.target.value, 10);
    for (const i of this.state.invoices) {
      if (i.id === target) {
        selected = i;
      }
    }
    this.setState({ selectedInvoice: selected, showingInvoice: true });
  }

  private newInvoice() {
    this.setState({ selectedInvoice: { id: 0, shouldCharge: "yes" }, showingInvoice: true });
  }

  private toggleInvoice() {
    this.setState({
      showingInvoice: !this.state.showingInvoice
    });
  }

  private handleSave() {
    this.setState({
      showingInvoice: false,
      selectedInvoice: {}
    }, () => {
      this.fetchInvoices();
    });
  }

  private handleDelete() {
    this.setState({
      showingInvoice: false,
      selectedInvoice: {}
    }, () => {
      this.fetchInvoices();
    });
  }

  private getSelectedStudent() {
    // TODO: this should probably moved into a set state lifecycle method for performance reasons
    if (!this.props.userId || this.props.userId === 0) {
      return [{ id: 0, fullName: "" }];
    }
    let s: any = [];
    const target = typeof this.props.userId === "string" ? parseInt(this.props.userId, 10) : this.props.userId;
    for (const student of this.props.schoolState.users) {
      if (student.id === target) {
        s = [student];
        break;
      }
    }
    return s;
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolInvoicesScreen) as React.ComponentType<any>);