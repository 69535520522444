import { makeCall } from "./client";

export class DocumentsAPI {

  public getDocumentCategories(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/documents/categories`, params);
  }

  public createDocumentCategory(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/documents/categories`, params);
  }

  public updateDocumentCategory(schoolId: number, categoryId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/documents/categories/${categoryId}`, params);
  }

  public deleteDocumentCategory(schoolId: number, categoryId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/documents/categories/${categoryId}`, params);
  }


  public getSchoolDocuments(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/documents`, params);
  }
  
  public getUserDocuments(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/documents`, params);
  }
  
  public getDocument(schoolId: number, documentId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/documents/${documentId}`, {}, params);
  }
  
  public updateDocument(schoolId: number, documentId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/documents/${documentId}`, params);
  }
  
  public deleteDocument(schoolId: number, documentId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/documents/${documentId}`, params);
  }
  
  public uploadDocument(schoolId: number, categoryId: number, file: any): Promise<any> {
    return makeCall("post", `schools/${schoolId}/documents/categories/${categoryId}`, file, {asMultipartFile: true});
  }
  
  public createDocument(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/users/${userId}/documents`, params);
  }
  
}