import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import { ScheduleAPI } from "../../../../API";
import Card from "../../../structure/Card";
import DatePicker from "../../../structure/DatePicker";
import {error} from "../../../structure/Alert";
import * as AppActions from "../../../../reducers/appReducer";
import UserAttendanceItem from "./UserAttendanceItem";
import LazyLoad from "react-lazyload";

interface IUserAttendanceProps {
  appActions: any;
  history: any;
  schoolId: number;
  userId: number;
  eventTypes: any;
}

interface IUserAttendanceState {
  loading: boolean;
  attendance: any;
  selectedEventType: number;
  start: moment.Moment;
  end: moment.Moment;
}

class UserAttendance extends React.Component<IUserAttendanceProps, IUserAttendanceState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      attendance: [],
      selectedEventType: 0,
      start: moment().subtract(30, "days"),
      end: moment(),
    };

    this.fetch = this.fetch.bind(this);
    this.updateField = this.updateField.bind(this);
    this.handleStartChanged = this.handleStartChanged.bind(this);
    this.handleEndChanged = this.handleEndChanged.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <Card title="Attendance" loading={this.state.loading} help="User attendance can be tracked either in the Schedule portion of this app or in the separate KDojang Admin mobile application, designed for tablets to be used for users to sign in before classes.">
        <div className="row">
          <div className="col-md-12">
            <label>Event Type</label>
            <select className="form-control" id="selectedEventType" value={this.state.selectedEventType} onChange={this.updateField}>
              <option value={0}>Showing All</option>
              {this.props.eventTypes.map((e: any) => {
                return (<option key={e.id} value={e.id}>{e.eventType}</option>);
              })}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>From</label>
            <DatePicker date={this.state.start} onDateSaved={this.handleStartChanged} />
          </div>
          <div className="col-md-6">
            <label>To</label>
            <DatePicker date={this.state.end} onDateSaved={this.handleEndChanged} />
          </div>
        </div>

        <div className="row" style={{ marginBottom: 10, marginTop: 10 }}>
          <div className="col-md-3 vertical-container">
            <strong>Date</strong>
          </div>
          <div className="col-md-4 vertical-container">
            <strong>Name</strong>
          </div>
          <div className="col-md-3 vertical-container">
            <strong>Event Type</strong>
          </div>
          <div className="col-md-2 vertical-container" />
        </div>
        <div className="note-overflow-container" style={{height: 300}}>
          {this.state.attendance.map((a: any, index: number) => {
            return (
              <LazyLoad once={true} key={index} overflow={true} throttle={100}>
                <UserAttendanceItem
                  key={a.id}
                  item={a}
                  userId={this.props.userId}
                  className={index % 2 === 1 ? "list-row-odd" : ""}
                  onDelete={this.handleDelete} />
                </LazyLoad>
            );
          })}
        </div>

        <div className="row">
          <div className="col-md-3 col-md-offset-9">
            <strong>Total: {this.state.attendance.length}</strong>
          </div>
        </div>
      </Card>
    );
  }


  private async fetch(){
    this.setState({loading: true}, async () => {
      try{
        const params: any = {
          start: this.state.start.format("YYYY-MM-DD"),
          end: this.state.end.format("YYYY-MM-DD")
        };
        if(this.state.selectedEventType !== 0){
          params.eventType = this.state.selectedEventType;
        }
        const res = await ScheduleAPI.getUserAttendance(this.props.schoolId, this.props.userId, params);
        this.setState({loading: false, attendance: res.body.data});
      }catch(err){
        this.setState({loading: false}, () => {
          return error("Could not load attendance");
        });
      }
    });
  }

  private handleDelete(item: any){
    const attendance: any = [];
    for(const a of this.state.attendance){
      if(a.id !== item.id){
        attendance.push(a);
      }
    }
    this.setState({attendance});
  }

  private updateField(e: any) {
    const ns: any = this.state;
    const v = e.target.id === "selectedEventType" ? parseInt(e.target.value, 10) :  e.target.value;
    ns[e.target.id] = v;
    this.setState(ns, () => { this.fetch();});
  }

  private handleStartChanged(newDate: moment.Moment) {
    const ns: any = this.state;
    ns.start = newDate;
    this.setState(ns, () => { this.fetch();});
  }
  
  private handleEndChanged(newDate: moment.Moment) {
    const ns: any = this.state;
    ns.end = newDate;
    this.setState(ns, () => { this.fetch();});
  }

}



const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAttendance) as React.ComponentType<any>);