import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import EquipmentListItem from "./EquipmentListItem";

import Card from "../../structure/Card";
import { success, error } from "../../structure/Alert";
import { EquipmentAPI } from "../../../API";
import * as AppActions from "../../../reducers/appReducer";

interface IEquipmentScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface IEquipmentScreenState {
  loading: boolean;
  selectedCategory: "all" | "other" | "uniform" | "belt" | "sparring" | "merchandise";
  selectedEquipmentId: number;
  equipment: any;
  filteredEquipment: any;
  newEquipmentName: string;
  newEquipmentCategory: string;
  newEquipmentBarcode: string;
  newEquipmentCurrentInventory: number;
  newEquipmentSize: string;
  newEquipmentSalePrice: number;
  showNewModal: boolean;
}

class EquipmentScreen extends React.Component<IEquipmentScreenProps, IEquipmentScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showNewModal: false,
      selectedCategory: "all",
      selectedEquipmentId: 0,
      equipment: [],
      filteredEquipment: [],
      newEquipmentName: "",
      newEquipmentCategory: "other",
      newEquipmentBarcode: "",
      newEquipmentCurrentInventory: 0,
      newEquipmentSize: "",
      newEquipmentSalePrice: 0,
    };

    this.updateField = this.updateField.bind(this);
    this.fetchEquipment = this.fetchEquipment.bind(this);
    this.filterEquipment = this.filterEquipment.bind(this);
    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.createNewEquipment = this.createNewEquipment.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  public componentDidMount(){
    this.fetchEquipment();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Card title="School Equipment" loading={this.state.loading} help="You may optionally track equipment inventory for your school. If you do so, you may also assign equipment to a student so that you know what size equipment that student currently uses.">
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-md-6">
                <label>Showing</label>
                <select id="category" className="form-control" value={this.state.selectedCategory} onChange={this.filterEquipment}>
                  <option value="all">All</option>
                  <option value="belt">Belts</option>
                  <option value="merchandise">Merchandise</option>
                  <option value="sparring">Sparring</option>
                  <option value="uniform">Uniform</option>
                  <option value="other">other</option>
                </select>
              </div>
              <div className="col-md-2 col-md-offset-4">
                <button onClick={this.toggleNewModal} className="btn btn-block btn-success" style={{marginTop: 20}}>Add New Inventory Item</button>
              </div>
            </div>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-md-3 vertical-container">
                <strong>Name</strong>
              </div>
              <div className="col-md-2 vertical-container">
                <strong>Category</strong>
              </div>
              <div className="col-md-1 vertical-container">
                <strong>Size</strong>
              </div>
              <div className="col-md-1 vertical-container">
                <strong>Current Inventory</strong>
              </div>
              <div className="col-md-2 vertical-container">
                <strong>Last Updated</strong>
              </div>
              <div className="col-md-2 vertical-container">
                <strong>Sale Price</strong>
              </div>
            </div>
            {this.state.filteredEquipment.map((e: any) => {
              return (
                <EquipmentListItem
                  key={e.id}
                  className=""
                  onDelete={this.handleDelete}
                  onUpdate={this.handleUpdate}
                  item={e} />
              );
            })}
          </Card>
        </div>
        <Modal show={this.state.showNewModal} onHide={this.toggleNewModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Add New Inventory Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Category</label>
              <select id="newEquipmentCategory" className="form-control" value={this.state.newEquipmentCategory} onChange={this.updateField}>  
                  <option value="belt">Belts</option>
                  <option value="merchandise">Merchandise</option>
                  <option value="sparring">Sparring</option>
                  <option value="uniform">Uniform</option>
                  <option value="other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label>Name</label>
              <input type="text" id="newEquipmentName" className="form-control" value={this.state.newEquipmentName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Barcode</label>
              <input type="text" id="newEquipmentBarcode" className="form-control" value={this.state.newEquipmentBarcode} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Equipment Size</label>
              <input type="text" id="newEquipmentSize" className="form-control" value={this.state.newEquipmentSize} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Sale Price</label>
              <input type="number" id="newEquipmentSalePrice" className="form-control" value={this.state.newEquipmentSalePrice} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Current On-Hand Inventory</label>
              <input type="number" id="newEquipmentCurrentInventory" className="form-control" value={this.state.newEquipmentCurrentInventory} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.createNewEquipment}>Create</button>
            <button className="btn" onClick={this.toggleNewModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    const id = e.target.id;
    let value = e.target.value;
    if(id === "newEquipmentCurrentInventory"){
      value = parseInt(value, 10);
    }
    if(id === "newEquipmentSalePrice"){
      value = parseFloat(value);
    }
    ns[id] = value;
    this.setState(ns);
  }

  private async fetchEquipment(){
    this.setState({loading: true}, async () => {
      try{
        const results = await EquipmentAPI.getSchoolEquipment(this.props.schoolState.school.id, "");
        this.setState({ loading: false, selectedCategory: "all", equipment: results.body.data, filteredEquipment: results.body.data});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private filterEquipment(e: any){
    const newCategory = e.target.value;
    let filteredEquipment: any = [];
    if(newCategory === "all"){
      filteredEquipment = this.state.equipment;
    } else {
      for(const eq of this.state.equipment){
        if(eq.category === newCategory){
          filteredEquipment.push(eq);
        }
      }
    }
    this.setState({filteredEquipment, selectedCategory: newCategory});
  }

  private toggleNewModal(){
    this.setState({showNewModal: !this.state.showNewModal});
  }

  private createNewEquipment(){
    this.setState({loading: true}, async () => {
      try{
        const data = {
          name: this.state.newEquipmentName,
          barcode: this.state.newEquipmentBarcode,
          category: this.state.newEquipmentCategory,
          currentInventory: this.state.newEquipmentCurrentInventory,
          salePrice: Math.round(this.state.newEquipmentSalePrice * 100),
          size: this.state.newEquipmentSize,
        };
        if(data.name === "" || data.category === ""){
          return error("Name and Category are required");
        }
        await EquipmentAPI.createSchoolEquipment(this.props.schoolState.school.id, data);
        success("Equipment created!");
        this.setState({
          newEquipmentName: "",
          newEquipmentCategory: "other",
          newEquipmentBarcode: "",
          newEquipmentCurrentInventory: 0,
          newEquipmentSize: "",
          newEquipmentSalePrice: 0,
          showNewModal: false,
        }, () => {
          this.fetchEquipment();
        });
      }catch(err){
        error("Could not create that equipment inventory item");
        this.setState({loading: false, showNewModal: false});
      }
    });
  }

  private handleDelete(deletedItem: any){
    const equipment: any = [];
    for(const eq of this.state.equipment){
      if(eq.id !== deletedItem.id){
        equipment.push(eq);
      }
    }
    this.setState({equipment}, () => {
      this.filterEquipment({
        target:{
          value: this.state.selectedCategory
        }
      });
    });
  }

  private handleUpdate(updatedItem: any){
    const equipment: any = [];
    for(let eq of this.state.equipment){
      if(eq.id === updatedItem.id){
        eq = updatedItem;
      }
      equipment.push(eq);
    }
    this.setState({equipment}, () => {
      this.filterEquipment({
        target:{
          value: this.state.selectedCategory
        }
      });
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentScreen) as React.ComponentType<any>);