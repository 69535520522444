import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Collapse } from "react-bootstrap";
import * as moment from "moment";


interface INoteProps {
  appActions: any;
  history: any;
  body: string;
  subject: string;
  id: number;
  users: any;
  archived: boolean;
  updated: string;
  display: "card" | "list";
  className: string;
  onUserAdded: any;
  onUserRemoved: any;
  onDelete: any;
  onUpdate: any;
  onStatusChange: any;
}

interface INoteState {
  loading: boolean;
  mode: "view" | "edit";
  showDeleteConfirm: boolean;
  // we keep them as state for editing
  subject: string;
  body: string;
  users: any;
  expanded: boolean;
}

class Note extends React.Component<INoteProps, INoteState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      mode: "view",
      showDeleteConfirm: false,
      subject: "",
      body: "",
      users: [],
      expanded: false
    };
    this.changeStatus = this.changeStatus.bind(this);
    this.delete = this.delete.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.save = this.save.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  public componentDidMount() {
    this.setState({
      subject: this.props.subject,
      body: this.props.body,
      users: this.props.users
    });
  }

  public render() {
    return (
      <div>
        {this.noteDisplay}
        <Modal show={this.state.showDeleteConfirm} onHide={this.toggleDelete} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-danger">This cannot be undone! Are you sure you want to delete "{this.props.subject}"?</h4>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.delete}>Yes, Delete This Note</button>
            <button className="btn btn-default" onClick={this.toggleDelete}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.mode === "edit"} onHide={this.toggleEdit} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Subject</label>
              <input type="text" id="subject" className="form-control" value={this.state.subject} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea id="body" className="form-control" value={this.state.body} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.save}>Save</button>
            <button className="btn btn-default" onClick={this.toggleEdit}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private get noteDisplay() {
    if (this.props.display === "card") {
      return (
        <div className={"note-card " + this.props.className} key={this.props.id}>
          <div className="row">
            <div className="col-md-11">
              <div className="note-card-users">{this.usersList}</div>
              <div className="note-card-subject">{this.state.subject}</div>
              <div className="row" style={{marginBottom: 5}}>
                <div className="col-md-12">
                  <div className="note-card-updated">{this.updated}</div>
                </div>
              </div>
              <div className="note-card-body">
                {this.props.body}
              </div>
              <div className="note-card-status">{this.statusDisplay}</div>
            </div>
            <div className="col-md-1">
              <div className="row">
                <div className="col-md-12">
                  <span className="text-success glyphicon glyphicon-pencil" onClick={this.toggleEdit} />
                </div>
              </div>
              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-md-12">
                  <span className="text-danger glyphicon glyphicon-trash" onClick={this.toggleDelete} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={"list-row " + this.props.className}>
        <div className="row">
          <div className="col-md-1 vertical-container">
            <span className={this.state.expanded ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus"} onClick={this.toggleExpanded} />
          </div>
          <div className="col-md-2 vertical-container">
            <div style={{ paddingTop: 7 }}>{this.updated}</div>
          </div>
          <div className="col-md-2 vertical-container">
            <div style={{ paddingTop: 7 }}>{this.usersList}</div>
          </div>
          <div className="col-md-2 vertical-container">
            <div style={{ paddingTop: 7 }}>{this.props.subject}</div>
          </div>
          <div className="col-md-2 vertical-container">
            {this.statusDisplay}
          </div>
          <div className="col-md-1 vertical-container">
            <span className="text-success glyphicon glyphicon-pencil" onClick={this.toggleEdit} />
          </div>
          <div className="col-md-1 vertical-container">
            <span className="text-danger glyphicon glyphicon-trash" onClick={this.toggleDelete} />
          </div>
        </div>
        <Collapse in={this.state.expanded}>
          <div className="row">
            <div className="col-md-12 vertical-container">
              <span>{this.props.body}</span>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private get usersList() {
    const users = [];
    for (let i = 0; i < this.state.users.length; i++) {
      const u = this.state.users[i];
      users.push(<span key={this.props.id + "-" + u.studentId}>{i > 0 ? ", " : ""}{u.firstName ? u.firstName : ""} {u.lastName ? u.lastName : ""}</span>);
    }
    return users;
  }

  private get statusDisplay() {
    return (
      <select className="form-control" value={this.props.archived ? "archived" : "open"} onChange={this.changeStatus}>
        <option value="open">Open</option>
        <option value="archived">Archived</option>
      </select>
    );
  }

  private get updated() {
    const d = moment(this.props.updated).format("MM/DD/YY");
    return (
      <span>{d}</span>
    );
  }

  private changeStatus() {
    this.props.onStatusChange(this.props.id, !this.props.archived);
  }

  private delete() {
    this.props.onDelete(this.props.id);
    this.setState({ showDeleteConfirm: false });
  }

  private toggleDelete() {
    this.setState({ showDeleteConfirm: !this.state.showDeleteConfirm });
  }

  private toggleEdit() {
    this.setState({
      mode: this.state.mode === "edit" ? "view" : "edit"
    });
  }

  private save() {
    this.setState({ mode: "view" }, () => {
      this.props.onUpdate(this.props.id, this.state.subject, this.state.body);
    });
  }

  private toggleExpanded() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {};
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Note) as React.ComponentType<any>);