import * as React from "react";
import * as moment from "moment";
import DatePicker from "../../structure/DatePicker";
import StateSelect from "../../structure/StateSelect";

interface INewUserFormProps {
  onChange: any;
  user: any;
  role: string;
  onSave: any;
}

interface INewUserFormState {
  loading: boolean;
}

class NewUserForm extends React.Component<INewUserFormProps, INewUserFormState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
    };
    this.updateField = this.updateField.bind(this);
    this.handleDOBChanged = this.handleDOBChanged.bind(this);
  }

  public render() {
    return (
      <div>
        <div className="form-group">
          <label>First Name</label>
          <input type="text" id="firstName" className="form-control" value={this.props.user.firstName} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input type="text" id="lastName" className="form-control" value={this.props.user.lastName} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" id="emailAddress" className="form-control" value={this.props.user.emailAddress} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Role</label>
          <select className="form-control" value={this.props.user.role} onChange={this.updateField} id="role">
            <option value="student">Student</option>
            <option value="user">Non-Student User (such as Guardian)</option>
          </select>
        </div>
        <div className="form-group">
          <label>Date of Birth</label>
          <DatePicker date={this.props.user.dob} onDateSaved={this.handleDOBChanged} />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input type="tel" id="phone" className="form-control" value={this.props.user.phone} onChange={this.updateField} />
        </div>
        {this.props.role === "primary" ? 
          (<div>
            <div className="form-group">
              <label>Street Address</label>
              <input type="text" id="addressStreet" className="form-control" value={this.props.user.addressStreet} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-4">
                  <label>City</label>
                  <input type="text" id="addressCity" className="form-control" value={this.props.user.addressCity} onChange={this.updateField} />
                </div>
                <div className="col-md-4">
                  <label>State</label>
                  <StateSelect id="addressState" selectedState={this.props.user.addressState} onSelect={this.updateField} />
                </div>
                <div className="col-md-4">
                  <label>Zip</label>
                  <input type="text" id="addressZip" className="form-control" value={this.props.user.addressZip} onChange={this.updateField} />
                </div>
              </div>
            </div>
          </div>) : 
          (null) }
          <div className="form-group">
           <button className="btn btn-block btn-success" onClick={this.props.onSave}>Create User</button>
          </div>
      </div>
    );
  }

  private updateField(e:any){
    this.props.onChange(e.target.id, e.target.value);
  }

  private handleDOBChanged(newDate: moment.Moment) {
    this.props.onChange("dob", newDate);
  }

}

export default NewUserForm;