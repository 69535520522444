import * as React from "react";

import Card from "../../structure/Card";

interface UpdateStepByNumberPayload {
  otherId: number;
  setupType: string;
  stepNumber: number;
  stepKey: string;
  stepTitle: string;
  stepDescription: string;
  status: "pending" | "completed" | "skipped";
  statusDate: string;
}

interface ISetupWizardStepProps {
  step: UpdateStepByNumberPayload;
  onStatusChange: any;
  onShowMe: any;
}

class SetupWizardStep extends React.Component<ISetupWizardStepProps, any> {

  constructor(props: any){
    super(props);
    this.generateButtons = this.generateButtons.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleShowMe = this.handleShowMe.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  public render() {
    let style = "info";
    if(this.props.step.status === "completed"){
      style = "success";
    } else if(this.props.step.status === "skipped"){
      style = "warning";
    }
    const stepStatus = this.props.step.status[0].toUpperCase() + this.props.step.status.substr(1);
    return (
      <Card title={`Step ${this.props.step.stepNumber}: ${this.props.step.stepTitle} - ${stepStatus}`} 
        loading={false} 
        bsStyle={style}
        key={this.props.step.stepNumber}
        canToggle={false}
        defaultOpen={true}>
        <p>{`${this.props.step.stepDescription}`}</p>
        <div className="row">
          <div className="col-md-4">
            <button className="btn btn-block btn-success" onClick={this.handleShowMe}>Show Me</button>
          </div>
          {this.generateButtons()}
        </div>
      </Card>
    );
  }

  private generateButtons(){
    const buttons: any = [];
    if(this.props.step.status === "pending"){
      // they can skip and they can complete
      buttons.push(<div className="col-md-4" key={0}><button className="btn btn-block btn-primary" onClick={this.handleComplete}>Mark as Complete</button></div>);
      buttons.push(<div className="col-md-4" key={1}><button className="btn btn-block btn-primary" onClick={this.handleSkip}>Skip</button></div>);
    } else {
      buttons.push(<div className="col-md-4" key={0}><button className="btn btn-block btn-danger" onClick={this.handleReset}>Reset</button></div>);
    } 
    return buttons;
  }

  private handleShowMe(){
    this.props.onShowMe(this.props.step);
  }

  private handleComplete(){
    this.handleStatusChange("completed");
  }

  private handleReset(){
    this.handleStatusChange("pending");
  }

  private handleSkip(){
    this.handleStatusChange("skipped");
  }

  private handleStatusChange(newStatus: string){
    this.props.onStatusChange(this.props.step, newStatus);
  }

}

export default SetupWizardStep;