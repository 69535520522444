import * as React from "react";
import * as moment from "moment";

interface Item {
  calculatedScore: number;
  criteria: any;
  examDate: string;
  examName: string;
  examTemplateId: number;
  id: number;
  privacy: string;
  schoolId: number;
  status: string;
  studentFirstName: string;
  studentId: number;
  studentLastName: string;
  studentNickname: string;
}

interface IExamListItemProps {
  onSelect: any;
  onStatusChanged: any;
  item: Item;
  className: string;
  showScore: boolean;
}

interface IExamListItemState {
  loading: boolean;
}

export default class ExamListItem extends React.Component<IExamListItemProps, IExamListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleStatusChanged = this.handleStatusChanged.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{marginBottom: 5, cursor: "pointer"}} onClick={this.handleSelect}>
        <div className="col-md-5" style={{marginTop: 5}}>
          {this.props.item.studentFirstName} {this.props.item.studentNickname && this.props.item.studentNickname !== "" && `(${this.props.item.studentNickname})`} {this.props.item.studentLastName}
        </div>
        <div className="col-md-3" style={{marginTop: 5}}>
          {moment(this.props.item.examDate).format("MM/DD/YY")}
        </div>
        <div className="col-md-2" style={{marginTop: 5}}>
          {this.props.showScore ? this.props.item.calculatedScore : "--"}
        </div>
        <div className="col-md-2" style={{marginTop: 5}}>
          {this.props.item.status === "pending" && (<span className="glyphicon glyphicon-pencil" />)}
        </div>
      </div>
    );
  }

  private handleSelect(){
    this.props.onSelect(this.props.item);
  }

  private handleStatusChanged(e: any){
    const item = this.props.item;
    item.status = e.target.value;
    this.props.onStatusChanged(item);
  }

}