import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as queryString from "query-string";

import { FinancesAPI, SchoolsAPI } from "../../../API";
import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";
import * as SchoolActions from "../../../reducers/schoolReducer";

interface ISchoolPayoutScreenProps {
  schoolActions: any;
  schoolState: any;
  history: any;
  location: any;
}

interface ISchoolPayoutScreenState {
  loading: boolean;
  connectURL: string;
  payoutMethod: "check" | "bank" | "none" | "stripe";
  payoutFrequency: "daily" | "weekly" | "monthly";
  school: any;
  step: "setup" | "oauth_verify";
}

class SchoolPayoutScreen extends React.Component<ISchoolPayoutScreenProps, ISchoolPayoutScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      connectURL: "",
      payoutMethod: "check",
      payoutFrequency: "monthly",
      school: {},
      step: "setup"
    };

    this.updateField = this.updateField.bind(this);
    this.savePayoutMethod = this.savePayoutMethod.bind(this);
    this.getSchoolInformation = this.getSchoolInformation.bind(this);
    this.getConnectURL = this.getConnectURL.bind(this);
    this.getPaymentInformationDisplay = this.getPaymentInformationDisplay.bind(this);
    this.getSaveButtonDisplay = this.getSaveButtonDisplay.bind(this);
  }

  public componentDidMount(){
    // there are two general flows here
    // one is that they are returning from an oauth connect, in qhich the query string will have a code parameter
    // the other is that they are visiting fresh

    // what step are we on? parse the query string
    const query = queryString.parse(this.props.location.search);
    if(query.code && query.code !== ""){
      // we are completing an oauth link
      return this.setState({loading: true, payoutMethod: "stripe"}, async () => {
        try{
          await FinancesAPI.attemptConnectVerify(this.props.schoolState.school.id, query.code, query.state, query.scope);
          success("Stripe account linked!");
          this.setState({ payoutMethod: "stripe"}, () => {
            this.getSchoolInformation();
          });
        }catch(err){
          error("Invalid OAuth connection. Please try again");
          return this.setState({ loading: false, payoutMethod: this.props.schoolState.school.payoutMethod}, () => {
            this.getConnectURL();
          });
        }
      });
    } else {
      this.setState({ payoutMethod: this.props.schoolState.school.payoutMethod}, async() => {
        await this.getSchoolInformation();
        this.getConnectURL();
      });
    }
  }

  public render() {
    return (
      <div className="row">
      <div className="col-md-3 col-md-offset-1">
        <Card title="Payout Settings Instructions" loading={this.state.loading} help="">
          <p>With KDojang, you can choose to handle invoicing yourself (called "External Invoices" in KDojang) without any fees or connections. However, to accept credit card payments from your students to be charged in KDojang (called "Internal Invoices"), you must set up a free Stripe account. Until you do so, money will be held and mailed via a check to the address on record</p>
          <p><strong>This is only allowed on a temporary basis and with a valid EIN on file due to "Know Your Customer" laws. Therefore, after 3 months you MUST create a Stripe account and connect your Stripe account here.</strong></p>
          <p>The process is quick and easy. Be sure to have your business or individual name, address, and tax information. If you need any support at all, please feel free to contact us at any time!</p>
        </Card>
      </div>
        <div className="col-md-7">
          <Card title="Payout Settings" loading={this.state.loading} help="">
            <div className="form-group">
              <label>Current Payout Method:</label>
              <select className="form-control" id="payoutMethod" value={this.state.payoutMethod} onChange={this.updateField}>
                <option value="none">None - Money is Held</option>
                <option value="check">Check - Mailed Monthly</option>
                <option value="stripe">Stripe Connect</option>
              </select>
            </div>
            {this.getPaymentInformationDisplay()}
            {this.getSaveButtonDisplay()}
          </Card>
        </div>
      </div>
    );
  }

  private getPaymentInformationDisplay(){
    if(this.state.payoutMethod === "check" || this.state.payoutMethod === "none"){
      return null;
    }

    // must be stripe, so let's show the information
    // if the school is already connected, we show the frequency
    // if not, we have them setup connect
    if(this.props.schoolState.school.payoutMethod === "stripe"){
      // they already have stripe as their payout method
      return (
        <div className="form-group">
          <strong>Congratulations!</strong> Your Stripe account is linked and you should receive the revenues from automatically charged invoices directly from Stripe. This is the easiest and most convenient
          way to receive your payments!
        </div>
      );
    }
    return (
      <div className="form-group">
        <p>Using <a href="https://stripe.com" target="_new">Stripe</a>, every invoice will automatically deposit the funds, minus fees, into your Stripe account. To get started, you need to setup your information with
          Stripe. Stripe is an industry leading payment processor and uses state of the art security measures for payment processing. You will need to provide them the following:
          <ul>
            <li>Business Information</li>
            <li>EIN or SSN for tax purposes</li>
            <li>Bank account information for depositing your payments</li>
          </ul>
        </p>
        <p><a href={this.state.connectURL}><img src="/img/stripe_connect.png" /></a></p>
      </div>
    );
  }

  private getSaveButtonDisplay(){
    if(this.state.payoutMethod !== this.props.schoolState.school.payoutMethod && this.state.payoutMethod !== "stripe"){
      return (
        <div className="form-group">
          <button className="btn btn-block btn-success" onClick={this.savePayoutMethod}>Update Payment Method</button>
        </div>
      );
    }
    return null;
  }

  private updateField(e: any){
    const ns = this.state;
    const id = e.target.id;
    const val = e.target.value;
    ns[id] = val;
    this.setState(ns);
  }

  private getConnectURL(){
    this.setState({loading: true}, async () => {
      try{
        const urlRes = await FinancesAPI.getConnectOAuthLink(this.props.schoolState.school.id);
        this.setState({connectURL: urlRes.body.data.url, loading: false });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private getSchoolInformation(){
    this.setState({loading: true}, async () => {
      try{
        const schoolRes = await SchoolsAPI.getSchoolInfo(this.props.schoolState.school.id);
        this.setState({school: schoolRes.body.data, loading: false, payoutMethod: schoolRes.body.data.payoutMethod}, () => {
          this.props.schoolActions.setSchool(this.state.school);
        });
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private savePayoutMethod(){
    this.setState({loading: true}, async () => {
      try{
        await SchoolsAPI.updateSchoolSettings(this.props.schoolState.school.id, {
          payoutMethod: this.state.payoutMethod,
        });
        this.getSchoolInformation();
      }catch(err){
        error("Could not update your payout method");
        this.setState({ loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    schoolActions: bindActionCreators(SchoolActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolPayoutScreen) as React.ComponentType<any>);