import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import Joyride from "react-joyride";

import Step from "./SetupWizardStep";
import * as steps from "./JoyrideSteps";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import * as SetupStepsActions from "../../../reducers/setupReducer";
import { SchoolsAPI } from "src/API";
import { error, success } from "../../structure/Alert";

interface ISetupWizardScreenProps {
  appActions: any;
  stepActions: any;
  history: any;
  setupState: any;
  schoolState: any;
}

interface ISetupWizardScreenState {
  loading: boolean;
  showResetModal: boolean;
  resetStatus: "pending" | "completed" | "skipped";
  showMeActive: boolean;
  joyrideSteps: any;
}

class SetupWizardScreen extends React.Component<ISetupWizardScreenProps, ISetupWizardScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showResetModal: false,
      resetStatus: "pending",
      showMeActive: false,
      joyrideSteps: [],
    };

    this.toggleResetModal = this.toggleResetModal.bind(this);
    this.resetAllSteps = this.resetAllSteps.bind(this);
    this.handleShowStep = this.handleShowStep.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          {this.props.setupState.steps.length === 0 && (
            <Card title="Set Up Wizard" loading={this.state.loading} help="">
              <p>No steps have been set up for your school.</p>
              <button className="btn btn-danger btn-block" onClick={this.toggleResetModal}>Reset The Setup Wizard</button>
            </Card>
          )}
          {this.props.setupState.steps.map((step: any) => {
            return (
              <Step
                key={step.stepNumber}
                step={step}
                onShowMe={this.handleShowStep}
                onStatusChange={this.handleStatusChange} />
            );
          })}
          {this.props.setupState.steps.length !== 0 && (
            <Card title="" loading={this.state.loading} help="" id="joyride_test">
              <button className="btn btn-danger btn-block" onClick={this.toggleResetModal}>Reset The Set Up Wizard</button>
            </Card>)}
        </div>

        <Modal show={this.state.showResetModal} onHide={this.toggleResetModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Reset The Wizard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to reset the Set Up Wizard?
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.resetAllSteps}>Yes, Reset The Wizard</button>
            <button className="btn btn-primary" onClick={this.toggleResetModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Joyride steps={this.state.joyrideSteps} run={this.state.showMeActive} showProgress={true} />
      </div>
    );
  }

  private async handleStatusChange(step: any, newStatus: string){
    this.setState({loading: true}, async () => {
      try{
        await SchoolsAPI.updateSingleSetupStepByStepKey(this.props.schoolState.school.id, step.stepKey, newStatus);
        this.props.stepActions.updateStepByKey({
          ...step,
          status: newStatus
        });
        success("Step marked " + newStatus);
        this.setState({loading: false});
      }catch(err){
        error("Could not update that step");
        this.setState({loading: false});
      }
    });
  }

  private handleShowStep(step: any){
    let newSteps: any = [];
    switch(step.stepKey){
      case "schoolSettings":
        newSteps = steps.schoolSettingsSteps;
        break;
      case "addUser":
        newSteps = steps.addStudentSteps;
        break;
      case "setupContract":
        newSteps = steps.addContractSteps;
        break;
      case "setupBelts":
        newSteps = steps.setupBelts;
        break;
      case "setupKnowledge":
        newSteps = steps.setupKnowledge;
        break;
      case "setupMessages":
        newSteps = steps.setupMessages;
        break;
    }

    if(newSteps.length > 0){
      this.props.appActions.setMenu(true);
      this.setState({showMeActive: false}, () => {
        this.setState({
          joyrideSteps: newSteps,
          showMeActive: true,
        });
      });
    }
  }

  private toggleResetModal(){
    this.setState({ showResetModal: !this.state.showResetModal});
  }

  private resetAllSteps(){
    this.setState({loading: true}, async () => {
      try{
        await SchoolsAPI.updateAllSetupSteps(this.props.schoolState.school.id, this.state.resetStatus);
        const foundRes = await SchoolsAPI.getSetupSteps(this.props.schoolState.school.id);
        const newSteps = foundRes.body.data;
        this.props.stepActions.loadAllSteps(newSteps);
        this.setState({loading: false, showResetModal: false}, () => {
          success("Steps successfully updated!");
        });
      } catch(err){
        error("Could not reset the wizard. Please contact support");
        this.setState({loading: false, showResetModal: false});
      }
    });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    setupState: s.setupState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    stepActions: bindActionCreators(SetupStepsActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetupWizardScreen) as React.ComponentType<any>);