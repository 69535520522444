import { createAction, createReducer } from "redux-act";

export const loadAllSteps: any = createAction("load all setup steps");
export const updateStepByNumber: any = createAction("updateStepByNumber");
export const updateStepByKey: any = createAction("updateStepByKey");

const defaultSteps = {
  steps: []
};

interface UpdateStepByNumberPayload {
  otherId: number;
  setupType: string;
  stepNumber: number;
  stepKey: string;
  stepTitle: string;
  stepDescription: string;
  status: "pending" | "completed" | "skipped";
  statusDate: string;
}

export default createReducer({
    [loadAllSteps]: (state, payload) => {
      return {
        ...state, 
        steps: payload
      };
    },
    [updateStepByNumber]: (state: any, payload: UpdateStepByNumberPayload) => {
      const current = state.steps;
      const newSteps: any = [];
      for(const s of current){
        if(s.stepNumber === payload.stepNumber){
          s.status = payload.status;
          s.statusDate = payload.statusDate;
        }
        newSteps.push(s);
      }
      return {
        ...state, 
        steps: newSteps,
      };
    },
    [updateStepByKey]: (state: any, payload: UpdateStepByNumberPayload) => {
      const current = state.steps;
      const newSteps: any = [];
      for(const s of current){
        if(s.stepKey === payload.stepKey){
          s.status = payload.status;
          s.statusDate = payload.statusDate;
        }
        newSteps.push(s);
      }
      return {
        ...state, 
        steps: newSteps,
      };
    },
  }, defaultSteps);