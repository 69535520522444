import * as React from "react";

const openMenuStep = {
  content: (<h2>Open the menu</h2>),
  target: "#menu_toggle",
  event: "hover",
};

export const schoolSettingsSteps: any = [
  openMenuStep,
  {
    content: (<h2>Enter your settings</h2>),
    target: "#menu_school_settings",
    event: "hover"
  },
];

export const addStudentSteps: any = [
  openMenuStep,
  {
    content: (<div>
      <h2>Choose "Add Student"</h2>
      <p>On the following screen, you will have the opportunity to create a family and then create a student. All students, even single students, are in a "family" for grouping purposes.
      </p>
    </div>),
    target: "#menu_students_add",
    event: "hover"
  },
];

export const addContractSteps: any = [
  openMenuStep,
  {
    content: (
      <div>
        <h2>Choose "Finances"</h2>
        <p>On the following screen, you will be able to add invoices and contracts. You do not need to use KDojang for collecting the amounts; you can simply use KDojang to track 
          your finances if you would like.
        </p>
      </div>),
    target: "#menu_students_add",
    event: "hover"
  },
];

export const addEvent: any = [
  openMenuStep,
  {
    content: (
      <div>
        <h2>Choose "Schedule"</h2>
        <p>Events, such as classes, are added to a Schedule for your school. You can then track student attendance and participation. If you have a table, you may optionally
          download the KDojang Attendance application and allow students to "sign in" for each class.
        </p>
      </div>),
    target: "#menu_schedule",
    event: "hover"
  },
];

export const setupBelts: any = [
  openMenuStep,
  {
    content: (
      <div>
        <h2>Choose "Belts"</h2>
        <p>Many schools have the concepts of "Belts" or "Ranks" for students to progress through. You can setup your school's belts here.
        </p>
      </div>),
    target: "#menu_setup_belts",
    event: "hover"
  },
  {
    content: (
      <div>
        <h2>After Adding a Belt, Choose "Knowledge"</h2>
        <p>Many schools also associate curriculum, such as specific forms, techniques, or knowledge required for promotion. You can setup Knowledge here and optionally
          associate it with a specific Belt. If it is associated with a Belt, when a student is promoted they will automatically be assigned the associated Knowledge.
        </p>
      </div>),
    target: "#menu_setup_knowledge",
    event: "hover",
    position: "right"
  },
];

export const setupKnowledge: any = [
  openMenuStep,
  {
    content: (
      <div>
        <h2>After Adding a Belt, Choose "Knowledge"</h2>
        <p>Many schools also associate curriculum, such as specific forms, techniques, or knowledge required for promotion. You can setup Knowledge here and optionally
          associate it with a specific Belt. If it is associated with a Belt, when a student is promoted they will automatically be assigned the associated Knowledge.
        </p>
      </div>),
    target: "#menu_setup_knowledge",
    event: "hover",
    position: "right"
  },
];

export const setupMessages: any = [
  openMenuStep,
  {
    content: (
      <div>
        <h2>Choose Messages</h2>
        <p>When certain things happen, such as a declined credit card or no attendance wih an active contract, emails can be automatically sent to drive engagement. However, we don't want to send emails without your permission. You should set up your messages and mark them as active when you are happy with the wording.
        </p>
      </div>),
    target: "#menu_setup_messages",
    event: "hover",
    position: "right"
  },
];