import * as React from "react";
import { AreaChart, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Area } from "recharts";

interface IAreaGraphProps {
  data: any;
}

interface IAreaGraphState {
  loading: boolean;
}

class AreaGraph extends React.Component<IAreaGraphProps, IAreaGraphState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
        <AreaChart data={this.props.data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="areaColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey="count" stroke="#8884d8" fillOpacity={1} fill="url(#areaColor)" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }

}


export default AreaGraph;