import * as React from "react";


interface IExamTemplateListItemProps {
  id: number;
  title: string;
  description: string;
  status: string;
  criteria: any;
  onSelect: (template: any) => {};
  onDelete: (template: any) => {};
}

interface IExamTemplateListItemState {
  loading: boolean;
}

export default class ExamTemplateListItem extends React.Component<IExamTemplateListItemProps, IExamTemplateListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

    this.onSelect = this.onSelect.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-10">{this.props.title}</div>
        <div className="col-md-2"><span className="glyphicon glyphicon-pencil"  onClick={this.onSelect} /></div>
      </div>
    );
  }

  private onSelect(){
    this.props.onSelect({
      id: this.props.id,
      title: this.props.title,
      description: this.props.description,
      status: this.props.status,
      criteria: this.props.criteria,
    });
  }

  private onDelete(){
    this.props.onDelete({
      id: this.props.id,
      title: this.props.title,
      description: this.props.description,
      status: this.props.status,
      criteria: this.props.criteria,
    });
  }

}