import { makeCall } from "./client";

export class CompetitionsAPI {

  public getSchoolCompetitions(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/competitions`, params);
  }
  
  public getUserCompetitions(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/competitions`, params);
  }
  
  public createSchoolCompetition(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/competitions`, params);
  }
  
  public getSchoolCompetition(schoolId: number, competitionId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/competitions/${competitionId}`, params);
  }
  
  public updateSchoolCompetition(schoolId: number, competitionId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/competitions/${competitionId}`, params);
  }
  
  public deleteSchoolCompetition(schoolId: number, competitionId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/competitions/${competitionId}`, params);
  }
  
  public getSchoolCompetitionEvents(schoolId: number, competitionId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/competitions/${competitionId}/events`, params);
  }
  
  public createSchoolCompetitionEvent(schoolId: number, competitionId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/competitions/${competitionId}/events`, params);
  }
  
  public getSchoolCompetitionEvent(schoolId: number, competitionId: number, eventId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/competitions/${competitionId}/events/${eventId}`, params);
  }
  
  public updateSchoolCompetitionEvent(schoolId: number, competitionId: number, eventId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/competitions/${competitionId}/events/${eventId}`, params);
  }
  
  public deleteSchoolCompetitionEvent(schoolId: number, competitionId: number, eventId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/competitions/${competitionId}/events/${eventId}`, params);
  }
  
  public linkUserToCompetition(schoolId: number, competitionId: number, eventId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/competitions/${competitionId}/events/${eventId}/users/${userId}`, params);
  }
  
  public removeUserFromCompetition(schoolId: number, competitionId: number, eventId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/competitions/${competitionId}/events/${eventId}/users/${userId}`, params);
  }
}