import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import * as moment from "moment";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";

import { ExamsAPI } from "../../../API";

import DatePicker from "../../structure/DatePicker";
import { success, error } from "../../structure/Alert";

import ExamListItem from "./ExamListItem";
import ExamCriteriaListItem from "./ExamCriteriaListItem";

interface IExamsProps {
  appActions: any;
  history: any;

  schoolState: any;
  userState: any;
}

interface IExamsState {
  loading: boolean;
  templates: any;
  exams: any;
  selectedTemplate: any;
  selectedExam: any;
  showingStatus: "pending" | "completed" | "archived";
  showScores: "yes" | "no";

  selectedStudent: any;
  examDate: moment.Moment;
  showGradeModal: boolean;
  showNewModal: boolean;
  showStatusChangeModal: boolean;
  newExamStatus: "pending" | "completed" | "archived";
  showDeleteExamModal: boolean;
}

const examHelp = "";
const examCriteriaHelp = "";

class Exams extends React.Component<IExamsProps, IExamsState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      templates: [],
      exams: [],
      showScores: "yes",
      selectedTemplate: { id: 0 },
      selectedExam: { id: 0 },
      showingStatus: "pending",
      showGradeModal: false,
      showNewModal: false,
      showStatusChangeModal: false,
      selectedStudent: { id: 0 },
      examDate: moment(),
      newExamStatus: "pending",
      showDeleteExamModal: false,
    };

    this.selectTemplate = this.selectTemplate.bind(this);
    this.fetchTemplates = this.fetchTemplates.bind(this);
    this.assignExam = this.assignExam.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.renderTypeahead = this.renderTypeahead.bind(this);
    this.handleSelectedStudent = this.handleSelectedStudent.bind(this);
    this.handleSelectExam = this.handleSelectExam.bind(this);
    this.handleChangeExamStatus = this.handleChangeExamStatus.bind(this);
    this.handleExamDateChanged = this.handleExamDateChanged.bind(this);
    this.updateField = this.updateField.bind(this);

    this.handleCriteriaUpdate = this.handleCriteriaUpdate.bind(this);

    this.toggleStatusChangeModal = this.toggleStatusChangeModal.bind(this);
    this.updateNewStatusField = this.updateNewStatusField.bind(this);
    this.toggleDeleteExamModal = this.toggleDeleteExamModal.bind(this);
    this.deleteExam = this.deleteExam.bind(this);
  }

  public componentDidMount() {
    this.fetchTemplates();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-5">
          <Card title={this.state.selectedTemplate.title ? this.state.selectedTemplate.title : "Exams"} loading={this.state.loading} help={examHelp}>
            {this.state.templates.length === 0 ? (
              <strong>You must set up Exam Templates before managing exams</strong>
            ) : (
                <div>
                  <div className="form-group">
                    <label>Select Template</label>
                    <select id="selectedTemplate" className="form-control" value={this.state.selectedTemplate.id} onChange={this.selectTemplate}>
                      <option value={0}>All</option>
                      {this.state.templates.map((t: any) => {
                        return (<option value={t.id} key={t.id}>{t.title}{t.status !== "active" && (" (archived)")}</option>);
                      })}
                    </select>
                  </div>
                  {this.state.selectedTemplate.id !== 0 && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Show Exams With Status</label>
                          <select id="showingStatus" className="form-control" value={this.state.showingStatus} onChange={this.changeStatus}>
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                            <option value="archived">Archived</option>
                          </select>
                        </div>
                      </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Show Calculated Scores</label>
                            <select id="showScores" className="form-control" value={this.state.showScores} onChange={this.updateField}>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                    </div>
                  )}
                  {this.state.selectedTemplate.id !== 0 && this.state.exams.length === 0 && (<strong>No exams exist for that template</strong>)}
                  {this.state.exams.length !== 0 && (
                    <div className="row" style={{ marginBottom: 10, marginTop: 10, borderBottom: "thin solid black" }}>
                      <div className="col-md-5 vertical-container">
                        <strong>Student</strong>
                      </div>
                      <div className="col-md-3 vertical-container">
                        <strong>Date</strong>
                      </div>
                      <div className="col-md-2 vertical-container">
                        <strong>Score</strong>
                      </div>
                      <div className="col-md-2 vertical-container" />
                    </div>
                  )}
                  {this.state.exams.map((exam: any, index: number) => {
                    return (
                      <ExamListItem
                        key={index}
                        item={exam}
                        showScore={this.state.showScores === "yes" ? true : false}
                        className={index % 2 === 1 ? "list-row-odd" : ""}
                        onSelect={this.handleSelectExam}
                        onStatusChanged={this.handleChangeExamStatus} />
                    );
                  })}

                  <div className="form-group" style={{ marginTop: 10 }}>
                    {this.state.selectedTemplate.id && this.state.selectedTemplate !== 0 ? (<button className="btn btn-block btn-success" onClick={this.toggleNewModal}>Assign New Exam</button>) : null}
                  </div>
                </div>
              )}
          </Card>
        </div>
        <div className="col-md-7">
          {this.state.selectedExam && this.state.selectedExam.id !== 0 && (
            <Card title={`${this.state.selectedExam.examName}`} loading={this.state.loading} help={examCriteriaHelp}>
              <div className="row" style={{ marginBottom: 10, borderBottom: "thin solid black" }}>
                <div className="col-md-6">
                  <strong>Criteria</strong>
                </div>
                <div className="col-md-2">
                  <strong>Score</strong>
                </div>
                <div className="col-md-2">
                  <strong>Max Score</strong>
                </div>
                <div className="col-md-2" />
              </div>
              {this.state.selectedExam.criteria.map((crit: any) => {
                return (
                  <ExamCriteriaListItem
                    loggedInUserId={this.props.userState.user.id}
                    loggedInUserRole={this.props.userState.selectedSchool.role}
                    key={crit.id}
                    item={crit}
                    examName={this.state.selectedExam.examName}
                    examStatus={this.state.selectedExam.status}
                    onUpdate={this.handleCriteriaUpdate} />
                );
              })}
              <div className="row">
                <div className="col-md-4 col-md-offset-6">
                  <strong>Calculated Score: {this.state.selectedExam.calculatedScore}</strong>
                </div>
              </div>
              <div className="form-group" style={{marginTop: 15}}>
                <button className="btn btn-block btn-success" onClick={this.toggleStatusChangeModal}>Change Status</button>
                <button className="btn btn-block btn-danger" onClick={this.toggleDeleteExamModal}>Unassign and Delete Exam</button>
              </div>
            </Card>
          )}
        </div>


        <Modal show={this.state.showNewModal} onHide={this.toggleNewModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Assign Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Student</label>
              {this.renderTypeahead()}
            </div>
            <div className="form-group">
              <label>Template</label>
              <input type="text" className="form-control" disabled={true} value={this.state.selectedTemplate.title} />
            </div>
            <div className="form-group">
              <label>Exam Date</label>
              <DatePicker date={this.state.examDate} onDateSaved={this.handleExamDateChanged} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.assignExam}>Assign Exam</button>
            <button className="btn btn-primary" onClick={this.toggleNewModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showNewModal} onHide={this.toggleNewModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Assign Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Student</label>
              {this.renderTypeahead()}
            </div>
            <div className="form-group">
              <label>Template</label>
              <input type="text" className="form-control" disabled={true} value={this.state.selectedTemplate.title} />
            </div>
            <div className="form-group">
              <label>Exam Date</label>
              <DatePicker date={this.state.examDate} onDateSaved={this.handleExamDateChanged} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.assignExam}>Assign Exam</button>
            <button className="btn btn-primary" onClick={this.toggleNewModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showStatusChangeModal} onHide={this.toggleStatusChangeModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Change the Exam's Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p>The exam's status is currently <strong>{this.state.selectedExam.status}</strong>.</p>
            </div>
            <div className="form-group">
              <p>The exam can be set to one of the following statuses:
              <ul>
                  <li>Pending - The exam is currently pending or in progress</li>
                  <li>Completed - The exam has been completed but is still needed for reference</li>
                  <li>Archived - The exam has been completed and can be archived</li>
                </ul>
              </p>
              <p>Please note that you can always change the status of the exam and it is primarily used for organizing exams for viewing or grading.</p>
            </div>
            <div className="row">
              <div className="col-md-5">
                <label>New Exam Status: </label>
              </div>
              <div className="col-md-7">
                <select id="status" className="form-control" value={this.state.newExamStatus} onChange={this.updateNewStatusField}>
                  <option value="pending">Pending</option>
                  <option value="completed">Completed</option>
                  <option value="archived">Archived</option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.handleChangeExamStatus}>Change the Status</button>
            <button className="btn btn-primary" onClick={this.toggleStatusChangeModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteExamModal} onHide={this.toggleDeleteExamModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
             Are you absolutely sure you want to delete the exam <strong>{this.state.selectedExam.examName}</strong>? This cannot be undone. The exam will be completely deleted as well as any existing scores. You will need to reassign the exam and then regrade the student.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteExam}>Unassign and Delete Exam</button>
            <button className="btn btn-primary" onClick={this.toggleDeleteExamModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }

  private selectTemplate(e: any) {
    const id = parseInt(e.target.value, 10);
    let temp: any = {};
    for (const t of this.state.templates) {
      if (t.id === id) {
        temp = t;
      }
    }
    this.setState({ loading: true }, async () => {
      try {
        const res = await ExamsAPI.getExams(this.props.schoolState.school.id, id, this.state.showingStatus);
        this.setState({ loading: false, selectedTemplate: temp, exams: res.body.data, selectedExam: { id: 0 } });
      } catch (e) {
        this.setState({ loading: false });
      }
    });
  }

  private changeStatus(e: any) {
    this.setState({ showingStatus: e.target.value }, () => {
      this.selectTemplate({
        target: {
          value: this.state.selectedTemplate.id + "",
        }
      });
    });
  }

  private fetchTemplates() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await ExamsAPI.getExamTemplates(this.props.schoolState.school.id, "");
        this.setState({ templates: res.body.data }, () => {
          this.selectTemplate({
            target: {
              value: 0,
            }
          });
        });
      } catch (e) {
        this.setState({ loading: false });
      }
    });
  }

  private assignExam() {
    if (!this.state.examDate.isValid()) {
      return error("You must provide a valid exam date");
    }
    if (!this.state.selectedStudent.id || this.state.selectedStudent.id === 0) {
      return error("You must select a student");
    }
    const data = {
      examTemplateId: this.state.selectedTemplate.id,
      studentId: this.state.selectedStudent.id,
      examDate: this.state.examDate.format("YYYY-MM-DD"),
    };
    this.setState({ loading: true }, async () => {
      try {
        const res = await ExamsAPI.createExam(this.props.schoolState.school.id, data);
        const exams = this.state.exams;
        exams.push(res.body.data);
        this.setState({ loading: false, showNewModal: false, selectedExam: res.body.data, exams });
        success("Exam created and assigned");
      } catch (e) {
        error("Could not assign that exam");
        this.setState({ loading: false, showNewModal: false });
      }
    });
  }

  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns);
  }

  private toggleNewModal() {
    this.setState({ showNewModal: !this.state.showNewModal });
  }

  private toggleStatusChangeModal() {
    this.setState({ showStatusChangeModal: !this.state.showStatusChangeModal, newExamStatus: this.state.selectedExam.status });
  }

  private updateNewStatusField(e: any){
    const ns: any = this.state;
    ns.newExamStatus = e.target.value;
    this.setState(ns);
  }

  private toggleDeleteExamModal(){
    this.setState({ showDeleteExamModal: !this.state.showDeleteExamModal});
  }

  private deleteExam(){
    this.setState({loading: true}, async () => {
      try{
        await ExamsAPI.deleteExam(this.props.schoolState.school.id, this.state.selectedExam.id);
        this.setState({loading: false, showDeleteExamModal: false, selectedExam: { id: 0 }}, () => {
          this.selectTemplate({
            target: {
              value: this.state.selectedTemplate.id + "",
            }
          });
        });
        success("Exam deleted!");
      }catch(err){
        error("Could not delete that exam. You may not have permission");
        this.setState({loading: false, showDeleteExamModal: false});
      }
    });
  }

  private renderTypeahead() {
    return (
      <Typeahead
        key={this.props.schoolState.users.length}
        labelKey="fullName"
        multiple={false}
        placeholder="Enter Student Name"
        onChange={this.handleSelectedStudent}
        defaultSelected={[]}
        options={this.props.schoolState.users} />
    );
  }

  private handleSelectedStudent(e: any) {
    if (e.length === 0) {
      return;
    }
    this.setState({ selectedStudent: e[0] });
  }

  private handleExamDateChanged(newDate: moment.Moment) {
    const ns: any = this.state;
    ns.examDate = newDate;
    this.setState(ns);
  }

  private handleSelectExam(exam: any) {
    this.setState({ loading: true }, async () => {
      try {
        const res = await ExamsAPI.getExam(this.props.schoolState.school.id, exam.id);
        this.setState({ loading: false, selectedExam: res.body.data, newExamStatus: res.body.data.status });
      } catch (err) {
        // this shouldn't happen...
        error("Could not load that exam");
        this.setState({ loading: false });
      }
    });
  }

  private handleChangeExamStatus(exam: any) {
    if (!exam.id) {
      // an event was passed in, so it's the current exam
      exam = this.state.selectedExam;
    }
    exam.status = this.state.newExamStatus;
    this.setState({ loading: true }, async () => {
      try {
        await ExamsAPI.updateExam(this.props.schoolState.school.id, exam.id, exam);
        this.setState({ loading: false, showStatusChangeModal: false }, () => {
          this.selectTemplate({
            target: {
              value: this.state.selectedTemplate.id + "",
            }
          });
        });
      } catch (err) {
        // this shouldn't happen...
        error("Could not update that exam");
        this.setState({ loading: false });
      }
    });
  }

  private handleCriteriaUpdate(exam: any) {
    // first, find the exam in the list and update the score
    const exams = [];
    for (const e of this.state.exams) {
      if (e.id === exam.id) {
        exams.push(exam);
      } else {
        exams.push(e);
      }
    }
    this.setState({ selectedExam: exam, exams });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exams) as React.ComponentType<any>);