import * as React from "react";
import { Modal } from "react-bootstrap";

interface IMessageTemplateListItem {
  id: number;
  status: string;
  body: string;
  description: string;
  name: string;
  shortName: string;
  subject: string;
  messageType: string;
  platformTemplateId: number;
  schoolId: number;
  placeholders: any;
}


interface IMessageTemplateListItemProps {
  className: string;
  template: IMessageTemplateListItem;
  onReset: any;
  onSelect: any;
}

interface IMessageTemplateListItemState {
  loading: boolean;
  showResetModal: boolean;
}

class MessageTemplateListItem extends React.Component<IMessageTemplateListItemProps, IMessageTemplateListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showResetModal: false,
    };

    this.toggleReset = this.toggleReset.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-3 vertical-container" onClick={this.handleSelect}>
          {this.props.template.name}
        </div>
        <div className="col-md-5 vertical-container" onClick={this.handleSelect}>
          {this.props.template.description}
        </div>
        <div className="col-md-2 vertical-container" onClick={this.handleSelect}>
          {this.props.template.status}
        </div>
        <div className="col-md-2 vertical-container pull-right">
          <span className="text-primary glyphicon glyphicon-edit" onClick={this.handleSelect} style={{ marginRight: 15 }} />
          <span className="text-danger glyphicon glyphicon-refresh" onClick={this.toggleReset} />
        </div>

        <Modal show={this.state.showResetModal} onHide={this.toggleReset} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Reset Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Warning! This will reset the template to the original settings and layout. This cannot be undone. Are you sure you want to reset it to the original template?

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleReset}>Yes, Reset It</button>
            <button className="btn btn-primary" onClick={this.toggleReset}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleReset() {
    this.setState({ showResetModal: !this.state.showResetModal});
  }

  private handleReset() {
    this.props.onReset(this.props.template.platformTemplateId);
    this.setState({ showResetModal: false });
  }

  private handleSelect() {
    this.props.onSelect(this.props.template.id);
    this.setState({ showResetModal: false });
  }
}

export default MessageTemplateListItem;