import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import {error} from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";
import {ScheduleAPI} from "../../../API";

import Form from "./EventInfoForm";

interface IEventInfoProps {
  appActions: any;
  history: any;
  event: any;
  addingNewEvent: boolean;
  schoolState: any;
  newEventStartTime: any;
  onEventSaved: any;
  onEventDeleted: any;
}

interface IEventInfoState {
  loading: boolean;
  classTypes: any;
  categories: any;
  event: any;
}

class EventInfo extends React.Component<IEventInfoProps, IEventInfoState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      classTypes: [],
      categories: [],
      event: { id: 0, newEventStartTime: this.props.newEventStartTime, eventType: 0 }
    };

    this.handleEventSaved = this.handleEventSaved.bind(this);
    this.handleEventDelete = this.handleEventDelete.bind(this);
    this.handleNewEventType = this.handleNewEventType.bind(this);
  }

  public componentDidMount(){
    this.fetchTypes();
  }

  public async fetchTypes(){
    try{
      const res = await ScheduleAPI.getEventsTypes(this.props.schoolState.school.id);
      const categories = await ScheduleAPI.getEventsCategories({});
      this.setState({
        classTypes: res.body.data,
        categories: categories.body.data,
      });
    }catch(e){
      error("Could not fetch event types");
    }
  }

  get instructors(){
    const instructors: any = [];
    for(const i of this.props.schoolState.users){
      if(i.role === "administrator" || i.role === "assistant"){
        instructors.push(i);
      }
    }
    instructors.sort((a: any, b: any) => {
      return a.lastName > b.lastName ? 1 : -1;
    });
    return instructors;
  }

  public render() {
    if(!this.props.addingNewEvent && (!this.props.event || this.props.event.id === 0)){
      return null;
    }
    return (
      <Card title={this.props.addingNewEvent ? "Add New Event" : this.props.event.title} loading={this.state.loading} help="Use this form to add information about the event or class, such as the time and the category. The category is important as it will allow for better analytics. For example, some schools require a specific amount of sparring or weapons classes before testing for the next rank.">
        <Form 
          key={this.props.addingNewEvent ? this.props.newEventStartTime : this.props.event.id}
          schoolId={this.props.schoolState.school.id}
          instructors={this.instructors}
          types={this.state.classTypes}
          categories={this.state.categories}
          event={this.props.addingNewEvent ? { id: 0, newEventStartTime: this.props.newEventStartTime, eventType: 0 } : this.props.event}
          onEventSaved={this.handleEventSaved}
          onNewEventType={this.handleNewEventType}
          onEventDelete={this.handleEventDelete}
          />
      </Card>
    );
  }

  private handleEventSaved(event:any){
    this.props.onEventSaved(event);
  }

  private handleNewEventType(eventType: any){
    // now just add it to the list
    const ns = this.state;
    ns.classTypes.push(eventType);
    if(this.props.event && this.props.event.eventType){
      this.props.event.eventType = eventType.id;
    }
    this.setState(ns);
  }

  private handleEventDelete(event: any){
    this.props.onEventDeleted(event);
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventInfo) as React.ComponentType<any>);