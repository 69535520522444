import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import * as moment from "moment";


import * as AppActions from "../../../../reducers/appReducer";
import { capFirstLetter } from "../../../../utils";
import { error, success} from "../../../structure/Alert";
import { EquipmentAPI } from "src/API";


interface IUserEquipment {
  id: number;
  schoolId: number;
  userId: number;
  equipmentId: number;
  name: string;
  category: string;
  barcode: string;
  size: string;
  notes: string;
  received: string;
}

interface IUserEquipmentItemProps {
  appActions: any;
  history: any;
  item: IUserEquipment;
  onDelete: any;
}

interface IUserEquipmentItemState {
  loading: boolean;
  showDeleteModal: boolean;
  showDetailsModal: boolean;
  updateInventory: "yes" | "no";
}

class UserEquipmentItem extends React.Component<IUserEquipmentItemProps, IUserEquipmentItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showDeleteModal: false,
      showDetailsModal: false,
      updateInventory: "no",
    };

    this.updateField = this.updateField.bind(this);
    this.toggleDetailsModal = this.toggleDetailsModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public render() {
    return (
      <div className="row" key={this.props.item.id}>
        <div className="col-md-3 vertical-container">
          {this.props.item.name}
        </div>
        <div className="col-md-3 vertical-container">
          {capFirstLetter(this.props.item.category)}
        </div>
        <div className="col-md-2 vertical-container">
          {this.props.item.size}
        </div>
        <div className="col-md-2 vertical-container">
          {moment(this.props.item.received).format("MM/DD/YY")}
        </div>
        <div className="col-md-2 vertical-container">
          <span className="glyphicon glyphicon-eye-open text-primary" style={{marginRight: 10}} onClick={this.toggleDetailsModal} />
          <span className="glyphicon glyphicon-remove text-danger" onClick={this.toggleDeleteModal} />
        </div>

        <Modal show={this.state.showDetailsModal} onHide={this.toggleDetailsModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4">
                <strong>Notes</strong>
              </div>
              <div className="col-md-4">
                {this.props.item.notes}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn" onClick={this.toggleDetailsModal}>Close</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to remove this inventory item from the user? You can add it back if you would like.
            <div className="form-group">
              <label>Update Existing Inventory?</label>
              <select className="form-control" id="updateInventory" value={this.state.updateInventory} onChange={this.updateField}>
                <option value="no">No, do not modify the school's inventory count for this item</option>
                <option value="yes">Yes, add 1 back into the school's inventory</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.handleDelete}>Delete</button>
            <button className="btn" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleDetailsModal(){
    this.setState({showDetailsModal: !this.state.showDetailsModal});
  }

  private toggleDeleteModal(){
    this.setState({showDeleteModal: !this.state.showDeleteModal});
  }

  private handleDelete(){
    this.setState({loading: true}, async () => {
      try{
        const data = {
          modifyInventory: this.state.updateInventory === "yes" ? true : false
        };
        await EquipmentAPI.removeEquipmentFromUser(this.props.item.schoolId, this.props.item.userId, this.props.item.equipmentId, this.props.item.id, data);
        success("Equipment unassigned!");
        this.setState({loading: false, showDeleteModal: false}, () => {
          this.props.onDelete(this.props.item);
        });
      }catch(err){
        error("Could not remove that equipment from that user");
        this.setState({loading: false, showDeleteModal: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEquipmentItem) as React.ComponentType<any>);