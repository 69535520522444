// serves as a rediect without the school id in the path
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as queryString from "query-string";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";

interface IConnectScreenProps {
  schoolState: any;
  history: any;
  location: any;
}

interface IConnectScreenState {
  loading: boolean;
  showError: boolean;
}

class ConnectScreen extends React.Component<IConnectScreenProps, IConnectScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showError: false,
    };
    this.examineURL = this.examineURL.bind(this);
  }

  public componentDidMount(){
    this.examineURL();
  }

  public render() {
    if(this.state.showError){
      return (
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Card title="Error" loading={this.state.loading} help="">
              We aren't quite sure how you got here, but you shouldn't have without the proper tokens. Please select a new screen from the menu or contact support for assistance.
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <Card title="Processing..." loading={this.state.loading} help="">
            Please wait while we examine the redirect...
          </Card>
        </div>
      </div>
    );
  }

  private examineURL(){
    const query = queryString.parse(this.props.location.search);
    if(query.code && query.code !== ""){
      // we are completing an oauth link
      // redirect
      const schoolId = this.props.schoolState.school.id;
      const stateParts = query.state.split("_");
      const url = `/schools/${schoolId}/settings/payout?code=${query.code}&state=${query.state}&scope=${query.scope}`;
      if(stateParts[0] !== "sid"){
        error("Invalid redirect");
      }
      return this.props.history.push(url);
    }
    this.setState({showError: true});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectScreen) as React.ComponentType<any>);