import * as React from "react";
import Dropzone from "react-dropzone";
import Img from "react-image";
import VisibilitySensor from "react-visibility-sensor";

import { error, success } from "./Alert";

import def from "../../img/default-user-icon.png";
import { SchoolsAPI } from "../../API";

interface ISchoolLogoProps {
  schoolId: number;
  mode?: "logo"|"banner";
  height?: number|string;
  width: number|string;
  size: string;
  useSensor?: boolean;
}

interface ISchoolLogoState {
  loading: boolean;
  mode: "logo"|"banner";
  loadTime: any;
  getElement?: any;
}

class SchoolLogo extends React.Component<ISchoolLogoProps, ISchoolLogoState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loadTime: new Date(), // cache busting,
      loading: false,
      getElement: null,
      mode: props.mode ? props.mode : "logo",
    };
    this.getImage = this.getImage.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  public render() {
    if(this.state.loading || !this.props.schoolId){
      return (<div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />);
    }
    
    if(!this.props.useSensor){
      // just return the drop zone
      return this.renderDropzone();
    }
    const containmentDOMRect = this.state.getElement
      ? this.state.getElement
      : null;

    return ( 
      <VisibilitySensor key={`image-${this.props.schoolId}-${this.state.mode}`} containment={containmentDOMRect} partial={false}>
        {(isVisible: any, rect: any) => {
          if(isVisible.isVisible){
          return this.renderDropzone();
        } else {
          return (<div style={{width: this.props.width, height: this.props.height}} />);
        }
      }}
      </VisibilitySensor>
    );
  }

  private renderDropzone(){
    const im = this.getImage();
    return ((<Dropzone onDrop={this.handleDrop}>
      {({getRootProps, getInputProps}) => {
        return (
          <div
            {...getRootProps()}
            className="dropzone"
            style={{"textAlign": "center"}}>
            <input {...getInputProps()} />
            <Img 
              width={this.props.width}
              loader={<div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />}
              src={[im, def]} 
              unloader={(<img src="/img/default-user-icon.png" />)}/>
          </div>
        );
      }
      }
    </Dropzone>));
  }

  private async handleDrop(acceptedFiles: any) {
    this.setState({loading: true}, async () => {
      try{
        if(this.state.mode === "logo"){
          await SchoolsAPI.uploadSchoolLogo(this.props.schoolId, acceptedFiles[0]);
        }else{
          await SchoolsAPI.uploadSchoolBanner(this.props.schoolId, acceptedFiles[0]);
        }
        return this.setState({ loadTime: new Date(), loading: false}, () => {
          return success("School image updated! It may take a few minutes for the new image to appear throughout the application.");
        });
      }catch(err){
        return this.setState({loading: false}, () => {
          return error("Could not save that image");
        });
      }
    });
  }

  private getImage() {
    if(this.state.mode === "logo"){
      return SchoolsAPI.getSchoolLogoString(this.props.schoolId);
    }else{
      return SchoolsAPI.getSchoolBannerString(this.props.schoolId);
    }
  }
}


export default SchoolLogo;