import * as React from "react";
import { Modal } from "react-bootstrap";

interface INewsItemProps {
  id: number;
  title: string;
  body: string;
  className: string;
  posted: string;
}

interface INewsItemState {
  loading: boolean;
  showingModal: boolean;
}

export default class NewsItem extends React.Component<INewsItemProps, INewsItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showingModal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  public render() {
    return (
        <div className={"row list-row " + this.props.className}>
          <div className="col-md-6 vertical-container">
            {this.props.title}
          </div>
          <div className="col-md-4 vertical-container">
            {this.props.posted}
          </div>
          <div className="col-md-2 vertical-container">
            <span className="text-primary glyphicon glyphicon-eye-open" onClick={this.toggleModal} />
          </div>

          <Modal show={this.state.showingModal} onHide={this.toggleModal} backdrop="static">
            <Modal.Header closeButton={true}>
              <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.body}

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={this.toggleModal}>Close</button>
            </Modal.Footer>
          </Modal>
        </div>
    );
  }

  private toggleModal(){
    this.setState({ showingModal: !this.state.showingModal});
  }
}
