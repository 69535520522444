import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as qs from "query-string";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { UserAPI } from "../../../API";
import { error, success } from "../../structure/Alert";

interface IPasswordResetScreenProps {
  appActions: any;
  history: any;
  location: any;
  match: any;
}

interface IPasswordResetScreenState {
  loading: boolean;
  step: 1 | 2 | 3;
  username: string;
  emailAddress: string;
  token: string;
  password: string;
}

class PasswordResetScreen extends React.Component<IPasswordResetScreenProps, IPasswordResetScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      step: 1,
      username: "",
      emailAddress: "",
      token: "",
      password: "",
    };

    this.updateField = this.updateField.bind(this);
    this.attemptVerify = this.attemptVerify.bind(this);
    this.beginReset = this.beginReset.bind(this);
  }

  public componentDidMount(){
    // parse the URL
    const params = qs.parse(this.props.location.search);
    if((params.token && params.token !== "") || this.props.match.params.verify === "verify" ){
      this.setState({
        step: 2,
        username: params.username ? params.username : "",
        token: params.token ? params.token : "",
      });
    } else {
      this.setState({step: 1});
    }
  }

  public render() {
    if(this.state.step === 1){
      return (
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="Verify Your Account" loading={this.state.loading} help="Use this form to reset your password.">
              <p>Please enter your username. If you do not know your username, you can attempt to use your email address, but if there are multiple accounts associated with that email,
                we cannot send the verification information. Regardless, your school can reset your password for you and tell you your username at any time.
              </p>
              <div className="form-group">
                <label>Username</label>
                <input type="text" id="username" className="form-control" value={this.state.username} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" id="emailAddress" className="form-control" value={this.state.emailAddress} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-success" onClick={this.beginReset}>Begin Reset</button>
              </div>
            </Card>
          </div>
        </div>
      );
    } else if(this.state.step === 2){
      // account has been verified, tell them and bring them to the login
      return (
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="Verify Your Account" loading={this.state.loading} help="Use this form to reset your password. You should have received an email with your username and a verification token.">
              <p>Please enter your username. If you do not know your username, you can attempt to use your email address, but if there are multiple accounts associated with that email,
                we cannot send the verification information. Regardless, your school can reset your password for you and tell you your username at any time.
              </p>
              <div className="form-group">
                <label>Username</label>
                <input type="text" id="username" className="form-control" value={this.state.username} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>Token</label>
                <input type="text" id="token" className="form-control" value={this.state.token} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-success" onClick={this.attemptVerify}>Reset Password</button>
              </div>
            </Card>
          </div>
        </div>
      );
    } else if(this.state.step === 3){
      // account has been verified, tell them and bring them to the login
      return (
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="Success!" loading={this.state.loading} help="">
              Thank you for verifying your email and resetting your password! You can now <Link to="/login">login</Link> with your username and password!
            </Card>
          </div>
        </div>
      );
    }
    return null;
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private beginReset(){
    if(this.state.username === "" && this.state.emailAddress === ""){
      return error("Username or Email Address is required");
    }
    this.setState({loading: true}, async () => {
      try{
        await UserAPI.resetPassword(this.state.username, this.state.emailAddress);
        success("If an account matches that information, an email will be sent with further instructions");
        this.setState({loading: false});
      }catch(err){
        success("If an account matches that information, an email will be sent with further instructions");
        this.setState({loading: false});
      }
    });
  }

  private attemptVerify(){
    if(this.state.password === ""){
      return error("Password cannot be blank");
    }
    this.setState({loading: true}, async () => {
      try {
        await UserAPI.verifyPasswordToken(this.state.username, this.state.token, this.state.password);
        this.setState({ loading: false, step: 3});
      } catch(err){
        error("Could not verify that username and token");
        this.setState({ loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetScreen) as React.ComponentType<any>);