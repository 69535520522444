import { makeCall } from "./client";

export class AlertsAPI {
  
  /**
   * Gets the alerts for a school
   * @param schoolId 
   */
  public getSchoolAlerts(schoolId: number, showDismissed: boolean = false, params: any = {}): Promise<any> {
    params.showDismissed = showDismissed ? 1 : 0;
    return makeCall("get", `schools/${schoolId}/alerts`, params);
  }

  /**
   * Dismisses an alert
   * @param schoolId 
   * @param alertId 
   */
  public dismissAlert(schoolId: number, alertId: number): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/alerts/${alertId}`);
  }

  /**
   * Dismiss all alerts on the account
   * @param schoolId 
   */
  public dismissAllAlerts(schoolId: number): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/alerts`);
  }
}