import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import Card from "../../structure/Card";
import List from "../../structure/List";
import * as AppActions from "../../../reducers/appReducer";
import * as SchoolActions from "../../../reducers/schoolReducer";
import { FinancesAPI, SchoolsAPI } from "../../../API/";
import { error } from "../../structure/Alert";
import { convertCurrency } from "src/utils";
import Contract from "./Contract";

interface ISchoolContractsScreenProps {
  appActions: any;
  schoolActions: any;
  history: any;
  schoolState: any;
  userId?: number;
  onSave: any;
  onDelete: any;
}

interface ISchoolContractsScreenState {
  loading: boolean;
  showStatus: string;
  contracts: any;
  filter: string;
  filteredContracts: any;
  selectedContract: any;
  showingContract: boolean;
  selectedStudent: any;
  schoolInfo: any;
  sortField: "student" | "status" | "start" | "end";
  sortDir: "asc" | "desc";
}

class SchoolContractsScreen extends React.Component<ISchoolContractsScreenProps, ISchoolContractsScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showStatus: "active",
      filter: "",
      contracts: [],
      filteredContracts: [],
      selectedContract: {},
      showingContract: false,
      schoolInfo: { terms: "" },
      sortDir: "asc",
      sortField: "student",
      selectedStudent: { id: 0 },
    };


    this.updateField = this.updateField.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.fetchContracts = this.fetchContracts.bind(this);
    this.viewContract = this.viewContract.bind(this);
    this.toggleContract = this.toggleContract.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.newContract = this.newContract.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.getSelectedStudent = this.getSelectedStudent.bind(this);

    this.sort = this.sort.bind(this);
    this.sortStudent = this.sortStudent.bind(this);
    this.sortStatus = this.sortStatus.bind(this);
    this.sortStart = this.sortStart.bind(this);
    this.sortEnd = this.sortEnd.bind(this);
  }

  public componentDidMount() {
    const student = this.getSelectedStudent();
    this.setState({ selectedStudent: student }, () => {
      this.fetchContracts();
    });
  }

  public render() {
    if (this.state.loading) {
      return (
        <div className="row">
          <div className="col-md-12">
            <Card title="Contracts"> <div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} /></Card>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <Card title="Contracts" loading={this.state.loading} help="Contracts are the fundamental method to charge on-going tuition for students. Contracts do not have to charge invoices and can instead be used to keep track of how long a student is allowed to participate. For example, a student may pay an up-front fee for six months of classes, which is fully supported in the Contracts system.">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Show With Status: </label>
                  <select className="form-control" id="showStatus" value={this.state.showStatus} onChange={this.updateField}>
                    <option value="active">Active</option>
                    <option value="paused">Paused</option>
                    <option value="expired">Expired But Invoices Remain</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="complete">Completed</option>
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <button className="btn btn-success btn-block" style={{ marginTop: 25 }} onClick={this.newContract}>
                  <i className="glyphicon glyphicon-plus" /> New Contract
                  </button>
              </div>
              <div className="col-md-4 col-md-offset-2">
                <div className="form-group">
                  <label>Filter</label>
                  <input type="text" className="form-control" id="filter" value={this.state.filter} onChange={this.updateFilter} />
                </div>
              </div>
            </div>



            <div className="row" style={{ marginTop: 15, paddingBottom: 15, borderBottom: "thin solid gray" }}>
              <div className="col-md-3">
                <span className="row-header">Students</span>
              </div>
              <div className="col-md-2">
                <span className="row-header-sortable" onClick={this.sortStatus}>Status</span>
                {this.state.sortField === "status" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                {this.state.sortField === "status" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
              </div>
              <div className="col-md-1">
                <span className="row-header-sortable" onClick={this.sortStart}>Start Date</span>
                {this.state.sortField === "start" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                {this.state.sortField === "start" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
              </div>
              <div className="col-md-1">
                <span className="row-header-sortable" onClick={this.sortEnd}>End Date</span>
                {this.state.sortField === "end" && this.state.sortDir === "asc" && (<i className="fa fa-fw fa-sort-asc" />)}
                {this.state.sortField === "end" && this.state.sortDir === "desc" && (<i className="fa fa-fw fa-sort-desc" />)}
              </div>
              <div className="col-md-2">
                <span className="row-header">Invoices Paid</span>
              </div>
              <div className="col-md-2">
                <span className="row-header">Monthly Payment</span>
              </div>
              <div className="col-md-1" />
            </div>

            <List
              data={this.state.filteredContracts}
              renderItem={this.renderItem}
              showTotal={true}
            />
          </Card>
        </div>
        <Contract
          schoolId={this.props.schoolState.school.id}
          show={this.state.showingContract}
          contract={this.state.selectedContract}
          contractId={this.state.selectedContract.id ? this.state.selectedContract.id : 0}
          onClose={this.toggleContract}
          onDelete={this.handleDelete}
          students={this.props.schoolState.users}
          onSave={this.handleSave}
          terms={this.state.schoolInfo.contractTerms ? this.state.schoolInfo.contractTerms : ""}
          schoolActions={this.props.schoolActions}
          userId={this.state.selectedStudent.length > 0 && this.state.selectedStudent[0].id ? this.state.selectedStudent[0].id : 0}
          user={this.state.selectedStudent} />

      </div>
    );
  }

  private async fetchContracts() {
    this.setState({ loading: true }, async () => {
      let contracts = this.state.contracts;
      let schoolInfo: any = {
        terms: "",
      };
      try {
        const status = this.state.showStatus;
        let res = null;
        if (this.props.userId && this.props.userId !== 0) {
          res = await FinancesAPI.getUserContracts(this.props.schoolState.school.id, this.props.userId, status);
        } else {
          res = await FinancesAPI.getSchoolContracts(this.props.schoolState.school.id, status);
        }

        contracts = res.body.data;
        // now sort
        contracts = contracts.sort((a: any, b: any) => {
          return a.daysRemaining < b.daysRemaining ? -1 : 1;
        });
        const schoolRes = await SchoolsAPI.getSchoolInfo(this.props.schoolState.school.id);
        schoolInfo = schoolRes.body.data;
      } catch (e) {
        error("Could not load contracts");
      }
      this.setState({ contracts, schoolInfo }, () => {
        this.sort(this.state.sortField, this.state.sortDir);
      });
    });
  }


  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns, () => {
      this.fetchContracts();
    });
  }

  private updateFilter(e: any) {
    this.setState({ filter: e.target.value }, () => {
      this.sort(this.state.sortField, this.state.sortDir);
    });
  }

  private renderItem(index: number) {
    const c = this.state.filteredContracts[index];
    if(!c){
      return null;
    }
    let classes = "row list-row school-users-row ";
    if (index % 2 !== 0) {
      classes += "list-row-odd";
    }
    // users
    const users: any = [];
    for (const u of c.users) {
      if (users.length !== 0) {
        users.push(<span key={u.id + "span"}>, </span>);
      }
      users.push(<Link key={u.id} to={`/schools/${this.props.schoolState.school.id}/users/${u.id}`}>{u.firstName} {u.lastName}</Link>);
    }
    let statusCell = null;
    switch (c.status) {
      case "complete":
        statusCell = (<div>Complete</div>);
        break;
      case "cancelled":
        statusCell = (<div>Cancelled</div>);
        break;
      case "paused":
        statusCell = (<div className="text-warning">Paused</div>);
        break;
      case "expired":
        statusCell = (<div className="text-danger">Expired - Invoices Remain</div>);
        break;
      case "active":
        // how many days remaing?
        if (c.daysRemaining < 30) {
          statusCell = (<div className="text-warning">Active - Ends Soon</div>);
        } else {
          statusCell = (<div>Active</div>);
        }
        break;
      default:
        statusCell = null;
    }

    let endDateCell = null;
    if (c.status === "cancelled" || c.status === "complete" || c.status === "expired") {
      endDateCell = (<div> -- </div>);
    }
    if (c.projectedEndDate === "unknown") {
      endDateCell = (<div> Unknown </div>);
    }
    endDateCell = (<div>{moment(c.projectedEndDate).format("MM/DD/YYYY")}</div>);

    const totalInvoices = c.invoices.length;
    let paidInvoices = 0;
    for (const i of c.invoices) {
      if (i.status === "paid") {
        paidInvoices += 1;
      }
    }


    return (
      <div className={classes} key={c.id}>
        <div className="col-md-3">
          {users}
        </div>
        <div className="col-md-2">
          {statusCell}
        </div>
        <div className="col-md-1">
          <div>{moment(c.startDate).format("MM/DD/YYYY")}</div>
        </div>
        <div className="col-md-1">
          {endDateCell}
        </div>
        <div className="col-md-2">
          {paidInvoices} / {totalInvoices}
        </div>
        <div className="col-md-2">
          <div>{convertCurrency(c.monthlyPayment)}</div>
        </div>
        <div className="col-md-1">
          <button className="btn btn-primary" onClick={this.viewContract} value={c.id}>View</button>
        </div>
      </div>);
  }

  private sort(sortField: "student" | "status" | "start" | "end", sortDir: "asc" | "desc") {
    let contracts = this.state.contracts;
    // first filter
    const lowerFilter = this.state.filter.toLowerCase();
    if (lowerFilter !== "") {
      contracts = contracts.filter((contract: any) => {
        // check if the users have a matching character
        for (const u of contract.users) {
          const uName = `${u.firstName.toLowerCase()} ${u.lastName.toLowerCase()}`;
          if (uName.indexOf(lowerFilter) > -1) {
            return true;
          }
        }
        return false;
      });
    }
    // now sort
      if (this.state.sortField === "status") {
        contracts = contracts.sort((a: any, b: any) => {
          if (this.state.sortDir === "asc") {
            return a.status > b.status ? 1 : -1;
          }
          return a.status > b.status ? -1 : 1;
        });
      } else if (this.state.sortField === "start") {
        contracts = contracts.sort((a: any, b: any) => {
          const aStart = moment(a.startDate);
          const bStart = moment(b.startDate);
          if (this.state.sortDir === "asc") {
            return aStart.isBefore(bStart) ? 1 : -1;
          }
          return aStart.isBefore(bStart) ? -1 : 1;
        });
      } else if (this.state.sortField === "end") {
        contracts = contracts.sort((a: any, b: any) => {
          const aEnd = moment(a.projectedEndDate);
          const bEnd = moment(b.projectedEndDate);
          if (this.state.sortDir === "asc") {
            return aEnd.isBefore(bEnd) ? 1 : -1;
          }
          return aEnd.isBefore(bEnd) ? -1 : 1;
        });
      }
      this.setState({ filteredContracts: contracts, sortDir, sortField, loading: false });
  }

  private sortStudent() {
    const dir = this.state.sortField === "student" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("student", dir);
  }

  private sortStatus() {
    const dir = this.state.sortField === "status" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("status", dir);
  }

  private sortStart() {
    const dir = this.state.sortField === "start" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("start", dir);
  }

  private sortEnd() {
    const dir = this.state.sortField === "end" && this.state.sortDir === "asc" ? "desc" : "asc";
    this.sort("end", dir);
  }

  private getSelectedStudent() {
    if (!this.props.userId || this.props.userId === 0) {
      return [{ id: 0, fullName: "" }];
    }
    let s: any = [];
    const target = typeof this.props.userId === "string" ? parseInt(this.props.userId, 10) : this.props.userId;
    for (const student of this.props.schoolState.users) {
      if (student.id === target) {
        s = [student];
        break;
      }
    }
    return s;
  }

  private viewContract(e: any) {
    let selected = this.state.selectedContract;
    const target = parseInt(e.target.value, 10);
    for (const i of this.state.contracts) {
      if (i.id === target) {
        selected = i;
      }
    }
    this.setState({ selectedContract: selected, showingContract: true });
  }

  private newContract() {
    this.setState({ selectedContract: { id: 0 }, showingContract: true });
  }

  private toggleContract() {
    this.setState({
      showingContract: !this.state.showingContract
    });
  }

  private handleSave() {
    this.setState({
      showingContract: false,
      selectedContract: {}
    }, () => {
      this.fetchContracts();
      if(this.props.onSave){
        this.props.onSave();
      }
    });
  }

  private handleDelete() {
    this.setState({
      showingContract: false,
      selectedContract: {}
    }, () => {
      this.fetchContracts();
      if(this.props.onDelete){
        this.props.onDelete();
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolContractsScreen) as React.ComponentType<any>);