import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";
import { NewsAPI } from "../../../API/";

import Item from "./AnnouncementItem";

interface IDashboardProps {
  appActions: any;
  schoolState: any;
  history: any;
}

interface IDashboardState {
  loading: boolean;
  news: any;
  showCreateModal: boolean;
  newNews: any;
}

const helpText = `Use this tool to make new announcements that will be available to all students who use the KDojang Student mobile application. If enabled, they will receive a push notification as well.`;

class AnnouncementsScreen extends React.Component<IDashboardProps, IDashboardState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      news: [],
      newNews: {
        body: "",
        title: "",
        sendPush: "no"
      },
      showCreateModal: false,
    };

    this.fetchNews = this.fetchNews.bind(this);
    this.toggleCreateNewsModal = this.toggleCreateNewsModal.bind(this);
    this.deleteNews = this.deleteNews.bind(this);
    this.saveNews = this.saveNews.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  public componentDidMount(){
    this.fetchNews();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <Card title="Announcements" loading={this.state.loading} help={helpText}>
            {this.state.news.length === 0 && (<strong>No announcements have been created</strong>)}
            {this.state.news.map((item: any, index: number) => {
              const className = index % 2 === 0 ? "" : "list-row-odd";
              item.posted = moment(item.posted);
              return (
                <Item 
                  key={item.id}
                  className={className}
                  announcement={item}
                  onDelete={this.deleteNews}
                />
              );
            })}
            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-block btn-success" onClick={this.toggleCreateNewsModal}>Create Announcement</button>
              </div>
            </div>
          </Card>
        </div>



        <Modal show={this.state.showCreateModal} onHide={this.toggleCreateNewsModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Announcement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Title</label>
              <input type="text" id="title" className="form-control" onChange={this.updateField} value={this.state.newNews.title} />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea id="body" className="form-control" onChange={this.updateField} value={this.state.newNews.body} />
            </div>
            <div className="form-group">
              <label>Send Push Notification</label>
              <select className="form-control" id="sendPush" value={this.state.newNews.sendPush} onChange={this.updateField}>
                <option value="no">No, just post the announcement</option>
                <option value="yes">Yes, also send a push notification to mobile users</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.saveNews}>Create Announcement</button>
            <button className="btn btn-primary" onClick={this.toggleCreateNewsModal}>Cancel</button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }

  private updateField(e: any){
    const id = e.target.id;
    const value = e.target.value;
    const newNews = this.state.newNews;
    newNews[id] = value;
    this.setState({ newNews });
  }

  private fetchNews(){
    this.setState({loading: true}, async () => {
      try{
        const newsResult = await NewsAPI.getSchoolNews(this.props.schoolState.school.id);
        this.setState({loading: false, news: newsResult.body.data});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private deleteNews(news: any){
    this.setState({loading: true}, async () => {
      try{
        await NewsAPI.deleteSchoolNews(this.props.schoolState.school.id, news.id);
        // remove it from the state
        const items: any = [];
        for(const i of this.state.news){
          if(i.id !== news.id){
            items.push(i);
          }
        }
        success("Announcement deleted!");
        this.setState({loading: false, news: items});
      }catch(err){
        error("Could not delete that announcement!");
        this.setState({loading: false});
      }
    });
  }

  private saveNews(){
    if(this.state.newNews.title === "" || this.state.newNews.body === ""){
      return error("Title and Body are required");
    }
    const data = {
      title: this.state.newNews.title,
      body: this.state.newNews.body,
      sendPush: this.state.newNews.sendPush === "yes" ? true : false
    };
    this.setState({loading: true}, async () => {
      try{
        const res = await NewsAPI.createSchoolNews(this.props.schoolState.school.id, data);
        const news = this.state.news;
        news.unshift(res.body.data);
        success("Announcement created!");
        this.setState({loading: false, news, showCreateModal: false, newNews: {title: "", body: ""}});
      }catch(err){
        error("Could not create that announcement!");
        this.setState({loading: false});
      }
    });
  }

  private toggleCreateNewsModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnnouncementsScreen) as React.ComponentType<any>);