import * as React from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend } from "recharts";


interface IBarGraphData {
  total: number;
  data: IBarGraphDataPoint[];
}

interface IBarGraphDataPoint {
  count: number;
  label?: string;
}

interface IBarGraphProps {
  data: IBarGraphData | any;
  bars?: any;
  hideLegend?: boolean;
}

interface IBarGraphState {
  loading: boolean;
}

class BarGraph extends React.Component<IBarGraphProps, IBarGraphState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
    };

    this.getBars = this.getBars.bind(this);

  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
        <BarChart data={this.props.data.data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
          {this.getBars()}
          <YAxis />
          <XAxis dataKey="label" />
          <Tooltip />
          {!this.props.hideLegend && (<Legend />)}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  private getBars(){
    if(!this.props.bars){
      return (<Bar dataKey="count" fill="#8884d8" />);
    }
    const bars = [];
    for(const bar of this.props.bars){
      bars.push(<Bar dataKey={bar.dataKey} fill={bar.fill ? bar.fill : "#8884d8"} />);
    }
    return bars;
  }

}

export default BarGraph;