import * as React from "react";

import Card from "../../structure/Card";
import { ReportsAPI } from "../../../API";
import BeltImage from "../Belts/BeltImage";
import PieChart from "../../structure/PieChart";

interface IBeltsReportProps {
  schoolId: number;
}

interface IBeltsReportState {
  loading: boolean;
  data: any[];
  pieData: any[];
}

class BeltsReport extends React.Component<IBeltsReportProps, IBeltsReportState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: [],
      pieData: [],
    };

    this.fetch = this.fetch.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-3 col-md-offset-2">
          <Card title="Belts Distribution" loading={this.state.loading} help="">
            {this.state.data.map((item: any, index: number) => {
              return (
                <div className="row" key={index} style={{marginBottom: 5}}>
                  <div className="col-md-1">
                    <BeltImage topColor={"#"+item.topColor} bottomColor={"#"+item.bottomColor} numberDisplay={0} numberDisplayColor={""} />
                  </div>
                  <div className="col-md-4">
                    {item.name}
                  </div>
                  <div className="col-md-3">
                    {item.count}
                  </div>
                </div>
              );
            })}
          </Card>
        </div>

        <div className="col-md-5">
          <Card title="Belts" loading={this.state.loading} help="">
            <PieChart data={this.state.pieData} />
          </Card>
        </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try {
        const res = await ReportsAPI.getBeltsDistributionReport(this.props.schoolId);
        // create the pie data
        const pieData = [];
        for(const d of res.body.data){
          pieData.push({
            name: d.name,
            color: d.topColor,
            topColor: d.topColor,
            bottomColor: d.bottomColor,
            value: d.count
          });
        }
        this.setState({loading: false, data: res.body.data, pieData});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

}


export default BeltsReport;