import * as React from "react";
import { Modal } from "react-bootstrap";
import * as Converter from "react-showdown";
import * as moment from "moment";

interface IMessageHistoryListItem {
  id: number;
  sentOn: string;
  subject: string;
  body: string;
  messageType: string;
}


interface IMessageHistoryListItemProps {
  className: string;
  message: IMessageHistoryListItem;
}

interface IMessageHistoryListItemState {
  loading: boolean;
  showModal: boolean;
}

class MessageHistoryListItem extends React.Component<IMessageHistoryListItemProps, IMessageHistoryListItemState> {

  private converter: any;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
    };
    this.converter = new Converter.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });

    this.toggleShow = this.toggleShow.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-3 vertical-container">
          {moment(this.props.message.sentOn).format("MM/DD/YY HH:mm")}
        </div>
        <div className="col-md-2 vertical-container">
          {this.props.message.messageType === "email" ? "Email" : "SMS"}
        </div>
        <div className="col-md-5 vertical-container">
        {this.props.message.subject && this.props.message.subject !== "" ? 
          (this.props.message.subject.length > 15 ? this.props.message.subject.substr(0, 13) + "..." : this.props.message.subject) : 
          (this.props.message.body.length > 15 ? this.props.message.body.substr(0, 13) + "..." : this.props.message.body)}
        </div>
        <div className="col-md-2 vertical-container">
          <span className="text-primary glyphicon glyphicon-eye-open" onClick={this.toggleShow} />
        </div>

        <Modal show={this.state.showModal} onHide={this.toggleShow} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Message Type</label>
              <p>{this.props.message.messageType === "email" ? "Email" : "SMS"}</p>
            </div>
            {this.props.message.messageType === "email" && (
              <div className="form-group">
                <label>Subject</label>
                <p>{this.props.message.subject}</p>
              </div>
            )}
            <div className="form-group">
              <label>Body</label>
              <div>{this.converter.convert(this.props.message.body)}</div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.toggleShow}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleShow() {
    this.setState({ showModal: !this.state.showModal});
  }
}

export default MessageHistoryListItem;