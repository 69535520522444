import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";
import LazyLoad from "react-lazyload";

import Card from "../../../structure/Card";
import * as AppActions from "../../../../reducers/appReducer";
import { CompetitionsAPI } from "../../../../API";
import { capFirstLetter } from "../../../../utils";

interface IUserCompetitionsProps {
  appActions: any;
  history: any;
  schoolId: number;
  userId: number;
}

interface IUserCompetitionsState {
  loading: boolean;
  competitions: any[];
}

class UserCompetitions extends React.Component<IUserCompetitionsProps, IUserCompetitionsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      competitions: []
    };

    this.fetch = this.fetch.bind(this);
  }

  public componentDidMount(){
    this.fetch(this.props.userId);
  }

  public componentWillReceiveProps(nextProps: any){
    if(nextProps.userId !== this.props.userId){
      this.fetch(nextProps.userId);
    }
  }

  public render() {
    return (
      <Card title="Competitions" loading={this.state.loading} help="Users who attend competitions can have their placements tracked. This is accomplished through the Competitions screen listed in the Menu.">
        <div className="row">
          <div className="col-md-2">
            <strong>Start Date</strong>
          </div>
          <div className="col-md-3">
            <strong>Competition</strong>
          </div>
          <div className="col-md-3">
            <strong>Event</strong>
          </div>
          <div className="col-md-2">
            <strong>Role</strong>
          </div>
          <div className="col-md-2">
            <strong>Place</strong>
          </div>
        </div>
        {this.state.competitions.length === 0 && (
          <div className="row"><div className="col-md-12">No competitions have been entered for this student.</div></div>
        )}
        {this.state.competitions.map((item: any, index: number) => {
          item.startDate = moment(item.startDate).format("MM/DD/YYYY");
          return (
            <LazyLoad once={true} key={index} overflow={true} throttle={100}>
              <div key={index} className={index % 2 === 1 ? "list-row-odd row" : "row"}>
                <div className="col-md-2">
                  {item.startDate}
                </div>
                <div className="col-md-3">
                  {item.competitionName}
                </div>
                <div className="col-md-3">
                  {item.eventName}
                </div>
                <div className="col-md-2">
                  {capFirstLetter(item.role)}
                </div>
                <div className="col-md-2">
                  {item.place}
                </div>
              </div>
            </LazyLoad>
          );
        })}
      </Card>
    );
  }

  private fetch(userId: number){
    this.setState({loading: true}, async () => {
      try{
        const res = await CompetitionsAPI.getUserCompetitions(this.props.schoolId, this.props.userId);
        this.setState({ loading: false, competitions: res.body.data});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserCompetitions) as React.ComponentType<any>);