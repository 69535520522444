
import { UserAPI, SchoolUsersAPI, BeltsAPI, AlertsAPI, SchoolsAPI } from "../API";

export async function refreshData(user: any, userActions: any, schoolActions: any, alertsActions: any, setupActions: any, redirect: boolean = false, history: any = null) : Promise<any> {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      // get the school information
      const schoolsResult = await UserAPI.getUserSchools(user.id);
      const schools = schoolsResult.body.data;
      if(schools.length === 0){
        return reject("You must be an assistant or administrator for a school");
      }
      let foundSchool = false;
      let selectedSchool: any = {};
      for(const s of schools){
        if(s.role === "administrator" || s.role === "assistant"){
          foundSchool = true;
          selectedSchool = s;
          break;
        }
      }
      if(!foundSchool){
        return reject("You must be an assistant or administrator for a school");
      }
      window.localStorage.schoolId = selectedSchool.id;
  
      const userPayload: any = {
        loggedIn: true,
        schools,
        selectedSchool,
        user
      };
  
      const schoolUsersResult = await SchoolUsersAPI.getUsersInSchool(selectedSchool.id, {sortDir: "lastName"});
      const schoolUsers = schoolUsersResult.body.data;
      const beltsResult = await BeltsAPI.getSchoolBelts(selectedSchool.id);
      const schoolBelts = beltsResult.body.data;
      selectedSchool.belts = schoolBelts;
  
      const alertsResult = await AlertsAPI.getSchoolAlerts(selectedSchool.id);
      const alerts = alertsResult.body.data;

      const setupStepsResult = await SchoolsAPI.getSetupSteps(selectedSchool.id);
      const steps = setupStepsResult.body.data;
  
      await userActions.loginUser(userPayload);
      await schoolActions.setSchool(selectedSchool);
      await schoolActions.setUsers(schoolUsers);
      await alertsActions.setAlerts(alerts);
      await setupActions.loadAllSteps(steps);

      if(redirect){
        history.push("/dashboard");
      }
      return resolve({
        userPayload,
        selectedSchool,
        schoolUsers,
        alerts
      });
      
    } catch(e){
      return reject("Could not log you in");
    }
  });
  
}