import * as React from "react";

interface IStarterProps {
  topColor: string;
  bottomColor: string;
  numberDisplay: number;
  numberDisplayColor: string;
}

class BeltImage extends React.Component<IStarterProps, any> {

  constructor(props: any){
    super(props);
  }

  public render() {
    return (
      <div style={{height: 30}}>
        <div className="row">
          <div className="col-md-12" style={{height: 15, backgroundColor: `${this.props.topColor}`, borderTop: "thin solid black", borderLeft: "thin solid black", borderRight: "thin solid black"}} />
        </div>
        <div className="row">
          <div className="col-md-12" style={{height: 15, backgroundColor: `${this.props.bottomColor}`, borderBottom: "thin solid black", borderLeft: "thin solid black", borderRight: "thin solid black"}} />
        </div>
      </div>
    );
  }

}

export default BeltImage;