import * as React from "react";

import Card from "../../structure/Card";
import { ReportsAPI } from "../../../API";
import * as moment from "moment";
import DatePicker from "../../structure/DatePicker";
import {format} from "../../../utils/currency";

interface IRevenuePerFamilyReport {
  id: number;
  name: string;
  amount: number;
}

interface IRevenuePerFamilyReportProps {
  schoolId: number;
}

interface IRevenuePerFamilyReportState {
  loading: boolean;
  data: IRevenuePerFamilyReport[];
  start: moment.Moment;
  end: moment.Moment;
  revenue: number;
}

class RevenuePerFamilyReport extends React.Component<IRevenuePerFamilyReportProps, IRevenuePerFamilyReportState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      data: [],
      start: moment().subtract(1, "year"),
      end: moment(),
      revenue: 0,
    };

    this.fetch = this.fetch.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Card title="Dates" loading={this.state.loading} help="">
              <div className="row">
                <div className="col-md-6">
                  <label>From</label>
                  <DatePicker date={this.state.start} onDateSaved={this.handleStartDateChanged} />
                </div>
                <div className="col-md-6">
                  <label>To</label>
                  <DatePicker date={this.state.end} onDateSaved={this.handleEndDateChanged} />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Card title="Revenue" loading={this.state.loading} help="">
              <div className="row" style={{marginBottom: 5}}>
                <div className="col-md-4 row-header">
                  Family Name
                </div>
                <div className="col-md-8 row-header">
                  Total Amount in Time Frame
                </div>
              </div>
              {this.state.data.map((item: IRevenuePerFamilyReport, index: number) => {
                return (
                  <div className={index % 2 === 1 ? "list-row-odd row" : "row"} key={index} style={{marginBottom: 5}}>
                    <div className="col-md-4">
                      {item.name}
                    </div>
                    <div className="col-md-8">
                      {format(item.amount)}
                    </div>
                  </div>
                );
              })}
              <div className="row" style={{marginBottom: 5}}>
                <div className="col-md-8 col-md-offset-4 row-header">
                  {format(this.state.revenue)}
                </div>
              </div>
            </Card>
          </div>
      </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try {
        const res = await ReportsAPI.getRevenuePerFamilyReport(this.props.schoolId, this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD"));
        let revenue = 0.0;
        
        for(const d of res.body.data){
          revenue += d.amount;
        }
        this.setState({loading: false, data: res.body.data, revenue});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private handleStartDateChanged(newDate: moment.Moment){
    this.setState({start: newDate}, () => { this.fetch();});
  }

  private handleEndDateChanged(newDate: moment.Moment){
    this.setState({end: newDate}, () => { this.fetch();});
  }

}


export default RevenuePerFamilyReport;