import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../reducers/appReducer";

import BasicSettings from "./SchoolBasicSettingsScreen";
import PlanScreen from "./SchoolPlanScreen";
import PayoutScreen from "./SchoolPayoutScreen";

interface ISchoolSettingsScreenProps {
  appActions: any;
  schoolState: any;
  userId: any;
  history: any;
  match: any;
}

interface ISchoolSettingsScreenState {
  loading: boolean;
  school: any;
}

class SchoolSettingsScreen extends React.Component<ISchoolSettingsScreenProps, ISchoolSettingsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      school: {
        id: 0,
      }
    };
  }

  get component(){
    switch(this.props.match.params.view){
      case "payout":
        return (<PayoutScreen />);
      case "plan":
        return (<PlanScreen />);
      case "settings":
      default:
        return (<BasicSettings />);
    }
  }


  public render() {
    const active = this.props.match.params && this.props.match.params.view ? this.props.match.params.view : "settings";
    return (
      <div>
        <div className="row">
          <div id="financesNavbar" className="col-md-8 col-md-offset-2">
            <nav className="navbar navbar-inverse" style={{borderRadius: "5px"}}>
              <div className="navbar-header">
                <Link 
                  to={`/schools/${this.props.schoolState.school.id}/settings`}
                  className="navbar-brand">
                  {`${this.props.schoolState.school.name}`}
                </Link>
              </div>
              <div className="collapse navbar-collapse">
                <ul className="nav navbar-nav">
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/settings`} 
                    className={active === "settings" ? "subNavLinkActive" : "subNavLink"}>Basic Settings</Link></li>
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/settings/plan`} 
                    className={active === "plan" ? "subNavLinkActive" : "subNavLink"}>Plan</Link></li>
                  <li><Link to={`/schools/${this.props.schoolState.school.id}/settings/payout`} 
                    className={active === "payout" ? "subNavLinkActive" : "subNavLink"}>Payout Settings</Link></li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.component}
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolSettingsScreen) as React.ComponentType<any>);