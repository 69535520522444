import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import { DocumentsAPI } from "../../../API";
import Card from "../../structure/Card";
import { success, error } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";

import Item from "./DocumentCategoryListItem";

interface IDocumentCategory {
  id: number;
  schoolId: number;
  name: string;
  description: string;
}

interface IDocumentsScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface IDocumentsScreenState {
  loading: boolean;
  categories: IDocumentCategory[];
  name: string;
  description: string;
  showCreateModal: boolean;
}

class DocumentsScreen extends React.Component<IDocumentsScreenProps, IDocumentsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      categories: [],
      name: "",
      description: "",
      showCreateModal: false
    };
    this.fetch = this.fetch.bind(this);
    this.updateField = this.updateField.bind(this);
    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <Card title="Document Categories" loading={this.state.loading} help="Document categories are types of documents that your school wants to keep for students, such as waivers, photo release forms, and other forms from outside of KDojang. You may create as many categories as you would like. Then, you should visit the student's profile and upload the file in the category for that student.">
          {this.state.categories.length === 0 && (<strong>No document categories have been created</strong>)}
            {this.state.categories.map((cat: IDocumentCategory, index: number) => {
              return (
                <Item 
                  key={cat.id}
                  item={cat}
                  schoolId={this.props.schoolState.school.id}
                  className={index % 2 === 1 ? "list-row-odd" : ""}
                  onDelete={this.handleDelete}
                  onEdit={this.handleEdit} />
              );
            })}
            <div className="form-group">
              <button className="btn btn-block btn-primary" onClick={this.toggleCreateModal}>Create New Category</button>
            </div>
          </Card>
        </div>

        <Modal show={this.state.showCreateModal} onHide={this.toggleCreateModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name</label>
              <input type="text" id="name" className="form-control" value={this.state.name} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea id="description" className="form-control" value={this.state.description} onChange={this.updateField} rows={4} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.createCategory}>Save</button>
            <button className="btn btn-primary" onClick={this.toggleCreateModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const res = await DocumentsAPI.getDocumentCategories(this.props.schoolState.school.id);
        this.setState({
          loading: false,
          categories: res.body.data,
          name: "",
          description: "",
          showCreateModal: false,
        });
      }catch(err){
        error("Could not load categories");
        this.setState({loading: false});
      }
    });
  }

  private handleEdit(id: number, name: string, description: string){
    const cats: IDocumentCategory[] = [];
    for(const c of this.state.categories){
      if(c.id === id){
        c.name = name;
        c.description = description;
      }
      cats.push(c);
    }
    this.setState({categories: cats});
  } 
  
  private handleDelete(id: number){
    const cats: IDocumentCategory[] = [];
    for(const c of this.state.categories){
      if(c.id !== id){
        cats.push(c);
      }
    }
    this.setState({categories: cats});
  }

  private toggleCreateModal(){
    this.setState({showCreateModal: !this.state.showCreateModal});
  }

  private createCategory(){
    const data = {
      name: this.state.name,
      description: this.state.description
    };
    if(data.name === "" || data.description === ""){
      return error("Name and description are both required");
    }
    this.setState({loading: true}, async () => {
      try{
        await DocumentsAPI.createDocumentCategory(this.props.schoolState.school.id, data);
        this.fetch();
        return success("Created!");
      }catch(err){
        error("Could not create that document category");
        this.setState({loading: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentsScreen) as React.ComponentType<any>);