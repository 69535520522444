import * as React from "react";
import { capFirstLetter } from "../../../utils";

interface ISchoolCompetitionEventsParticipantsListItem {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  role: string;
  place: number;
}


interface ISchoolCompetitionEventsParticipantsListItemProps {
  className: string;
  item: ISchoolCompetitionEventsParticipantsListItem;
  onSelect: any;
  onDelete: any;
}

interface ISchoolCompetitionEventsParticipantsListItemState {
  loading: boolean;
}

class SchoolCompetitionEventsParticipantsListItem extends React.Component<ISchoolCompetitionEventsParticipantsListItemProps, ISchoolCompetitionEventsParticipantsListItemState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public render() {
    return (
      <div className={"row list-row " + this.props.className} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4 }}>
        <div className="col-md-4 vertical-container">
          {this.props.item.firstName} {this.props.item.lastName}
        </div>
        <div className="col-md-4 vertical-container">
          {capFirstLetter(this.props.item.role)}
        </div>
        <div className="col-md-2 vertical-container">
          {this.props.item.place === 0 ? "" : this.props.item.place}
        </div>
        <div className="col-md-2 vertical-container">
          <span className="text-primary glyphicon glyphicon-edit" onClick={this.handleSelect} style={{marginRight: 5}} />
          <span className="text-danger glyphicon glyphicon-remove" onClick={this.handleDelete}  />
        </div>

        
      </div>
    );
  }

  private handleSelect() {
    this.props.onSelect(this.props.item);
  }

  private handleDelete() {
    this.props.onDelete(this.props.item);
  }
}

export default SchoolCompetitionEventsParticipantsListItem;