import { makeCall } from "./client";

export class NotesAPI {
  
  /**
   * Gets the notes for a school
   * @param schoolId 
   */
  public getSchoolNotes(schoolId: number, includeArchived: boolean, params: any = {}): Promise<any> {
    if(includeArchived){
      params.includeArchived = true;
    } else {
      params.includeArchived = false;
    }
    return makeCall("get", `schools/${schoolId}/notes`, params);
  }

  /**
   * Get the notes for a user
   * @param schoolId 
   * @param userId 
   * @param includeArchived 
   * @param params 
   */
  public getUserNotes(schoolId: number, userId: number, includeArchived: boolean, params: any = {}): Promise<any> {
    if(includeArchived){
      params.includeArchived = true;
    } else {
      params.includeArchived = false;
    }
    return makeCall("get", `schools/${schoolId}/users/${userId}/notes`, params);
  }

  /**
   * Update the subject or body of a note
   * @param schoolId 
   * @param noteId 
   * @param subject 
   * @param body 
   * @param params 
   */
  public updateNote(schoolId: number, noteId: number, subject: string, body: string, params: any = {}): Promise<any> {
    if(subject !== ""){
      params.subject = subject;
    }
    if(body !== ""){
      params.body = body;
    }
    return makeCall("patch", `schools/${schoolId}/notes/${noteId}`, params);
  }

  /**
   * Toggles the archive status for a note
   * @param schoolId 
   * @param noteId 
   * @param archived 
   * @param params 
   */
  public toggleNoteArchiveStatus(schoolId: number, noteId: number, archived: boolean, params: any = {}): Promise<any> {
    params.archived = archived;
    return makeCall("patch", `schools/${schoolId}/notes/${noteId}`, params);
  }
  
  /**
   * Delete a note
   * @param schoolId 
   * @param noteId 
   * @param params 
   */
  public deleteNote(schoolId: number, noteId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/notes/${noteId}`, params);
  }

  /**
   * Create a new note
   * @param schoolId 
   * @param subject 
   * @param body 
   * @param students 
   * @param params 
   */
  public createNote(schoolId: number, subject: string, body: string, students: any = [], params: any = {}){
    params.subject = subject;
    params.body = body;
    params.students = students;
    return makeCall("post", `schools/${schoolId}/notes`, params);
  }

  /**
   * Add a user to a note
   * @param schoolId 
   * @param noteId 
   * @param userId 
   * @param params 
   */
  public addUserToNote(schoolId: number, noteId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/notes/${noteId}`, params);
  }

  /**
   * Remove a user from a note
   * @param schoolId 
   * @param noteId 
   * @param userId 
   * @param params 
   */
  public removeUserFromNote(schoolId: number, noteId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/users/${userId}/notes/${noteId}`, params);
  }

  
}