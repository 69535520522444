import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SchoolsAPI } from "../../../API";
import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import StateSelect from "../../structure/StateSelect";
import Terms from "../Terms";
import { error } from "../../structure/Alert";

interface ISchoolSignupScreenProps {
  appActions: any;
  history: any;
}

interface ISchoolSignupScreenState {
  loading: boolean;
  step: number;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPassword: string;
  userUsername: string;
  schoolName: string;
  schoolStreet: string;
  schoolCity: string;
  schoolState: string;
  schoolZip: string;
  schoolEIN: string;
  schoolPhone: string;
  agreedToTerms: "no" | "yes";
}

class SchoolSignupScreen extends React.Component<ISchoolSignupScreenProps, ISchoolSignupScreenState> {

   private terms = new Terms({});

  constructor(props: any) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userPassword: "",
      userUsername: "",
      schoolName: "",
      schoolStreet: "",
      schoolCity: "",
      schoolState: "",
      schoolZip: "",
      schoolEIN: "",
      schoolPhone: "",
      agreedToTerms: "no",
    };

    this.updateField = this.updateField.bind(this);
    this.signupSchool = this.signupSchool.bind(this);
  }

  public render() {
    if(this.state.step === 2){
      return (
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="Success!" loading={this.state.loading} help="">
              <p><strong>Success!</strong></p>
              <p>Please check your email for your account verifcation. You will then be able to login and get started with your new school. If you have any questions or
                need any assistance, please reach out to our support team at any time!
              </p>
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="Set Up a New School" loading={this.state.loading} help="">
              <p>Use this screen to create a brand new school.You will be creating a new administrator account and school in the system. Using KDojang is free; we only charge a 
                a small fee on credit card transactions or for services such as email or SMS. Basic use, including contract management, schedule management, and attendance tracking
                will always be free!</p>
              <p>If you need assistance transferring
                an existing school, please contact our support team for assistance first.
              </p>
              <p><strong>Note: </strong> You must agree to the terms and conditions when signing up a new school. We currently only allow schools in the United States. If you
                represent a school in a different country, please contact support to discuss our ability to serve you!
              </p>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-md-offset-1">
            <Card title="Step 1: Administrator Account" loading={this.state.loading} help="">
              <p>Please create a new account to serve as the administrator of the school</p>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input type="text" id="userFirstName" className="form-control" value={this.state.userFirstName} onChange={this.updateField} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input type="text" id="userLastName" className="form-control" value={this.state.userLastName} onChange={this.updateField} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Desired Username</label>
                <input type="text" id="userUsername" className="form-control" value={this.state.userUsername} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" id="userEmail" className="form-control" value={this.state.userEmail} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input type="password" id="userPassword" className="form-control" value={this.state.userPassword} onChange={this.updateField} />
              </div>
            </Card>
          </div>
          <div className="col-md-6">
            <Card title="Step 2: School Information" loading={this.state.loading} help="">
              <p>Tell us about your school</p>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>School Name</label>
                    <input type="text" id="schoolName" className="form-control" value={this.state.schoolName} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label>School Phone</label>
                    <input type="text" id="schoolPhone" className="form-control" value={this.state.schoolPhone} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label>School EIN</label>
                    <input type="text" id="schoolEIN" className="form-control" value={this.state.schoolEIN} onChange={this.updateField} />
                    <span className="small">Although an EIN is not required for signup, you must provide one if you utilize our credit card processing features.</span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Street Address</label>
                    <input type="text" id="schoolStreet" className="form-control" value={this.state.schoolStreet} onChange={this.updateField} />
                  </div>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="form-group">
                        <label>City</label>
                        <input type="text" id="schoolCity" className="form-control" value={this.state.schoolCity} onChange={this.updateField} />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label>State</label>
                        <StateSelect id="schoolState" selectedState={this.state.schoolState} onSelect={this.updateField} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Zip</label>
                    <input type="text" id="schoolZip" className="form-control" value={this.state.schoolZip} onChange={this.updateField} />
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <Card title="Step 3: Terms of Service" loading={this.state.loading} help="">
                <div className="row">
                  <div className="col-md-3">
                    <p>Use of this application requires acceptance of our Terms of Service, located to the right and available <a href="/terms" target="_new">here</a></p>
                    <div className="form-group">
                      <label>Do you agree to the terms?</label>
                      <select id="agreedToTerms" className="form-control" value={this.state.agreedToTerms} onChange={this.updateField}>
                        <option value="no">No, I Do Not Agree</option>
                        <option value="yes">Yes, I Agree</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-9" style={{height: 200, overflowY: "scroll"}}>
                    {this.terms.getTerms()}
                  </div>
                </div>
              </Card>
            </div>
          </div>


          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <Card title="Step 4: Confirm and Setup School" loading={this.state.loading} help="">
                <button className="btn btn-block btn-success" onClick={this.signupSchool} disabled={this.state.agreedToTerms === "no"}>Signup A New School!</button>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    const key = e.target.id;
    const value = e.target.value;
    ns[key] = value;
    this.setState(ns);
  }

  private signupSchool(){
    const data = {
      school: {
        name: this.state.schoolName,
        addressStreet: this.state.schoolStreet,
        addressCity: this.state.schoolCity,
        addressState: this.state.schoolState,
        addressZip: this.state.schoolZip,
        ein: this.state.schoolEIN,
        phone: this.state.schoolPhone,
      },
      user: {
        username: this.state.userUsername,
        firstName: this.state.userFirstName,
        lastName: this.state.userLastName,
        emailAddress: this.state.userEmail,
        password: this.state.userPassword,
      }
    };
    // all fields except ein are required
    for(const k of Object.keys(data.school)){
      if(k !== "ein"){
        if(data.school[k] === ""){
          return error("All fields except for EIN are required");
        }
      }
    }
    for(const k of Object.keys(data.user)){
      if(data.user[k] === ""){
        return error("All fields except for EIN are required");
      }
    }
    this.setState({ loading: true}, async () => {
      try{
        await SchoolsAPI.createNewSchool(data);
        this.setState({ step: 2, loading: false});
      }catch(err){
        this.setState({ loading: false }, () => {
          if(err.code && err.code === 409){
            error("That username already exists. Please try a new one or contact support.");
          } else {
            error("We could not create that school. Please check your data and contact support if you need additional assistance.");
          }
        });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolSignupScreen) as React.ComponentType<any>);