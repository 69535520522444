import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import { error } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";
import { CompetitionsAPI } from "../../../API";

import SchoolCompetitionsList from "./SchoolCompetitionsList";
import EventsList from "./SchoolCompetitionsEventsList";
import ParticipantsList from "./SchoolCompetitionsEventsParticipantsList";

interface ISchoolCompetitionsScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface ISchoolCompetitionsScreenState {
  loading: boolean;
  competitions: any;
  start: moment.Moment;
  end: moment.Moment;
  selectedCompetition: any;
  selectedEvent: any;
}

class SchoolCompetitionsScreen extends React.Component<ISchoolCompetitionsScreenProps, ISchoolCompetitionsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      competitions: [],
      start: moment().subtract(6, "months"),
      end: moment().add(6, "months"),
      selectedCompetition: {
        id: 0,
      },
      selectedEvent: {
        id: 0,
      },
    };

    this.fetch = this.fetch.bind(this);
    this.handleSelectCompetition = this.handleSelectCompetition.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleNewCompetition = this.handleNewCompetition.bind(this);
    this.handleDeleteCompetition = this.handleDeleteCompetition.bind(this);
    this.handleNewEvent = this.handleNewEvent.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.handleUserAdded = this.handleUserAdded.bind(this);
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
    this.handleUserRemoved = this.handleUserRemoved.bind(this);
    this.handleUserEdited = this.handleUserEdited.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <SchoolCompetitionsList 
            filterStart={this.state.start}
            filterEnd={this.state.end}
            selectedCompetition={this.state.selectedCompetition}
            onNewCompetition={this.handleNewCompetition}
            onSelectCompetition={this.handleSelectCompetition}
            onFilterChange={this.handleFilterChange}
            competitions={this.state.competitions}
            schoolId={this.props.schoolState.school.id}
            onDeleteCompetition={this.handleDeleteCompetition} />
        </div>
        {this.state.selectedCompetition.id !== 0 && (
          <div className="col-md-4">
            <EventsList 
              schoolId={this.props.schoolState.school.id}
              onNewEvent={this.handleNewEvent}
              onDeleteEvent={this.handleDeleteEvent}
              selectedCompetition={this.state.selectedCompetition}
              selectedEvent={this.state.selectedEvent}
              onSelectEvent={this.handleSelectEvent} />
          </div>
        )}
        {this.state.selectedEvent.id !== 0 && (
          <div className="col-md-4">
            <ParticipantsList 
              selectedEvent={this.state.selectedEvent}
              schoolId={this.props.schoolState.school.id}
              users={this.props.schoolState.users} 
              onUserAdded={this.handleUserAdded}
              onUserRemoved={this.handleUserRemoved}
              onUserEdited={this.handleUserEdited} />
          </div>
        )}
      </div>
    );
  }

  private fetch(){
    this.setState({ loading: true }, async () => {
      try{
        const params = {
          start: this.state.start,
          end: this.state.end,
        };
        const res = await CompetitionsAPI.getSchoolCompetitions(this.props.schoolState.school.id, params);
        this.setState({ loading: false, competitions: res.body.data});
      }catch(err){
        error("Could not load competitions. Ensure you have the correct permissions!");
        this.setState({loading: false});
      }
    });
  }

  private handleFilterChange(filter: string, newDate: moment.Moment){
    const ns = this.state;
    ns[filter] = newDate;
    this.setState(ns, () => {
      this.fetch();
    });
  }

  private handleSelectCompetition(selectedCompetition: any){
    this.setState({loading: true}, async () => {
      try{
        const res = await CompetitionsAPI.getSchoolCompetition(this.props.schoolState.school.id, selectedCompetition.id);
        this.setState({ loading: false, selectedCompetition: res.body.data});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private handleNewCompetition(newCompetition: any){
    newCompetition.events = [];
    this.setState({ selectedCompetition: newCompetition}, () => {
      this.fetch();
    });
  }

  private handleDeleteCompetition(){
    this.fetch();
  }

  private handleNewEvent(newEvent: any){
    const comp = this.state.selectedCompetition;
    comp.events.push(newEvent);
    this.setState({ selectedCompetition: comp});
  }

  private handleSelectEvent(event: any){
    this.setState({ selectedEvent: event});
  }

  private handleUserAdded(user: any){
    const event = this.state.selectedEvent;
    event.users.push(user);
    this.setState({selectedEvent:event });
  }

  private handleUserEdited(user: any){
    const users = [];
    for(let u of this.state.selectedEvent.users){
      if(u.userId === user.userId){
        u = user;
      }
      users.push(u);
    }
    const event = this.state.selectedEvent;
    event.users = users;
    this.setState({selectedEvent: event});
  }

  private handleUserRemoved(user: any){
    const event = this.state.selectedEvent;
    const users = [];
    for(const u of this.state.selectedEvent.users){
      if(u.userId !== user.userId){
        users.push(u);
      }
    }
    event.users = users;
    this.setState({selectedEvent: event});
  }

  private handleDeleteEvent(){
    const comp = this.state.selectedCompetition;
    const events = [];
    for(const e of comp.events){
      if(e.id !== this.state.selectedEvent.id){
        events.push(e);
      }
    }
    comp.events = events;
    this.setState({ selectedCompetition: comp, selectedEvent: {id: 0}});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolCompetitionsScreen) as React.ComponentType<any>);