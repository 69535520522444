import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// import Card from "../../../structure/Card";
import * as AppActions from "../../../../reducers/appReducer";

interface IUserPersonalRecordsProps {
  appActions: any;
  history: any;
}

interface IUserPersonalRecordsState {
  loading: boolean;
}

class UserPersonalRecords extends React.Component<IUserPersonalRecordsProps, IUserPersonalRecordsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

  }

  public render() {
    return (<span />);
    // return (
    //   <Card title="Personal Records" loading={this.state.loading} help="">
    //     Personal Records (COMING SOON!)
    //   </Card>
    // );
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPersonalRecords) as React.ComponentType<any>);