import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

import Card from "../../../structure/Card";
import DatePicker from "../../../structure/DatePicker";
import StateSelect from "../../../structure/StateSelect";
import BeltSelect from "../../../structure/BeltSelect";
import UserImage from "../../../structure/UserImage";
import * as AppActions from "../../../../reducers/appReducer";
import { UserAPI, BeltsAPI } from "../../../../API/";
import { error, success } from "../../../structure/Alert";

const blankProfile = {
  id: 0,
  firstName: "",
  lastName: "",
  nickname: "",
  created: moment(),
  dob: moment(),
  emailAddress: "",
  emailStatus: "pending",
  familyId: 0,
  lastLoggedIn: moment(),
  phone: "",
  receiveEmail: false,
  receiveSMS: false,
  smsStatus: "pending",
  username: "",
  password: "",
  verified: false,
  addressId: 0,
  addressStreet: "",
  addressCity: "",
  addressState: "AL",
  addressZip: "",
  schoolRole: "user",
  beltId: 0,
  beltName: "",
};

interface IUserProfileProps {
  appActions: any;
  history: any;
  schoolState: any;
  userId: number;
}

interface IUserProfileState {
  loading: boolean;
  id: number;
  firstName: string;
  lastName: string;
  nickname: string;
  created: moment.Moment;
  dob: moment.Moment;
  emailAddress: string;
  emailStatus: string;
  familyId: number;
  lastLoggedIn: moment.Moment;
  phone: string;
  receiveEmail: boolean;
  receiveSMS: boolean;
  smsStatus: string;
  username: string;
  password: string;
  verified: boolean;
  addressId: number;
  addressStreet: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  beltId: number;
  beltName: string;
  schoolRole: string;
  newBeltId: number;
  newSchoolRole: string;

  showPasswordModal: boolean;
  showRoleModal: boolean;
}

const helpText = `Here you can set basic information about the user. Clicking on the image will allow you to change the user's profile image. You can also change the user's password so they may log into the KDojang Student mobile application to access their assigned material and see their attendance.`;

class UserProfile extends React.Component<IUserProfileProps, IUserProfileState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      ...blankProfile,
      showPasswordModal: false,
      showRoleModal: false,
      newBeltId: blankProfile.beltId,
      newSchoolRole: blankProfile.schoolRole
    };

    this.fetchProfile = this.fetchProfile.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.updateField = this.updateField.bind(this);
    this.handleDOBChanged = this.handleDOBChanged.bind(this);
    this.togglePasswordChangeModal = this.togglePasswordChangeModal.bind(this);
    this.toggleShowRoleModal = this.toggleShowRoleModal.bind(this);
    this.savePassword = this.savePassword.bind(this);
  }

  public componentDidMount(){
    this.fetchProfile();
  }

  public componentWillReceiveProps(nextProps: any){
    if(nextProps.userId !== this.props.userId){
      this.fetchProfile();
    }
  }
  
  public render() {
    return (
      <Card title="Profile" loading={this.state.loading} help={helpText}>
      <div className="form-group">
        <UserImage
          userId={this.props.userId}
          schoolId={this.props.schoolState.school.id} 
          height={256}
          width={256}
          size="large"
          useSensor={false}/>
          <p className="small">Click the image to upload a new image</p>
      </div>
      <div className="form-group">
        <label>Username</label>
        <input type="text" id="username" className="form-control" disabled={true} value={this.state.username} />
      </div>
        <div className="form-group">
          <label>First Name</label>
          <input type="text" id="firstName" className="form-control" value={this.state.firstName} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input type="text" id="lastName" className="form-control" value={this.state.lastName} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Nickname</label>
          <input type="text" id="nickname" className="form-control" value={this.state.nickname} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" id="emailAddress" className="form-control" value={this.state.emailAddress} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Current School Role</label>
          {this.state.newSchoolRole === "administrator" && (<p><strong>Administrator</strong></p>)}
          {this.state.newSchoolRole !== "administrator" && (
            <select className="form-control" value={this.state.newSchoolRole} onChange={this.updateField} id="newSchoolRole">
              <option value="student">Student</option>
              <option value="assistant">Assistant</option>
              <option value="user">Non-Student User</option>
              <option value="inactive">Inactive</option>
            </select>
          )}
        </div>
        <div className="form-group">
          <label>Belt</label>
          <BeltSelect selectedBelt={this.state.newBeltId} onSelect={this.updateField} id="newBeltId" belts={this.props.schoolState.school.belts}/>
        </div>
        <div className="form-group">
          <label>Date of Birth</label>
          <DatePicker date={this.state.dob} onDateSaved={this.handleDOBChanged} />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input type="tel" id="phone" className="form-control" value={this.state.phone} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <label>Street Address</label>
          <input type="text" id="addressStreet" className="form-control" value={this.state.addressStreet} onChange={this.updateField} />
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-md-4">
              <label>City</label>
              <input type="text" id="addressCity" className="form-control" value={this.state.addressCity} onChange={this.updateField} />
            </div>
            <div className="col-md-4">
              <label>State</label>
              <StateSelect id="addressState" selectedState={this.state.addressState} onSelect={this.updateField} />
            </div>
            <div className="col-md-4">
              <label>Zip</label>
              <input type="text" id="addressZip" className="form-control" value={this.state.addressZip} onChange={this.updateField} />
            </div>
          </div>
        </div>
        <div className="form-group">
          <button className="btn btn-block btn-success" onClick={this.saveProfile}>Save User Profile</button>
          <button className="btn btn-block btn-primary" onClick={this.togglePasswordChangeModal}>Change User Password</button>
        </div>

        <Modal show={this.state.showPasswordModal} onHide={this.togglePasswordChangeModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Change User Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you absolutely sure you want to change the user's password? The password is used to login to the mobile Student app and other services.
            <div className="form-group">
              <label>New Password:</label>
              <input type="text" id="password" className="form-control" value={this.state.password} onChange={this.updateField} />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.savePassword}>Yes, Change the Password</button>
            <button className="btn btn-primary" onClick={this.togglePasswordChangeModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showRoleModal} onHide={this.toggleShowRoleModal} backdrop="static">
          <Modal.Header closeButton={true}>
            <Modal.Title>Role Changed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.newSchoolRole === "assistant" && (
              <p><strong>Permissions </strong> must be set on the user screen. By default, this new assistant will not have the required permissions until you activate them.
              You can do this by visiting their <Link to={`/schools/${this.props.schoolState.school.id}/users/${this.props.userId}/permissions`}>profile screen</Link>.</p>
            )}
            {this.state.newSchoolRole === "inactive" && (
              <p><strong>Warning </strong> even though you marked the user as inactive, we have <strong>not</strong> modified any of their financial records. If you need 
              to cancel their contract or invoice, please go to their <Link to={`/schools/${this.props.schoolState.school.id}/users/${this.props.userId}/finances`}>profile screen</Link>.</p>
            )}

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.toggleShowRoleModal}>Ok</button>
          </Modal.Footer>
        </Modal>
      </Card>
    );
  }


  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private togglePasswordChangeModal(){
    this.setState({showPasswordModal: !this.state.showPasswordModal});
  }

  private toggleShowRoleModal(){
    this.setState({showRoleModal: !this.state.showRoleModal});
  }

  private async fetchProfile(){
    this.setState({loading: true}, async () => {
      try{
        let profile: any = this.state;
        const profRet = await UserAPI.getUserProfile(this.props.schoolState.school.id, this.props.userId);
        profile = {...profRet.body.data};
        
        // parse to moment, etc
        profile.created = moment(profRet.body.data.created);
        profile.dob = moment(profRet.body.data.dob);
        profile.lastLoggedIn = moment(profRet.body.data.lastLoggedIn);
        profile.newBeltId = profile.beltId;
        profile.newSchoolRole = profile.schoolRole;
        
        this.setState({ ...profile, loading: false});
      }catch(err){
        return this.setState({loading: false}, () => {
          error("Could not load user profile");
        });
      }
    });
  }

  private async saveProfile(){
    const data: any = this.state;
    data.password = "";
    data.dob = data.dob.format("YYYY-MM-DD");
    this.setState({loading: true}, async () => {
      try{
        const newState: any = this.state;

        // first the role
        // we need to show the modal if we go from anything to inactive, or anything to assistant
        let showSuccess = true;
        if(this.state.newSchoolRole !== this.state.schoolRole){
          if(this.state.newSchoolRole === "assistant" || this.state.newSchoolRole === "inactive"){
            newState.showRoleModal = true;
            newState.schoolRole = newState.newSchoolRole;
            showSuccess = false;
            data.schoolRole = data.newSchoolRole;
          }
        }

        await UserAPI.saveUserProfile(this.props.schoolState.school.id, this.props.userId, data);
        // if the address isn't blank, save it
        if(this.state.addressStreet !== "" && this.state.addressCity !== "" && this.state.addressZip !== ""){
          const address = {
            street: this.state.addressStreet,
            city: this.state.addressCity,
            state: this.state.addressState,
            zip: this.state.addressZip
          };
          await UserAPI.saveUserAddress(this.props.schoolState.school.id, this.props.userId, address);
        }

        // now the belt
        if(this.state.beltId && this.state.newBeltId !== this.state.beltId){
          newState.beltId = this.state.newBeltId;
          await BeltsAPI.assignStudentToBelt(this.props.schoolState.school.id, this.props.userId, this.state.newBeltId);
        }

        

        newState.loading = false;
        newState.showPasswordModal = false;
        newState.password = "";

        this.setState(newState, () => {
          if(showSuccess){
            success("Success!");
          }
        });
      }catch(err){
        return this.setState({loading: false, showPasswordModal: false, password: ""}, () => {
          error("Could not save user profile");
        });
      }
    });
  }

  private async savePassword(){
    const data = {
      password: this.state.password
    };
    this.setState({loading: true}, async () => {
      try{
        await UserAPI.saveUserProfile(this.props.schoolState.school.id, this.props.userId, data);
        this.setState({loading: false, showPasswordModal: false, password: ""}, () => {
          success("Success!");
        });
      }catch(err){
        return this.setState({loading: false, showPasswordModal: false, password: ""}, () => {
          error("Could not save user password");
        });
      }
    });
  }

  private handleDOBChanged(newDate: moment.Moment) {
    const ns: any = this.state;
    ns.dob = newDate;
    this.setState(ns);
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile) as React.ComponentType<any>);