import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";

import Card from "../../structure/Card";
import { error, success } from "src/components/structure/Alert";
import Markdown from "../../structure/MarkdownTextarea";
import {MessagesAPI} from "../../../API";

interface ISendMessageScreenProps {
  schoolState: any;
}

interface ISendMessageScreenState {
  loading: boolean;
  subject: string;
  body: string;
  messageType: "email" | "sms";
  selectedUsers: any;
  toType: "select" | "group" | "allActive";
}

class SendMessageScreen extends React.Component<ISendMessageScreenProps, ISendMessageScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      subject: "",
      body: "",
      messageType: "email",
      selectedUsers: [],
      toType: "select"
    };

    this.updateField = this.updateField.bind(this);
    this.updateBody = this.updateBody.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleSelectStudent = this.handleSelectStudent.bind(this);
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-4 col-md-offset-1">
          <Card title="Users" loading={this.state.loading} help="If enabled as part of your plan, you can send messages to either all active users or individually selected users. The message can be sent via email or SMS. If SMS, please ensure you limit the message length, as not all users will have devices that can handle long messages.">
            <div className="form-group">
              <label>Who are you sending the message to?</label>
              <select id="toType" className="form-control" value={this.state.toType} onChange={this.updateField}>
                <option value="select">I Will Select</option>
                <option value="allActive">All Active Users</option>
              </select>
            </div>
            {this.state.toType === "select" && (
              <div className="form-group">
                <label>Select Users</label>
                <Typeahead
                  key={this.props.schoolState.users.length}
                  labelKey="fullName"
                  multiple={true}
                  placeholder="Enter User Name"
                  onChange={this.handleSelectStudent}
                  options={this.props.schoolState.users} />
              </div>
            )}
          </Card>
        </div>
        <div className="col-md-6">
          <Card title="Message" loading={this.state.loading} help="">
            <div className="form-group">
              <label>Message Type</label>
              <select id="messageType" className="form-control" value={this.state.messageType} onChange={this.updateField}>
                <option value="email">Email</option>
            <option value="sms" disabled={true}>SMS - Coming Soon</option>
              </select>
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input type="text" id="subject" className="form-control" value={this.state.subject} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Body</label>
            {this.state.messageType === "sms" ? 
              (<textarea id="body" className="form-control" value={this.state.body} onChange={this.updateField} rows={10}/>) : 
              (<Markdown content={this.state.body} onChange={this.updateBody} showToggle={true} mode="edit"  showHelp={true} />)
            }
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.sendMessage}>Send</button>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateBody(newBody: string){
    const ns: any = this.state;
    ns.body = newBody;
    this.setState(ns);
  }

  private handleSelectStudent(e: any){
    this.setState({selectedUsers: e});
  }

  private sendMessage(){
    // if the toType is allActive, we loop through
    let to = [];
    if(this.state.toType === "allActive"){
      for(const u of this.props.schoolState.users){
        if(u.role !== "inactive"){
          // don't bother if they don't have the relevant fields
          if(this.state.messageType === "sms"){
            if(u.phone !== "" && u.receiveSMS){
              to.push(u);
            }
          }else if(this.state.messageType === "email"){
            if(u.emailAddress !== "" && u.receiveEmail){
              to.push(u);
            }
          }
        }
      }
    } else if(this.state.toType === "select"){
      to = this.state.selectedUsers;
    }
    if(to.length === 0){
      return error("You must select more than one user for the message");
    }
    if(this.state.body === ""){
      return error("Body cannot be blank");
    }
    if(this.state.messageType === "email" && this.state.subject === ""){
      return error("Subject cannot be blank");
    }
    // well, just send it now
    // the users should be an array of user ids
    const users: number[] = [];
    for(const u of to){
      users.push(u.id);
    }
    const data = {
      subject: this.state.subject,
      body: this.state.body,
      users,
      messageType: this.state.messageType
    };
    this.setState({loading: true}, async () => {
      try{
        await MessagesAPI.sendMessageToUsers(this.props.schoolState.school.id, users, data);
        success("Message queued!");
        this.setState({ loading: false, subject: "", body: "", selectedUsers: []});
      }catch(err){
        error("Could not send the message");
        this.setState({loading: false, selectedUsers: []});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    schoolState: s.schoolState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendMessageScreen) as React.ComponentType<any>);