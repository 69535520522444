import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReactList from "react-list";
import { withRouter } from "react-router";
import * as moment from "moment";
import { CSVLink } from "react-csv";

import * as AppActions from "../../reducers/appReducer";

interface IListProps {
  data: any;
  showTotal: boolean;
  addToTotal: number;
  allowDownload: boolean;
  downloadData: any;
  downloadName: string;
  renderItem: any;
  maxHeight: number;
}

class List extends React.Component<IListProps, {}> {

  get total(){
    if(this.props.showTotal){
      const toAdd = this.props.addToTotal && !isNaN(this.props.addToTotal) ? this.props.addToTotal : 0;
      return (
        <div className="row">
          <div className="col-md-3 col-md-offset-9" style={{marginTop:15}}>
            <strong className="pull-right">Total: {this.props.data.length + toAdd}</strong>
          </div>
        </div>
      );
    }
    return (<span />);
  }

  get download(){
    if(this.props.allowDownload){
      const dataToUse = this.props.downloadData ? this.props.downloadData : this.props.data;
      const timestamp = moment().format("YYYY-MM-DD-HH-mm:SS");
      const fileName = this.props.downloadName ? `kdojang-${this.props.downloadName}-${timestamp}.csv` : `kdojang-data-${timestamp}.csv`;
      return (
        <div className="row">
          <div className="col-md-3 col-md-offset-9" style={{marginTop:15}}>
            <CSVLink data={dataToUse} filename={fileName} className="pull-right">Download Data</CSVLink>
          </div>
        </div>
      );
    }
    return null;
  }

  get list(){
    if(this.props.data.length === 0){
      return (
        <div>
          There are no items to display
        </div>
      );
    }
    return (
      <div>
        <div style={{overflowY: "auto", overflowX: "hidden", maxHeight: this.props.maxHeight ? this.props.maxHeight : 400}}>
          <ReactList 
            itemRenderer={this.props.renderItem}
            length={this.props.data.length}
            type="simple"
          />
        </div>
        {this.total}
        {this.download}
      </div>
    );
  }

  public render(){
    return this.list;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(List));