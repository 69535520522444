import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { FinancesAPI, ScheduleAPI, SchoolUsersAPI, NewsAPI, SchoolsAPI } from "../../../API/";
import { error } from "../../structure/Alert";
import BarGraph from "../../structure/BarGraph";
import NewsItem from "./NewsItem";
import List from "src/components/structure/List";

const start = moment().subtract(14, "days").format("YYYY-MM-DDT00:00:00")+"Z";
const end = moment().add(1, "day").format("YYYY-MM-DDT00:00:00Z");
const lastYearStart = moment().subtract(1, "year").format("YYYY-MM-DDT00:00:00")+"Z";

interface IDashboardProps {
  appActions: any;
  schoolState: any;
  history: any;
}

interface IDashboardState {
  newsLoading: boolean;
  invoicesLoading: boolean;
  attendancLoading: boolean;
  newUsersLoading: boolean;
  logsLoading: boolean;
  expiringLoading: boolean;
  expiringThreshold: number;
  invoices: any;
  attendance: any;
  users: any;
  news: any;
  logs: any;
  contracts: any;
  logCategories: any[];
  selectedCategory: string;
  filteredLogs: any[];
}

class DashboardScreen extends React.Component<IDashboardProps, IDashboardState> {

  constructor(props: any){
    super(props);
    this.state = {
      newsLoading: true,
      invoicesLoading: true,
      attendancLoading: true,
      newUsersLoading: true,
      logsLoading: true,
      expiringLoading: true,
      invoices: [],
      attendance: [],
      users: [],
      news: [],
      logs: [],
      contracts: [],
      expiringThreshold: 30,
      logCategories: ["all"],
      selectedCategory: "all",
      filteredLogs: [],
    };

    this.fetchDashboardData = this.fetchDashboardData.bind(this);
    this.fetchAttendance = this.fetchAttendance.bind(this);
    this.fetchInvoicesDue = this.fetchInvoicesDue.bind(this);
    this.fetchNewUsers = this.fetchNewUsers.bind(this);
    this.fetchLogs = this.fetchLogs.bind(this);
    this.fetchNews = this.fetchNews.bind(this);
    this.fetchExpiringContracts = this.fetchExpiringContracts.bind(this);
    this.toggleExpiringThreshold = this.toggleExpiringThreshold.bind(this);
    this.toggleSelectedLogFilter = this.toggleSelectedLogFilter.bind(this);
    this.filterLogs = this.filterLogs.bind(this);
    this.renderLog = this.renderLog.bind(this);
    this.renderContract = this.renderContract.bind(this);
  }

  public componentDidMount(){
    this.fetchDashboardData();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <Card title="System News" loading={this.state.newsLoading} help="Here you will find system-wide news that may be relevant to your usage of the application. Often, new features are announced here.">
              {this.state.news.map((news: any, index: number) => {
                return (
                  <NewsItem
                    key={news.id}
                    id={news.id}
                    title={news.title}
                    body={news.body}
                    posted={moment(news.posted).format("MM/DD/YYYY")}
                    className={index % 2 === 0 ? "even" : "odd"}
                    />
                );
              })}
            </Card>
          </div>
          <div className="col-md-3">
            <Card title="Invoices Due" loading={this.state.invoicesLoading} help="Below is a quick summary of the amount of money due via invoices over the previous timeframe.">
              <BarGraph data={this.state.invoices} hideLegend={true} />
            </Card>
          </div>
          <div className="col-md-3">
            <Card title="Attendance" loading={this.state.attendancLoading} help="Below is a quick summary of the school attendance over the previous timeframe.">
              <BarGraph data={this.state.attendance} hideLegend={true} />
            </Card>
          </div>
          <div className="col-md-3">
            <Card title="New Students" loading={this.state.newUsersLoading} help="Below is a quick summary of the number of new students and users over the previous timeframe.">
              <BarGraph data={this.state.users} hideLegend={true} />
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5">
            <Card title="Contracts Ending Soon" loading={this.state.expiringLoading}>
                <div className="row" style={{marginTop: 5, marginBottom: 10}}>
                  <div className="col-lg-12">
                    <select className="form-control" id="expiringThreshold" value={this.state.expiringThreshold} onChange={this.toggleExpiringThreshold}>
                      <option value={7}>Expiring Within 7 Days</option>
                      <option value={14}>Expiring Within 14 Days</option>
                      <option value={30}>Expiring Within 30 Days</option>
                      <option value={60}>Expiring Within 60 Days</option>
                      <option value={90}>Expiring Within 90 Days</option>
                    </select>
                  </div>
                </div>
                <div className="row row-header">
                  <div className="col-lg-3">
                    Started
                  </div>
                  <div className="col-lg-3">
                    Days Left
                  </div>
                  <div className="col-lg-6">
                    Students
                  </div>
                </div>
                <List
                    data={this.state.contracts}
                    renderItem={this.renderContract}
                    showTotal={true}
                  />
            </Card>
          </div>
          <div className="col-lg-7">
            <Card title="School Logs" loading={this.state.logsLoading} help="Below is a list of the most recent logs for your school.">
                <div className="row" style={{marginTop: 5, marginBottom: 10}}>
                  <div className="col-lg-12">
                    <select className="form-control" id="selectedCategory" value={this.state.selectedCategory} onChange={this.toggleSelectedLogFilter}>
                      {this.state.logCategories.map((c: string) => {
                        return <option key={c} value={c}>Show {SchoolsAPI.schoolLogEventTypeToDisplay(c)}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <List
                    data={this.state.filteredLogs}
                    renderItem={this.renderLog}
                    showTotal={true}
                  />
            </Card>
          </div>
        </div>
      </div>
    );
  }

  private async fetchDashboardData(){
    // TODO: break this out into separate try catches so that a failure on one doesn't bork them all
    // this.setState({loading: true}, async () => {    
    //   try{
    //     let schoolId = this.props.schoolState.school.id ? this.props.schoolState.school.id : 0;
    //     if(!schoolId || schoolId === 0){
    //       // there is a chance that the redux store has not been set, so we need to grab it from local storage
    //       schoolId = window.localStorage.schoolId ? window.localStorage.schoolId : 0;
    //     }
    //     const invRes = await FinancesAPI.getSchoolInvoiceDashboard(schoolId, start, end);
    //     const attRes = await ScheduleAPI.getEventsAttendanceDashboard(schoolId, start, end);
    //     const usersRes = await SchoolUsersAPI.getNewUsersDashboard(schoolId, lastYearStart, end);
    //     const newsRes = await NewsAPI.getNews();
    //     const logsRes = await SchoolsAPI.getSchoolUserLogs(schoolId, 0, start, end, "all");
    //     const contractsRes = await FinancesAPI.getSchoolContractsExpiringSoon(schoolId, 7);

    //     // now process the data
    //     const invoices: any = {
    //       data: [],
    //       total: 0
    //     };
    //     for(const i of invRes.body.data.data){
    //       i.label = moment(i.day).format("MM/DD");
    //       i.count = i.count / 100;
    //       invoices.data.push(i);
    //     }

    //     const attendance: any = {
    //       data: [],
    //       total: 0
    //     };
    //     for(const i of attRes.body.data.data){
    //       i.label = moment(i.day).format("MM/DD");
    //       i.count = i.count;
    //       attendance.data.push(i);
    //     }

    //     const users: any = {
    //       data: [],
    //       total: 0
    //     };
    //     for(const i of usersRes.body.data.data){
    //       i.label = moment(i.day).format("MM/YY");
    //       i.count = i.count;
    //       users.data.push(i);
    //     }

    //     const logs: any = logsRes.body.data.sort((a: any, b: any) => {
    //       const aDate = moment(a.occurredOnDate + "T" + a.occurredOnTime + "Z");
    //       const bDate = moment(b.occurredOnDate + "T" + b.occurredOnTime + "Z");
    //       return aDate.isBefore(bDate) ? 1 : -1;
    //     });

    //     const contracts: any = contractsRes.body.data.sort((a: any, b: any) => {
    //       return a.daysRemaining > b.daysRemaining ? 1 : -1;
    //     });

    //     this.setState({
    //       loading: false,
    //       invoices,
    //       attendance,
    //       users,
    //       news: newsRes.body.data,
    //       logs,
    //       contracts,
    //     });
    //   }catch(err){
    //     return this.setState({loading: false}, () => {
    //       error("Could not load the dashboard");
    //     });
    //   }
    // });
    await this.fetchNews();
    await this.fetchLogs();
    await this.fetchExpiringContracts();
    await this.fetchInvoicesDue();
    await this.fetchAttendance();
    await this.fetchNewUsers();
  }

  private async fetchNews(){
    this.setState({newsLoading: true}, async () => {
      try{
        const newsRes = await NewsAPI.getNews();
        this.setState({newsLoading: false, news: newsRes.body.data,});
      }catch(err){
        return this.setState({newsLoading: false}, () => {
          error("Could not load the news");
        });
      }
    });
  }

  private async fetchLogs(){
    this.setState({logsLoading: true}, async () => {
      try{
        let schoolId = this.props.schoolState.school.id ? this.props.schoolState.school.id : 0;
        if(!schoolId || schoolId === 0){
          // there is a chance that the redux store has not been set, so we need to grab it from local storage
          schoolId = window.localStorage.schoolId ? window.localStorage.schoolId : 0;
        }
        const categories = ["all"];
        const categoryMap: any= {};
        const logsRes = await SchoolsAPI.getSchoolUserLogs(schoolId, 0, start, end, "all");
        const logs: any = logsRes.body.data.sort((a: any, b: any) => {
          const aDate = moment(a.occurredOnDate + "T" + a.occurredOnTime + "Z");
          const bDate = moment(b.occurredOnDate + "T" + b.occurredOnTime + "Z");
          return aDate.isBefore(bDate) ? 1 : -1;
        });
        for(const l of logs){
          if(!categoryMap[l.event]){
            categoryMap[l.event] = true;
            categories.push(l.event);
          }
        }
        this.setState({logsLoading: false, logs, logCategories: categories}, () => {
          this.filterLogs();
        });
      }catch(err){
        return this.setState({logsLoading: false}, () => {
          error("Could not load the logs");
        });
      }
    });
  }

  private async fetchExpiringContracts(){
    this.setState({expiringLoading: true}, async () => {
      try{
        let schoolId = this.props.schoolState.school.id ? this.props.schoolState.school.id : 0;
        if(!schoolId || schoolId === 0){
          // there is a chance that the redux store has not been set, so we need to grab it from local storage
          schoolId = window.localStorage.schoolId ? window.localStorage.schoolId : 0;
        }
        const contractsRes = await FinancesAPI.getSchoolContractsExpiringSoon(schoolId, this.state.expiringThreshold);
        const contracts: any = contractsRes.body.data.sort((a: any, b: any) => {
          return a.daysRemaining > b.daysRemaining ? 1 : -1;
        });
        this.setState({expiringLoading: false, contracts});

      }catch(err){
        return this.setState({expiringLoading: false}, () => {
          error("Could not load the expiring contracts");
        });
      }
    });
  }

  private async fetchInvoicesDue(){
    this.setState({invoicesLoading: true}, async () => {
      try{
        let schoolId = this.props.schoolState.school.id ? this.props.schoolState.school.id : 0;
        if(!schoolId || schoolId === 0){
          // there is a chance that the redux store has not been set, so we need to grab it from local storage
          schoolId = window.localStorage.schoolId ? window.localStorage.schoolId : 0;
        }
        const invRes = await FinancesAPI.getSchoolInvoiceDashboard(schoolId, start, end);
        const invoices: any = {
          data: [],
          total: 0
        };
        for(const i of invRes.body.data.data){
          i.label = moment(i.day).format("MM/DD");
          i.count = i.count / 100;
          invoices.data.push(i);
        }
        this.setState({invoicesLoading: false, invoices});

      }catch(err){
        return this.setState({invoicesLoading: false}, () => {
          error("Could not load the due invoices");
        });
      }
    });
  }

  private async fetchAttendance(){
    this.setState({attendancLoading: true}, async () => {
      try{
        let schoolId = this.props.schoolState.school.id ? this.props.schoolState.school.id : 0;
        if(!schoolId || schoolId === 0){
          // there is a chance that the redux store has not been set, so we need to grab it from local storage
          schoolId = window.localStorage.schoolId ? window.localStorage.schoolId : 0;
        }
        const attRes = await ScheduleAPI.getEventsAttendanceDashboard(schoolId, start, end);

        const attendance: any = {
          data: [],
          total: 0
        };
        for(const i of attRes.body.data.data){
          i.label = moment(i.day).format("MM/DD");
          i.count = i.count;
          attendance.data.push(i);
        }
        this.setState({attendancLoading: false, attendance});

      }catch(err){
        return this.setState({attendancLoading: false}, () => {
          error("Could not load the attendance");
        });
      }
    });
  }

  private async fetchNewUsers(){
    this.setState({newUsersLoading: true}, async () => {
      try{
        let schoolId = this.props.schoolState.school.id ? this.props.schoolState.school.id : 0;
        if(!schoolId || schoolId === 0){
          // there is a chance that the redux store has not been set, so we need to grab it from local storage
          schoolId = window.localStorage.schoolId ? window.localStorage.schoolId : 0;
        }
        const usersRes = await SchoolUsersAPI.getNewUsersDashboard(schoolId, lastYearStart, end);

        const users: any = {
          data: [],
          total: 0
        };
        for(const i of usersRes.body.data.data){
          i.label = moment(i.day).format("MM/YY");
          i.count = i.count;
          users.data.push(i);
        }
        this.setState({newUsersLoading: false, users});

      }catch(err){
        return this.setState({newUsersLoading: false}, () => {
          error("Could not load the new users");
        });
      }
    });
  }

    private renderLog(logIndex: any){
      const classes: string = "row list-row";
      const log = this.state.filteredLogs[logIndex];
      const typeDisplay = SchoolsAPI.schoolLogEventTypeToDisplay(log.event);
      const dateDisplay = moment(log.occurredOnDate + "T" + log.occurredOnTime + "Z").format("MM/DD/YYYY hh:mm A");

      return (
        <div className={classes} key={logIndex}>
          <div className="col-lg-3">
            {dateDisplay}
          </div>
          <div className="col-lg-3">
            {typeDisplay}
          </div>
          <div className="col-lg-6">
            {log.details}
          </div>
        </div>
      );
    }

    private renderContract(contractIndex: any){
      const classes: string = "row list-row";
      const c = this.state.contracts[contractIndex];
      const dateDisplay = moment(c.startDate).format("MM/DD/YYYY");

      return (
        <div className={classes} key={contractIndex}>
          <div className="col-lg-3">
            {dateDisplay}
          </div>
          <div className="col-lg-3">
            {c.daysRemaining}
          </div>
          <div className="col-lg-6">
            {c.users.map((student: any, index: number) => {
              return <Link to={`/schools/${c.schoolId}/users/${student.id}/finances`} key={student.id}>{index > 0 ? ", " : ""}{student.firstName} {student.lastName}</Link>;
            })}
          </div>
        </div>
      );
    }

    private toggleExpiringThreshold(e: any){
      this.setState({
        expiringThreshold: e.target.value,
      }, async () => {
        await this.fetchExpiringContracts();
      });
    }

    private toggleSelectedLogFilter(e: any){
      this.setState({
        selectedCategory: e.target.value,
      }, async () => {
        await this.filterLogs();
      });
    }

    private filterLogs(){
      this.setState({ logsLoading: true, filteredLogs: []}, () => {
        const filteredLogs = [];
        for(const log of this.state.logs){
          if(this.state.selectedCategory === "all" || this.state.selectedCategory === log.event){
            filteredLogs.push(log);
          }
        } 
        this.setState({filteredLogs, logsLoading: false});
      });
    }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardScreen) as React.ComponentType<any>);