import { makeCall } from "./client";

export class NewsAPI {
  
  /**
   * Gets the news
   */
  public getNews(params: any = {}): Promise<any> {
    return makeCall("get", "news", params);
  }

  public getSchoolNews(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/news`, params);
  }

  /**
   * Creates a new news announcement
   * @param schoolId 
   * @param params 
   */
  public createSchoolNews(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/news`, params);
  }

  public deleteSchoolNews(schoolId: number,  newsId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/news/${newsId}`, params);
  }
}