import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { capFirstLetter } from "../../../../utils";

interface IUserMaterial {
  content:  string;
  groupId: number;
  groupName: string;
  groupType: string;
  materialId: number;
  status: string;
  statusDate: string;
  title: string;
  verifiedCompleteBy: number;
}


interface IUserMaterialProps {
  appActions: any;
  history: any;
  className: string;
  material: IUserMaterial;
  onChangeStatus: any;
  onRemove: any;
}

interface IUserMaterialState {
  loading: boolean;
  showRemoveModal: boolean;
}

class UserMaterial extends React.Component<IUserMaterialProps, IUserMaterialState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showRemoveModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.toggleRemove = this.toggleRemove.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  public render() {
    return (
        <div className={"row list-row " + this.props.className}>
          <div className="col-md-3 vertical-container">
            {this.props.material.groupName}
          </div>
          <div className="col-md-1 vertical-container">
            {capFirstLetter(this.props.material.groupType)}
          </div>
          <div className="col-md-4 vertical-container">
            {this.props.material.title}
          </div>
          <div className="col-md-3 vertical-container">
            <select className="form-control" id="status" value={this.props.material.status} onChange={this.handleStatusChange}>
              <option value="assigned">Assigned</option>
              <option value="pending_verification">Pending Instructor Verification</option>
              <option value="completed">Completed</option>
            </select>
          </div>
          <div className="col-md-1 vertical-container">
            <span className="text-danger glyphicon glyphicon-trash" onClick={this.toggleRemove} />
          </div>

          <Modal show={this.state.showRemoveModal} onHide={this.toggleRemove} backdrop="static">
            <Modal.Header closeButton={true}>
              <Modal.Title>Remove Material</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Warning! This will remove the assigned material "{this.props.material.title}" from the student. Are you sure you want to do that?

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-success" onClick={this.handleRemove}>Yes, Remove It</button>
              <button className="btn btn-primary" onClick={this.toggleRemove}>Nevermind</button>
            </Modal.Footer>
          </Modal>
        </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleRemove(){
    this.setState({ showRemoveModal: !this.state.showRemoveModal});
  }

  private handleStatusChange(e: any){
    const newStatus = e.target.value;
    this.props.onChangeStatus(this.props.material.groupId, this.props.material.materialId, newStatus);
  }

  private handleRemove(){
    this.props.onRemove(this.props.material.groupId, this.props.material.materialId);
    this.setState({showRemoveModal: false});
  }


}


const mapStateToProps = function map(s: any) {
  return {};
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMaterial) as React.ComponentType<any>);