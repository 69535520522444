import { makeCall } from "./client";

export class KnowledgeAPI {

  /**
   * Get the material groups for the school
   * @param schoolId 
   * @param params 
   */
  public getMaterialGroups(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/materials`, params);
  }

  /**
   * Create a new material group
   * @param schoolId 
   * @param params 
   */
  public createMaterialGroup(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/materials/groups`, params);
  }

  /**
   * Get a single material group
   * @param schoolId 
   * @param groupId 
   * @param params 
   */
  public getMaterialGroup(schoolId: number, groupId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/materials/groups/${groupId}`, params);
  }

  /**
   * Update a single material group
   * @param schoolId 
   * @param groupId 
   * @param params 
   */
  public updateMaterialGroup(schoolId: number, groupId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/materials/groups/${groupId}`, params);
  }

  /**
   * Updates the group order
   * @param schoolId 
   * @param params 
   */
  public updateMaterialGroupOrder(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/materials/groups`, params);
  }

  /**
   * Delete a material group
   * @param schoolId 
   * @param groupId 
   * @param params 
   */
  public deleteMaterialGroup(schoolId: number, groupId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/materials/groups/${groupId}`, params);
  }

  /**
   * Create a new piece of material
   * @param schoolId 
   * @param groupId 
   * @param params 
   */
  public createMaterial(schoolId: number, groupId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/materials/groups/${groupId}`, params);
  }

  /**
   * Get the material
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public getMaterial(schoolId: number, groupId: number, materialId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/materials/groups/${groupId}/${materialId}`, params);
  }

  /**
   * Update a single piece of material
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public updateMaterial(schoolId: number, groupId: number, materialId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/materials/groups/${groupId}/${materialId}`, params);
  }

  /**
   * Delete a piece of material
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public deleteMaterial(schoolId: number, groupId: number, materialId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/materials/groups/${groupId}/${materialId}`, params);
  }

  /**
   * Get materials for a student
   * @param schoolId 
   * @param userId 
   * @param params 
   */
  public getMaterialsForStudent(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/materials`, params);
  }

  /**
   * Assign a group of materials to a student
   * @param schoolId 
   * @param userId 
   * @param groupId 
   * @param params 
   */
  public assignMaterialGroupToStudent(schoolId: number, userId: number, groupId: number, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/materials/groups/${groupId}`, params);
  }

  /**
   * Remove a material group from a student
   * @param schoolId 
   * @param userId 
   * @param groupId 
   * @param params 
   */
  public removeMaterialGroupFromStudent(schoolId: number, userId: number, groupId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/users/${userId}/materials/groups/${groupId}`, params);
  }

  /**
   * Update the status of a material group for a student
   * @param schoolId 
   * @param userId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public updateMaterialAssignmentForStudent(schoolId: number, userId: number, groupId: number, materialId: number, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/materials/groups/${groupId}/${materialId}`, params);
  }

  /**
   * Remove a single piece of material from a student
   * @param schoolId 
   * @param userId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public removeSingleMaterialFromStudent(schoolId: number, userId: number, groupId: number, materialId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/users/${userId}/materials/groups/${groupId}/${materialId}`, params);
  }

  /**
   * Get all of the external attachments for a piece of material
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public getAttachmentsForMaterial(schoolId: number, groupId: number, materialId: number, params: any ={}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/materials/groups/${groupId}/${materialId}/attachments`, params);
  }

  /**
   * Create a new attachment for material, such as a link to a Youtube video
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param params 
   */
  public createAttachmentForMaterial(schoolId: number, groupId: number, materialId: number, params: any ={}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/materials/groups/${groupId}/${materialId}/attachments`, params);
  }

  /**
   * Update an attachment
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param attachmentId 
   * @param params 
   */
  public updateAttachmentForMaterial(schoolId: number, groupId: number, materialId: number, attachmentId: number, params: any ={}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/materials/groups/${groupId}/${materialId}/attachments/${attachmentId}`, params);
  }

  /**
   * Delete an attachment
   * @param schoolId 
   * @param groupId 
   * @param materialId 
   * @param attachmentId 
   * @param params 
   */
  public deleteAttachmentForMaterial(schoolId: number, groupId: number, materialId: number, attachmentId: number, params: any ={}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/materials/groups/${groupId}/${materialId}/attachments/${attachmentId}`, params);
  }

}