import { makeCall } from "./client";

export class SchoolUsersAPI {

  /**
   * Get the users in a school
   * @param schoolId 
   * @param params 
   */
  public getUsersInSchool(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users`, params);
  }

  /**
   * Create a new school user
   * @param schoolId 
   * @param params 
   */
  public createNewSchoolUser(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/users`, params);
  }

  /**
   * Gets the new users dashboard data
   * @param schoolId 
   * @param start 
   * @param end 
   * @param params 
   */
  public getNewUsersDashboard(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      start, 
      end
    };
    return makeCall("get", `schools/${schoolId}/users/dashboard`, params);
  }

  /**
   * Get the families in a school
   * @param schoolId 
   * @param params 
   */
  public getFamiliesInSchool(schoolId: number, status: string = "active", params: any = {}): Promise<any> {
    const data = {
      ...params,
      status
    };
    return makeCall("get", `schools/${schoolId}/families`, data);
  }

  /**
   * Create a new family
   * @param schoolId 
   * @param params 
   */
  public createFamily(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/families`, params);
  }

  /**
   * Update a family
   * @param schoolId 
   * @param familyId 
   * @param params 
   */
  public updateFamily(schoolId: number, familyId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/families/${familyId}`, params);
  }

  /**
   * Gets a family
   * @param schoolId 
   * @param familyId 
   * @param params 
   */
  public getFamily(schoolId: number, familyId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/families/${familyId}`, params);
  }

  /**
   * Add a user to a family
   * @param schoolId 
   * @param familyId 
   * @param userId 
   * @param asHeadAccount 
   * @param params 
   */
  public addUserToFamily(schoolId: number, familyId: number, userId: number, asHeadAccount: boolean = false, params: any = {}): Promise<any> {
    params = {
      ...params,
      asHeadAccount
    };
    return makeCall("put", `schools/${schoolId}/families/${familyId}/users/${userId}`, params);
  }

  /**
   * Update a user's role in the school
   * @param schoolId 
   * @param userId 
   * @param role 
   * @param params 
   */
  public updateUserRole(schoolId: number, userId: number, role: string, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/role/${role}`, params);
  }
}